// component's config object.
const components = {
	academicYear: {
		component: 'AcademicYear',
		url: '/academic-year',
		title: 'Academic Year',
		icon: 'nav-icon fa fa-cog  text-sm',
		inMenu:true,
		ismodule:false
	},
	classSection: {
		component: 'ClassSection',
		url: '/class-section',
		title: 'Class Section',
		icon: 'nav-icon fa fa-cog  text-sm',
		inMenu:true,
		ismodule:false
	},
	dashboard: {
		component: 'Dashboard',
		url: '/dashboard',
		title: 'Dashboard',
		icon: 'menu',
		inMenu:true,
		module: "0"
	},
	classSubject: {
		component: 'ClassSubject',
		url: '/class-subject',
		title: 'Class Subject',
		icon: 'nav-icon fa fa-cog  text-sm',
		inMenu:true,
		ismodule:false
	},
	optionalSubject: {
		component: 'OptionalSubject',
		url: '/optional-subject',
		title: 'Optional Subject',
		icon: 'nav-icon fa fa-cog  text-sm',
		inMenu:true,
		ismodule:false
	},
	optionalSubjectBatch: {
		component: 'OptionalSubjectBatch',
		url: '/optional-subject-batch',
		title: 'Optional Subject Batch',
		icon: 'nav-icon fa fa-users  text-sm',
		inMenu:true,
		ismodule:false
	},
	classNoteAdmin: {
		component: 'AdminClassNote',
		url: '/class-note-admin',
		title: 'Class Note',
		icon: 'nav-icon far fa-sticky-note  text-sm',
		inMenu:true,
		ismodule:false
	},
	assignmentsAdmin: {
		component: 'AdminAssignment',
		url: '/assignment-admin',
		title: 'Assignments',
		icon: 'nav-icon fa fa-puzzle-piece  text-sm',
		inMenu:true,
		ismodule:false
	},
	questionBankAdmin: {
		component: 'AdminQuestionList',
		url: '/question-list-admin',
		title: 'Question Bank',
		icon: 'nav-icon far fa-list-alt  text-sm',
		inMenu:true,
		ismodule:false
	},
	student : {
		component: 'Student',
		url: '/student',
		title: 'Student List',
		icon: 'nav-icon fa fa-user-plus  text-sm',
		inMenu:true,
		ismodule:false
	},
	studentList : {
		component: 'StudentList',
		url: '/student-list',
		title: 'Student Profile',
		icon: 'nav-icon fa fa-user  text-sm',
		inMenu:true,
		ismodule:false
	},
	difficultyLevel : {
		component: 'DifficultyLevel',
		url: '/difficulty-level',
		title: 'Difficulty Level',
		icon: 'nav-icon fa fa-cog  text-sm',
		inMenu:true,
		ismodule:false
	},
	courseLevel : {
		component: 'CourseLevel',
		url: '/course-level',
		title: 'Course Level',
		icon: 'nav-icon fa fa-share-alt  text-sm',
		inMenu:true,
		ismodule:false
	},
	courseItem : {
		component: 'CourseItem',
		url: '/course-item',
		title: 'Course Item',
		icon: 'nav-icon fa fa-book  text-sm',
		inMenu:true,
		ismodule:false
	},	
	courseItemStaff : {
		component: 'CourseItemStaff',
		url: '/course-item-staff',
		title: 'Course Item',
		icon: 'nav-icon fa fa-book  text-sm',
		inMenu:true,
		ismodule:false
	},
	studentAttendaceReprt : {
		component: 'StudentAttenceReport',
		url: '/attendance-report',
		title: 'Attendance Summary',
		icon: 'nav-icon fa fa-file-pdf  text-sm',
		inMenu:true,
		ismodule:false
	},
	studentAttendanceReportStaff : {
		component: 'StudentAttendenceReportStaff',
		url: '/attendance-report-staff',
		title: 'Attendance Summary',
		icon: 'nav-icon fa fa-file-pdf  text-sm',
		inMenu:true,
		ismodule:false
	},
	individualAttendanceReport : {
		component: 'IndividualAttendanceReport',
		url: '/single-student-report',
		title: 'Student Attendance',
		icon: 'nav-icon fa fa-file-code  text-sm',
		inMenu:true,
		ismodule:false
	},
	individualAttendanceReportStaff : {
		component: 'IndividualAttendanceReportStaff',
		url: '/single-student-report-staff',
		title: 'Student Attendance',
		icon: 'nav-icon fa fa-file-code  text-sm',
		inMenu:true,
		ismodule:false
	},
	attendanceReportStudentView : {
		component: 'AttendanceReportStudentView',
		url: '/attendace-report-student',
		title: 'Attendance Report',
		icon: 'nav-icon fa fa-file-code  text-sm',
		inMenu:true,
		ismodule:false
	},
	detailedAttendaceReport : {
		component: 'DetailedAttendanceReport',
		url: '/attendance-report-detail',
		title: 'Attendance Details',
		icon: 'nav-icon fa fa-file-archive  text-sm',
		inMenu:true,
		ismodule:false
	},
	detailedAttendaceReportStaff : {
		component: 'DetailedAttendanceReportStaff',
		url: '/attendance-report-detail-staff',
		title: 'Attendance Details',
		icon: 'nav-icon fa fa-file-archive  text-sm',
		inMenu:true,
		ismodule:false
	},
	emailConfiguration : {
		component: 'EmailConfiguration',
		url: '/email-setting',
		title: 'Email Configuration',
		icon: 'nav-icon fa fa-cog  text-sm',
		inMenu:true,
		ismodule:false
	},
	staffClassRoom: {
		component: 'StaffClassRoom',
		url: '/staff-class-room',
		title: 'Class Room',
		icon: 'menu',
		inMenu:true,
		ismodule:false
	},
	teacher: {
		component: 'Teacher',
		url: '/teacher',
		title: 'Teacher List',
		icon: 'nav-icon fa fa-user-plus  text-sm',
		inMenu:true,
		ismodule:false
	},
	teacherList: {
		component: 'TeacherList',
		url: '/teacher-list',
		title: 'Teacher Profile',
		icon: 'nav-icon fa fa-user  text-sm',
		inMenu:true,
		ismodule:false
    },
    teacherAssociation: {
		component: 'TeacherAssociation',
		url: '/teacher-association',
		title: 'Teacher Association',
		icon: 'nav-icon fa fa-sitemap  text-sm',
		inMenu:true,
		ismodule:false
	},
	studentNotification: {
		component: 'SendStudentNotification',
		url: '/student-notification',
		title: 'Student Email',
		icon: 'nav-icon far fa-envelope   text-sm',
		inMenu:true,
		ismodule:false
	},
	studentNotificationTeacher: {
		component: 'SendStudentNotificationTeacher',
		url: '/student-notification-teacher',
		title: 'Student Email',
		icon: 'nav-icon far fa-envelope   text-sm',
		inMenu:true,
		ismodule:false
	},
	teacherNotification: {
		component: 'SendTeacherNotification',
		url: '/teacher-notification',
		title: 'Teacher Email',
		icon: 'nav-icon far fa-envelope  text-sm',
		inMenu:true,
		ismodule:false
    },
	newExam: {
		component: 'NewExam',
		url: '/newexam',
		title: 'New Exam',
		icon: 'menu',
		inMenu:true,
		module: "0"
	},
	examAdmin: {
		component: 'AdminExamination',
		url: '/exam-list',
		title: 'Exam',
		icon: 'nav-icon fa fa-list  text-sm',
		inMenu:true,
		ismodule:false
	},
	examquestionSetting: {
		component: 'ExamQuestionSetting',
		url: '/examquestionsettings/:id',
		title: 'Exam',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	questionSetting: {
		component: 'QuestionSettings',
		url: '/questionsettings/:id',
		title: 'Exam',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	questionPaperAdmin: {
		component: 'AdminQuestionPaper',
		url: '/admin-question-paper',
		title: 'Question Paper',
		icon: 'nav-icon far fa-file  text-sm',
		inMenu:true,
		ismodule:false
	},
	questionPaper: {
		component: 'QuestionPaper',
		url: '/question-paper',
		title: 'Question Paper',
		icon: 'nav-icon far fa-file  text-sm',
		inMenu:true,
		ismodule:false
	},
	questionpaperView: {
		component: 'ViewQuestionPaper',
		url: '/view-question-paper/:id',
		title: 'Exam',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
    classNote : {
		component: 'ClassNote',
		url: '/class-note',
		title: 'Class Note ',
		icon: 'nav-icon far fa-sticky-note  text-sm',
		inMenu:true,
		ismodule:false
	},
	studentClassNote : {
		component: 'StudentClassNote',
		url: '/student-class-note',
		title: 'Class Note ',
		icon: 'nav-icon far fa-sticky-note  text-sm',
		inMenu:true,
		ismodule:false
	},
	publishClassNote : {
		component: 'PublishClassNote',
		url: '/publish-class-note',
		title: 'Class Note ',
		icon: 'menu',
		inMenu:false,
		module: "0"
    },
    assignmentTask : {
		component: 'AssignmentTask',
		url: '/assignment-task',
		title: 'Assignment ',
		icon: 'nav-icon fa fa-puzzle-piece  text-sm',
		inMenu:true,
		ismodule:false
	},
	questionBank : {
		component: 'QuestionBank',
		url: '/question-bank',
		title: 'List Questions',
		icon: 'menu',
		inMenu:true,
		module: 'module1'
	},
	listQuestions : {
		component: 'Questions',
		url: '/list-question',
		title: 'Questions List',
		icon: 'nav-icon far fa-list-alt  text-sm',
		inMenu:true,
		ismodule:false
	},
	oneWord : {
		component: 'OneWordQuestion',
		url: '/one-word',
		title: 'One Word Question ',
		icon: 'nav-icon fa fa-question  text-sm',
		inMenu:true,
		ismodule:false
	},
	multipleChoice : {
		component: 'MultipleChoiceQuestion',
		url: '/multiple-choice',
		title: 'Multiple Choice Question ',
		icon: 'nav-icon fa fa-question  text-sm',
		inMenu:true,
		ismodule:false
	},
	paragraph : {
		component: 'ParagraphQuestion',
		url: '/paragraph',
		title: 'Paragraph Question ',
		icon: 'nav-icon fa fa-question  text-sm',
		inMenu:true,
		ismodule:false
	},
	essay : {
		component: 'EssayQuestion',
		url: '/essay',
		title: 'Essay Question ',
		icon: 'nav-icon fa fa-question  text-sm',
		inMenu:true,
		ismodule:false
	},
    qASession : {
		component: 'QandASession',
		url: '/qa-session',
		title: 'Q&A Session',
		icon: 'menu',
		inMenu:true,
		module: "0"
    },
    onlineExam : {
		component: 'OnlineExamStaff',
		url: '/online-exam',
		title: 'Online Exam ',
		icon: 'menu',
		inMenu:true,
		module: "0"
	},
	examinationStaff : {
		component: 'Examination',
		url: '/examination',
		title: 'Exam List ',
		icon: 'nav-icon fa fa-list  text-sm',
		inMenu:true,
		ismodule:false
	},
	examEvaluationStaff : {
		component: 'ExamEvaluation',
		url: '/exam-evaluation',
		title: 'Evaluation ',
		icon: 'nav-icon fa fa-check  text-sm',
		inMenu:true,
		ismodule:false
	},
	evaluateExamStudnts : {
		component: 'ExamStudents',
		url: '/evaluate-exam-students/:id/:examName',
		title: 'Evaluate Exam ',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	masterResult : {
		component: 'MasterResultView',
		url: '/master-result/:id/:examName',
		title: 'Evaluate Exam ',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	studentExamResult : {
		component: 'StudentResultView',
		url: '/student-exam-result/:id',
		title: 'Evaluate Exam ',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	evaluateExam : {
		component: 'EvaluateExam',
		url: '/evaluate-exam/:id/:examName',
		title: 'Evaluate Exam ',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	viewResult : {
		component: 'ViewResult',
		url: '/result/:id/:examName',
		title: 'Result ',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	Exam : {
		component: 'StudentExamination',
		url: '/exam',
		title: 'Exam List',
		icon: 'nav-icon fa fa-list  text-sm',
		inMenu:true,
		ismodule:false
	},	
	ExamToday : {
		component: 'TodaysExam',
		url: '/exam-today',
		title: 'Todays Exam ',
		icon: 'nav-icon fa fa-calendar-check-o  text-sm',
		inMenu:true,
		ismodule:false
	},	
	studentTodaysExam : {
		component: 'StudentTodaysExaminations',
		url: '/student-exam-today',
		title: 'Todays Exam ',
		icon: 'nav-icon fa fa-calendar-check  text-sm',
		inMenu:true,
		ismodule:false
	},	
	attendance : {
		component: 'Attendance',
		url: '/attendance',
		title: 'Mark Attendance',
		icon: 'nav-icon fa fa-users  text-sm',
		inMenu:true,
		ismodule:false
	},
	intaiteAttendnce : {
		component: 'IntiateAttendance',
		url: '/initiate-attendance',
		title: 'Initiate Attenendance',
		icon: 'nav-icon fa fa-info  text-sm',
		inMenu:true,
		ismodule:false
	},
	studentAttendnce : {
		component: 'StudentAttendance',
		url: '/student-attendance',
		title: 'Attenendance',
		icon: 'nav-icon fa fa-users  text-sm',
		inMenu:true,
		ismodule:false
	},
	adminSchedule : {
		component: 'AdminSchedule',
		url: '/admin-schedule',
		title: 'Class Schedule',
		icon: 'nav-icon far fa-clock  text-sm',
		inMenu:true,
		ismodule:false
	},
	staffSchedule : {
		component: 'StaffSchedule',
		url: '/staff-schedule',
		title: 'Class Schedule',
		icon: 'nav-icon far fa-clock  text-sm',
		inMenu:true,
		ismodule:false
	},
	studentSchedule : {
		component: 'StudentShedule',
		url: '/student-schedule',
		title: 'Class Schedule',
		icon: 'nav-icon far fa-clock  text-sm',
		inMenu:true,
		ismodule:false
    },
    staffForums : {
		component: 'StaffForum',
		url: '/staff-qa',
		title: 'Q & A',
		icon: 'nav-icon far fa-question-circle  text-sm',
		inMenu:true,
		ismodule:false
    },
    studentForums : {
		component: 'StudentForum',
		url: '/student-qa',
		title: 'Q & A ',
		icon: 'nav-icon far fa-question-circle  text-sm',
		inMenu:true,
		ismodule:false
	},
	qASessionStudent : {
		component: 'StudentQandASession',
		url: '/student-qa-session',
		title: 'Q&A Session',
		icon: 'menu',
		inMenu:true,
		module: "0"
	},
	assignments : {
		component: 'Assignments',
		url: '/assignments',
		title: 'Assignment',
		icon: 'nav-icon fa fa-puzzle-piece  text-sm',
		inMenu:true,
		ismodule:false
	},
	assignmentDetail : {
		component: 'AssignmentDetail',
		url: '/assignment-detail/:id',
		title: 'Assignments',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	attendanceDetail : {
		component: 'AttendanceDetail',
		url: '/attendance-detail',
		title: 'Attendance',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},	
	staffProfile : {
		component: 'StaffProfile',
		url: '/staff-profile',
		title: 'Profile',
		icon: 'nav-icon far fa-user  text-sm',
		inMenu:true,
		ismodule:false
	},
	staffProfileView : {
		component: 'StaffProfileView',
		url: '/staff-profile-view/:id',
		title: 'Profile',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	studentProfile : {
		component: 'StudentProfile',
		url: '/student-profile',
		title: 'Profile',
		icon: 'nav-icon far fa-user  text-sm',
		inMenu:true,
		ismodule:false
	},
	stuProfile : {
		component: 'StudentProfileView',
		url: '/student-profile-view/:id',
		title: 'Profile',
		icon: 'menu',
		inMenu:false,
		module: "0"
	},
	stuCalendar : {
		component: 'StudentCalendarDisplay',
		url: '/student-calendar',
		title: 'Calendar',
		icon: 'menu',
		inMenu:true,
		module: "0"
	},
	demoEditor : {
		component: 'DemoEditor',
		url: '/demo-editor',
		title: 'Editor',
		icon: 'menu',
		inMenu:true,
		module: "0"
	},
	changeProfile : {
		component: 'UpdateProfilePicture',
		url: '/update-profile',
		title: 'Image Change',
		icon: 'menu',
		inMenu:false,
		module: "0"
    }
};

// modules for grouping.
const modules = {
	module0: {
		title: 'Dashboard',
		icon: 'home',
		moduleName:"module0",
		isExpendable: true
	},
	module1: {
		title: 'Question Bank',
		icon: 'far fa-circle ',
		moduleName:"module1",
		isExpendable: true
	},
	module2: {
		title: 'Exam',
		icon: 'far fa-circle ',
		moduleName:"module2",
		isExpendable: true
	},
	module3: {
		title: 'Attendance',
		icon: 'far fa-circle ',
		moduleName:"module3",
		isExpendable: true
	}

};

// const moduleConfig = {

// 	Admin: {
// 		parentMenus: []
// 	},
// 	Teacher: {
// 		parentMenus: [modules.module3,
// 						modules.module1]
// 	},
// 	Student: {
// 		parentMenus: [modules.module2]
// 	},
// 	common: {
// 		parentMenus: []
// 	}

// }
const moduleConfig = {

	Admin: {
		routes: [
			components.assignmentsAdmin,
			components.classNoteAdmin,
			components.adminSchedule,
			components.examAdmin,
			{
				url:'abc',
				title: 'Notification',
				icon:"nav-icon far fa-bell  text-sm",
				inMenu:true,
				ismodule:true,
				children:[	
						components.studentNotification,
						components.teacherNotification
					]
			},
			components.questionBankAdmin,
			components.questionPaperAdmin,
			{
				url:'bcd',
				title: 'Reports',
				icon:"nav-icon fa fa-briefcase  text-sm",
				inMenu:true,
				ismodule:true,
				children:[					
						components.detailedAttendaceReport,
						components.studentAttendaceReprt,
						components.individualAttendanceReport
					]
			},
			{
				url:'cde',
				title: 'Settings',
				icon:"nav-icon fa fa-cogs  text-sm",
				inMenu:true,
				ismodule:true,
				children:[					
						components.academicYear,
						components.classSection,
						components.classSubject,
						components.difficultyLevel,
						components.emailConfiguration,
						components.optionalSubject
					]
			},
			{
				url:'def',
				title: 'Student',
				icon:"nav-icon fa fa-user-circle  text-sm",
				inMenu:true,
				ismodule:true,
				children:[					
						components.student,
						components.studentList,
						components.optionalSubjectBatch,
					]
			},
			{
				url:'efg',
				title: 'Syllabus',
				icon:"nav-icon fa fa-database  text-sm",
				inMenu:true,
				ismodule:true,
				children:[					
						components.courseLevel,
						components.courseItem
					]
			},
			{
				url:'fgh',
				title: 'Teacher',
				icon:"nav-icon fa fa-user-secret  text-sm",
				inMenu:true,
				ismodule:true,
				children:[					
						components.teacher,
						components.teacherList,
						components.teacherAssociation
					]
			}
		]
	},
	Teacher: {
		routes: [
			components.staffProfile,
			components.assignmentTask,
			{
				url:'abc',
				title: 'Attendance',
				icon:"nav-icon fa fa-hourglass-start  text-sm",
				inMenu:true,
				ismodule:true,
				children:[
					components.staffSchedule,
					components.attendance,
					components.intaiteAttendnce
				]
			},
			components.classNote,
			components.courseItemStaff,
			{
				url:'bcd',
				title: 'Exam',
				icon:"nav-icon fa fa-certificate  text-sm",
				inMenu:true,
				ismodule:true,
				children:[					
						components.examinationStaff,
						components.examEvaluationStaff,
						components.questionPaper
					]
			},
			{
				url:'cde',
				title: 'Notification',
				icon:"nav-icon far fa-bell  text-sm",
				inMenu:true,
				ismodule:true,
				children:[	
						components.studentNotificationTeacher
					]
			},
			components.staffForums,
			{
				url:'def',
				title: 'Question Bank',
				icon:"nav-icon fa fa-university  text-sm",
				inMenu:true,
				ismodule:true,
				children:[	
						components.listQuestions,
						components.oneWord,
						components.multipleChoice,
						components.paragraph,
						components.essay
					]
			},
			{
				url:'efg',
				title: 'Reports',
				icon:"nav-icon fa fa-briefcase  text-sm",
				inMenu:true,
				ismodule:true,
				children:[					
						components.detailedAttendaceReportStaff,
						components.studentAttendanceReportStaff,
						components.individualAttendanceReportStaff
					]
			}
		]
	},
	Student: {
		routes: [
			components.studentProfile,
			components.assignments,
			{
				url:'abc',
				title: 'Attendance',
				icon:"nav-icon fa fa-hourglass-start  text-sm",
				inMenu:true,
				ismodule:true,
				children:[					
						components.studentSchedule,
						components.studentAttendnce
					]
			},
			components.studentClassNote,
			{
				url:'bcd',
				title: 'Exam',
				icon:"nav-icon fa fa-certificate  text-sm",
				inMenu:true,
				ismodule:true,
				children:[	
						components.Exam,	
						components.studentTodaysExam
					]
			},
			components.studentForums,
			{
				url:'cde',
				title: 'Reports',
				icon:"nav-icon fa fa-briefcase  text-sm",
				inMenu:true,
				ismodule:true,
				children:[			
						components.attendanceReportStudentView
					]
			},

		]
	},
	common: {
		routes: []
	}

}
// component's access to roles.
const rolesConfig = {
	Admin: {
		routes: [
            components.academicYear,
			components.classSection,
			// components.examList,
			// components.newExam,
			components.classSubject,
			components.optionalSubject,
			components.optionalSubjectBatch,
			components.emailConfiguration,
			components.teacher,			
			components.teacherList,
            components.teacherAssociation,
			components.student,
			components.studentList,
			components.difficultyLevel,
			components.courseLevel,
			components.courseItem,
			components.studentNotification,
			components.teacherNotification,
			components.studentAttendaceReprt,
			components.detailedAttendaceReport,
			components.individualAttendanceReport,
			components.stuProfile,
			components.adminSchedule,
			components.examAdmin,
			components.classNoteAdmin,
			components.assignmentsAdmin,
			components.questionBankAdmin,
			components.questionPaperAdmin,
			components.questionpaperView,
			components.changeProfile,
			components.staffProfileView,
			components.viewResult,			
			components.masterResult
        ]
	},
	Teacher: {
		routes: [
			components.staffProfile,
			components.classNote,
			components.assignmentTask,
			components.questionPaper,
			components.questionpaperView,
			components.questionSetting,
			// components.onlineExam,
			components.examinationStaff,
			components.examEvaluationStaff,
			components.evaluateExamStudnts,
			components.masterResult,
			// components.newExam,
			components.attendance,
			components.intaiteAttendnce,
			components.examquestionSetting,
			components.evaluateExam,
			components.staffSchedule,
			components.staffForums,
			//components.staffClassRoom,
			components.courseItemStaff,
			components.studentNotificationTeacher,
			components.studentAttendanceReportStaff,
			components.detailedAttendaceReportStaff,
			components.individualAttendanceReportStaff,
			components.listQuestions,
			components.oneWord,
			components.multipleChoice,
			components.paragraph,
			components.essay,
			components.publishClassNote,
			components.assignmentDetail,
			components.changeProfile
			// components.demoEditor
		]
	},
	Student: {
		routes: [
				components.studentProfile,
				components.studentAttendnce,
				components.studentSchedule,
				components.studentClassNote,
				components.attendanceDetail,
				components.studentForums,
				//components.staffClassRoom,
				//components.stuCalendar,
				components.assignments,
				components.attendanceReportStudentView,
				components.Exam,
				components.studentExamResult,
				// components.ExamToday,
				components.studentTodaysExam,
				components.changeProfile]
	},
	common: {
		routes: [
			{
				// component: 'ChatRoom',
				// url: '/chat-room',
				// title: 'Chat Room',
				// icon: 'menu',
				// inMenu:true,
				// module: 1
			}
		]
	}
};

export { modules, moduleConfig, rolesConfig };
