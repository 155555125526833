import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loader from '../../ui/loader/Loader'
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import ClassNoteDataService from "../../../services/ClassNoteDataService"
import ClassNoteShareDataService from "../../../services/ClassNoteShareDataService"
import CommonDataService from "../../../services/CommonDataService"
import ClassSubjectDataService from '../../../services/ClassSubjectDataService';
import ValidationRule from './ValidationRule'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';

let classesListIds = ""
let subjectListIds = ""
const ClassNote = () => {

    const [show, setShow] = useState(false);
    const [isFirst, setIsFirst] = useState(true);
    const [IsSecond, setIsSecond] = useState(false);
    const [isThird, setIsThird] = useState(false);
    const [isFourth, setIsFourth] = useState(false);
    const [isFiveth, setIsFiveth] = useState(false);
    const [isFinsh, setIsFinish] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formType, setFormType] = useState("Add")
    const [formTitle, setFormTitle] = useState("Class Note")
    const [classesId, setClassesId] = useState(0)
    const [classSubjectId, setClassSubjectId] = useState("")
    const [classes, setClasses] = useState([])
    const [allclasses, setAllClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [allClassSubject, setAllClassSubject] = useState([]);
    const [classNoteId, setClassNoteId] = useState(0)
    const [classNoteName, setClassNoteName] = useState("")
    const [noteDetail, setNoteDetail] = useState("")
    const [documentId, setDocumentId] = useState(0)
    const [documentName, setDocumentName] = useState("")
    const [documentFile, setDocumentFile] = useState("")
    const [uploadProgress, setuploadProgress] = useState("0")
    const [linkName, setLinkName] = useState("")
    const [linkDetail, setLinkDetail] = useState("")
    const [imageName, setImageName] = useState("")
    const [imageFile, setImageFile] = useState("")
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState(false);
    const [isfilterModified, setisFilterModified] = useState(false)
    const [searchTopic, setSearchTopic] = useState("")

    const [classNotes, setClassNotes] = useState([])
    const [classDocumentList, setClassDocumentList] = useState([])
    const [linkList, setLinkList] = useState([])
    const [imageList, setImageList] = useState([])

    const [classNoteSections, setClassNoteSections] = useState([])
    const [classNoteShares, setClassNoteShares] = useState([])
    const [publishDate, setPublishDate] = useState("")
    const [classSectionId, setClassSectionId] = useState("")


    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        classNoteName: searchTopic,
        classList: classesListIds,
        subjectList: subjectListIds
    })



    // const filecheck = (field, state) => (field===(/\.(jpg|jpeg|png|gif)$/))

    const validatorClassNote = ValidationRule.ClassNoteRule();
    const validatorLink = ValidationRule.LinkRule();
    const validatorDocument = ValidationRule.DocumentRule();
    const validatorImage = ValidationRule.ImageRule();

    const [validationClassNote, setValidationClassNote] = useState(validatorClassNote.valid());
    const [validateSubmittedClassNote, setValidateSubmittedClassNote] = useState(false)

    const [validationLink, setValidationLink] = useState(validatorLink.valid());
    const [validateSubmittedLink, setValidateSubmittedLink] = useState(false)

    const [validationDocument, setValidationDocument] = useState(validatorDocument.valid());
    const [validateSubmittedDocument, setValidateSubmittedDocument] = useState(false)

    const [validationImage, setValidationImage] = useState(validatorImage.valid());
    const [validateSubmittedImage, setValidateSubmittedImage] = useState(false)

    var classNoteValidationData = {
        classSubjectId: classSubjectId,
        classNoteName: classNoteName,
        noteDetail: noteDetail,
        validation: validationClassNote

    }
    var LinkValidationData = {
        linkName: linkName,
        linkDetail: linkDetail,
        validation: validationLink
    }
    var DocumentValidationData = {
        documentName: documentName,
        documentFile: documentFile,
        validation: validationDocument
    }

    var ImageValidationData = {
        imageName: imageName,
        imageFile: imageFile,
        validation: validationImage
    }

    const handleClose = () => {
        setFormType("Create");
        resetClassnote();
        resetDocument();
        resetImage();
        resetLink();
        setPublishDate("");
        setClassSectionId("")
        setIsFirst(true);
        setIsSecond(false)
        setIsThird(false)
        setIsFourth(false)
        setIsFiveth(false)
        setClassNoteShares([])
        setClassDocumentList([])
        setLinkList([])
        setImageList([])
        setShow(false);
    }
    const handleShow = () => setShow(true);

    const createNote = () => {
        setIsEdit(false);
        setFormType("Create");
        handleShow();
    }

    const editNote = (id) => {
        setClassNoteId(id)
        setIsFinish(false);
        setIsFirst(false);
        setFormTitle("Link")
        fetchClassDocumentList(id, "Link")
        setIsSecond(true);
        handleShow()
    }

    const saveNote = () => {
        var data = {
            classNoteId: 0,
            classSubjectId: parseInt(classSubjectId),
            classNoteName: classNoteName,
            noteDetail: noteDetail
        }
        const validate = validatorClassNote.validate(classNoteValidationData)
        setValidationClassNote({ validate });
        setValidateSubmittedClassNote(true)
        if (validate.isValid) {
            ClassNoteDataService.createClassNote(data)
                .then(response => {
                    setSubmitted(!submitted)
                    setClassNoteId(response.data.result)
                    setIsFinish(false)
                    setIsFirst(false)
                    setFormTitle("Link")
                    fetchClassDocumentList(response.data.result, "Link")
                    setIsSecond(true)
                    LearnToast(success, "Class Note Saved Successfully")
                })
                .catch(error => {
                    let errorMsg=[]
                    let msgText=""
                    setLoading(false)      
                    if(error.response.status==500)
                    {
                        LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                    }
                    else
                    {
                        errorMsg=error.response.data.errors
                        errorMsg.map((item,index)=>{
                            if(msgText.length>0)
                            {
                            msgText = msgText+","
                            }
                            msgText=msgText+item
                        })            
                            LearnToast(danger,msgText)
                    
                    }
                })
            resetClassnote();


        }

    }

    const resetClassnote = () => {
        setClassesId(0);
        setClassSubjectId(0);
        setClassNoteName("");
        setNoteDetail("");
        setValidationClassNote(validatorClassNote.valid())
        setValidateSubmittedClassNote(false)
    }

    const skipSaveNote = () => {
        fetchClassDocumentList(classNoteId, "Link")
        setIsFirst(false);
        setFormTitle("Link")
        setIsSecond(true);
    }

    const saveLink = () => {
        fetchClassDocumentList(classNoteId, "File")
        setIsSecond(false);
        setFormTitle("Document")
        setIsThird(true)
    }

    const addLink = () => {
        var data = {
            classNoteDocumentId: 0,
            classNoteId: classNoteId,
            documentId: 0,
            documentTypeName: "Link",
            displayName: linkName,
            documentName: linkDetail
        }
        const validate = validatorLink.validate(LinkValidationData)
        setValidationLink({ validate });
        setValidateSubmittedLink(true)
        if (validate.isValid) {
            saveClassNoteDocument(data, "Link")
            resetLink()
        }

    }

    const resetLink = () => {
        setLinkName("");
        setLinkDetail("");
        setValidationLink(validatorLink.valid())
        setValidateSubmittedLink(false);
    }

    const skipSaveLink = () => {
        fetchClassDocumentList(classNoteId, "File")
        setIsSecond(false);
        setFormTitle("Document")
        setIsThird(true)
    }

    const backToSaveLink = () => {
        fetchClassDocumentList(classNoteId, "Link")
        setIsThird(false)
        setIsSecond(true);
        setFormTitle("Link")
    }

    const saveDocument = () => {
        fetchClassDocumentList(classNoteId, "Image")
        setIsThird(false);
        setFormTitle("Image")
        setIsFourth(true);
    }

    const addDocument = () => {
        const validate = validatorDocument.validate(DocumentValidationData)
        setValidationDocument({ validate });
        setValidateSubmittedDocument(true)
        if (validate.isValid) {
            var file = new FormData()
            file.append('file', documentFile)
            CommonDataService.fileUpload(file, {
                onUploadProgress: progressEvent => {
                    setuploadProgress(progressEvent.loaded / progressEvent.total * 100)
                }
            })
                .then(response => {
                    setuploadProgress(0)
                    setDocumentId(response.data.result)
                    var data = {
                        classNoteDocumentId: 0,
                        classNoteId: classNoteId,
                        documentId: parseInt(response.data.result),
                        documentTypeName: "File",
                        displayName: documentName,
                        documentName: ""
                    }
                    saveClassNoteDocument(data, "File")

                })
                .catch(error => {
                    LearnToast(danger, "Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                })

            resetDocument();
        }
    }

    const resetDocument = () => {
        setDocumentName("");
        setDocumentFile("");
        setValidationDocument(validatorDocument.valid())
        setValidateSubmittedDocument(false)
    }

    const skipSaveDocument = () => {
        fetchClassDocumentList(classNoteId, "Image")
        setIsThird(false);
        setFormTitle("Image")
        setIsFourth(true);
    }

    const backToSaveDocument = () => {
        fetchClassDocumentList(classNoteId, "File")
        setIsFourth(false);
        setIsThird(true);
        setFormTitle("Document")
    }

    const saveImage = () => {
        fetchClassNoteSections()
        fetchClassNoteShareList()
        setIsFourth(false);
        setFormTitle("Publish Classes")
        setIsFiveth(true)
    }

    const addImage = () => {
        const validate = validatorImage.validate(ImageValidationData)
        setValidationImage({ validate });
        setValidateSubmittedImage(true)
        if (validate.isValid) {
            var file = new FormData()
            file.append('file', imageFile)
            CommonDataService.fileUpload(file, {
                onUploadProgress: progressEvent => {
                    setuploadProgress(progressEvent.loaded / progressEvent.total * 100)
                }
            })
                .then(response => {
                    setuploadProgress(0)
                    setDocumentId(response.data.result)
                    var data = {
                        classNoteDocumentId: 0,
                        classNoteId: classNoteId,
                        documentId: parseInt(response.data.result),
                        documentTypeName: "Image",
                        displayName: imageName,
                        documentName: ""
                    }
                    saveClassNoteDocument(data, "Image")

                })
                .catch(error => {
                    const errorMsg = error.message;
                })

            resetImage()
        }
    }

    const resetImage = () => {
        setImageName("");
        setImageFile("");
        setValidationImage(validatorImage.valid())
        setValidateSubmittedImage(false);
    }


    const skipSaveImage = () => {
        fetchClassNoteSections()
        fetchClassNoteShareList()
        setIsFourth(false);
        setFormTitle("Publish Classes")
        setIsFiveth(true)
    }

    const backToSaveImage = () => {
        fetchClassDocumentList(classNoteId, "Image")
        setIsFiveth(false);
        setIsFourth(true);
        setFormTitle("Image")
    }


    const publishNote = () => {
        // setIsFiveth(false);
        // setIsFinish(true);
        // setIsFirst(true)
        // setFormTitle("Class Note")
        // handleClose();
        let data = {
            classNoteShareId: 0,
            classNoteId: classNoteId,
            classSectionId: parseInt(classSectionId),
            publishFrom: publishDate,
            classSectionName: ""
        }
        setLoading(true)
        ClassNoteShareDataService.createClassNoteShare(data)
            .then(response => {
                setLoading(false)
                LearnToast(success, "Published Successfully")
                fetchClassNoteShareList()
                setClassSectionId("")
                setPublishDate("")
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }
            })

    }




    const saveClassNoteDocument = async (data, Type) => {
        setLoading(true)
        ClassNoteDataService.createClassNoteDocument(data)
            .then(response => {
                setLoading(false)
                fetchClassDocumentList(classNoteId, Type)
                LearnToast(success, "Saved Successfully")
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }
            })
    }

    useEffect(() => {
        fetchTeacherAllCurrentClasses()
        fetchTeacherAllCurrentSubjects(classesListIds)
    }, [])


    const fetchTeacherAllCurrentClasses = async () => {
        let classesList = []
        await TeacherAssociationDataService.getTeacherCurrentClasses()
            .then(response => {

                response.data.result.map((classdata, index) => (
                    classesList.push({ id: classdata.classesId, value: classdata.classesName, isChecked: false })
                ));
                setAllClasses(classesList)

            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchTeacherAllCurrentSubjects = async (classesId) => {

        let SubjectList = []
        await ClassSubjectDataService.getTeacherSubjects(classesId)
            .then(response => {
                response.data.result.map((subjectdata, index) => (
                    SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
                ));
                setSubjects(SubjectList)

            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }


    const handleCheckClasses = (event) => {
        let chkclasses = allclasses
        classesListIds = ""
        chkclasses.forEach(classdata => {
            if (classdata.value === event.target.value) {
                classdata.isChecked = event.target.checked

            }
            if (classdata.isChecked) {
                if (classesListIds.length > 0 || classesListIds != "") {
                    classesListIds += ","
                }
                classesListIds += classdata.id
            }
        })
        fetchTeacherAllCurrentSubjects(classesListIds)
        setClasses(chkclasses)
        setisFilterModified(!isfilterModified)
    }


    const handleCheckSubjects = (event) => {
        let chksubject = subjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }

    useEffect(() => {
        setParametes()
    }, [submitted, isfilterModified])

    useEffect(() => {
        fetchTeacherCurrentClasses()
        fetchClassNotes()
    }, [params])

    useEffect(() => {
        fetchTeacherCurrentSubjects()
    }, [classesId])

    const fetchTeacherCurrentClasses = async () => {
        await TeacherAssociationDataService.getTeacherCurrentClasses()
            .then(response => {
                setClasses(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchTeacherCurrentSubjects = async () => {
        await TeacherAssociationDataService.getTeacherCurrentSubjects(parseInt(classesId))
            .then(response => {
                setAllClassSubject(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const setParametes = () => {
        setParams({
            classNoteName: searchTopic,
            classList: classesListIds,
            subjectList: subjectListIds
        })
    }


    const fetchClassNotes = async () => {
        setLoading(true)
        await ClassNoteDataService.getTeacherNotes(params)
            .then(response => {
                setClassNotes(response.data.result.itemList)
                setTotalPages(response.data.result.totalPages)
                setActivePage(response.data.result.activePageNumber)
                setLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message;
                setLoading(false)
            })
    }

    const fetchClassDocumentList = async (id, documentType) => {
        await ClassNoteDataService.getClassNoteDocumentList(id, documentType, "")
            .then(response => {
                if (documentType == "Link") {
                    setLinkList(response.data.result)
                }
                if (documentType == "Image") {
                    setImageList(response.data.result)
                }
                if (documentType == "File") {
                    setClassDocumentList(response.data.result)
                }
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchClassNoteSections = async () => {
        await ClassNoteShareDataService.getClassNoteSections(classNoteId)
            .then(response => {
                setClassNoteSections(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchClassNoteShareList = async () => {
        await ClassNoteShareDataService.getClassNoteShareList(classNoteId)
            .then(response => {
                setClassNoteShares(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }


    const deleteDocument = async (id, documentType) => {
        setLoading(true)
        await ClassNoteDataService.deleteClassNoteDocument(id)
            .then(response => {
                setLoading(false)
                LearnToast(success, "Deleted Successfully")
                fetchClassDocumentList(classNoteId, documentType)
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })       
                        LearnToast(danger,msgText)
                
                }
            })
    }

    const deleteNote = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this?</p>
                        <button onClick={onClose}>No</button>
                        <button className="btn-alert-danger"
                            onClick={() => {
                                handleClickDelete(id);
                                onClose();
                            }}
                        >
                            Yes, Delete it!
                  </button>
                    </div>
                );
            }
        });

    }

    const deleteClassNoteShare = async (id,) => {
        setLoading(true)
        await ClassNoteShareDataService.deleteClassNoteShare(id)
            .then(response => {
                fetchClassNoteShareList()
                setLoading(false)
                LearnToast(success, "Deleted Successfully")
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })         
                        LearnToast(danger,msgText)
                
                }
            })
    }

    const handleClickDelete = async (id) => {
        setLoading(true)
        await ClassNoteDataService.deleteClassNote(id)
            .then(response => {
                setLoading(false)
                LearnToast(success, "Class Note Deleted Successfully")
                setSubmitted(!submitted);
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })      
                        LearnToast(danger,msgText)
                
                }
            })

    }

    let validateOutputClassNote = validateSubmittedClassNote ? validatorClassNote.validate(classNoteValidationData) : classNoteValidationData.validation
    let validateOutputLink = validateSubmittedLink ? validatorLink.validate(LinkValidationData) : LinkValidationData.validation
    let validateOutputDocument = validateSubmittedDocument ? validatorDocument.validate(DocumentValidationData) : DocumentValidationData.validation
    let validateOutputImage = validateSubmittedImage ? validatorImage.validate(ImageValidationData) : ImageValidationData.validation
    return (
        <>
            <ToastContainer />
            {loading ? <Loader /> :
                <>
                    <Modal show={show} size="lg">
                        <Modal.Header>
                            <Modal.Title>{formType + " " + formTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {isFirst ? (
                                <form>
                                    <div className="form-row">
                                        <div className="col">
                                            <label htmlFor="displayname">Class </label>
                                            <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className="form-control">
                                                <option value="">-- select class --</option>
                                                {classes.map(item => (
                                                    <option
                                                        key={item.classesId}
                                                        value={item.classesId}
                                                    >
                                                        {item.classesName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="displayname">Class Subject</label>
                                            <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className={validateOutputClassNote.classSubjectId.isInvalid ? 'form-control is-invalid' : 'form-control'} >
                                                <option value="">-- select subject --</option>
                                                {allClassSubject.map(item => (
                                                    <option
                                                        key={item.classSubjectId}
                                                        value={item.classSubjectId}
                                                    >
                                                        {item.subjectName}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="text-danger">{validateOutputClassNote.classSubjectId.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="todate">Topic</label>
                                        <input type="text" onChange={event => setClassNoteName(event.target.value)} value={classNoteName} name="classNoteName" id="classNoteName" className={validateOutputClassNote.classNoteName.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Topic" />
                                        <span className="text-danger">{validateOutputClassNote.classNoteName.message}</span>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="todate">Note Detail</label>
                                        <textarea onChange={event => setNoteDetail(event.target.value)} value={noteDetail} name="noteDetail" id="noteDetail" className={validateOutputClassNote.noteDetail.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Place some text here"
                                            style={{
                                                width: "100%", height: "200px", fontSize: "14px", lineHeight: "18px",
                                                border: "1px solid #dddddd", padding: "10px"
                                            }} />
                                        <span className="text-danger">{validateOutputClassNote.noteDetail.message}</span>
                                    </div>
                                </form>
                            ) : (<></>)}
                            {IsSecond ? (<>
                                <div className="form-row">
                                    <div className="col">
                                        <label htmlFor="todate">Link Name</label>
                                        <input type="text" onChange={event => setLinkName(event.target.value)} value={linkName} name="linkName" id="linkName" className={validateOutputLink.linkName.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Link Name" />
                                        <span className="text-danger">{validateOutputLink.linkName.message}</span>
                                    </div>
                                    <div className="col">
                                        <label htmlFor="todate">Type Link</label>
                                        <div className="input-group">
                                            <input type="text" onChange={event => setLinkDetail(event.target.value)} value={linkDetail} name="linkDetail" id="linkDetail" className={validateOutputLink.linkDetail.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Type link" />
                                            <span className="input-group-append">
                                                <button type="button" onClick={addLink} className="btn btn-primary">Add</button>
                                            </span>
                                        </div>
                                        <span className="text-danger">{validateOutputLink.linkDetail.message}</span>
                                    </div>
                                </div>
                                <br />
                                {linkList.length>0?
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Link Name</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {linkList.map((item, index) => (
                                                <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td><a href={item.documentName} target="_blank">{item.displayName}</a></td>
                                                    <td className="text-right">
                                                        {/* <span className="text-info" onClick={()=>editLink(item.classNoteDocumentId)}><i className="fa fa-edit"></i></span>&nbsp;&nbsp;&nbsp;   */}
                                                        <button type="button" className="btn btn_link" onClick={() => deleteDocument(item.classNoteDocumentId, "Link")}><span className="text-danger"><i className="fa fa-times"></i></span></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                 :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }
                            </>) : (<></>)}
                            {isThird ? (<>
                                <form>
                                    <div className="form-row">
                                        <div className="col">
                                            <label htmlFor="todate">File Name</label>
                                            <input type="text" onChange={event => setDocumentName(event.target.value)} value={documentName} className={validateOutputDocument.documentName.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="File Name" />
                                            <span className="text-danger">{validateOutputDocument.documentName.message}</span>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="todate">Upload File</label>
                                            <div className="input-group">
                                                <input type="file" onChange={event => setDocumentFile(event.target.files[0])} name="documentFile" id="documentFile" className={validateOutputDocument.documentFile.isInvalid ? 'form-control is-invalid' : 'form-control'} />
                                                <span className="input-group-append">
                                                    <button type="button" className="btn btn_link" onClick={addDocument} className="btn btn-primary">Add</button>
                                                </span>
                                            </div>
                                            <span className="text-danger">{validateOutputDocument.documentFile.message}</span>
                                        </div>
                                    </div>
                                </form>
                                <br />
                                <div className="progress progress-sm active">
                                    <div className="progress-bar bg-success progress-bar-striped" role="progressbar"
                                        aria-valuemin="0" aria-valuemax="100" style={{ width: uploadProgress + "%" }}>
                                        <span className="sr-only">20% Complete</span>
                                    </div>
                                </div>
                                {classDocumentList.length>0?
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Document Name</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {classDocumentList.map((item, index) => (
                                                <tr>
                                                    <th scope="row">{index + 1}</th>
                                                    <td><a href={item.documentBaseUrl + item.documentName} target="_blank">{item.displayName}</a></td>
                                                    <td className="text-right">
                                                        <button type="button" className="btn btn_link" onClick={() => deleteDocument(item.classNoteDocumentId, "File")}><span className="text-danger"><i className="fa fa-times"></i></span></button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                 :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }
                            </>) : (<></>)}
                            {isFourth ? (
                                <>
                                    <div className="form-row">
                                        <div className="col">
                                            <label htmlFor="todate">Image Name</label>
                                            <input type="text" onChange={event => setImageName(event.target.value)} value={imageName} name="imageName" id="imageName" className={validateOutputImage.imageName.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Image Name" />
                                            <span className="text-danger">{validateOutputImage.imageName.message}</span>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="todate">Upload Image</label>
                                            <div className="input-group">
                                                <input type="file" onChange={event => setImageFile(event.target.files[0])} name="imageFile" id="imageFile" className={validateOutputImage.imageFile.isInvalid ? 'form-control is-invalid' : 'form-control'} />
                                                <span className="input-group-append">
                                                    <button type="button" onClick={addImage} className="btn btn-primary">Add</button>
                                                </span>
                                            </div>
                                            <span className="text-danger">{validateOutputImage.imageFile.message}</span>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="progress progress-sm active">
                                        <div className="progress-bar bg-success progress-bar-striped" role="progressbar"
                                            aria-valuemin="0" aria-valuemax="100" style={{ width: uploadProgress + "%" }}>
                                            <span className="sr-only">{uploadProgress + "% Complete"}</span>
                                        </div>
                                    </div>
                                    {imageList.length>0?
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Image Name</th>
                                                    <th className="text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {imageList.map((item, index) => (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td><a href={item.documentBaseUrl + item.documentName} target="_blank">{item.displayName}</a></td>
                                                        <td className="text-right">
                                                            <button type="button" className="btn btn_link" onClick={() => deleteDocument(item.classNoteDocumentId, "Image")}><span className="text-danger"><i className="fa fa-times"></i></span></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    :
                                         <div className="alert alert-info alert-dismissible">
                                             <div className="text-center text-white"><h4>No Records Found</h4></div>
                                         </div>
                                     }
                                </>
                            ) : (<></>)}
                            {isFiveth ? (
                                <>
                                    <div className="form-row">
                                        <div className="col">
                                            <label htmlFor="classSectionId">Class Section </label>
                                            <select onChange={event => setClassSectionId(event.target.value)} value={classSectionId} name="classSectionId" className="form-control">
                                                <option value="">-- select class section --</option>
                                                {classNoteSections.map(item => (
                                                    <option
                                                        key={item.classSectionId}
                                                        value={item.classSectionId}
                                                    >
                                                        {item.classSectionName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="col">
                                            <label htmlFor="todate">date</label>
                                            <div className="input-group">
                                                <input type="date" onChange={event => setPublishDate(event.target.value)} value={publishDate} id="publishDate" name="publishDate" className="form-control" />
                                                <span className="input-group-append">
                                                    <button type="button" onClick={() => publishNote()} className="btn btn-primary">Publish</button>
                                                </span>
                                            </div>
                                            <span className="text-danger">{validateOutputDocument.documentFile.message}</span>
                                        </div>
                                    </div>
                                    <br />
                                    {classNoteShares.length>0?
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Class Section</th>
                                                    <th scope="col">Date</th>
                                                    <th className="text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {classNoteShares.map((item, index) => (
                                                    <tr>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{item.classSectionName}</td>
                                                        <td>{item.publishFrom}</td>
                                                        <td className="text-right">
                                                            <button type="button" className="btn btn_link" onClick={() => deleteClassNoteShare(item.classNoteShareId)}><span className="text-danger"><i className="fa fa-times"></i></span></button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                     :
                                        <div className="alert alert-info alert-dismissible">
                                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                                        </div>
                                    }
                                </>
                            ) : (<></>)}
                        </Modal.Body>
                        <Modal.Footer>
                            {isFirst ? (
                                <>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    {isEdit ? (<Button variant="secondary" onClick={skipSaveNote}>
                                        Skip
                                    </Button>) : (<></>)}
                                    <Button type="submit" variant="primary" onClick={saveNote}>
                                        Next
                                    </Button>
                                </>
                            ) : (
                                    <>
                                    </>
                                )}
                            {IsSecond ? (
                                <>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <Button variant="secondary" onClick={skipSaveLink}>
                                        Skip
                                    </Button>
                                    <Button type="submit" variant="primary" onClick={saveLink}>
                                        Next
                                </Button>
                                </>
                            ) : (<></>)}
                            {isThird ? (
                                <>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <button className="btn btn-warning" onClick={backToSaveLink}>Back</button>
                                    <Button variant="secondary" onClick={skipSaveDocument}>
                                        Skip
                                    </Button>
                                    <Button type="submit" variant="primary" onClick={saveDocument}>
                                        Next
                                    </Button>
                                </>
                            ) : (<></>)}
                            {isFourth ? (
                                <>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <button className="btn btn-warning" onClick={backToSaveDocument}>Back</button>
                                    <Button variant="secondary" onClick={skipSaveImage}>
                                        Skip
                                    </Button>
                                    <Button type="submit" variant="primary" onClick={saveImage}>
                                        Next
                                    </Button>
                                </>
                            ) : (<></>)}
                            {isFiveth ? (
                                <>
                                    <Button variant="secondary" onClick={handleClose}>
                                        Close
                                    </Button>
                                    <button className="btn btn-warning" onClick={backToSaveImage}>Back</button>
                                </>
                            ) : (
                                    <>
                                    </>
                                )}
                        </Modal.Footer>
                    </Modal>
                    <div className="container">
                        <div className="jumbotron">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Class Note</h5>
                                        </div>
                                        <div className="col-md-6 text-right">
                                            <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={createNote}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                        </div>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="card card-outline card-primary">
                                                <div className="text-center">
                                                    <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                                </div>
                                                <hr />
                                                <div className="col-md-12">
                                                    <div className="input-group">
                                                        <div className="input-group input-group-sm">
                                                            <input onChange={event => setSearchTopic(event.target.value)} value={searchTopic} name="searchTopic" id="searchTopic" className="form-control form-control-navbar" type="text" placeholder="Type Topic" aria-label="Search" />
                                                            <div className="input-group-append">
                                                                <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                                    <i className="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="col-md-12">
                                                    <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Classes</b></h6>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        {allclasses.map((classdata, index) => (
                                                            <li key={index} className="list-group-item">
                                                                <input key={classdata.id} onClick={event => handleCheckClasses(event)} type="checkbox" checked={classdata.isChecked} value={classdata.value} /> {classdata.value}
                                                            </li>

                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="col-md-12">
                                                    <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        {subjects.map((subjectdata, index) => (
                                                            <li key={index} className="list-group-item">
                                                                <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                            </li>

                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            {classNotes.length>0?
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                        </div>
                                                    </div>
                                                    <table className="table table-bordered table-hover">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col">#</th>
                                                                <th scope="col">Subject</th>
                                                                <th scope="col">Topic</th>
                                                                <th className="text-right">Actions</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {classNotes.map((item, index) => (
                                                                <tr key={index}>
                                                                    <th scope="row">{index + 1}</th>
                                                                    <td>{item.classSubjectName}</td>
                                                                    <td>{item.classNoteName}</td>
                                                                    <td className="text-right">
                                                                        <div className="d-flex justify-content-end">
                                                                            <button onClick={() => editNote(item.classNoteId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>
                                                                            <button onClick={() => deleteNote(item.classNoteId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            ))}

                                                        </tbody>
                                                    </table>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                        </div>
                                                    </div>
                                                </>
                                             :
                                                <div className="alert alert-info alert-dismissible">
                                                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
};

export default ClassNote;