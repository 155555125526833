import  FormValidator  from '../../../helpers/form-validator'
const StudentForumRule = () =>  new FormValidator([
    { 
      field: 'subjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'teacherId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Teacher is Required'
    },
    { 
      field: 'forumDetail', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Detail is Required'
    }
  ])
  const StaffForumRule = () =>  new FormValidator([   
    { 
      field: 'forumDetail', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Detail is Required'
    }
  ])
  export default {StudentForumRule,
                  StaffForumRule}

  