import React, { useState, useEffect } from 'react'
import { PieChart } from 'react-minimal-pie-chart';
import StudentDataService from "../../../services/StudentDataService"
import Loader from '../../ui/loader/Loader';
import ReactSpeedometer from "react-d3-speedometer"

const StudentAssignment = (props) => {

    const [assignmentDetail, setAssignmentDetail]= useState({})
    const [singleDetail, setSingleDetail] = useState([])
    const [isDetailView, setIsDetailView] = useState(false) 
    const [loading,setLoading] = useState(false);
  
  
  const ViewAssignmentDetail = async (classSectionId,subjectId) =>{
    setIsDetailView(true)
    setLoading(true)
    await StudentDataService.getSubjectAssignmentProfile(parseInt(props.profile.studentId),parseInt(classSectionId),parseInt(subjectId)) 
      .then(response => {
        setAssignmentDetail(response.data.result)
        setSingleDetail(response.data.result.studentAssignmentDetails)
        setLoading(false)
     })
     .catch(error => {
         const errorMsg=error.message;            
     })
  }  

  return (
    <>
    {!isDetailView?
      <div className="row">
        <div className="col-md-4 text-center">
        <div className="card card-outline">
            <div className="card-header">
                <h5 className="card-title">Overall Assignment</h5>
            </div>
            <div className="card-body box-profile">
                <ReactSpeedometer
                    maxValue={100}
                    value={props.profile.assignmentPerformancePercent}
                    needleColor="blue"
                    startColor="red"
                    segments={10}
                    width={200}
                    height={150}
                    endColor="green"
                    />
                </div>   
            </div>                                           
        </div>
        <div className="col-md-8"> 

        <div className="card card-outline">
            <div className="card-header">
                <h5 className="card-title">Subject Assignment</h5>
            </div>
            <div className="card-body box-profile">  
            {/* <h5 className="text-center">Subject Attendance</h5>  */}
            <br/>
                {props.assignmentProfileSummaries.map((item,index)=>(
                    <div  className="row" key={index}>
                        <div className="col-md-11">
                            <div className="progress-group">
                                {item.classSubjectName} - {item.totalPercent}%
                                <span className="float-right"><b>{item.totalSubmitted}</b>/{item.totalAssignments}</span>
                                <div className="progress progress-sm">
                                    <div className={item.progressbarClassName} style={{ width: item.totalPercent+"%" }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1">
                          {item.totalAssignments>0?
                            <button className="btn btn-link" onClick={()=>ViewAssignmentDetail(props.profile.classSectionId,item.classSubjectId)}>
                                <span className="text-info"><i className="fa fa-search"></i></span>
                            </button>
                            :
                            <button className="btn btn-link" disabled>
                              <span className="text-info"><i className="fa fa-search"></i></span>
                            </button>
                          }
                        </div>
                    </div>
                ))}
                </div>
                <div className="card-footer">
                <div className="d-flex justify-content-between">
                    <span className="mr-2">
                        <i className="fas fa-square text-success"></i> Above 80%
                    </span>
                    <span className="mr-2">
                        <i className="fas fa-square text-warning"></i> Between 60% and 80%
                    </span>
                    <span className="mr-2">
                        <i className="fas fa-square text-danger"></i> Lessthan 60%
                    </span>
                </div>
                </div>
            </div>
        </div>
    </div>
:
<>
{loading? <Loader/>:
  <div className="row">
      <div className="col-md-12">
          <div className="card card-outline">
              <div className="card-body box-profile">
                  <div className="d-flex justify-content-between">
                      <div className="col-md-8"> 
                           <h6>{assignmentDetail.classSubjectName}</h6>
                      </div>
                      <div className="col-md-4">
                          <button onClick={()=>setIsDetailView(false)} className="btn btn-primary btn-block">Back to Summary</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  <div className="col-md-4 text-center">
      <div className="card card-outline">
          <div className="card-header">
                <h5 className="card-title">Assignment Summary</h5>
          </div>
          <div className="card-body box-profile">
          {/* <h5>Overall Attendance</h5>                                                 */}
              <span className="mr-2">
                  <i className="fas fa-square text-success"></i> Submitted - {assignmentDetail.totalSubmitted}
              </span>
              &nbsp; &nbsp;
              <span className="mr-2">
                  <i className="fas fa-square text-danger"></i> Pending - {assignmentDetail.totalPending}
              </span> 
          <PieChart
              data={[                                                        
                  { title: 'Pending', value: assignmentDetail.totalPending, color: '#FF0000' },
                  { title: 'Submitted', value: assignmentDetail.totalSubmitted, color: '#008000' }
              ]}
              label={({ dataEntry }) => dataEntry.value}
              labelStyle={(index) => ({
                  fill: "#ffffff",
                  fontSize: '10px',
                  fontFamily: 'sans-serif',                                                                    
                  })}
                  radius={40}
          />      
          </div>   
          </div>                                           
      </div>
      <div className="col-md-8"> 

        <div className="card card-outline">
            <div className="card-header">
                <h5 className="card-title">Assignment Detail</h5>
            </div>
            <div className="card-body box-profile">  
                <table className="table table-hover">
                      <thead>
                          <tr>
                              <th scope="col">#</th>
                              <th scope="col">Topic</th>
                              <th scope="col">Due Date</th>
                              <th className="text-right"></th>
                          </tr>
                      </thead>
                      <tbody> 
                      {singleDetail.map((item, index)=>(
                            <tr>
                              <th scope="row">{index+1}</th>
                              <td>{item.topicName}</td>
                              <td>{item.dueDate}</td>
                              <td className="text-right">
                                {item.isSubmitted && item.isEvaluated?
                                    <span className="text-success"><i className="fa fa-check"></i> </span>
                                :item.isSubmitted && !item.isEvaluated?
                                    <span className="text-warning"><i className="fa fa-times"></i> </span>
                                    :
                                    <span className="text-danger"><i className="fa fa-times"></i> </span>
                                  }                        
                              </td>
                          </tr>
                        ))}                                
                    </tbody>
                </table>
            </div>
        </div>
      </div>
  </div>
 }
 </>
}
 </>
  )
}

export default StudentAssignment
