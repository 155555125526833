import React, { useState, useEffect } from 'react'
import AssignmentDataService from "../../../services/AssignmentDataService"

const AssignmnetMarkEntry = (props) => {
    const [maxMark, setMaxMark] = useState("")
    const saveMark = async () =>{    
        await props.onSavemark(props.assignmentStudentId,maxMark)
        setMaxMark("")
        
    }
  return (
    <div>
         <div className="input-group input-group-sm">
            <input type="text" onChange={event => setMaxMark(event.target.value)} value={maxMark} name="maxMark" id="maxMark" className="form-control" />
            <span className="input-group-append">
            <button type="button" onClick={()=>saveMark()} className="btn btn-info btn-flat">Go!</button>
            </span>
        </div>
    </div>
  )
}

export default AssignmnetMarkEntry
