import  ApiDataService  from './ApiDataService'

const changePassword = data => {
    return ApiDataService.put("user/changepassword", data);
}

const resetPassword = data => {
    return ApiDataService.put("user/resetpassword", data);
}

const changeProfilePicture = data => {
    return ApiDataService.put("user/profilepicture", data);
}

  export default {changePassword,
                  resetPassword,
                  changeProfilePicture};