import  FormValidator  from '../../../helpers/form-validator'

const ClassNoteRule = () =>  new FormValidator([
    { 
      field: 'classSubjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Subject is Required'
    },
    { 
      field: 'classNoteName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Topic is Required'
    },
    { 
      field: 'noteDetail', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Note Detail is Required'
    }
  ])
  const LinkRule = () =>  new FormValidator([
    { 
      field: 'linkName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Name is Required'
    },
    { 
      field: 'linkDetail', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'File is Required'
    },
    {
      field: 'linkDetail', 
      method: 'matches',
      args: [/^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.​\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[​6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1​,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00​a1-\uffff0-9]+-?)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]+-?)*[a-z\u​00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i],
      validWhen: true, 
      message: 'That is not a valid Url'
    }
  ])
const DocumentRule = () =>  new FormValidator([
    { 
      field: 'documentName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Name is Required'
    },
    { 
      field: 'documentFile', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'File is Required'
    }
  ])
  const ImageRule = () =>  new FormValidator([
    { 
      field: 'imageName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Name is Required'
    },
    { 
      field: 'imageFile', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'File is Required'
    }
  ])

  export default {ClassNoteRule,
                  DocumentRule,
                  LinkRule,
                  ImageRule}

  