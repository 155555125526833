import React, { Component } from 'react'
import {
    receiveMessage,
    requestMessages
} from "../../redux/messageList/MessageListAction";
import { connect } from 'react-redux';
class MessageList extends Component {

    componentDidMount() {
        console.log("connection", this.props.connection)
        this.props.connection.on(
            "ReceiveMessage",
                (userName,
                messageContent,
                chatRoomId,
                chatMessageId,
                postedAt,
                displayName,
                gender,
                userTypeName) => {
                this.props.onReceiveMessage(
                    userName,
                    messageContent,
                    chatRoomId,
                    chatMessageId,
                    postedAt,
                    displayName,
                    gender,
                    userTypeName
                );
            })
        this.props.onRequestMessages(this.props.roomId);
        this.scrollToBottom();
    }

    componentDidUpdate()
    {
        this.scrollToBottom();
    }

    scrollToBottom() {
        const element = document.getElementById("message-list");
        element.scrollIntoView({ behavior: 'smooth', block:'end'});
      }
    render() {
        if (!this.props.roomId) {
            return (
                <div className="text-danger">Not Authorized</div>
            );
        }
        return (
            <div className="scrollbar scrollbar-primary">
                <div className="force-overflow" id="message-list">
                    <div>
                        {this.props.messages.map((message, i) => (
                            <div className={(message.userName==this.props.username)? "direct-chat-msg right" :"direct-chat-msg"} >
                                <div className="direct-chat-infos clearfix">
                                    <span className="direct-chat-name float-left">{message.displayName}</span>
                                    <span className="direct-chat-timestamp float-right">{(message.postedAt)}</span>
                                </div>
                                <img className="direct-chat-img" src={(message.userTypeName=="Student")?
                                ((message.gender=="Male") ? 
                                "assets/avatar/student-male.png":"assets/avatar/student-female.png"):
                                ((message.gender=="Male") ? 
                                "assets/avatar/teacher-male.png":"assets/avatar/teacher-female.png")}
                                 alt="message user image" />
                                <div className={(message.userName==this.props.username)? "direct-chat-text bg-primary" :"direct-chat-text"}>
                                    {message.messageContent}
                                </div>
                            </div>))}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        messages: state.messages.messages,
        username: state.login.user.userName
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRequestMessages: roomId => dispatch(requestMessages(roomId)),
        onReceiveMessage: (
            userName,
            messageContent,
            chatRoomId,
            chatMessageId,
            postedAt,
            displayName,
            gender,
            userTypeName
        ) =>
            dispatch(
                receiveMessage(
                    userName,
                    messageContent,
                    chatRoomId,
                    chatMessageId,
                    postedAt,
                    displayName,
                    gender,
                    userTypeName
                )
            )
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MessageList);
