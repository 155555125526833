import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';


const ClassNote = (ClassNoteID) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const createPublish = () => {
        handleShow();
    }

    const editPublish = ()=> {

        handleShow();
    }
 

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Add Publish</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <form>
                        <div className="form-group">
                            <label htmlFor="displayname">Class</label>
                            <select className="form-control">
                                    <option>-- select --</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label htmlFor="todate">Date</label>
                            <input type="text" className="form-control"/>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={handleClose}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal>          
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">                            
                            <div className="row">
                                <div className="col-md-6" style={{verticalAlign:"middle"}}>
    <h5 style={{marginBottom:"0.4rem",marginTop:"0.4rem"}}>Publish Class Note {ClassNoteID}</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{marginBottom:"0.1rem",marginTop:"0.1rem"}} onClick={createPublish}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                        <div className="card-body">
                           
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Class Section</th>
                                        <th scope="col">Date</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">1</th>
                                        <td>BCA I Year A</td>
                                        <td className="text-right">
                                            <button onClick={editPublish} className="btn btn-info badge-pill" style={{width:'80px'}}>Edit</button>&nbsp;&nbsp;&nbsp;                                            
                                            <button className="btn btn-danger badge-pill" style={{width:'80px'}}>Delete</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClassNote;