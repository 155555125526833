import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'batchName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Batch Name is Required'
    },
    { 
      field: 'optionalSubjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'classSectionId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Section is Required'
    },
    { 
      field: 'teacherId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Teacher is Required'
    }
  ])

  