import React, { useState, useEffect } from 'react'
import ClassSectionDataService from "../../../services/ClassSectionDataService"
import CommonDataService from "../../../services/CommonDataService"
import ExamDataService from "../../../services/ExamDataService"
import { Button } from 'react-bootstrap'
import ValidationRule from './ValidationRule'

let classSectionListIds=""
const NewExam = () => {

    const validator= ValidationRule.NewExamAdminRule();
   
    const [examTitle, setExamTitle] = useState("")
    const [examGrade, setExamGrade] =useState("")
    const [timeBound, setTimeBound] = useState(false)
    const [remarks, setRemarks] =useState("")
    const [examGrades, setExamGrades] =useState([])
    const [allClassSection,setAllClassSection]=useState([])
    const [submitted,setSubmitted]=useState(false);
    const [loading,setLoading] = useState(false); 
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false);
    
    var validationData={
        examTitle:examTitle,
        examGrade:examGrade,
        remarks:remarks,
        validation:validation,
    }

    const handleTimeBoundClick = () => {
        setTimeBound(!timeBound)
    }

    useEffect(() =>{
        setLoading(true);
        fetchClassSection();
        fetchAllGrades()
    },[]) 


    const fetchClassSection = async () =>{
        let classSectionList=[]
        await ClassSectionDataService.allClassSection() 
          .then(response => {
            response.data.result.map((item,index)=>(
                classSectionList.push({id:item.classSectionId,value:item.classesName+ " " +item.sectionName})
            )); 
              setAllClassSection(classSectionList)
              setLoading(false);
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
       }

       const fetchAllGrades = async () =>
       {
           await CommonDataService.allGradeSchemes() 
           .then(response => {
               setExamGrades(response.data.result)
          })
          .catch(error => {
              const errorMsg=error.message;            
          })
       }

       const handleCheckClassSections = (event) =>{
        let chkclassSections =allClassSection
        classSectionListIds=""
        chkclassSections.forEach(sectiondata => {
           if (sectiondata.value === event.target.value)
           {
                sectiondata.isChecked =  event.target.checked
               
           }
           if(sectiondata.isChecked)
           {
               if(classSectionListIds.length >0 || classSectionListIds !="")
               {
                classSectionListIds+=","
               }
               classSectionListIds+=sectiondata.id
           }
        })
        setAllClassSection(chkclassSections)
      }
      

      const submitForm = async () =>
      {
        var data = {
            examId: 0,
            examName: examTitle,
            remarks:remarks,
            isTimeBound:timeBound,
            gradeSchemeId:parseInt(examGrade),
            classSections: classSectionListIds
          };
          const validate = validator.validate(validationData)
          setValidation({ validate });
          setValidateSubmitted(true)
        if (validate.isValid) {
            if(classSectionListIds.length>0)
            {
                await ExamDataService.createExam(data)
                    .then(response => {
                    })
                    .catch(error => {
                    })
                setSubmitted(!submitted)
                resetForm()
            }
            else
            {
                alert("You must choose at least one class section")
            }
        }

      }

      const resetForm = () =>{
        setExamTitle('');
        setExamGrade('');
        setRemarks("");
        classSectionListIds=""
        setValidation(validator.valid());
        setValidateSubmitted(false);
    }

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
  return (
    <div className="container">
        <div className="jumbotron">
            <div className="card card-primary">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>New Exam</h5>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-12">
                                <div className="form-group">
                                    <label>Exam Title</label>
                                    <input type="text" onChange={event => setExamTitle(event.target.value)} value={examTitle} name="examTitle" id="examTitle" className={validateOutput.examTitle.isInvalid ? 'form-control is-invalid':'form-control'}  placeholder="Exam Title" />
                                    <span className="text-danger">{validateOutput.examTitle.message}</span>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" onChange={handleTimeBoundClick} checked={timeBound} name="timeBound" id="timeBoundcurrent" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Time Bound</label>
                                </div>
                                <div className="form-group">
                                    <label>Grade</label>
                                    <select className={validateOutput.examGrade.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setExamGrade(event.target.value)} value={examGrade} name="examGrade" >
                                        <option value="">--select --</option>
                                        {examGrades.map(item => (
                                        <option
                                            key={item.gradeSchemeId}
                                            value={item.gradeSchemeId}
                                        >
                                            {item.gradeSchemeName}
                                        </option>
                                    ))}
                                    </select>
                                    <span className="text-danger">{validateOutput.examGrade.message}</span>
                                </div>
                                <div className="form-group">
                                    <label>Remarks</label>
                                    <textarea  onChange={event => setRemarks(event.target.value)} value={remarks} name="remarks" id="remarks" className={validateOutput.remarks.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Place some text here"
                                    style={{
                                        width: "100%", height: "100px", fontSize: "14px", lineHeight: "18px",
                                        border: "1px solid #dddddd", padding: "10px"
                                    }} />
                                     <span className="text-danger">{validateOutput.remarks.message}</span>
                                </div>
                                <div className="col-md-12">
                                    <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Class Sections</b></h6>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        {allClassSection.map((item,index)=>(
                                            <li key={index} className="list-group-item">
                                                <input key={item.id} onClick={event=>handleCheckClassSections(event)} type="checkbox" checked={item.isChecked} value={item.value} /> {item.value}
                                            </li>

                                        ))}
                                    </ul>
                                </div>
                                <div className="text-right">
                                <Button type="submit" variant="primary" onClick={()=>submitForm()}>
                                    Submit
                                </Button>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NewExam
