import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'teacherId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Please Select a Teacher'
    },
    { 
      field: 'academicYearId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide Academic Year'
    },
    { 
        field: 'classSectionId', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Pleave provide Class Section'
    },
    { 
        field: 'classSubjectId', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Pleave provide Class Subject'
    }
  ])

  