import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'hostName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Host Name is Required'
    },
    { 
      field: 'port', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave Provide Port Number'
    },
    { 
        field: 'userId', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Pleave Provide UserId'
    },
    { 
        field: 'password', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Pleave Provide Password'
    },
    { 
        field: 'displayName', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Pleave Provide Display Name'
    }
  ])

  