import  ApiDataService  from './ApiDataService'

const createClassSubject = data => {
  return ApiDataService.post("classsubject", data);
};

const allClassSubject = () => {
    return ApiDataService.get("classsubject/all");
  };

  const findClassSubject = (data) => {
    return ApiDataService.getWithParam("classsubject/find",data);
  };

const getClassSubject = (id) => {
  let values={'id':id}
    return ApiDataService.getWithParam("classsubject", values);
};

const updateClassSubject = data => {
    return ApiDataService.put("classsubject", data);
};

const deleteClassSubject = (id) => {
  let values={'id':id}
  return ApiDataService.remove("classsubject", values);
};

const getClassSubjectWithClassSection = (id) => {
  let values={'classeSectionId':id}
    return ApiDataService.getWithParam("classsubject/classsectionsubjects", values);
};

const getUserSubjects = (classesList,subjectName) => {
  let values={"classesList":classesList,"subjectName":subjectName}
  return ApiDataService.getWithParam("classsubject/usersubjects",values);
};

const getUserSubjectBySection = (classSectionList,subjectName) => {
  let values={"classSectionList":classSectionList,"subjectName":subjectName}
  return ApiDataService.getWithParam("classsubject/usersubjectsbysection",values);
};

const getTeacherSubjects = (classesList,subjectName) => {
  let values={"classesList":classesList,"subjectName":subjectName}
  return ApiDataService.getWithParam("classsubject/teachersubjects",values);
};

const getsubjectTeachers = (classSectionId,classSubjectId) => {
  let values={"classSectionId":classSectionId,"classSubjectId":classSubjectId}
  return ApiDataService.getWithParam("classsubject/subjectteachers",values);
};

const getSubjectWithFilter= (classesId,subjectName) => {
  let values={"classesId":classesId,"subjectName":subjectName}
  return ApiDataService.getWithParam("classsubject/allwithfilter",values);
};

export default {createClassSubject,
                allClassSubject,
                findClassSubject,
                getClassSubject,
                updateClassSubject,
                deleteClassSubject,
                getClassSubjectWithClassSection,
                getUserSubjects,
                getTeacherSubjects,
                getUserSubjectBySection,
                getsubjectTeachers,
                getSubjectWithFilter};