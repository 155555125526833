import React from 'react'

const TimelineAssignmentItem = () => {
  return (
    <>
      <i class="fas fa-envelope bg-blue"></i>
            <div class="timeline-item" style={{border:"0"}}>
                <div className="card card-outline card-primary">
                    <div className="card-header" id="headingOne">
                        <i className="d-flex justify-content-between"> 
                            <p className="text-muted text-xs">Subject: C++ Programming</p>
                            <p className="text-justify">02-06-2020, 10:00AM</p>                                   
                        </i>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="d-flex justify-content-between">
                                    <h5>Series Exam</h5>                                     
                                </div>
                            </div>
                            <div className="col-md-3">
                                <div className="row">
                                    <div className="col-md-6 border-left">
                                        <div className="description-block">
                                            <h5 className="description-header">100</h5>
                                            <span className="description-text">Total</span>
                                        </div>
                                    </div>  
                                    <div className="col-md-6 border-left">
                                        <div className="description-block">
                                            <h5 className="description-header">75</h5>
                                            <span className="description-text">Scored</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </>
  )
}

export default TimelineAssignmentItem
