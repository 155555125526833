import { 
        FETCH_LOGIN_REQUEST,
        FETCH_LOGIN_SUCCESS,
        FETCH_LOGIN_FAILURE, 
        LOGOUT_SUCCESS,
        CHANGE_IMAGE
        }from "./loginTypes"
import AuthDataService from "../../../services/AuthDataService"
import storage from 'redux-persist/lib/storage'
import history from "../../../util/history"


export const fetchLoginRequest = () => {
    return {
        type: FETCH_LOGIN_REQUEST
    }
}

export const fetchLoginSuccess = user => {
    return {
        type: FETCH_LOGIN_SUCCESS,
        payload: user
    }
}

export const fetchLoginFailure = error => {
    return {
        type: FETCH_LOGIN_FAILURE,
        payload: error
    }
}

export const logoutUser = () => {
    return {
        type:LOGOUT_SUCCESS
    }
}

export const fetchProfileImage = profileUrl => {
    return {
        type:CHANGE_IMAGE,
        payload:profileUrl
    }
}


export const fetchlogin = data =>{

    return async dispatch => {
        dispatch(fetchLoginRequest);
      await AuthDataService.signin(data)
      .then(response => {
         //console.warn("test",response.data.result)
            const user=response.data.result;
            if(response.data.result.userTypeName)
            {            
            let roles=[response.data.result.userTypeName];
            localStorage.setItem('roles', JSON.stringify(roles));
            localStorage.setItem('token',response.data.result.token);
            dispatch(fetchLoginSuccess(user))
            }
        })
        .catch(error => {
            //console.warn("test","Failure")
            const errorMsg=error.message;
            dispatch(fetchLoginFailure(errorMsg)) 
        })
    }
   
}

export const logout = () =>{
    console.log("logout action")
    return (dispatch) => {
        dispatch(logoutUser);
        localStorage.removeItem('roles'); 
        localStorage.removeItem('token');  
        storage.removeItem('persist:root'); 
        history.go("/");
    }
}

export const changeProfileImage = (imgSrc) =>{
    return (dispatch) => {
        dispatch(fetchProfileImage(imgSrc))
    }
}