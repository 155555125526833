import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import ClassSubjectDataService from "../../../services/ClassSubjectDataService"
import AcademicYearDataService from "../../../services/AcademicYearDataService"
import ClassSectionDataService from "../../../services/ClassSectionDataService"
import TeacherDataSevice from "../../../services/TeacherDataSevice"
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import Loader from '../../ui/loader/Loader'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import LearnPagination from '../../ui/pagination/LearnPagination'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

let subjectListIds = ""
const TeacherAssociation = (props) => {
    const validator= ValidationRule;

    const [show, setShow] = useState(false);
    const [buttonName, setButtonName] = useState('');
    const [teacherAssociationId, setTeacherAssociationId] = useState(0);
    const [teacherId,setTeacherId] =useState("");
    const [academicYearId, setAcademicYearId] = useState("");
    const [classSectionId, setClassSectionId] = useState ("");
    const [classSubjectId, setClassSubjectId] = useState("");
    const [allTeacher, setAllTeacher] = useState([]);
    const [allAcademicYear, setAllAcademicYear] =useState([]);
    const [allClassSection, setAllClassSection] = useState([]);
    const [allClassSubject, setAllClassSubject] = useState([]);
    const [allTeacherAssociation,setAllTeacherAssociation] = useState([]);
    const [formTitle, setFormTitle] = useState('');
    const [isEdit, setIsEdit] =useState(false);
    const [submitted,setSubmitted]=useState(false);
    const [loading,setLoading] = useState(false);
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)    

    const [searchName, setSearchName] = useState('')
    const [allsubjects,setAllSubjects]=useState([]) 
    const [classSections, setClassSections]= useState([])
    const [filterclassSectionId, setFilterClassSectionId] = useState("")
    const [isfilterModified,setisFilterModified]=useState(false)

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        teacherName:searchName,
        classSectionList:filterclassSectionId.toString(),
        classSubjectList:subjectListIds
    })
    

    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        teacherId:teacherId,
        academicYearId:academicYearId,
        classSectionId:classSectionId,
        classSubjectId:classSubjectId,
        validation:validation,
    }

    const createClassSubject = () => {
        resetForm();
        setTeacherAssociationId(0)
        setIsEdit(false);
        setFormTitle('Add');
        setButtonName('Save');
        handleShow();
    }

    const editTeacherAssociation = Id => {

        TeacherAssociationDataService.getTeacherAssociation(Id)
        .then(response => {
           setTeacherId(response.data.result.teacherId)
           setClassSectionId(response.data.result.classSectionId)
           setClassSubjectId(response.data.result.classSubjectId)
           setAcademicYearId(response.data.result.academicYearId)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
        fetchAllClassSubject();
        setTeacherAssociationId(Id);
        setIsEdit(true);
        setFormTitle('Edit');
        setButtonName('Update');
        handleShow();
    }
    const handleClickDelete = async id =>{
        setLoading(true)
        await TeacherAssociationDataService.deleteTeacherAssociation(id) 
        .then(response => {
            setLoading(false)
            setSubmitted(!submitted);          
            LearnToast(success,"Deleted Successfully")
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }       
        })
   }

    const deleteTeacherAssociation = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }

    const resetForm = () =>{
        setTeacherId("");
        setAcademicYearId("");
        setClassSectionId("");
        setClassSubjectId("");
        setTeacherAssociationId("");
        setIsEdit(false);
        setValidation(validator.valid()); 
        setValidateSubmitted(false);
       
    }
    

    useEffect(() => {
        fetchClassSections()
        setParametes()
    }, [submitted,isfilterModified,filterclassSectionId])

    useEffect(() => {
        fetchAllClassSetion();
        fetchAllAcademicYear();
        fetchAllTeacher();
        fetchTeacherAssociation();
    }, [params])
   

    useEffect(() =>{
        fetchAllClassSubject();
    },[classSectionId]) 

    useEffect(() =>{
        fetchfilterClassSubjects()
    },[filterclassSectionId])  

    const setParametes = () => {
        setParams({
        teacherName:searchName,
        classSectionList:filterclassSectionId.toString(),
        classSubjectList:subjectListIds.toString()
        })
    }


   const fetchTeacherAssociation = async () =>{
        setLoading(true);
        await TeacherAssociationDataService.findTeacherAssociation(params) 
        .then(response => {
           setAllTeacherAssociation(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false);
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchAllTeacher = async () =>
    {
        await TeacherDataSevice.allTeacher() 
        .then(response => {
            setAllTeacher(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchAllClassSetion = async () =>
    {
        await ClassSectionDataService.allClassSection() 
        .then(response => {
            setAllClassSection(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchAllClassSubject = async () =>
    {
        await ClassSubjectDataService.getClassSubjectWithClassSection(classSectionId) 
        .then(response => {
            setAllClassSubject(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchAllAcademicYear = async () =>
    {
        await AcademicYearDataService.allAcademicYear() 
        .then(response => {
            setAllAcademicYear(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }
 

    const submitForm = async () => {
        var data = {
            teacherAssociationId: parseInt(teacherAssociationId),
            teacherId: parseInt(teacherId),
            academicYearId: parseInt(academicYearId),
            classSectionId: parseInt(classSectionId),
            classSubjectId: parseInt(classSubjectId),
        };
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        
        if (validate.isValid) {
            setLoading(true)
            isEdit ? (
                await TeacherAssociationDataService.updateTeacherAssociation(data)
                    .then(response => {
                        setLoading(false)                                  
                        LearnToast(success,"updated Successfully")
                    })
                    .catch(error => {
                        let errorMsg=[]
                        let msgText=""
                        setLoading(false)      
                        if(error.response.status==500)
                        {
                            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                        }
                        else
                        {
                            errorMsg=error.response.data.errors
                            errorMsg.map((item,index)=>{
                                if(msgText.length>0)
                                {
                                msgText = msgText+","
                                }
                                msgText=msgText+item
                            })            
                                LearnToast(danger,msgText)
                        
                        }          
                    })
            ) : (
                    await TeacherAssociationDataService.createTeacherAssociation(data)
                        .then(response => {
                            setLoading(false)                                  
                            LearnToast(success,"Created Successfully")
                        })
                        .catch(error => {
                            let errorMsg=[]
                            let msgText=""
                            setLoading(false)      
                            if(error.response.status==500)
                            {
                                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                            }
                            else
                            {
                                errorMsg=error.response.data.errors
                                errorMsg.map((item,index)=>{
                                    if(msgText.length>0)
                                    {
                                    msgText = msgText+","
                                    }
                                    msgText=msgText+item
                                })            
                                    LearnToast(danger,msgText)
                            
                            }          
                        })
                )
            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }

    useEffect(() => {
        fetchClassSections()
    }, [])

    const fetchClassSections = async () =>{
        await ClassSectionDataService.allClassSection() 
          .then(response => {
              setClassSections(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
    }


    const fetchfilterClassSubjects = async () =>{
        let SubjectList = []
        setAllSubjects([])
        await ClassSubjectDataService.getClassSubjectWithClassSection(filterclassSectionId===""?0:parseInt(filterclassSectionId)) 
        .then(response => {
            response.data.result.map((subjectdata, index) => (
                SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
            ));
            setAllSubjects(SubjectList)
        })
        .catch(error => {
            const errorMsg=error.message;            
        })
    }

    const handleCheckSubjects = (event) => {
        let chksubject = allsubjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setAllSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }


    const serachByName=()=>{
        setisFilterModified(!isfilterModified)
      }




    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    
    return (
        <>
        <ToastContainer/>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>{formTitle} Teacher Association</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label htmlFor="teacherId"> Teacher Name</label>
                            <select className={validateOutput.teacherId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setTeacherId(event.target.value)} value={teacherId} name="teacherId" >
                                <option value="">-- select teacher --</option>
                                {allTeacher.map(item => (
                                    <option
                                        key={item.teacherId}
                                        value={item.teacherId}
                                    >
                                        {item.fullName}
                                    </option>
                                ))}
                            </select>
                            <span className="text-danger">{validateOutput.teacherId.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="academicYearId"> Academic Year</label>
                            <select className={validateOutput.academicYearId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setAcademicYearId(event.target.value)} value={academicYearId} name="academicYearId" >
                                <option value="">-- select academic Year --</option>
                                {allAcademicYear.map(item => (
                                    <option
                                        key={item.academicYearId}
                                        value={item.academicYearId}
                                    >
                                        {item.displayName}
                                    </option>
                                ))}
                            </select>
                            <span className="text-danger">{validateOutput.academicYearId.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="classSectionId"> Class Section</label>
                            <select className={validateOutput.classSectionId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setClassSectionId(event.target.value)} value={classSectionId} name="classSectionId" >
                                <option value="">-- select section --</option>
                                {allClassSection.map(item => (
                                    <option
                                        key={item.classSectionId}
                                        value={item.classSectionId}
                                    >
                                        {item.classesName + ' ' + item.sectionName}
                                    </option>
                                ))}
                            </select>
                            <span className="text-danger">{validateOutput.classSectionId.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="classSubjectId"> Class Subject</label>
                            <select className={validateOutput.classSubjectId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" >
                                <option value="">-- select subject --</option>
                                {allClassSubject.map(item => (
                                    <option
                                        key={item.classSubjectId}
                                        value={item.classSubjectId}
                                    >
                                        {item.subjectName}
                                    </option>
                                ))}
                            </select>
                            <span className="text-danger">{validateOutput.classSubjectId.message}</span>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={submitForm}>
                        {buttonName}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Teacher Association</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={createClassSubject}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-3">
                                    <div className="card card-outline card-primary">
                                        <div className="text-center">
                                            <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                        </div>
                                        <hr/>
                                        <div className="col-md-12">
                                            <div className="input-group">
                                                <div className="input-group input-group-sm">
                                                    <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control form-control-navbar" placeholder="Teacher Name" type="text" aria-label="Search"/>
                                                    <div className="input-group-append">
                                                        <button onClick={()=>serachByName()} className="btn btn-primary" type="submit">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Classes</b></h6>
                                            <select onChange={event => setFilterClassSectionId(event.target.value)} value={filterclassSectionId} name="filterclassSectionId" className="form-control">
                                                <option value="">-- select class section --</option>
                                                {classSections.map(item => (
                                                    <option 
                                                    key={item.classSectionId} 
                                                    value={item.classSectionId}
                                                    >                                                
                                                        {item.classesName + " " + item.sectionName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {allsubjects.length>0 &&
                                        <div className="col-md-12">
                                            <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                {allsubjects.map((subjectdata, index) => (
                                                    <li key={index} className="list-group-item">
                                                        <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        }
                                        <br/>
                                    </div>
                                </div>
                                {loading? (<Loader/>) :
                                    <div className="col-md-9">
                                        {allTeacherAssociation.length>0?
                                        <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Teacher Name</th>
                                                    <th scope="col">Academic Year</th>
                                                    <th scope="col">Class Section</th>
                                                    <th scope="col">Subject</th>

                                                    <th className="text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(allTeacherAssociation.map((teacherAssociation, index) => (
                                                    <tr key={teacherAssociation.teacherAssociationId}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{teacherAssociation.teacherName}</td>
                                                        <td>{teacherAssociation.academicYearName}</td>
                                                        <td>{teacherAssociation.classSectionName}</td>
                                                        <td>{teacherAssociation.classSubjectName}</td>
                                                        <td className="text-right">
                                                        <div className="d-flex justify-content-end">
                                                            <button onClick={() => editTeacherAssociation(teacherAssociation.teacherAssociationId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>
                                                            <button onClick={() => deleteTeacherAssociation(teacherAssociation.teacherAssociationId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                                        </div>
                                                        </td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                        </>
                                        :
                                        <div className="alert alert-info alert-dismissible">
                                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                                        </div>
                                    }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TeacherAssociation;