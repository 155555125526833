import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import ClassSubjectDataService from "../../../services/ClassSubjectDataService"
import CommonDataService from "../../../services/CommonDataService"
import Loader from '../../ui/loader/Loader'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import LearnPagination from '../../ui/pagination/LearnPagination';
import TeacherAssociationDataService from '../../../services/TeacherAssociationDataService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const ClassSubject = (props) => {
    const validator= ValidationRule;

    const [show, setShow] = useState(false);
    const [classesId, setClassesId] = useState("");
    const [subjectName, setSubjectName] = useState('');
    const [buttonName, setButtonName] = useState('');
    const [allClassSubject, setAllClassSubject] = useState([]);
    const [formTitle, setFormTitle] = useState('');
    const [isEdit, setIsEdit] =useState(false);
    const [classes,setClasses]=useState([]);
    const [submitted,setSubmitted]=useState(false);
    const [loading,setLoading] = useState(false);
    const [classSubjectId, setClassSubjectId] = useState(0);

    const[searchSubject, setSearchSubject] = useState("")
    const [allClasses,setAllClasses]=useState([])    
    const [classId, setClassId] = useState("")
    const [isfilterModified,setisFilterModified]=useState(false)

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        classesList: classId.toString(),
        subjectName: searchSubject
    })
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)   

    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        classesId:classesId,
        subjectName:subjectName,
        validation:validation,
    }

    const createClassSubject = () => {
        resetForm();
        setClassSubjectId(0)
        setIsEdit(false);
        setFormTitle('Add');
        setButtonName('Save');
        handleShow();
    }

    const editClassSubject = SubjectId => {

        ClassSubjectDataService.getClassSubject(SubjectId)
        .then(response => {
            setSubjectName(response.data.result.subjectName)
            setClassesId(response.data.result.classesId)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
        setClassSubjectId(SubjectId)
        setIsEdit(true);
        setFormTitle('Edit');
        setButtonName('Update');
        handleShow();
    }
    const handleClickDelete = async id =>{
        setLoading(true)
        await ClassSubjectDataService.deleteClassSubject(id) 
        .then(response => {
            setLoading(false)
            LearnToast(success,"Class Subject Deleted Successfully")
            setSubmitted(!submitted);
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }              
        })
   }

    const deleteClassSubject = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }

    const resetForm = () =>{
        setClassesId("")
        setSubjectName('');
        setIsEdit(false);
        setValidation(validator.valid());
        setValidateSubmitted(false);
    }

    useEffect(() => {
        setParametes()
    }, [submitted,isfilterModified, classId])

    useEffect(() => {
        fetchAllClasses();
        fetchClassSubject();
    }, [params])

    const setParametes = () => {
        setParams({
            classesList: classId.toString(),
            subjectName: searchSubject
        })
    }


   const fetchClassSubject = async () =>{       
        setLoading(true);
        await ClassSubjectDataService.findClassSubject(params) 
        .then(response => {
           setAllClassSubject(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false);
      })
       .catch(error => {
        setLoading(false);          
      })
    }

    const fetchAllClasses = async () =>
    {
        await CommonDataService.allClasses() 
        .then(response => {
            setClasses(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }
 

    const submitForm = async () => {
        var data = {
            classSubjectId: classSubjectId,
            classesId: parseInt(classesId),
            subjectName: subjectName,
          };
          const validate = validator.validate(validationData)
          setValidation({ validate });
          setValidateSubmitted(true)
        if (validate.isValid) {
            setLoading(true)
            isEdit ? (
                await ClassSubjectDataService.updateClassSubject(data)
                    .then(response => {
                        setLoading(false)
                        LearnToast(success,"Class Subject Updated Successfully")
                    })
                    .catch(error => {
                        let errorMsg=[]
                        let msgText=""
                        setLoading(false)      
                        if(error.response.status==500)
                        {
                            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                        }
                        else
                        {
                            errorMsg=error.response.data.errors
                            errorMsg.map((item,index)=>{
                                if(msgText.length>0)
                                {
                                msgText = msgText+","
                                }
                                msgText=msgText+item
                            })            
                                LearnToast(danger,msgText)
                        
                        }     
                    })
            ) : (
                    await ClassSubjectDataService.createClassSubject(data)
                        .then(response => {
                            setLoading(false)
                            LearnToast(success,"Class Subject Created Successfully")
                        })
                        .catch(error => {
                            let errorMsg=[]
                            let msgText=""
                            setLoading(false)      
                            if(error.response.status==500)
                            {
                                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                            }
                            else
                            {
                                errorMsg=error.response.data.errors
                                errorMsg.map((item,index)=>{
                                    if(msgText.length>0)
                                    {
                                    msgText = msgText+","
                                    }
                                    msgText=msgText+item
                                })            
                                    LearnToast(danger,msgText)
                            
                            }     
                        })
                )
            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }

    useEffect(() => {
        fetchClasses()
    }, [])

    const fetchClasses = async () =>{
        await CommonDataService.allClasses() 
          .then(response => {
              setAllClasses(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
       }
    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }


    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    
    return (
        <>
         <ToastContainer />    
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton >
                    <Modal.Title>{formTitle} Subject</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label htmlFor="classesId"> Class Name</label>
                            <select className={validateOutput.classesId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" >
                                <option value="">-- select class --</option>
                                {classes.map(item => (
                                    <option
                                        key={item.classesId}
                                        value={item.classesId}
                                    >
                                        {item.classesName}
                                    </option>
                                ))}
                            </select>
                            <span className="text-danger">{validateOutput.classesId.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="subjectName">Subject</label>
                            <input type="text" onChange={event => setSubjectName(event.target.value)} name="subjectName" id="subjectName" value={subjectName} className={validateOutput.subjectName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Subject Name" />
                            <span className="text-danger">{validateOutput.subjectName.message}</span>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={submitForm}>
                        {buttonName}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Class Subject</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={createClassSubject}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card card-outline card-primary">
                                        <div className="text-center">
                                            <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                        </div>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="input-group">
                                                <div className="input-group input-group-sm">
                                                    <input onChange={event => setSearchSubject(event.target.value)} value={searchSubject} name="searchSubject" id="searchSubject" className="form-control form-control-navbar" type="text" placeholder="Type Name" aria-label="Search" />
                                                    <div className="input-group-append">
                                                        <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Class</label>
                                                <select onChange={event => setClassId(event.target.value)} value={classId} name="classId" className="form-control">
                                                    <option value="">-- select class --</option>
                                                    {allClasses.map(item => (
                                                        <option 
                                                        key={item.classesId} 
                                                        value={item.classesId}
                                                        >                                                
                                                            {item.classesName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>  
                                    </div>
                                </div>
                                {loading? (<Loader/>) :
                                <div className="col-md-9">
                                    {allClassSubject.length>0?
                                    <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Class Name</th>
                                                <th scope="col">Subject</th>

                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(allClassSubject.map((ClassSubject, index) => (
                                                <tr key={ClassSubject.classSubjectId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{ClassSubject.classesName}</td>
                                                    <td>{ClassSubject.subjectName}</td>
                                                    <td className="text-right">
                                                    <div className="d-flex justify-content-end">
                                                        <button onClick={() => editClassSubject(ClassSubject.classSubjectId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>
                                                        <button onClick={() => deleteClassSubject(ClassSubject.classSubjectId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                                    </div>
                                                    </td>
                                                </tr>
                                            )))}
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ClassSubject;