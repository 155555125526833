import React from 'react'
import ReactApexChart from 'react-apexcharts'

class ApexBarChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        
          series: [{
            name: "Percent",
            data: props.seriesdata
          }],
          options: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: true,
              }
            },
            dataLabels: {
              enabled: false
            },
            xaxis: {
              categories: props.categories,
            }
          },
        
        
        };
      }

    

      render() {
        return (
          

    <div id="chart">
<ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
</div>

        );
      }
    }

export default ApexBarChart