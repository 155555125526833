import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

class UserLayout extends Component {
  componentDidMount() {
    const script = document.createElement("script");
    script.src = "/assets/logintemplate/js/main.js";
    script.async = true;
    document.body.appendChild(script);
  }



  render() {
    return (
      <>
        <Helmet>
            <link rel="stylesheet" type="text/css" href="/assets/logintemplate/css/style.css"/>
            <link href="https://fonts.googleapis.com/css?family=Poppins:600&display=swap" rel="stylesheet"/>
            <script src="https://kit.fontawesome.com/a81368914c.js"></script>
        </Helmet>
            <div>
                <img className="wave" src="assets/logintemplate/img/wave.png" alt="wave" />
                <div className="containerlogin">
                    <div className="img">
                        <img src="assets/logintemplate/img/bg.svg" alt="bg" />
                    </div>
                    {this.props.children}
                </div>
            </div>

            {/* <script type="text/javascript" src="assets/logintemplate/js/main.js"></script> */}
      </>
    );
  }
}

export default UserLayout;




    