import React, { useState, useEffect } from 'react'
import ClassSectionDataService from "../../../services/ClassSectionDataService"
import CommonDataService from "../../../services/CommonDataService"
import ExamDataService from "../../../services/ExamDataService"

let classSectionListIds=""
let examGradeListIds=""
const ExamListAdmin = () => {
    
    const [timeBound, setTimeBound] = useState(false)
    const [searchExam,setSearchExam]=useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [loading,setLoading] = useState(false);
    const [examGrades, setExamGrades] =useState([])
    const [allClassSection,setAllClassSection]=useState([])
    const [exams,setExams]=useState([])

    const handleTimeBoundClick = () => {
        setTimeBound(!timeBound)
    }

    useEffect(() =>{
       fetchAllGrades();
       fetchClassSection();
    },[]) 

    useEffect(() =>{
        setLoading(true);
        fetchExams();
    },[isfilterModified,timeBound  ]) 

    let values={
     "examName":searchExam,
     "isTimeboundOnly":timeBound,
     "gradeSchemes":examGradeListIds,
     "classSections":classSectionListIds
    }

    const fetchExams = async () =>{
        await ExamDataService.findExam(values) 
        .then(response => {
        setExams(response.data.result)
        setLoading(false)
       })
       .catch(error => {
           const errorMsg=error.message;   
                    
       })
    }

    const fetchClassSection = async () =>{
        let classSectionList=[]
        await ClassSectionDataService.allClassSection() 
          .then(response => {
            response.data.result.map((item,index)=>(
                classSectionList.push({id:item.classSectionId,value:item.classesName+ " " +item.sectionName,isChecked:false})
            )); 
              setAllClassSection(classSectionList)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
       }

    const fetchAllGrades = async () => {
        let gradeList = []
        await CommonDataService.allGradeSchemes()
            .then(response => {
                response.data.result.map((item, index) => (
                    gradeList.push({ id: item.gradeSchemeId, value: item.gradeSchemeName, isChecked:false })
                ));
                setExamGrades(gradeList)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const handleCheckClassSections = (event) => {
        let chkclassSections = allClassSection
        classSectionListIds = ""
        chkclassSections.forEach(sectiondata => {
            if (sectiondata.value === event.target.value) {
                sectiondata.isChecked = event.target.checked

            }
            if (sectiondata.isChecked) {
                if (classSectionListIds.length > 0 || classSectionListIds != "") {
                    classSectionListIds += ","
                }
                classSectionListIds += sectiondata.id
            }
        })
        setAllClassSection(chkclassSections)
        setisFilterModified(!isfilterModified)
    }

    const handleCheckGrades = (event) => {
        let chkGrades = examGrades
        examGradeListIds = ""
        chkGrades.forEach(gradedata => {
            if (gradedata.value === event.target.value) {
                gradedata.isChecked = event.target.checked

            }
            if (gradedata.isChecked) {
                if (examGradeListIds.length > 0 || examGradeListIds != "") {
                    examGradeListIds += ","
                }
                examGradeListIds += gradedata.id
            }
        })
        setExamGrades(chkGrades)
        setisFilterModified(!isfilterModified)
    }

    const serachByText=()=>{
        setisFilterModified(!isfilterModified)
      }

  return (
    <div className="container">
        <div className="jumbotron">
            <div className="card card-primary">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Exams</h5>
                        </div>
                        <br />
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                    <div className="col-md-3">
                            <div className="card card-outline card-primary">
                                <div className="text-center">
                                    <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                </div>
                                <hr/>
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <div className="input-group input-group-sm">
                                            <input onChange={event => setSearchExam(event.target.value)} value={searchExam} name="searchExam" id="searchExam" className="form-control form-control-navbar" type="text" placeholder="Exam Name" aria-label="Search"/>
                                            <div className="input-group-append">
                                                <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                                <div className="col-md-12">
                                    <div className="form-check">
                                        <input type="checkbox" onChange={handleTimeBoundClick} checked={timeBound} name="timeBound" id="timeBoundcurrent" className="form-check-input" />
                                        <label className="form-check-label" htmlFor="exampleCheck1">Time Bound</label>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Grade Schemes</b></h6>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        {examGrades.map((item,index)=>(
                                            <li key={index} className="list-group-item">
                                                <input key={item.id} onClick={event=>handleCheckGrades(event)} type="checkbox" checked={item.isChecked} value={item.value} /> {item.value}
                                            </li>

                                        ))}
                                    </ul>
                                </div>
                                <div className="col-md-12">
                                    <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Class Sections</b></h6>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        {allClassSection.map((item,index)=>(
                                            <li key={index} className="list-group-item">
                                                <input key={item.id} onClick={event=>handleCheckClassSections(event)} type="checkbox" checked={item.isChecked} value={item.value} /> {item.value}
                                            </li>

                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ExamListAdmin
