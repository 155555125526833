import React, { useState, useEffect } from 'react';
import Loader from '../../ui/loader/Loader';
import StudentDataService from "../../../services/StudentDataService"
import ClassNoteDataService from "../../../services/ClassNoteDataService"
import LearnPagination from '../../ui/pagination/LearnPagination';

let subjectListIds = ""
const StudentClassNote = () => {
    const [subjects, setSubjects] = useState([])
    const [classNotes, setClassNotes] =useState([])
    const [loading,setLoading] = useState(false);
    const [searchNote, setSearchNote] = useState("")
    const [isfilterModified, setisFilterModified] = useState(false)
    // const [classNoteFiles, setClassNoteFiles] = useState([])
    // const [classNoteImages, setClassNoteImages] = useState([])
    // const [classNoteLinks, setClassNoteLinks] = useState([])

    
    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        noteName: searchNote,
        noteDetail: "",
        subjectList: subjectListIds
    })

    useEffect(() =>{
        fetchSubjects();
    },[]) 

    useEffect(() => {
        setParametes()
    }, [isfilterModified])
    
    useEffect(() =>{
        setLoading(true)
        fetchClassNote();
    },[params]) 


    const setParametes = () => {
        setParams({
            noteName: searchNote,
            noteDetail: "",
            subjectList: subjectListIds
        })
    }

    const fetchClassNote = async () =>{
        await ClassNoteDataService.getStudentNotes(params) 
        .then(response => {
           setClassNotes(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchSubjects = async () => {
        let SubjectList = []
        await StudentDataService.getStudentSubjects()
        .then(response => {
            response.data.result.map((subjectdata, index) => (
                SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
            ));
            setSubjects(SubjectList)

        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }

    const handleCheckSubjects = (event) => {
        let chksubject = subjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }

    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }
    
  return (
    <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Class Note</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-3">
                                    <div className="card card-outline card-primary">
                                        <div className="text-center">
                                            <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                        </div>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="input-group">
                                                <div className="input-group input-group-sm">
                                                    <input onChange={event => setSearchNote(event.target.value)} value={searchNote} name="searchNote" id="searchNote" className="form-control form-control-navbar" type="text" placeholder="Topic..." aria-label="Topic.." />
                                                    <div className="input-group-append">
                                                        <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-md-12">
                                            <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                {subjects.map((subjectdata, index) => (
                                                    <li key={index} className="list-group-item">
                                                        <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                    </li>

                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {loading?<Loader/>:<> 
                                <div className="col-md-9">
                                {classNotes.length>0?
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                               
                                    {classNotes.map((item, index)=>(                                    
                                    <div className="card card-outline card-primary" key={index}>
                                        <div className="card-header" id="headingOne">
                                            <i className="d-flex justify-content-between">
                                            <p className="text-muted text-xs">Subject: {item.classSubjectName}</p>
                                            <p className="text-muted text-xs">Posted By: {item.teacherName}</p>
                                            <p className="text-muted text-xs">{item.publishDate}</p>
                                                    </i>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="d-flex justify-content-between">
                                                        <h5>{item.classNoteName}</h5>
                                                    </div>
                                                    <p className="text-justify">
                                                        {item.classNoteDetail}
                                                    </p>
                                                </div>
                                                {(item.classNoteImages !=null || item.classNoteFiles !=null)?
                                                    <div className="col-md-12 d-flex justify-content-start">
                                                        {item.classNoteFiles.map((file,fileindex)=>(
                                                            <>
                                                                <i className="fa fa-paperclip text-info" key={fileindex}></i> 
                                                                <a href={file.documentUrl}  target="_blank">{file.documentName}</a> &nbsp;&nbsp;
                                                            </>
                                                        ))}
                                                        {item.classNoteImages.map((image, imageindex)=>(
                                                            <>
                                                                <i className="fa fa-paperclip text-info" key={imageindex}></i> 
                                                                <a href={image.imageUrl} target="_blank">{image.displayName}</a> &nbsp;&nbsp;
                                                            </>
                                                        ))}
                                                    </div>
                                                :<></>}
                                                
                                                <div className="col-md-12 d-flex justify-content-start mt-3" >
                                                    {item.classNoteLinks.map((links, linkindex)=>(
                                                        <>
                                                            <i className="fa fa-link text-info" key={linkindex}></i> 
                                                            <a href={links.linkUrl} target="_blank" style={{borderRight:"2px solid #ddd",paddingRight:"5px"}}>{links.linkName}</a> &nbsp;&nbsp;
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                               
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                </>
                                    :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }
                                </div>
                                </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}

export default StudentClassNote
