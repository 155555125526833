import  ApiDataService  from './ApiDataService'

const createExamQuestionSetting = data => {
  return ApiDataService.post("examquestionsetting", data);
};

const allExamQuestionSetting = (id) => {
    let values={'id':id}
    return ApiDataService.getWithParam("examquestionsetting",values);
  };

  const deleteExamQuestionSetting = (id) => {
    let values={'id':id}
    return ApiDataService.remove("examquestionsetting",values);
  };

export default {createExamQuestionSetting,
                allExamQuestionSetting,
                deleteExamQuestionSetting}