import  ApiDataService  from './ApiDataService'

const createClassNoteShare = data => {
  return ApiDataService.post("classnoteshare", data);
};

const getClassNoteSections =  id => {
    let values={'classNoteId':id}
  return  ApiDataService.getWithParam("classnoteshare/noteclasssections",values);
};

const getClassNoteShareList = id => {
  let values={'classNoteId':id}
  return  ApiDataService.getWithParam("classnoteshare/list",values);
};

const deleteClassNoteShare = id => {
  let values={'classNoteShareId':id}
  return  ApiDataService.remove("classnoteshare",values);
};


export default {createClassNoteShare,
                getClassNoteSections,
                getClassNoteShareList,
                deleteClassNoteShare};