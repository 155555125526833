import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../../ui/loader/Loader';
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import CommonDataService from '../../../services/CommonDataService';
import ClassSubjectDataService from '../../../services/ClassSubjectDataService';
import QuestionPaperMasterDataService from "../../../services/QuestionPaperMasterDataService"
import QuestionPaperSectionDataService from "../../../services/QuestionPaperSectionDataService"
import ValidationRule from './ValidationRule'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';

let subjectListIds=""
const QuestionPaper = () => {
    const [show, setShow] = useState(false);
    const [formMode, setFormMode]= useState("QuestionPaperMaster")
    const [formTitle, setFormTitle] = useState("")
    const [modalSize, setModalSize] = useState("lg")
    const [submitted,setSubmitted]=useState(false);
    const [classes,setClasses] =useState([])
    const [allClassSubject, setAllClassSubject]= useState([]);    
    const [classesId,setClassesId] = useState("")
    const [questionPaperName,setQuestionPaperName] = useState("")
    const [classSubjectId,setClassSubjectId]= useState("")
    const [maxMark, setMaxMark] = useState("")
    const [loading,setLoading] = useState(false);
    const [isContinue, setIsContinue] = useState(false)

    const [questionPaperMstId, setQuestionPaperMstId]=useState(0)
    const [sectionTitle, setSectionTitle] = useState("")
    const [sectionOrder, setSectionOrder] = useState("")
    const [questionTypes,setQuestionTypes]=useState([])
    const [allQuestionSettings,setAllQuestionSettings]=useState([])
    const [questionType, setQuestionType] = useState("")
    const [requiredQuestion, setRequiredQuestion] = useState("")
    const [totalQuestion, setTotalQuestion] = useState("")
    const [questionTypemark, setQuestionTypeMark] = useState("")
    const [totalMark, setTotalMark] = useState(0)
    const [isAutoAnswer,setIsAutoAnswer] =useState("Manual")
    const [questionSection, setQuestionSection] = useState([])


    const [questionPapers,setQuestionPapers]= useState([])
    const [subjects,setSubjects]=useState([])  
    const [searchQus,setSearchQus]=useState("")    
    const [isfilterModified,setisFilterModified]=useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
            academicYearId:0,
            questionPaperName:searchQus,
            classSubjects:subjectListIds
         })

    const validator= ValidationRule.QuestionPaperMasterRule();    
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)

    const validatorQuestionSetting=ValidationRule.QuestionSettingRule();
    const [validationQuestionSetting, setValidationQuestionSetting] = useState(validatorQuestionSetting.valid());
    const [validateSubmittedQuestionSetting, setValidateSubmittedQuestionSetting]=useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        classSubjectId:classSubjectId,
        questionPaperName:questionPaperName,
        maxMark:maxMark,
        validation:validation,
    }

    var questionSettingValiationData={
        questionType:questionType,
        requiredQuestion:requiredQuestion,
        totalQuestion:totalQuestion,
        questionTypemark:questionTypemark,
        sectionTitle:sectionTitle,
        sectionOrder:sectionOrder,
        validation:validationQuestionSetting,
    }

//Filter

    const fetchTeacherSubjects = async () => {
        let SubjectList = []
        await ClassSubjectDataService.getTeacherSubjects()
            .then(response => {
                response.data.result.map((subjectdata, index) => (
                    SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
                ));
                setSubjects(SubjectList)

            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const handleCheckSubjects = (event) => {
        let chksubject = subjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }

    const serachByText=()=>{
        setisFilterModified(!isfilterModified)
      }


      useEffect(() => {
        setParametes()
    }, [isfilterModified,submitted])

    useEffect(() => {
        fetchQuestionPapers()
    }, [params])
    

    const setParametes = () => {
        setParams({
            academicYearId:0,
            questionPaperName:searchQus,
            classSubjects:subjectListIds
        })
    }

    const fetchQuestionPapers = async () =>{
        setLoading(true);
        await QuestionPaperMasterDataService.findQuestionPaperMaster(params) 
        .then(response => {
        setQuestionPapers(response.data.result.itemList)
        setTotalPages(response.data.result.totalPages)
        setActivePage(response.data.result.activePageNumber)
        setLoading(false)
       })
       .catch(error => {
        setLoading(false)   
                    
       })
    }

    //create

    const createQuestionPaper=()=>{
        setFormMode("QuestionPaperMaster")
        setFormTitle("Create Question Paper")
        handleShow()
    }

    useEffect(() =>{
        fetchTeacherCurrentClasses()
        fetchTeacherSubjects()
    },[]) 

    useEffect(() =>{
        fetchTeacherCurrentSubjects()
    },[classesId]) 

    const fetchTeacherCurrentClasses = async () =>{
        await TeacherAssociationDataService.getTeacherCurrentClasses()
        .then(response => {
            setClasses(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchTeacherCurrentSubjects = async () =>{
        await TeacherAssociationDataService.getTeacherCurrentSubjects((classesId=="")?0:parseInt(classesId)) 
        .then(response => {
            setAllClassSubject(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const saveQuestionPaper=()=>{
        setIsContinue(false)
        createQuestionPaperMaster(false)
    }

    const saveandContinueQuestionPaper=()=>{
        setIsContinue(true)     
        createQuestionPaperMaster(true)

    }

    const createQuestionPaperMaster = async (formstatus) =>{

        let data={
            questionPaperMstId:0,
            questionPaperName:questionPaperName,
            classSubjectId:parseInt(classSubjectId),
            maxMarks:parseInt(maxMark)
        };
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        if (validate.isValid)
        {
        setLoading(true)
          await QuestionPaperMasterDataService.createQuestionPaperMaster(data)
          .then(response => {
            setQuestionPaperMstId(response.data.result.id)
            setLoading(false)                    
            LearnToast(success,"Question Paper Created Successfully")
            resetCreateForm()
            if(!formstatus) 
               { 
                   handleClose()
                }
            else{                
                setFormMode("QuestionPaperSection")
                setFormTitle("Question Paper Section")
                fetchQuestionTypes()
                allQuestionPaperSection(response.data.result.id)
            }
            setSubmitted(!submitted)
          })
          .catch(error => { 
            handleClose()
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }
          })
        }
    }

    const resetCreateForm = () =>{
        setFormTitle("")
        setClassesId("")
        setClassSubjectId("")
        setMaxMark("")
        setValidation(validator.valid());
        setValidateSubmitted(false);   
    }

    const viewQuestionSection = (id) =>{
        resetCreateForm()
        setFormMode("QuestionPaperSection")
        setFormTitle("Question Paper Section")
        fetchQuestionTypes()
        allQuestionPaperSection(id)
        setQuestionPaperMstId(id)
        handleShow()
    }

    // Question Section

    useEffect(() => {
        setTotalMark(((requiredQuestion=="")?0:parseInt(requiredQuestion)) * ((questionTypemark=="")?0:parseInt(questionTypemark)))
    }, [questionTypemark, requiredQuestion])

    const fetchQuestionTypes = async () =>{
        let QuesTypes=[]
        await CommonDataService.allQuestionTypes() 
        .then(response => {
            setQuestionTypes(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const allQuestionPaperSection = async (questionPaperMstId) =>{
        await QuestionPaperSectionDataService.getQuestionPaperSection(questionPaperMstId) 
                .then(response => {
                    setQuestionSection(response.data.result)
            })
            .catch(error => {
                const errorMsg=error.message;            
            })

    }

    const saveQuestionSetting = async ()=>{

        let data={
            questionPaperSectionId: 0,
            questionPaperMstId: questionPaperMstId,
            sectionTitle: sectionTitle,
            questionTypeId: parseInt(questionType),
            requiredQuestions: parseInt(requiredQuestion),
            availableQuestions: parseInt(totalQuestion),
            markPerQuestion: parseInt(questionTypemark),
            sectionOrder: parseInt(sectionOrder),
            isAutoEvaluation: (isAutoAnswer==="Manual")?false:true
          }
       
        const validateQuestionsetting = validatorQuestionSetting.validate(questionSettingValiationData)
        setValidation({ validateQuestionsetting });
        setValidateSubmittedQuestionSetting(true)
        if(validateQuestionsetting.isValid)
        {
            setLoading(true)
            await QuestionPaperSectionDataService.createQuestionPaperSection(data) 
                .then(response => {
                    setLoading(false)
                    allQuestionPaperSection(questionPaperMstId)
                    LearnToast(success,"Saved Successfully")
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }
              })  
            resetQuestionSection()
        }
    }

    const resetQuestionSection = () =>{
        setSectionOrder("")
        setSectionTitle("")
        setIsAutoAnswer("Manual")
        setQuestionType("")
        setQuestionTypeMark("")
        setTotalMark(0)
        setRequiredQuestion("")
        setTotalQuestion("")    
        setValidationQuestionSetting(validatorQuestionSetting.valid())
        setValidateSubmittedQuestionSetting(false)   
    }

    const handleClickDeleteQuestionSetting= async (id) =>{
       
        setLoading(true)
        await QuestionPaperSectionDataService.deleteQuestionPaperSection(id) 
            .then(response => {
                setLoading(false)
                allQuestionPaperSection(questionPaperMstId)
                LearnToast(success,"Deleted Successfully")
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }
          })  

    }

    const deleteQuestionSection = (id)=>{
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await QuestionPaperMasterDataService.deleteQuestionPaperMaster(id) 
            .then(response => {
                setLoading(false)
                LearnToast(success,"Deleted Successfully")
                setSubmitted(!submitted);
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }     
            })
   }
   const publishQuestionPaper = async id =>{
    setLoading(true)
    await QuestionPaperMasterDataService.publishQuestionPaperMaster(id) 
        .then(response => {
            setLoading(false)
            LearnToast(success,"Published Successfully")
            setSubmitted(!submitted);
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }     
        })
}

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    let validateQuestionSetting= validateSubmittedQuestionSetting ? validatorQuestionSetting.validate(questionSettingValiationData) : questionSettingValiationData.validation 

  return (
    <>
        <ToastContainer />    
            {loading?<Loader/>:
            <>
                <Modal show={show} onHide={handleClose} size={modalSize}>
                    <Modal.Header closeButton>
                        <Modal.Title>{formTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {(formMode=="QuestionPaperMaster")?
                            <>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Class</label>
                                        <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className="form-control">
                                            <option value="">-- select class --</option>
                                            {classes.map(item => (
                                                <option 
                                                key={item.classesId} 
                                                value={item.classesId}
                                                >                                                
                                                    {item.classesName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label>Subject</label>
                                        <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className={validateOutput.classSubjectId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                            <option value="">-- select subject --</option>
                                            {allClassSubject.map(item => (
                                                <option
                                                    key={item.classSubjectId}
                                                    value={item.classSubjectId}
                                                >
                                                    {item.subjectName}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutput.classSubjectId.message}</span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Question Paper Name</label>
                                        <input type="text" onChange={event => setQuestionPaperName(event.target.value)} value={questionPaperName} name="questionPaperName" id="questionPaperName" className={validateOutput.questionPaperName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Question Paper Name" />
                                        <span className="text-danger">{validateOutput.questionPaperName.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Maximum Mark</label>
                                        <input type="number" onChange={event => setMaxMark(event.target.value)} value={maxMark} name="maxMark" id="maxMark" className={validateOutput.maxMark.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Maximum Mark" />
                                        <span className="text-danger">{validateOutput.maxMark.message}</span>
                                    </div>
                                </div></> : <></>}
                                {(formMode=="QuestionPaperSection")?
                                    <div>
                                    <div className="form-row">
                                        <div className="col">
                                            <label>Section Title</label>
                                            <input type="text" onChange={event => setSectionTitle(event.target.value)} value={sectionTitle} name="sectionTitle" id="sectionTitle" className={validateQuestionSetting.sectionTitle.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Section Title" />
                                            <span className="text-danger">{validateQuestionSetting.sectionTitle.message}</span>
                                        </div>
                                        <div className="col">
                                            <label>Section Order</label>
                                            <input type="number" onChange={event => setSectionOrder(event.target.value)} value={sectionOrder} name="sectionOrder" id="sectionOrder" className={validateQuestionSetting.sectionOrder.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Section Order" />
                                            <span className="text-danger">{validateQuestionSetting.sectionOrder.message}</span>
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col">
                                            <label>Question Type</label>
                                            <select className={validateQuestionSetting.questionType.isInvalid ? 'form-control is-invalid' : 'form-control'} onChange={event => setQuestionType(event.target.value)} value={questionType} name="questionType" id="questionType">
                                                <option value="">--select --</option>
                                                {questionTypes.map((item, index) => (
                                                    <option
                                                        key={index}
                                                        value={item.questionTypeId}
                                                    >
                                                        {item.questionTypeName}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="text-danger">{validateQuestionSetting.questionType.message}</span>
                                        </div>
                                        {!(questionType == "3" || questionType == "4" || questionType == "5" || questionType == "6") ?
                                            <div className="col">
                                                <label>Evaluation Mode</label>
                                                <select className="form-control" onChange={event => setIsAutoAnswer(event.target.value)} value={isAutoAnswer} name="isAutoAnswer" id="isAutoAnswer">
                                                    <option value="Manual">Manual</option>
                                                    <option value="Auto">Auto</option>
                                                </select>
                                            </div>
                                            : <></>}
                                        <div className="col">
                                            <label>Mark (for single question)</label>
                                            <input type="number" onChange={event => setQuestionTypeMark(event.target.value)} value={questionTypemark} name="questionTypemark" id="questionTypemark" className={validateQuestionSetting.questionTypemark.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Mark" />
                                            <span className="text-danger">{validateQuestionSetting.questionTypemark.message}</span>
                                        </div>
                                        <div className="col">
                                            <label>Total Mark</label>
                                            <input type="number" value={totalMark} disabled className="form-control text-bold " />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="col">
                                            <label>Required Questions (Total Questions to be Answered)</label>
                                            <input type="number" onChange={event => setRequiredQuestion(event.target.value)} value={requiredQuestion} name="requiredQuestion" id="requiredQuestion" className={validateQuestionSetting.requiredQuestion.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Required Questions" />
                                            <span className="text-danger">{validateQuestionSetting.requiredQuestion.message}</span>
                                        </div>
                                        <div className="col">
                                            <label>Total Questions (Total Available Questions in Exam)</label>
                                            <div className="input-group">
                                                <input type="number" onChange={event => setTotalQuestion(event.target.value)} value={totalQuestion} name="totalQuestion" id="totalQuestion" className={validateQuestionSetting.totalQuestion.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Total Questions" />
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary" onClick={() => saveQuestionSetting()} type="submit">
                                                        Save
                                                                </button>
                                                </div>
                                            </div>
                                            <span className="text-danger">{validateQuestionSetting.totalQuestion.message}</span>
                                        </div>
                                    </div>
                                    <br />
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Section</th>
                                                <th scope="col">Question Type</th>
                                                <th scope="col">Total Question</th>
                                                <th scope="col">Required</th>
                                                <th scope="col">Mark/Question</th>
                                                <th scope="col">Total mark</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {questionSection.map((item, index) => (
                                                <tr key={index}>
                                                    <td>{item.sectionTitle}</td>
                                                    <td>{item.questionTypeName}</td>
                                                    <td>{item.availableQuestions}</td>
                                                    <td>{item.requiredQuestions}</td>
                                                    <td>{item.markPerQuestion}</td>
                                                    <td>{item.totalMarks}</td>
                                                    <td className="text-right">
                                                        <button className="btn btn-link" onClick={() => handleClickDeleteQuestionSetting(item.questionPaperSectionId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                            <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    </div> : <></>}                                               
                    </Modal.Body>
                    <Modal.Footer>
                    {(formMode=="QuestionPaperMaster")?
                    <>
                        <Button variant="secondary" onClick={handleClose}>
                                Close
                        </Button>
                        <Button type="submit" onClick={()=>saveQuestionPaper()} variant="primary">
                                Save
                        </Button>
                        <Button type="submit" onClick={()=>saveandContinueQuestionPaper()} variant="primary">
                                Save and Continue
                        </Button>
                    </>:<></>}
                    {(formMode=="QuestionPaperSection")?
                    <>
                        <Button variant="secondary" onClick={handleClose}>
                                Close
                        </Button>
                    </>
                    :<></>}
                       

                    </Modal.Footer>
                </Modal>
                <div className="container">
                    <div className="jumbotron">
                        <div className="card card-primary">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Question Paper</h5>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={()=>createQuestionPaper()}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-3">
                                <div className="card card-outline card-primary">
                                    <div className="text-center">
                                        <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12">
                                    <div className="input-group">
                                        <div className="input-group input-group-sm">
                                            <input onChange={event => setSearchQus(event.target.value)} value={searchQus} name="searchQus" id="searchQus" className="form-control form-control-navbar" type="text" placeholder="Type Question Paper Name" aria-label="Search"/>
                                            <div className="input-group-append">
                                                <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    <div className="col-md-12">
                                        <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            {subjects.map((subjectdata, index) => (
                                                <li key={index} className="list-group-item">
                                                    <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                </li>

                                            ))}
                                        </ul>
                                    </div>
                                   </div>
                                </div>
                              
                                    <div className="col-md-9">
                                    {questionPapers.length>0?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                                {questionPapers.map((item,index)=>(
                                                    <div className="card card-outline card-primary" key={item.questionPaperMstId}>
                                                    <div className="card-header" id="headingOne">
                                                        <i className="d-flex justify-content-between"> 
                                                            <p className="text-muted text-xs">Prepared By: {item.preparedBy}</p>
                                                            <p className="text-muted text-xs">Prepared On : {item.preparedOn}</p>
                                                            {/* <button className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                                <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                            </button> */}
                                                        </i>
                                                        <div className="">
                                                            <h5>{item.questionPaperName}</h5> 
                                                            <h6>{item.classSubjectName}</h6>
                                                            
                                                        </div>  
                                                        <div className="d-flex justify-content-between">
                                                        
                                                            <Link className="btn btn-link" to={{pathname: '/app/view-question-paper/'+item.questionPaperMstId,props:{ id: item.questionPaperMstId}}}>
                                                                <span className="text-warning"><i className="fa fa-search"></i> View</span>
                                                            </Link>
                                                            {!item.isPublished?
                                                            <>
                                                                <button className="btn btn-link" onClick={()=>publishQuestionPaper(item.questionPaperMstId)}  type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                    <span className="text-success"><i className="fa fa-cog"></i> Publish</span>
                                                                </button> 
                                                                <button className="btn btn-link" onClick={()=>viewQuestionSection(item.questionPaperMstId)}  type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                    <span className="text-info"><i className="fa fa-plus"></i> Question Section</span>
                                                                </button> 
                                                                <Link className="btn btn-link" to={{pathname: '/app/questionsettings/'+item.questionPaperMstId,props:{ id: item.questionPaperMstId}}}>
                                                                    <span className="text-warning"><i className="fa fa-id-card"></i> Question Setting</span>
                                                                </Link>
                                                            </>:<></>}    
                                                            <button onClick={()=>deleteQuestionSection(item.questionPaperMstId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                                    <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                                </button>
                                                        </div>
                                                    </div>
                                                </div>

                                                ))}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                    </>
                                    :
                                       <div className="alert alert-info alert-dismissible">
                                           <div className="text-center text-white"><h4>No Records Found</h4></div>
                                       </div>
                                   }
                                    </div>
                                    
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
  )
}

export default QuestionPaper
