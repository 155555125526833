import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import ExaminationScheduleDataSevice from '../../../services/ExaminationScheduleDataSevice';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';
import Loader from '../../ui/loader/Loader';
import ClassSubjectDataService from "../../../services/ClassSubjectDataService"

let subjectListIds = ""
const ExamEvaluation = () => {
    const [evalSchedules, setEvalSchedules] = useState([])
    const [searchExam, setSearchExam] = useState("")
    const [allsubjects, setAllSubjects] = useState([])
    const [isfilterModified, setisFilterModified] = useState(false)
    const [loading, setLoading] = useState(false);

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        examName: searchExam,
        classSubjects: subjectListIds
    })


    useEffect(() => {
        fetchTeacherCurrentSubjects()
    }, [])

    const fetchTeacherCurrentSubjects = async () => {
        let SubjectList = []
        await ClassSubjectDataService.getUserSubjectBySection("", "")
            .then(response => {
                response.data.result.map((subjectdata, index) => (
                    SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
                ));
                setAllSubjects(SubjectList)

            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const handleCheckSubjects = (event) => {
        let chksubject = allsubjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setAllSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }

    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }

    useEffect(() => {
        setParametes()
    }, [isfilterModified])

    useEffect(() => {
        fetchSchedules()
    }, [params])

    const setParametes = () => {
        setParams({
            examName: searchExam,
            classSubjects: subjectListIds
        })
    }

    const fetchSchedules = async () => {
        setLoading(true)
        await ExaminationScheduleDataSevice.getTeacherEvaluationSchedules(params)
            .then(response => {
                setEvalSchedules(response.data.result.itemList)
                setTotalPages(response.data.result.totalPages)
                setActivePage(response.data.result.activePageNumber)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

    return (
        <>
            {loading ? <Loader /> :
                <div className="container">
                    <div className="jumbotron">
                        <div className="card card-primary">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Exam Evaluation</h5>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="card card-outline card-primary">
                                            <div className="text-center">
                                                <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                            </div>
                                            <hr />
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <div className="input-group input-group-sm">
                                                        <input onChange={event => setSearchExam(event.target.value)} value={searchExam} name="searchExam" id="searchExam" className="form-control form-control-navbar" type="text" placeholder="Exam Name" aria-label="Search" />
                                                        <div className="input-group-append">
                                                            <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                                <i className="fas fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="col-md-12">
                                                <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                                <ul className="list-group list-group-unbordered mb-3">
                                                    {allsubjects.map((subjectdata, index) => (
                                                        <li key={index} className="list-group-item">
                                                            <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-9">
                                        {evalSchedules.length>0?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                            </div>
                                            {evalSchedules.map((item, index) => (
                                                <div className="card card-outline card-primary" key={item.examId}>
                                                    <div className="card-header" id="headingOne">
                                                        <i className="d-flex justify-content-between">
                                                            <p className="text-muted text-xs">Class: {item.classesName}</p>
                                                            <p className="text-muted text-xs">Subject: {item.subjectName}</p>
                                                            {/* <button className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                            </button> */}
                                                        </i>
                                                        <div className="row">
                                                            <div className="col-md-8">
                                                                <div className="d-flex justify-content-between">
                                                                    <h5>{item.examName}</h5>

                                                                </div>
                                                                <i className="d-flex justify-content-between">
                                                                    <p className="text-muted text-xs">Exam Time: {item.scheduleDate + ", " + item.fromTime+" - "+ item.toTime}</p>
                                                                    {/* <p><i>Pending Valuation</i></p> */}
                                                                </i>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <div className="row">
                                                                    <div className="col-md-6 border-left">
                                                                        <div className="description-block">
                                                                            <h5 className="description-header">{item.totalAttended}</h5>
                                                                            <span className="description-text">Attended</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 border-left">
                                                                        <div className="description-block">
                                                                            <h5 className="description-header">{item.totalEvaluated}</h5>
                                                                            <span className="description-text">Evaluated</span>
                                                                        </div>
                                                                    </div> 
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                            <Link className="btn btn-link" to={{pathname: '/app/evaluate-exam-students/'+item.examScheduleId+'/'+item.examName,props:{ id: item.examScheduleId}}}>
                                                                <span className="text-warning"><i className="fa fa-id-card"></i> Valuate</span>
                                                            </Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            ))}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                            </div>
                                        </>
                                         :
                                         <div className="alert alert-info alert-dismissible">
                                             <div className="text-center text-white"><h4>No Records Found</h4></div>
                                         </div>
                                     }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default ExamEvaluation
