import  ApiDataService  from './ApiDataService'

const createClassNote = data => {
  return ApiDataService.post("classnote", data);
};

const findClassNote = (values) => {
  return  ApiDataService.getWithParam("classnote/find",values);
};

const getTeacherNotes = (values) => {
  return  ApiDataService.getWithParam("classnote/teachernotes",values);
};

const getStudentNotes = (vlaues) => {
  return  ApiDataService.getWithParam("classnote/studentnotes",vlaues);
};

const getClassNote = id => {
  let values={'id':id}
  return  ApiDataService.getWithParam("classnote",values);
};

const deleteClassNote = id => {
  let values={'id':id}
  return  ApiDataService.remove("classnote",values);
};

const createClassNoteDocument = data => {
  return ApiDataService.post("classnotedocument", data);
}

const getClassNoteDocument = id => {
  let values={'id':id}
  return  ApiDataService.getWithParam("classnotedocument",values);
};

const getClassNoteDocumentList = (id,documentTypeName,displayName) => {
  let values={'classNoteId':id,"documentTypeName":documentTypeName,"displayName":displayName}
  return  ApiDataService.getWithParam("classnotedocument/list",values);
};

const deleteClassNoteDocument = id => {
  let values={'id':id}
  return  ApiDataService.remove("classnotedocument",values);
};


export default {createClassNote,
                findClassNote,
                getTeacherNotes,
                getStudentNotes,
                getClassNote,
                deleteClassNote,
                createClassNoteDocument,
                getClassNoteDocument,
                getClassNoteDocumentList,
                deleteClassNoteDocument};