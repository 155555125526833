import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'subject', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'message', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide Message'
    }
  ])