import  ApiDataService  from './ApiDataService'

const createTeacherAssociation = data => {
  return ApiDataService.post("teacherassociation", data);
};

const allTeacherAssociation = () => {
    return ApiDataService.get("teacherassociation/all");
  };

const findTeacherAssociation = (data) => {
  return ApiDataService.getWithParam("teacherassociation/find",data);
};

const getTeacherAssociation = (id) => {
  let values={'id':id}
    return ApiDataService.getWithParam("teacherassociation", values);
};

const updateTeacherAssociation = data => {
    return ApiDataService.put("teacherassociation", data);
};

const deleteTeacherAssociation = (id) => {
  let values={'id':id}
  return ApiDataService.remove("teacherassociation", values);
};

const getTeacherCurrentClasses = () => {
  return ApiDataService.get("teacherassociation/currentclasses");
};

const getTeacherCurrentSubjects = (id) => {
  let values={"classesId":id}
  return ApiDataService.getWithParam("teacherassociation/currentsubjects",values);
};


const getTeacherCurrentSections = () => {
  return ApiDataService.get("teacherassociation/currentsections");
};

const getTeacherCurrentSubjectbySection = (id) => {
  let values={"classSectionId":id}
  return ApiDataService.getWithParam("teacherassociation/currentsubjectbysection",values);
};

const getTeachers = (values) => {
  return ApiDataService.getWithParam("teacherassociation/teachers",values);
};


export default {createTeacherAssociation,
                allTeacherAssociation,
                findTeacherAssociation,
                getTeacherAssociation,
                updateTeacherAssociation,
                deleteTeacherAssociation,
                getTeacherCurrentClasses,
                getTeacherCurrentSubjects,
                getTeacherCurrentSections,
                getTeacherCurrentSubjectbySection,
                getTeachers};