import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
      flexDirection: 'row'
    },
    pageSize:{    
      margin:20
    },
    section: {
      margin: 0,
      padding:10,
      flexGrow: 1,
      borderColor: "blue",
      border:1,
      fontSize:"12", 
      width:"15%"
    },
    section2: {
        margin: 0,
        padding:10,
        flexGrow: 1,
        borderColor: "blue",
        border:1,
        fontSize:"12",
        width:"40%"
      },
      head2: {
        margin: 0,
        padding:10,
        flexGrow: 1,
        borderColor: "blue",
        border:1,
        fontSize:"12",
        width:"40%",
        fontWeight:"15"
      },
      title:{
          fontSize:"14",
         textAlign:"center"
        },
        subtitle:{
            fontSize:"10",
            textAlign:"center",
            marginBottom:"10",
            marginTop:"2"
        },
        image: {
          marginVertical: 15,
          marginHorizontal: 10,
          textAlign:"center"
        }


     });

  

const PdfReportView = (props) => {
    const PDFdemo = () => {
        return(
            <Document>
                <Page size="A4">                    
                    <View style={styles.pageSize}>
                            <Image                          
                                style={styles.image}
                                src="/assets/dist/img/reportHeader.jpg"
                            />
                            <View style={styles.title}>
                                <Text>{props.reportTitle}</Text>
                            </View>
                            <View style={styles.subtitle}>
                                <Text>{props.reportSubTitle}</Text>
                            </View>
                        <View style={styles.page}>
                            <View style={styles.section2}>
                                <Text>Name</Text>
                            </View>

                            <View style={styles.section}>
                                <Text>Schedules</Text>
                            </View>
                            <View style={styles.section}>
                                <Text>Presents</Text>
                            </View>
                            <View style={styles.section}>
                                <Text>Absents</Text>
                            </View>
                            <View style={styles.section}>
                                <Text>Percent</Text>
                            </View>
                        </View>
                        {props.reportData.map((item, index) => (
                            <View style={styles.page}>
                                <View style={styles.section2}>
                                    <Text>{item.studentName}</Text>
                                </View>
                                <View style={styles.section}>
                                    <Text>{item.totalSchedules}</Text>
                                </View>
                                <View style={styles.section}>
                                    <Text>{item.totalPresent}</Text>
                                </View>
                                <View style={styles.section}>
                                    <Text>{item.totalAbsent}</Text>
                                </View>
                                <View style={styles.section}>
                                    <Text>{item.attendancePercent}</Text>
                                </View>
                            </View>
                        ))}
                    </View>

                </Page>
            </Document>
        )
    };
  return (
    <PDFViewer width="100%" height="450px">
    <PDFdemo/>
</PDFViewer>
  )
}

export default PdfReportView
