import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import Select from 'react-select';
import OptionalSubjectDataService from "../../../services/OptionalSubjectDataService"
import CommonDataService from "../../../services/CommonDataService"
import Loader from '../../ui/loader/Loader'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';

let options=[]
let classListIds=""
const OptionalSubject = () => {
    
    const validator= ValidationRule;
    const [show, setShow] = useState(false);
    const [classesId, setClassesId] = useState("");
    const [subjectName, setSubjectName] = useState('');
    const [searchText, setSearchText] = useState('');
    const [classes,setClasses]=useState([]);
    const [selectedClasses, setSelectedClasses] = useState([])
    const [optionalSubjects,setOptionalSubjects]=useState([]);
    const [submitted,setSubmitted]=useState(false);
    const [loading,setLoading] = useState(false);

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        subjectName:searchText
    })

    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)   

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        classesId:classesId,
        subjectName:subjectName,
        classListIds:classListIds,
        validation:validation
    }

    useEffect(() => {
        setParametes()
    }, [submitted])

    useEffect(() => {
        fetchOptionalSubject()
    }, [params])


    const setParametes = () => {
        setParams({
            subjectName: searchText
        })
    }

    const fetchOptionalSubject= async () => {
        setLoading(true)
        await OptionalSubjectDataService.findOptonalSubject(params)
            .then(response => {
                setOptionalSubjects(response.data.result.itemList)
                setTotalPages(response.data.result.totalPages)
                setActivePage(response.data.result.activePageNumber)
                setLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message;
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchAllClasses();
        fetchClassListToAssociate();
    }, [])

    
    const fetchAllClasses = async () =>
    {
        await CommonDataService.allClasses() 
        .then(response => {
            setClasses(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchClassListToAssociate = async () => {
        options=[]
        await CommonDataService.allClasses() 
            .then(response => {
                response.data.result.map((item, index)=>(
                    options.push({value:item.classesId,label:item.classesName})
                ));
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const submitForm = async () => {
        classListIds=""
        if(selectedClasses !==null)
        {
            selectedClasses.map((item, index)=>(
                (classListIds==="")?
                classListIds =item.value:
                classListIds =classListIds+"|" + item.value  
            ))
        }
        var data = {
            optionalSubjectId: 0,
            originClassesId: parseInt(classesId),
            optionalSubjectName: subjectName,
            classSubjectId:0,
            associatedClassesList:classListIds.toString()
          };
          const validate = validator.validate(validationData)
          setValidation({ validate });
          setValidateSubmitted(true)
            if (validate.isValid) {
                setLoading(true)
                await OptionalSubjectDataService.createOptonalSubject(data)
                    .then(response => {
                        setSubmitted(!submitted)
                        setLoading(false)
                        LearnToast(success, "Saved Successfully")
                        resetForm()
                    })
                    .catch(error => {   
                        let errorMsg=[]
                        let msgText=""
                        setLoading(false)      
                        if(error.response.status==500)
                        {
                            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                        }
                        else
                        {
                            errorMsg=error.response.data.errors
                            errorMsg.map((item,index)=>{
                                if(msgText.length>0)
                                {
                                msgText = msgText+","
                                }
                                msgText=msgText+item
                            })            
                                LearnToast(danger,msgText)
                        
                        }     
                    })
            }
    }

    const resetForm=()=>{        
        setValidation(validator.valid());
        setValidateSubmitted(false)
        handleClose()
        setSubjectName('')
        setClassesId('')
        options=[]
        classListIds=""
      }

    
    const serachByName=()=>{
        setSubmitted(!submitted)
      }

    const handleClickDelete = async id =>{
        setLoading(true)
        await OptionalSubjectDataService.deleteOptionalSubject(id) 
        .then(response => {
            setSubmitted(!submitted);
            setLoading(false)
            LearnToast(success, "Deleted Successfully")
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }               
        })
   }

    const deleteOptional = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 


  return (
    <>
    <ToastContainer/>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton >
                <Modal.Title>Add Optional Subject</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form>
                    <div className="form-group">
                        <label htmlFor="classesId"> Class Name</label>
                        <select className={validateOutput.classesId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" >
                            <option value="">-- select class --</option>
                            {classes.map(item => (
                                <option
                                    key={item.classesId}
                                    value={item.classesId}
                                >
                                    {item.classesName}
                                </option>
                            ))}
                        </select>
                        <span className="text-danger">{validateOutput.classesId.message}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="subjectName">Subject</label>
                        <input type="text" onChange={event => setSubjectName(event.target.value)} name="subjectName" id="subjectName" value={subjectName} className={validateOutput.subjectName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Subject Name" />
                        <span className="text-danger">{validateOutput.subjectName.message}</span>
                    </div>
                    <div className="form-group">
                        <label>Associated Classes</label>
                        <Select
                            value={selectedClasses}
                            isMulti
                            onChange={selectedClasses => setSelectedClasses(selectedClasses)}
                            options={options}
                        />
                         <span className="text-danger">{validateOutput.classListIds.message}</span>
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button type="submit" variant="primary" onClick={submitForm}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Optional Subject</h5>
                            </div>
                            <div className="col-md-6 text-right">
                                <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={handleShow}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">       
                        {loading? (<Loader/>) :                   
                            <div className="col-md-12">
                            <div className="row">
                                    <div className="col-md-12 d-flex justify-content-between">
                                            <div className="col-md-3">
                                                <div className="input-group">
                                                    <div className="input-group input-group-sm">
                                                        <input onChange={event => setSearchText(event.target.value)} value={searchText} name="searchText" id="searchText" className="form-control form-control-navbar" type="text" placeholder="Type Subjet Name" aria-label="Search"/>
                                                        <div className="input-group-append">
                                                            <button onClick={()=>serachByName()} className="btn btn-primary" type="submit">
                                                                <i className="fas fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                    </div>
                                </div>
                                <br/>
                                {optionalSubjects.length>0?
                                <>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Associated Classes</th>

                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {(optionalSubjects.map((item, index) => (
                                            <tr key={item.optionalSubjectId}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{item.optionalSubjectName}</td>
                                                <td>{item.associatedClassesList}</td>
                                                <td className="text-right">
                                                <div className="d-flex justify-content-end">
                                                    {/* <button onClick={() => editClassSubject(ClassSubject.classSubjectId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button> */}
                                                    <button onClick={() => deleteOptional(item.optionalSubjectId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                                </div>
                                                </td>
                                            </tr>
                                        )))}
                                    </tbody>
                                </table>
                                <div className="row">
                                    <div className="col-md-12">
                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                    </div>
                                </div>
                                </>
                                :
                                <div className="alert alert-info alert-dismissible">
                                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                                </div>
                            }
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);
}

export default OptionalSubject
