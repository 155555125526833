import React from 'react'

const StudentCalendarDisplay = () => {
  return (
    <div className="container">
        <div className="jumbotron">
          <div className="card card-primary">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                  <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Calandar</h5>
                </div>
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <iframe width="100%" height="100%" style={{border:"none"}} src="http://localhost:3000/#/calender"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default StudentCalendarDisplay
