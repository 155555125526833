import  ApiDataService  from './ApiDataService'

const createTeacher = data => {
  return ApiDataService.post("teacher", data);
};

const allTeacher = () => {
    return ApiDataService.get("teacher/all");
  };

  const allTeacherWithFilter = (teacherName) => {
    let data={"teacherName":teacherName}
    return ApiDataService.getWithParam("teacher/allwithfilter",data);
  };
  const findTeacher = (data) => {
    return ApiDataService.getWithParam("teacher/find",data);
  };

const getTeacher = (id) => {
  let values={'id':id}
    return ApiDataService.getWithParam("teacher", values);
};

const updateTeacher = data => {
    return ApiDataService.put("teacher", data);
};

const deleteTeacher = (id) => {
  let values={'id':id}
  return ApiDataService.remove("teacher", values);
};

const getTeacherProfile = (id) => {
  let values={'teacherId':id}
    return ApiDataService.getWithParam("teacher/profile", values);
};

export default {createTeacher,
                allTeacher,
                allTeacherWithFilter,
                findTeacher,
                getTeacher,
                updateTeacher,
                deleteTeacher,
                getTeacherProfile};