import React, { useState, useEffect } from 'react'
import { PieChart } from 'react-minimal-pie-chart';
const StaffAttendanceDetail = (props) => {


  return (
        
        <div className="row">
          <div className="col-md-4 text-center">
          <div className="card card-outline">
              <div className="card-header">
                  <h5 className="card-title">Overall Attendance</h5>
              </div>
              <div className="card-body box-profile">
              {/* <h5>Overall Attendance</h5>                                                 */}
                  <span className="mr-2">
                      <i className="fas fa-square text-success"></i> Present - {props.profile.totalPresent}
                  </span>
                  &nbsp; &nbsp;
                  <span className="mr-2">
                      <i className="fas fa-square text-danger"></i> Absent - {props.profile.totalAbsent}
                  </span> 
              <PieChart
                  data={[                                                        
                      { title: 'Absent', value: props.profile.totalAbsent, color: '#FF0000' },
                      { title: 'Present', value: props.profile.totalPresent, color: '#008000' }
                  ]}
                  label={({ dataEntry }) => dataEntry.value}
                  labelStyle={(index) => ({
                      fill: "#ffffff",
                      fontSize: '10px',
                      fontFamily: 'sans-serif',                                                                    
                      })}
                      radius={40}
              />      
              </div>   
              </div>                                           
          </div>
          <div className="col-md-8"> 

          <div className="card card-outline">
              <div className="card-header">
                  <h5 className="card-title">Subject Attendance</h5>
              </div>
              <div className="card-body box-profile">  
              {/* <h5 className="text-center">Subject Attendance</h5>  */}
              <br/>
                  {props.attendanceProfileSummaries.map((item,index)=>(
                      <div  className="row" key={index}>
                          <div className="col-md-12">
                              <div className="progress-group">
                                  {item.classSubjectName} - {item.presentPercent}%
                                  <div className="progress progress-sm">
                                      <div className={item.progressbarClassName} style={{ width: item.presentPercent+"%" }} />
                                  </div>
                              </div>
                          </div>
                      </div>
                  ))}
                  </div>
                  <div className="card-footer">
                  <div className="d-flex justify-content-between">
                      <span className="mr-2">
                          <i className="fas fa-square text-success"></i> Above 80%
                      </span>
                      <span className="mr-2">
                          <i className="fas fa-square text-warning"></i> Between 60% and 80%
                      </span>
                      <span className="mr-2">
                          <i className="fas fa-square text-danger"></i> Lessthan 60%
                      </span>
                  </div>
                  </div>
              </div>
          </div>
      </div>

  )
  }

export default StaffAttendanceDetail
