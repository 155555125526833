import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const Navigation = (props) => {

    useEffect(()=>{
        // console.log(props.routes)
        const trees = window.$('[data-widget="treeview"]');
        trees.Treeview('init');
    },[])

    return(
        <nav className="mt-2">            
            {/* <ul className="nav nav-pills nav-sidebar flex-column nav-compact nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">     
                {props.routes.map((route) => (
                    route.inMenu  && route.module=="0"?( <li className="nav-item" key={route.url}>
                        <Link
                            className="nav-link"
                            to={`${props.path}${route.url}`}
                        >
                            <i className="nav-icon far fa-circle text-info" />
                            <p>{route.title}</p>
                        </Link>
                    </li>):<></>
                ))}
                {props.modules.map((module)=>(
                    <li className="nav-item has-treeview">
                        <Link href="#"
                            className="nav-link">
                                <i className={"nav-icon "+module.icon}/>
                                <p>
                                    {module.title}
                                <i className="right fas fa-angle-left" />
                                </p>
                        </Link>
                        <ul className="nav nav-treeview">
                            {props.routes.map((route) => (
                            route.inMenu && route.module==module.moduleName?( <li className="nav-item" key={route.url}>
                                    <Link
                                        className="nav-link"
                                        to={`${props.path}${route.url}`}
                                    >
                                        <i className="nav-icon far fa-circle text-info" />
                                        <p>{route.title}</p>
                                    </Link>
                                </li>):<></>
                            ))}
                        </ul>
                    </li>
                ))}                
            </ul> */}
             <ul className="nav nav-pills nav-sidebar flex-column nav-compact nav-child-indent" data-widget="treeview" role="menu" data-accordion="false">     
                {props.routes.map((route,i) => {
                        // console.log(route)
                    return(
                    (route.inMenu && !route.ismodule)?                        
                        <li className="nav-item" key={i}>
                            <Link
                                className="nav-link"
                                to={`${props.path}${route.url}`}
                            >
                                <i className={route.icon} />
                                <p>{route.title}</p>
                            </Link>
                        </li>
                        :            
                        route.ismodule?
                          <li className="nav-item has-treeview" key={i}>
                                <Link to="/"
                                    className="nav-link">
                                        <i className={route.icon}/>
                                        <p>
                                            {route.title}
                                        <i className="right fas fa-angle-left" />
                                        </p>
                                </Link>
                                <ul className="nav nav-treeview">
                                    {route.children.map((childroute,j) => (
                                        
                                    <li className="nav-item" key={j}>
                                            <Link
                                                className="nav-link"
                                                to={`${props.path}${childroute.url}`}
                                            >
                                                <i className={childroute.icon} />
                                                <p>{childroute.title}</p>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </li>
                        
                        :<></>
                )})}
                 
            </ul>
         </nav>
            
    );
}

export default Navigation;