import React, { useState, useEffect } from 'react';
import ClassScheduleDataService from "../../../services/ClassScheduleDataService"
import Loader from '../../ui/loader/Loader';
import LearnPagination from '../../ui/pagination/LearnPagination';

const StudentShedule = () => {
    
    const [loading,setLoading] = useState(false);
    const [classShedules, setClassSchedules] = useState([])
    const [searchDate,setSearchDate]=useState(new Date().toLocaleDateString())
    const [isfilterModified,setisFilterModified]=useState(false)

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        periodFrom:searchDate,
        periodTo:searchDate,
        classList:"",
        subjectList:""
         })

  
    useEffect(() => {
        setParametes()
    }, [isfilterModified,])

    useEffect(() => {
        fetchClassSchedules()
    }, [params])  

    const setParametes = () => {
        setParams({
            periodFrom:searchDate,
            periodTo:searchDate,
            classList:"",
            subjectList:""
        })
    }

    const fetchClassSchedules = async () =>{
        setLoading(true)
        await ClassScheduleDataService.findStudentClassSchedule(params) 
        .then(response => {
            setClassSchedules(response.data.result.itemList)
            setTotalPages(response.data.result.totalPages)
            setActivePage(response.data.result.activePageNumber)
            setLoading(false)
       })
       .catch(error => {
           const errorMsg=error.message;      
           setLoading(false)      
       })
    }

    const serachByDate=()=>{
        setisFilterModified(!isfilterModified)
      }

  return (
    <div className="container">
    <div className="jumbotron">
        <div className="card card-primary">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Class Schedule</h5>
                    </div>
                    <br/>
                    <br/>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card card-outline card-primary">
                            <div className="text-center">
                                <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                            </div>
                            <hr/>
                            <div className="col-md-12">
                                <div className="input-group">
                                    <div className="input-group input-group-sm">
                                        <input onChange={event => setSearchDate(event.target.value)} value={searchDate} name="searchDate" id="searchDate" className="form-control form-control-navbar" type="date" aria-label="Search"/>
                                        <div className="input-group-append">
                                            <button onClick={()=>serachByDate()} className="btn btn-primary" type="submit">
                                                <i className="fas fa-search"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                        </div>
                    </div>
                    {loading?<Loader/>:                   
                    <div className="col-md-9">
                    {classShedules.length>0?
                        <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                    </div>
                                </div>
                            {classShedules.map((item,index)=>(
                                    <div className="card card-outline card-primary">
                                        <div className="card-header" id="headingOne">
                                            <i className="d-flex justify-content-between"> 
                                                <p className="text-muted text-xs">Scheduled By:  {item.createdBy}</p>
                                                <p className="text-muted text-xs">Posted On:  {item.createdOn} </p>
                                            </i>
                                            <div className="row">
                                                <div className="col-md-7">
                                                    <h5>Class: {item.classSectionName}</h5> 
                                                    <h6>Subject:  {item.classSubjectName}</h6>   
                                                    <h6>Teacher: {item.teacherName} </h6>
                                                    <div className="d-flex justify-content-between">                                  
                                                        <i> 
                                                            <p className="text-muted">Date:  {item.scheduleDate} <br/> Time:  {item.fromTime + " - " + item.toTime}</p>  
                                                        </i>                                                                    
                                                    </div>  
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                ))}
                                <div className="row">
                                    <div className="col-md-12">
                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                    </div>
                                </div>
                        </>
                    :
                        <div className="alert alert-info alert-dismissible">
                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                        </div>
                     }
                     </div>
                    }
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default StudentShedule
