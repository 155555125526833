import React, { useState, useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import LearnPagination from '../../ui/pagination/LearnPagination'
import ClassSubjectDataService from '../../../services/ClassSubjectDataService'
// import TeacherAssociationDataService from '../../../services/TeacherAssociationDataService'
import Loader from '../../ui/loader/Loader'
import AssignmentDataService from '../../../services/AssignmentDataService';
import ClassSectionDataService from '../../../services/ClassSectionDataService';

const AdminAssignment = () => {    
    const [show, setShow] = useState(false);
    const [students, setStudents]= useState([])
    const [searchTopic, setSearchTopic] = useState("")
    const [isfilterModified, setisFilterModified] = useState(false)
    const [loading, setLoading] = useState(false);
    const [allClasses, setAllClasses] = useState([])
    const [classSubjects, setClassSubjects] = useState([])
    // const [teachers, setTeachers] = useState([])
    const [classId, setClassId] = useState("")
    const [subjectId, setSubjectId] = useState("")
    const [assignments, setAssignments] = useState([])
    // const [teacherId, setTeacherId] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        topicName: searchTopic,
        topicDetail:"",
        classSectionList:classId.toString(),
        classSubjectList:subjectId.toString()
    })
    const [teacherFilterData, setTeacherFilterData] = useState({
        classesId: 0,
        classSubjectId: 0
    })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() => {
        fetchClasses();
    }, [])

    // useEffect(() => {
    //     fetchAllTeachers()
    // }, [teacherFilterData])

    useEffect(() => {
        fetchClassSubjects();
    }, [classId])


    const fetchClasses = async () => {
        await ClassSectionDataService.allClassSection()
            .then(response => {
                setAllClasses(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchClassSubjects = async () => {
        setClassSubjects([])
        await ClassSubjectDataService.getClassSubjectWithClassSection(classId)
            .then(response => {
                setClassSubjects(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }

    const changeClass = async (value) => {
        setClassSubjects([])
        setSubjectId("")
        setClassId(value)
    }

    const changeSubject = async (value) => {
        setSubjectId(value)
        // setTeachers([])
        // setTeacherId("")
        // setTeacherFilterData({
        //     classesId: (classId == "") ? 0 : parseInt(classId),
        //     classSubjectId: (value == "") ? 0 : parseInt(value)
        // })
    }

    useEffect(() => {
        setParametes()
    }, [isfilterModified, classId, subjectId])

    useEffect(() => {
        setLoading(true)
        fetchAssignments();
    }, [params])

    const setParametes = () => {
        setParams({
            topicName: searchTopic,
            topicDetail:"",
            classSectionList:classId.toString(),
            classSubjectList:subjectId.toString()
        })
    }

    const fetchAssignments = async () => {
        setLoading(true)
        await AssignmentDataService.findAssignment(params)
            .then(response => {
                setAssignments(response.data.result.itemList)
                setTotalPages(response.data.result.totalPages)
                setActivePage(response.data.result.activePageNumber)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }


    
  const allAssignmentStudents = async (id) => {
      setLoading(true)
    await AssignmentDataService.assignmentStudents(id)
    .then(response => {
        setStudents(response.data.result)
        setLoading(false)
        handleShow()
    })
    .catch(error => {
        setLoading(false)
    })
  }

    return (
        <>
        <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                <Modal.Title>Assignments</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="col-md-12">
                    <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Student Name</th>                
                <th scope="col">Status</th>
                
                <th scope="col">Mark</th>
                <th className="text-right">Download</th>
              </tr>
            </thead>
            <tbody>
              {students.map((item, index)=>(            
              <tr key={index}>
                <th scope="row">{index+1}</th>
                <td>{item.studentName}</td>
                {item.isSubmitted && !item.isEvaluated && item.isGraded?
                  <td className="text-warning">Not Evaluated</td>
                  :item.isSubmitted && item.isEvaluated && item.isGraded?
                  <td className="text-success">Valuated</td>
                  :!item.isSubmitted?
                  <td className="text-danger">Not Submitted</td>
                  :item.isSubmitted && !item.isGraded?
                  <td className="text-success">Submitted</td>
                  :<td></td>
                }
                {item.isGraded?
                <>
                  {item.isSubmitted && item.markAwarded>-1?
                    <td>
                      {item.markAwarded}
                    </td>
                    :item.isSubmitted && item.markAwarded==-1?
                    <td>
                       -
                    </td>
                    :
                    <td>
                        -
                    </td>
                  }
                </>      
                :<>
                {item.isSubmitted && item.markAwarded==-2?
                    <td>
                     <p className="text-success">Valuated</p>
                    </td>
                    :item.isSubmitted && item.markAwarded==-1?
                    <td>
                       <p className="text-danger">Not Evaluated</p>
                    </td>
                    :
                    <td>
                        Non Graded Assignment
                    </td>
                  }
                </>
                }
                {item.isSubmitted && item.hasCorrectedDocument?
                  <td className="text-right">
                    <a href={item.submittedDocumentName} target="_blank" className="btn btn-info"><i className="fas fa-download"></i></a>&nbsp;&nbsp;&nbsp;
                    <a href={item.correctedDocumentName} target="_blank" className="btn btn-info"><i className="fas fa-upload"></i></a>
                    {/* <button onClick={()=>uploadCorrectedFile(item.assignmentStudentId)} className="btn btn-info"><i className="fas fa-upload"></i></button> */}
                  </td>
                :item.isSubmitted && !item.hasCorrectedDocument?
                <td className="text-right">
                  <a href={item.submittedDocumentName} target="_blank" className="btn btn-info"><i className="fas fa-download"></i></a>&nbsp;&nbsp;&nbsp;
                  {/* <a href="" disabled className="btn btn-info"><i className="fas fa-upload"></i></a> */}
                  <button className="btn btn-info" disabled><i className="fas fa-upload"></i></button>
                </td>
                :!item.isSubmitted?
                <td className="text-right">
                    <button className="btn btn-info" disabled><i className="fas fa-download"></i></button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-info" disabled><i className="fas fa-upload"></i></button>
                </td>:<></>
                }
              </tr>
                ))}
            </tbody>
          </table>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal> 
        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Assignments</h5>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card card-outline card-primary">
                                    <div className="text-center">
                                        <h5 style={{ marginTop: "10px" }}>Filtering Options</h5>
                                    </div>
                                    <hr />
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <div className="input-group input-group-sm">
                                                <input onChange={event => setSearchTopic(event.target.value)} value={searchTopic} name="searchTopic" id="searchTopic" className="form-control form-control-navbar" type="text" placeholder="Topic..." aria-label="Topic.." />
                                                <div className="input-group-append">
                                                    <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                        <i className="fas fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class</label>
                                            <select onChange={event => changeClass(event.target.value)} value={classId} name="classId" className="form-control">
                                                <option value="">-- select class --</option>
                                                {allClasses.map(item => (
                                                    <option
                                                        key={item.classSectionId}
                                                        value={item.classSectionId}
                                                    >
                                                        {item.classesName +" "+ item.sectionName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class Subject</label>
                                            <select onChange={event => changeSubject(event.target.value)} value={subjectId} name="subjectId" className="form-control">
                                                <option value="">-- select subject --</option>
                                                {classSubjects.map(item => (
                                                    <option
                                                        key={item.classSubjectId}
                                                        value={item.classSubjectId}
                                                    >
                                                        {item.subjectName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    {/* <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Teacher</label>
                                            <select onChange={event => setTeacherId(event.target.value)} value={teacherId} name="teacherId" className="form-control">
                                                <option value="">-- select teacher --</option>
                                                {teachers.map(item => (
                                                    <option
                                                        key={item.teacherId}
                                                        value={item.teacherId}
                                                    >
                                                        {item.teacherName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div> */}

                                </div>

                            </div>
                            <div className="col-md-9">
                                {loading ? <Loader /> :
                                    <>
                                        {assignments.length>0?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                            </div>
                                            {assignments.map((item, index) => (
                                                <div className="card card-outline card-primary">
                                                    <div className="card-header">
                                                        <div class="row">
                                                            <div className="col-md-8">
                                                                <h2 className="card-title">
                                                                    <span class="info-box-icon text-success"><i class="far fa-bookmark"></i> <b>{item.topicName}</b></span>
                                                                </h2>
                                                            </div>
                                                            <div className="col-md-4 text-right">
                                                                <p><i>Due On {item.dueDate}</i></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col-md-9">
                                                                <p>Posted On {item.postedOn}</p>
                                                                <h5>Class {item.classSectionName}</h5>
                                                                <h5>Subject {item.classSubjectName}</h5>
                                                                <p>{item.topicDetail}</p>
                                                            </div>
                                                            <div className="col-md-3">
                                                                <div className="row">
                                                                    <div className="col-md-6 border-left">
                                                                        <div className="description-block">
                                                                            <h5 className="description-header">{item.totalAssigned}</h5>
                                                                            <span className="description-text">Assigned</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 border-left">
                                                                        <div className="description-block">
                                                                            <h5 className="description-header">{item.totalSubmitted}</h5>
                                                                            <span className="description-text">Turned In</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-12 text-right">
                                                                        <br />
                                                                        <br />
                                                                        {item.isEvaluated ?
                                                                            <span class="info-box-icon text-success"><i class="fa fa-check"></i> Evaluated Successfully</span>
                                                                            :
                                                                            <span class="info-box-icon text-danger"><i class="fa fa-times"></i> Pending Evaluation</span>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {/* <div className="col-md-12">
                                                            <div className="d-flex justify-content-between">
                                                                <button className="btn btn-link" onClick={()=>openInstruction(item.assignmentId)}><span className="text-info"><i className="fas fa-search"> &nbsp; Instructions</i></span></button>
                                                                <button className="btn btn-link" onClick={()=>deleteAssignment(item.assignmentId)}><span className="text-danger"><i className="fa fa-trash"> &nbsp; Delete</i></span></button>
                                                            </div>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                    <div className="card-footer">
                                                        {/* <Link className="btn btn-primary" to={{
                                                            pathname: '/app/assignment-detail/' + item.assignmentId,
                                                            props: { id: item.assignmentId }
                                                        }}>View Assignments</Link> */}
                                                        <button className="btn btn-primary" onClick={()=>allAssignmentStudents(item.assignmentId)}>View Assignments</button> 
                                                    </div>
                                                </div>
                                            ))}

                                            <div className="row">
                                                <div className="col-md-12">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                            </div>
                                        </>
                                     :
                                     <div className="alert alert-info alert-dismissible">
                                         <div className="text-center text-white"><h4>No Records Found</h4></div>
                                     </div>
                                  }
                                
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default AdminAssignment
