import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser';
import ExamAnswerSheetDataService from '../../../services/ExamAnswerSheetDataService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const LoadSingleQuestionAnswer = (props) => {
    const [mark, setMark] = useState(0)
    const [markError, setMarkError] =useState("")    
    const [selectedStatus, setselectedStatus] =useState(0)
    const [selectedError, setSelectedError] = useState("")

   

    const onValueChange = (id) =>{
        setselectedStatus(id)
        setSelectedError("")
    }

    const saveMark= async id =>{
        let valid=true
        if(mark<0)
        {
            valid=false
            setMarkError("give valid mark")
        }
        if(selectedStatus<0)
        {
            valid=false
            setSelectedError("please choose a status")
        }
        let data={
            examAnswerSheetId:id,
            answerStatusId:selectedStatus,
            markScored:parseFloat(mark)
        }
        if(valid)
        {
            await ExamAnswerSheetDataService.evaluateExamAnswer(data)
            .then(response => {
                LearnToast(success,"Mark Added Successfully")
            })
            .catch(error => { 
                let errorMsg=[]
                let msgText=""    
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }
            })
        }

    }

    return (
        <>
        <ToastContainer/>
        <div className="col-md-12">
            <div className="d-flex justify-content-between mt-1">
                <div className="">
                    {props.slNo+1}. &nbsp;
                                </div>
                <div className="flex-grow-1" style={{ fontSize: "18px" }}>
                    <p style={{ textAlign: "justify", marginBottom: "3px" }}>{parse("" + props.item.questionText)}</p>

                    <div className="row">
                        <div className="col-md-9" style={{ border: "1px solid #e2d9d9" }}>
                            <i>Answer Text:</i>&nbsp;&nbsp;
                                                    <p className={(((props.item.answerStatusName == "Correct") && props.item.isEvaluated) ? "text-success" : ((props.item.isCorrectAnswer == "Wrong") && props.item.isEvaluated) ? "text-danger" : "")}> {parse(props.item.studentAnswerText)}</p>
                        </div>
                        <div className="col-md-3">
                            {!props.item.isEvaluated ?
                                <>
                                    <div className="form-group">
                                    {props.answerStatuses.map((item,index)=>(
                                        <div className="form-check" key={index}>
                                            <input className="form-check-input" 
                                            type="radio" 
                                            name={"radio"+props.item.examAnswerSheetId}
                                            value={item.answerStatusId}
                                            checked={selectedStatus === item.answerStatusId}
                                            onClick={()=>onValueChange(item.answerStatusId)} />
                                            <label className="form-check-label">{item.answerStatusName }</label>
                                        </div>
                                    ))}
                                    <span className="text-danger">{selectedError}</span>
                                    </div>
                                    <div className="input-group">
                                        <input type="number" style={{ height: "2.16rem", borderRadius: "0", marginTop: ".03rem" }} onChange={event => setMark(event.target.value)} id="mark" name="mark" value={mark} className="form-control" placeholder="Mark" />
                                        <span className="input-group-append">
                                            <button type="button" onClick={() => saveMark(props.item.examAnswerSheetId)}
                                                className="btn btn-primary">Save</button>
                                        </span>
                                    </div>
                                    <span className="text-danger">{markError}</span>
                                </>
                                :
                                <p className="text-right">{props.item.markScored}</p>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <p><i>Answer Key:  </i></p>&nbsp;&nbsp;
                        <p className="text-success">{parse("" + props.item.correctAnswerText)}</p>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default LoadSingleQuestionAnswer
