import React, { useState, useEffect } from 'react'
import AssignmentDataService from "../../../services/AssignmentDataService"
import CommonDataService from "../../../services/CommonDataService"
import ValidationRule from './ValidationRule'
import { Button, Modal } from 'react-bootstrap'
import AssignmnetMarkEntry from './AssignmnetMarkEntry'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"
import Loader from '../../ui/loader/Loader'
import EvaluateUngradedAssignment from './EvaluateUngradedAssignment'

const AssignmentDetail = (props) => {
  const [show, setShow] = useState(false);
  const [students, setStudents]= useState([])
  const [assignmentStudentId, setAssignmentStudentId] = useState(0)
  const [documentId, setDocumentId] = useState(0)
  const [documentFile, setDocumentFile] =useState("")
  const [uploadProgress,setuploadProgress] =useState("0")
  const [loading, setLoading] = useState(false);
  const [isfilterModified, setisFilterModified] = useState(false)   

  const validatorDocument=ValidationRule.DocumentRule();
  const [validationDocument, setValidationDocument] = useState(validatorDocument.valid());
  const [validateSubmittedDocument, setValidateSubmittedDocument]=useState(false)

  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  var DocumentValidationData={
      documentFile:documentFile,
      validation:validationDocument
  }

  useEffect(() => {   
    allAssignmentStudents()
}, [isfilterModified])

  const allAssignmentStudents = async () => {
    await AssignmentDataService.assignmentStudents(props.match.params.id)
    .then(response => {
        setStudents(response.data.result)
    })
    .catch(error => {
        const errorMsg = error.message;
    })
}

const uploadCorrectedFile = (id) =>{
  setAssignmentStudentId(id)
  handleShow()
}

const submitCorrectedFile =async () =>{
  const validate = validatorDocument.validate(DocumentValidationData)
  setValidationDocument({ validate });
  setValidateSubmittedDocument(true)
  if (validate.isValid) 
  { 
      var file =new FormData()
      file.append('file',documentFile)
      await CommonDataService.fileUpload(file,{
          onUploadProgress: progressEvent => {
              setuploadProgress(progressEvent.loaded / progressEvent.total*100)
          }})
      .then(response => {  
          setuploadProgress(0)        
          setDocumentId(response.data.result)   
          let data={
              assignmentStudentId:assignmentStudentId,
              markAwarded:0,
              correctedDocumentId:response.data.result,
              isMarkEntry:false,
              isDocument:true
          }
          setLoading(true)
          AssignmentDataService.evaluateExam(data) 
          .then(response => {
              setDocumentFile(""); 
              setAssignmentStudentId(0)
              handleClose()
              setisFilterModified(!isfilterModified) 
              setLoading(false)                
              LearnToast(success,"Uploaded Successfully")
          })
          .catch(error => {
            setLoading(false)    
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")          
          })

      })
      .catch(error => {
          const errorMsg=error.message;            
      })
  }
}

const saveMark = async (assignmentStudentId,maxMark) =>{    
  let data={
     assignmentStudentId:assignmentStudentId,
     markAwarded:parseInt(maxMark),
     correctedDocumentId:0,
     isMarkEntry:true,
     isDocument:false
   }
   if(maxMark>=0)
   {
    setLoading(true)    
    await AssignmentDataService.evaluateExam(data) 
     .then(response => {
         setisFilterModified(!isfilterModified)   
         setLoading(false)          
         LearnToast(success,"Saved Successfully")
     })
     .catch(error => { 
        setLoading(false)    
        LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")          
     })
   }
   
 }
 const valuateAssignment = async (assignmentStudentId,maxMark) =>{    
  let data={
     assignmentStudentId:assignmentStudentId,
     markAwarded:parseInt(maxMark),
     correctedDocumentId:0,
     isMarkEntry:true,
     isDocument:false
   }
   if(maxMark>=-2)
   {
    setLoading(true)    
    await AssignmentDataService.evaluateExam(data) 
     .then(response => {
         setisFilterModified(!isfilterModified)   
         setLoading(false)          
         LearnToast(success,"Saved Successfully")
     })
     .catch(error => { 
        setLoading(false)    
        LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")          
     })
   }

  }



let validateOutputDocument= validateSubmittedDocument ? validatorDocument.validate(DocumentValidationData) : DocumentValidationData.validation 

  return ( 
     <>
    <ToastContainer />    
   {loading?<Loader/>:
       <>
    <Modal show={show} onHide={handleClose} size="xs">
       <Modal.Header closeButton>
           <Modal.Title>CorrectedDocument</Modal.Title>
       </Modal.Header>
       <Modal.Body>
           <div className="form-group">
                <label htmlFor="todate">Upload File</label>
               {/* <div className="input-group"> */}
                   <input type="file" onChange={event => setDocumentFile(event.target.files[0])} name="documentFile" id="documentFile" className={validateOutputDocument.documentFile.isInvalid ? 'form-control is-invalid':'form-control'} />
                   {/* <span className="input-group-append">
                       <button type="button" onClick={()=>} className="btn btn-primary">Add</button>
                   </span> */}
               {/* </div>                             */}
               <span className="text-danger">{validateOutputDocument.documentFile.message}</span>
           </div>
           <br/>
           <div className="progress progress-sm active">
               <div className="progress-bar bg-success progress-bar-striped" role="progressbar"
                   aria-valuemin="0" aria-valuemax="100" style={{width: uploadProgress+"%"}}>
                   <span className="sr-only">{uploadProgress+"%"} Complete</span>
               </div>
           </div>
       </Modal.Body>
       <Modal.Footer>
           <Button variant="secondary" onClick={handleClose}>
               Close
           </Button>
           <Button type="submit" variant="primary" onClick={()=>submitCorrectedFile()}>
               Submit
           </Button>
       </Modal.Footer>
   </Modal>
    <div className="container">
    <div className="jumbotron">
      <div className="card card-primary">
        <div className="card-header">
          <div className="row">
            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
              <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Assignment Evaluation </h5>
            </div>
            <br />
            <br />
          </div>
        </div>
        <div className="card-body">

          <table className="table table-bordered table-hover">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Student Name</th>                
                <th scope="col">Status</th>
                
                <th scope="col">Mark</th>
                <th className="text-right">Actions</th>
              </tr>
            </thead>
            <tbody>
              {students.map((item, index)=>(            
              <tr key={index}>
                <th scope="row">{index+1}</th>
                <td>{item.studentName}</td>
                {item.isSubmitted && !item.isEvaluated && item.isGraded?
                  <td className="text-warning">Not Evaluated</td>
                  :item.isSubmitted && item.isEvaluated && item.isGraded?
                  <td className="text-success">Valuated</td>
                  :!item.isSubmitted?
                  <td className="text-danger">Not Submitted</td>
                  :item.isSubmitted && !item.isGraded?
                  <td className="text-success">Submitted</td>
                  :<td></td>
                }
                {item.isGraded?
                <>
                  {item.isSubmitted && item.markAwarded>-1?
                    <td>
                      {item.markAwarded}
                    </td>
                    :item.isSubmitted && item.markAwarded==-1?
                    <td>
                       <AssignmnetMarkEntry onSavemark={saveMark} assignmentStudentId={item.assignmentStudentId} />
                    </td>
                    :
                    <td>
                        -
                    </td>
                  }
                </>      
                :<>
                {item.isSubmitted && item.markAwarded==-2?
                    <td>
                     <p className="text-success">Valuated</p>
                    </td>
                    :item.isSubmitted && item.markAwarded==-1?
                    <td>
                       <EvaluateUngradedAssignment onSavemark={valuateAssignment} assignmentStudentId={item.assignmentStudentId} />
                    </td>
                    :
                    <td>
                        Non Graded Assignment
                    </td>
                  }
                </>
                }
                {item.isSubmitted && !item.hasCorrectedDocument?
                  <td className="text-right">
                    <a href={item.submittedDocumentName} target="_blank" className="btn btn-info"><i className="fas fa-download"></i></a>&nbsp;&nbsp;&nbsp;
                    <button onClick={()=>uploadCorrectedFile(item.assignmentStudentId)} className="btn btn-info"><i className="fas fa-upload"></i></button>
                  </td>
                :item.isSubmitted && item.hasCorrectedDocument?
                <td className="text-right">
                  <a href={item.submittedDocumentName} target="_blank" className="btn btn-info"><i className="fas fa-download"></i></a>&nbsp;&nbsp;&nbsp;
                  <button className="btn btn-info" disabled><i className="fas fa-upload"></i></button>
                </td>
                :!item.isSubmitted?
                <td className="text-right">
                    <button className="btn btn-info" disabled><i className="fas fa-download"></i></button>&nbsp;&nbsp;&nbsp;
                    <button className="btn btn-info" disabled><i className="fas fa-upload"></i></button>
                </td>:<></>
                }
              </tr>
                ))}
            </tbody>
          </table>

        </div>
      </div>
    </div>
  </div>
  </>
  }
  </>
  )
}

export default AssignmentDetail
