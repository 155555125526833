import React, { useState, useEffect } from 'react'
import LoadEvalSingleQuestion from './LoadEvalSingleQuestion'

const LoadEvalQuestions = (props) => {

    const [allQuestios, setAllQuestions] = useState([])
    useEffect(() =>{
        setAllQuestions(props.questions)
    },[]) 


  return (
    <>
      {allQuestios.map((item,index)=>(
        <LoadEvalSingleQuestion 
        question={item} 
        maxMark={props.maxMark}
        studentID={props.studentID}
        questionTypeName={props.questionTypeName} />

      ))}
    </>
  )
}

export default LoadEvalQuestions
