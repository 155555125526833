import React from 'react'
import ReactDOM from 'react-dom'
import Avatar from 'react-avatar-edit'
import Loader from '../../ui/loader/Loader';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"

import { changeProfileImage } from "../../redux/login/loginActions";
import { connect } from 'react-redux';
import UserDataService from '../../../services/UserDataService';

class UpdateProfilePicture extends React.Component {

    constructor(props) {
        super(props)
        const src = ''
        this.state = {
            loading:false,
            preview: null,
            src
        }
        this.onCrop = this.onCrop.bind(this)
        this.onClose = this.onClose.bind(this)
        this.Change = this.Change.bind(this)
    }

    onClose() {
        this.setState({ preview: null })
    }

    onCrop(preview) {
        this.setState({ preview })
    }

    Change() {
        this.setState({loading:true})
        let data={
            base64ImageString:this.state.preview
        }
        UserDataService.changeProfilePicture(data)
          .then(response => {
            this.props.changeProfileImage(this.state.preview)              
            this.setState({loading:false})
            LearnToast(success, "Profile Picture Changed Successfully")
         })
         .catch(error => {
            let errorMsg=[]
            let msgText=""   
            this.setState({loading:false})
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }           
         })
    }

    render() {
        if(this.state.loading)
        {
        return <Loader/>
        }
       else{
        return (
            <>
            <ToastContainer />
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Change Image </h5>
                                </div>
                                <div className="col-md-6 text-right">

                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-8 text-center">
                                <Avatar
                                    width={390}
                                    height={295}
                                    onCrop={this.onCrop}
                                    onClose={this.onClose}
                                    src={this.state.src}
                                />
                                </div>
                                <div className="col-md-4">
                                    {this.state.preview ?
                                        <img src={this.state.preview} alt="Preview" />
                                        : <></>
                                    }
                                </div>
                            </div>
                        </div>
                        {this.state.preview ?
                        <div className="card-footer">
                            <button className="btn btn-primary" onClick={this.Change} >Change</button>

                        </div>
                        :<></>
                        }
                        
                    </div>
                </div>
            </div>
         </>
        )
        }
    }
}


const mapDispatchToProps = dispatch => {
    return {
        changeProfileImage: data => dispatch(changeProfileImage(data))
    }
  }
  
  export default connect(null,mapDispatchToProps)(UpdateProfilePicture);