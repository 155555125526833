import  ApiDataService  from './ApiDataService'

const createStudent = data => {
  return ApiDataService.post("student", data);
};

const allStudent = () => {
    return ApiDataService.get("student/all");
  };

  const findStudent = (values) => {
      return ApiDataService.getWithParam("student/find", values);
  };

  const findStudentWithoutPagination = (values) => {
    return ApiDataService.getWithParam("student/findwithoutpagination", values);
};

const getStudent = (id) => {
  let values={'id':id}
    return ApiDataService.getWithParam("student", values);
};

const updateStudent = data => {
    return ApiDataService.put("student", data);
};

const deleteStudent = (id) => {
  let values={'id':id}
  return ApiDataService.remove("student", values);
};

const getStudentSubjects = (id) => {
  
  let values={'academicYearId':id}
  return ApiDataService.getWithParam("student/subjects", values);
};

const getSubjectsByStudent = (studentId) => {  
  let values={'studentId':studentId}
  return ApiDataService.getWithParam("student/subjectsbystudent", values);
};

const getSubjectTeachers = (id,academicyearid) => {  
  let values={'classSubjectId':id,'academicYearId':academicyearid}
  return ApiDataService.getWithParam("student/subjectteachers", values);
};

const getClassSectionStudentList = (classSectionId,studentName) => {  
  let values={'classSectionId':classSectionId,'studentName':studentName}
  return ApiDataService.getWithParam("student/studentlist", values);
};

const getStudentProfile = (studentId) => {
  let values={'studentId':studentId}
    return ApiDataService.getWithParam("student/profile", values);
};

const getStudentTimeline = (studentId) => {
  let values={'studentId':studentId}
    return ApiDataService.getWithParam("student/timeline", values);
};

const getSubjectAttendanceProfile = (studentId,classSectionId,classSubjectId) => {
  let values={'studentId':studentId,'classSectionId':classSectionId,'classSubjectId':classSubjectId}
    return ApiDataService.getWithParam("student/subjectattendanceprofile", values);
};


const getSubjectAssignmentProfile = (studentId,classSectionId,classSubjectId) => {
  let values={'studentId':studentId,'classSectionId':classSectionId,'classSubjectId':classSubjectId}
    return ApiDataService.getWithParam("student/subjectassignmentprofile", values);
};

const getSubjectExamProfile = (studentId,classSectionId,classSubjectId) => {
  let values={'studentId':studentId,'classSectionId':classSectionId,'classSubjectId':classSubjectId}
    return ApiDataService.getWithParam("student/subjectexamprofile", values);
};

export default {createStudent,
                allStudent,
                findStudent,
                findStudentWithoutPagination,
                getStudent,
                updateStudent,
                deleteStudent,
                getStudentSubjects,
                getSubjectsByStudent,
                getSubjectTeachers,
                getClassSectionStudentList,
                getStudentProfile,
                getStudentTimeline,
                getSubjectAttendanceProfile,
                getSubjectAssignmentProfile,
                getSubjectExamProfile};
                