import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../../ui/loader/Loader';
import ExamDataService from "../../../services/ExamDataService"
import ClassSubjectDataService from '../../../services/ClassSubjectDataService'
import ClassSectionDataService from '../../../services/ClassSectionDataService';
import ExamInstructionDataService from '../../../services/ExamInstructionDataService'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';


const OnlineExamAdmin = () => {
    const [show, setShow] = useState(false);
    const [timeBoundFilter, setTimeBoundFilter] = useState(false)
    const [searchExam,setSearchExam]=useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [loading,setLoading] = useState(false);
    const [allClasses, setAllClasses] = useState([])
    const [classSubjects, setClassSubjects] = useState([])
    const [exams,setExams]=useState([])
    const [classId, setClassId] = useState("")
    const [subjectId, setSubjectId] = useState("")

    const [examInstructions, setExamInstructions] = useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        examName:searchExam,
        isTimeboundOnly:timeBoundFilter,
        gradeSchemes:"",
        classSections:"",
        classSubjects:"" 
         })

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
     

    
         useEffect(() => {
            fetchClasses();
        }, [])
    
        useEffect(() => {
            fetchClassSubjects();
        }, [classId])
    
    
        const fetchClasses = async () => {
            await ClassSectionDataService.allClassSection()
                .then(response => {
                    setAllClasses(response.data.result)
                })
                .catch(error => {
                    const errorMsg = error.message;
                })
        }
    
        const fetchClassSubjects = async () => {
            setClassSubjects([])
            await ClassSubjectDataService.getClassSubjectWithClassSection(classId)
                .then(response => {
                    setClassSubjects(response.data.result)
                })
                .catch(error => {
                    const errorMsg = error.message;
                })
        }
        const changeClass = async (value) => {
            setClassSubjects([])
            setSubjectId("")
            setClassId(value)
        }
    
        const changeSubject = async (value) => {
            setSubjectId(value)
        }
    
        useEffect(() => {
            setParametes()
        }, [isfilterModified, classId, subjectId, timeBoundFilter])    

        useEffect(() => {
            fetchExams()
        }, [params])  
    
        const setParametes = () => {
            setParams({
            examName:searchExam,
            isTimeboundOnly:timeBoundFilter,
            gradeSchemes:"",
            classSections:classId.toString(),
            classSubjects:subjectId.toString()  
            })
        }
    
        const fetchExams = async () =>{
            setLoading(true)
            await ExamDataService.findExam(params) 
            .then(response => {
            setExams(response.data.result.itemList)
            setTotalPages(response.data.result.totalPages)
            setActivePage(response.data.result.activePageNumber)
            setLoading(false)
           })
           .catch(error => {
               const errorMsg=error.message; 
               setLoading(false)                      
           })
        }
        const serachByText=()=>{
            setisFilterModified(!isfilterModified)
          }

        const handleTimeBoundClickFilter = () => {
            setTimeBoundFilter(!timeBoundFilter)
        }

        const openInstructions = (ExamID) =>
        {
            fetchExamInstruction(ExamID)
            handleShow()
        }

        const fetchExamInstruction = async (examID) =>
        {
            setLoading(true)
            await ExamInstructionDataService.getExamInstructions(examID) 
            .then(response => {
                setExamInstructions(response.data.result)
                setLoading(false)
           })
           .catch(error => {
            setLoading(false)           
           })
        }
  return (
      <>
    <ToastContainer />    
    {loading?<Loader/>:
    <>
         <Modal show={show} onHide={handleClose} size="xz">
                    <Modal.Header closeButton>
                        <Modal.Title>Instructions</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                            <div>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl</th>
                                            <th scope="col">Instruction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examInstructions.map((item,index)=>(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{item.instructionText}</td>                                               
                                            </tr>
                                        ))}                                    
                                    </tbody>
                                </table>
                            </div>                
                    </Modal.Body>
                    <Modal.Footer>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                    </Modal.Footer>
                </Modal>
    <div className="container">
    <div className="jumbotron">
        <div className="card card-primary">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Exam</h5>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                <div className="col-md-3">
                                <div className="card card-outline card-primary">
                                    <div className="text-center">
                                        <h5 style={{ marginTop: "10px" }}>Filtering Options</h5>
                                    </div>
                                    <hr />
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <div className="input-group input-group-sm">
                                                <input onChange={event => setSearchExam(event.target.value)} value={searchExam} name="searchExam" id="searchExam" className="form-control form-control-navbar" type="text" placeholder="Exam Name" aria-label="Search"/>
                                                <div className="input-group-append">
                                                    <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                                        <i className="fas fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="col-md-12">
                                        <div className="form-check">
                                            <input type="checkbox" onChange={()=>handleTimeBoundClickFilter()} checked={timeBoundFilter} name="timeBoundFilter" id="timeBoundFilter" className="form-check-input" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Time Bound</label>
                                        </div>
                                     </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class</label>
                                            <select onChange={event => changeClass(event.target.value)} value={classId} name="classId" className="form-control">
                                                <option value="">-- select class --</option>
                                                {allClasses.map(item => (
                                                    <option
                                                        key={item.classSectionId}
                                                        value={item.classSectionId}
                                                    >
                                                        {item.classesName +" "+ item.sectionName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class Subject</label>
                                            <select onChange={event => changeSubject(event.target.value)} value={subjectId} name="subjectId" className="form-control">
                                                <option value="">-- select subject --</option>
                                                {classSubjects.map(item => (
                                                    <option
                                                        key={item.classSubjectId}
                                                        value={item.classSubjectId}
                                                    >
                                                        {item.subjectName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
               
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-12">
                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                            </div>
                        </div>
                        {exams.map((item,index)=>(
                        <div className="card card-outline card-primary" key={item.examId}>
                            <div className="card-header" id="headingOne">
                                <i className="d-flex justify-content-between"> 
                                    <p className="text-muted text-xs">Class: {item.classSectionName}</p>
                                    <p className="text-muted text-xs">Subject: {item.classSubjectName}</p>
                                    <p className="text-justify">Total Mark : {item.maxMarks}</p>
                                </i>
                                <div className="d-flex justify-content-between">
                                    <h5>{item.examName}</h5> 
                                    
                                </div>                                         
                                <i className="d-flex justify-content-between"> 
                                    <p className="text-muted text-xs">Exam Time: {item.scheduleDate+", "+item.scheduleTime}</p>          
                                </i>
                                <div className="d-flex justify-content-between">
                                    <Link className="btn btn-link" to={{pathname: '/app/result/'+item.examId+'/'+item.examName,props:{ id: item.examId}}}>
                                        <span className="text-warning"><i className="fa fa-id-card"></i> View Result</span>
                                    </Link>
                                    <button className="btn btn-link" onClick={()=>openInstructions(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                        <span className="text-info"><i className="fa fa-search"></i> Instructions</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    
                        ))}
                        <div className="row">
                            <div className="col-md-12">
                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                            </div>
                        </div>
                    </div>
                    
            </div>
            </div>
        </div>
    </div>
</div>
    </>
            }
        </>
  )
}

export default OnlineExamAdmin
