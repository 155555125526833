import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import CommonDataService from '../../../services/CommonDataService';
import AcademicYearDataService from "../../../services/AcademicYearDataService"
import ClassSectionDataService from "../../../services/ClassSectionDataService"
import StudentDataService from "../../../services/StudentDataService"
import Loader from '../../ui/loader/Loader';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import { Link } from 'react-router-dom';
import LearnPagination from '../../ui/pagination/LearnPagination';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const Student = (props) => {
    const passwordMatch = (confirmation, state) => (password === confirmation)
    const validator= ValidationRule.FormRule(passwordMatch);
 
    const [show, setShow] = useState(false);
    const [studentId,setstudentId]=useState(0);
    const [studentName,setStudentName] = useState('');
    const [genderId, setGenderId] = useState("");
    const [mobile,setMobile]=useState('');
    const [eMail, setEmail] = useState('');
    const [password ,setPassword]=useState('');
    const [confirmpassword ,setConfirmPassword]=useState('');
    const [parentName, setParentName] = useState('');
    const [parentMobile, setParentMobile] = useState('');
    const [parentEMail, setParentEMail] = useState('');
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [academicYearId, setAcademicYearId] = useState(0);
    const [classSectionId, setClassSectionId] = useState("");
    const [genders,setGenders] = useState([]);
    const [classSection,setClassSection] = useState([]);
    const [buttonName, setButtonName] = useState('');
    const [allStudent, setAllStudent] = useState([]);
    const [formTitle, setFormTitle] = useState('');
    const [isEdit, setIsEdit] =useState(false);
    const [submitted,setSubmitted]=useState(false);
    const [loading,setLoading] = useState(false);
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)    

    const [searchName, setSearchName]= useState("")
    const [searchMobile, setSearchMobile]= useState("")
    const [searchEmail, setSearchEmail]= useState("")
    const [searchGenderId, setSearchGenderId]= useState("")
    const [filterclassSectionId, setFilterClassSectionId] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        studentName:searchName,
        mobile:searchMobile,
        email:searchEmail,
        genderList:searchGenderId.toString(),
        classSectionList:filterclassSectionId.toString()
    })
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        studentName:studentName,
        genderId:genderId,
        mobile:mobile,
        eMail:eMail,
        password:password,
        confirmpassword:confirmpassword,
        parentName:parentName,
        parentMobile:parentMobile,
        parentEMail:parentEMail,
        parentEMail:parentEMail,
        classSectionId:classSectionId,
        validation:validation,
    }

    const createStudent = () => {
        resetForm();
        fetchCurrentAcademicYear();
        setstudentId(0);
        setIsEdit(false);
        setFormTitle('Add');
        setButtonName('Save');
        handleShow();
    }

    const editStudent = Id => {

         StudentDataService.getStudent(Id)
        .then(response => {
            setStudentName(response.data.result.studentName)
            setGenderId(response.data.result.genderId)
            setMobile(response.data.result.mobile)
            setEmail(response.data.result.eMail)
            setParentName(response.data.result.parentName)
            setParentMobile(response.data.result.parentMobile)
            setParentEMail(response.data.result.parentEMail)
            setProfilePicUrl(response.data.result.profilePicUrl)
            setAcademicYearId(response.data.result.academicYearId)
            setClassSectionId(response.data.result.classSectionId)
            // To make Validation true in Edit
            setPassword("123");
            setConfirmPassword("123");
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
        setstudentId(Id);
        setIsEdit(true);
        setFormTitle('Edit');
        setButtonName('Update');
        handleShow();
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await StudentDataService.deleteStudent(id) 
        .then(response => {
            setLoading(false)
            LearnToast(success,"Deleted Successfully")
            setSubmitted(!submitted);
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }                  
        })
   }

    const deleteStudent = async id => {

        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }


    const resetForm = () =>{
        setstudentId(0)
        setStudentName('');
        setGenderId("");
        setMobile('');
        setEmail('');
        setPassword('');
        setConfirmPassword('')
        setParentName('');
        setParentMobile('');
        setParentEMail('');
        setProfilePicUrl('');
        setAcademicYearId(0);
        setClassSectionId("");
        setValidation(validator.valid());
        setValidateSubmitted(false);
    }

    useEffect(() =>{        
        fetchClassSection();
        fetchallGenders();
    },[submitted]) 

    useEffect(() => {
        setParametes()
    }, [submitted])

    useEffect(() => {
        fetchStudent()
    }, [params])

    const setParametes = () => {
        setParams({
        studentName:searchName,
        mobile:searchMobile,
        email:searchEmail,
        genderList:searchGenderId.toString(),
        classSectionList:filterclassSectionId.toString()
        })
    }


   const fetchStudent = async () =>{
        setLoading(true);
        await StudentDataService.findStudent(params) 
        .then(response => {
            setAllStudent(response.data.result.itemList)
            setTotalPages(response.data.result.totalPages)
            setActivePage(response.data.result.activePageNumber)
            setLoading(false);
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchallGenders= async () =>
    {
        await CommonDataService.allGender() 
        .then(response => {
            setGenders(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchClassSection= async () =>
    {
        await ClassSectionDataService.allClassSection() 
        .then(response => {
            setClassSection(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchCurrentAcademicYear= async () =>
    {
        await AcademicYearDataService.currentAcademicYear() 
        .then(response => {
            setAcademicYearId(response.data.result.academicYearId)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }
 

    const submitForm = async () => {
        var data = {
            studentId: studentId,
            studentName: studentName,
            genderId: parseInt(genderId),
            mobile: mobile,
            eMail: eMail,
            password: password,
            parentName: parentName,
            parentMobile: parentMobile,
            parentEMail: parentEMail,
            profilePicUrl: profilePicUrl,
            academicYearId: academicYearId,
            classSectionId: parseInt(classSectionId)
        };
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        if (validate.isValid) {
            setLoading(true)
            isEdit ? (
                await StudentDataService.updateStudent(data)
                    .then(response => {
                        setLoading(false)
                        LearnToast(success,"Updated Successfully")
                    })
                    .catch(error => {
                        let errorMsg=[]
                        let msgText=""
                        setLoading(false)      
                        if(error.response.status==500)
                        {
                            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                        }
                        else
                        {
                            errorMsg=error.response.data.errors
                            errorMsg.map((item,index)=>{
                                if(msgText.length>0)
                                {
                                msgText = msgText+","
                                }
                                msgText=msgText+item
                            })            
                                LearnToast(danger,msgText)
                        
                        }          
                    })
            ) : (
                    await StudentDataService.createStudent(data)
                        .then(response => {
                            setLoading(false)
                            LearnToast(success,"Created Successfully")
                        })
                        .catch(error => {
                            let errorMsg=[]
                            let msgText=""
                            setLoading(false)      
                            if(error.response.status==500)
                            {
                                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                            }
                            else
                            {
                                errorMsg=error.response.data.errors
                                errorMsg.map((item,index)=>{
                                    if(msgText.length>0)
                                    {
                                    msgText = msgText+","
                                    }
                                    msgText=msgText+item
                                })            
                                    LearnToast(danger,msgText)
                            
                            }          
                        })
                )

            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }

    const searchStudent = () =>{
        setParametes()
    }

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    
    return (
        <>
        <ToastContainer/>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle} Student</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-row">
                            <div className="col">
                                <label htmlFor="studentName"> Name</label>
                                <input type="text" onChange={event => setStudentName(event.target.value)} value={studentName} name="studentName" id="studentName" className={validateOutput.studentName.isInvalid ? 'form-control is-invalid':'form-control'}placeholder="Name" />
                                <span className="text-danger">{validateOutput.studentName.message}</span>
                            </div>
                            <div className="col">
                                <label htmlFor="genderId"> Gender</label>
                                <select className={validateOutput.genderId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setGenderId(event.target.value)} value={genderId} name="genderId"  >                      
                                    <option value="">-- select gender --</option>
                                    {genders.map(item => (
                                        <option
                                            key={item.genderId}
                                            value={item.genderId}
                                        >
                                            {item.genderName}
                                        </option>
                                    ))}
                                </select>
                                <span className="text-danger">{validateOutput.genderId.message}</span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <label htmlFor="mobile">Mobile</label>
                                <input type="text" onChange={event => setMobile(event.target.value)} value={mobile} name="mobile" id="mobile" className={validateOutput.mobile.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Mobile" />
                                <span className="text-danger">{validateOutput.mobile.message}</span>
                            </div>
                            {!isEdit?(
                            <>
                            <div className="col">
                                <label htmlFor="eMail">E-mail</label>
                                <input type="text" onChange={event => setEmail(event.target.value)} value={eMail} name="eMail" id="eMail" className={validateOutput.eMail.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="E-mail" />
                                <span className="text-danger">{validateOutput.eMail.message}</span>
                            </div>
                            </>):(<></>)}
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <label htmlFor="parentName">Parent Name </label>
                                <input type="text" onChange={event => setParentName(event.target.value)} value={parentName} name="parentName" id="parentName" className={validateOutput.parentName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Parent Name" />
                                <span className="text-danger">{validateOutput.parentName.message}</span>
                            </div>
                            <div className="col">
                                <label htmlFor="parentMobile">Parent Mobile</label>
                                <input type="text" onChange={event => setParentMobile(event.target.value)} value={parentMobile} name="parentMobile" id="parentMobile" className={validateOutput.parentMobile.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Parent Mobile" />
                                <span className="text-danger">{validateOutput.parentMobile.message}</span>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <label htmlFor="parentEMail">Parent Email</label>
                                <input type="text" onChange={event => setParentEMail(event.target.value)} value={parentEMail} name="parentEMail" id="parentEMail" className={validateOutput.parentEMail.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Parent Email" />
                                <span className="text-danger">{validateOutput.parentEMail.message}</span>
                            </div>
                            <div className="col">
                                <label htmlFor="classSectionId">Class Section</label>
                                <select className={validateOutput.classSectionId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setClassSectionId(event.target.value)} value={classSectionId} name="classSectionId"  >
                                    <option value="">-- select class section --</option>
                                    {classSection.map(item => (
                                        <option
                                            key={item.classSectionId}
                                            value={item.classSectionId}
                                        >
                                            {item.classesName+' '+item.sectionName}
                                        </option>
                                    ))}
                                </select>
                                <span className="text-danger">{validateOutput.classSectionId.message}</span>
                            </div>
                        </div>
                        {!isEdit?(
                        <>
                            <div className="form-row">
                                <div className="col">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" onChange={event => setPassword(event.target.value)} value={password} name="password" id="password" className={validateOutput.password.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Password" />
                                    <span className="text-danger">{validateOutput.password.message}</span>
                                </div>
                                <div className="col">
                                    <label htmlFor="confirmpassword">Confirm Password</label>
                                    <input type="password" onChange={event => setConfirmPassword(event.target.value)} value={confirmpassword} name="confirmpassword" id="confirmpassword" className={validateOutput.confirmpassword.isInvalid ? 'form-control is-invalid':'form-control'}placeholder="Confirm Password" />
                                    <span className="text-danger">{validateOutput.confirmpassword.message}</span>
                                </div>
                            </div>
                        </>):(<></>)}
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={submitForm}>
                        {buttonName}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}> Student </h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={createStudent}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                        <div className="card card-outline card-primary">
                                            <div className="text-center">
                                                <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                            </div>
                                            <hr/>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control form-control-navbar" type="text" placeholder="Name"/>                                                                                               
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input onChange={event => setSearchMobile(event.target.value)} value={searchMobile} name="searchMobile" id="searchMobile" className="form-control form-control-navbar" type="text" placeholder="Mobile"/>  
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input onChange={event => setSearchEmail(event.target.value)} value={searchEmail} name="searchEmail" id="searchEmail" className="form-control form-control-navbar" type="text" placeholder="Email"/>  
                                                </div>
                                            </div>                                    
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <select onChange={event => setFilterClassSectionId(event.target.value)} value={filterclassSectionId} name="filterclassSectionId" className="form-control">
                                                        <option value="">-- select class section --</option>
                                                        {classSection.map(item => (
                                                            <option 
                                                            key={item.classSectionId} 
                                                            value={item.classSectionId}
                                                            >                                                
                                                                {item.classesName + " " + item.sectionName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <select className="form-control" onChange={event => setSearchGenderId(event.target.value)} value={searchGenderId} name="searchGenderId"  >                      
                                                        <option value="">-- select gender --</option>
                                                        {genders.map(item => (
                                                            <option
                                                                key={item.genderId}
                                                                value={item.genderId}
                                                            >
                                                                {item.genderName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block" onClick={()=>searchStudent()}>
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                </div>
                                 {loading?(<Loader/>):
                                    <div className="col-md-9">
                                    {allStudent.length>0?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    {/* <th scope="col">Gender</th> */}
                                                    <th scope="col">Mobile</th>
                                                    <th scope="col">Class Section</th>
                                                    <th className="text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(allStudent.map((student, index) => (
                                                    <tr key={student.studentId}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{student.studentName}</td>
                                                        {/* <td>{student.genderId}</td> */}
                                                        <td>{student.mobile}</td>
                                                        <td>{student.currentClassSection}</td>

                                                        <td className="text-right">
                                                            {/* <Link className="btn btn-link" to={{pathname: '/app/student-profile-view/'+student.studentId,props:{ id: student.studentId}}} data-toggle="tooltip" data-placement="top" title="Profile"><i className="fa fa-user text-primary"></i></Link>&nbsp;&nbsp;&nbsp; */}
                                                            <button onClick={() => editStudent(student.studentId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>&nbsp;&nbsp;&nbsp;
                                                            <button onClick={() => deleteStudent(student.studentId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                                        </td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                    </>
                                    :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                    }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Student;