import React from 'react'

const TimelineAttendanceItem = (props) => {
  return (
    <>
        <i class={props.data.isPresent?"fas fa-id-card bg-blue":"fas fa-id-card bg-red"}></i>
            <div class="timeline-item" style={{border:"0"}}>
                <div className={props.data.isPresent?"card card-outline card-primary":"card card-outline card-danger"}>
                    <div className="card-header" id="headingOne">
                        <i className="d-flex justify-content-between"> 
                            <p className="text-muted text-xs">Subject: {props.data.classSubjectName }</p>
                            <p className="text-justify">{props.data.classTeacherName}</p>                                   
                        </i>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                    <h5>{props.data.fromTime + " - " + props.data.toTime}</h5>   
                                    {props.data.isPresent?
                                        <span className="text-success"><i className="fa fa-check"></i> Present</span>                               
                                    :
                                        <span className="text-danger"><i className="fa fa-times"></i> Absent</span>                               
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
    </>
  )
}

export default TimelineAttendanceItem
