import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import CourseLevelDataService from "../../../services/CourseLevelDataService"
import Loader from '../../ui/loader/Loader';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import LearnPagination from '../../ui/pagination/LearnPagination'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const CourseLevel = () => {
    const validator= ValidationRule;
    
    const [show, setShow] = useState(false);    
    const [courseLevelId, setCourseLevelLevelId] = useState(0);
    const [courseLevel, setCourseLevel] = useState("");
    const [displayOrder, setDisplayOrder] = useState("");
    const [courseLevels, setCourseLevels] = useState([]);
    const [isEdit, setIsEdit] =useState(false);
    const [submitted,setSubmitted]=useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [buttonName, setButtonName] = useState('');
    const [loading,setLoading] = useState(false);
    
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)   
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        courseLevel:courseLevel,
        displayOrder:displayOrder,
        validation:validation,
    }

    const createCourseLevel = () => {
        setIsEdit(false)
        setFormTitle('Add');
        setButtonName('Save');
        handleShow();
    }

    const editCourseLevel = id => {
        CourseLevelDataService.getCourseLevel(id)
        .then(response => {
            setCourseLevel(response.data.result.courseLevelName)
            setDisplayOrder(response.data.result.levelOrder)
            setCourseLevelLevelId(id)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
        setIsEdit(true);
        setFormTitle('Edit');
        setButtonName('Update');
        handleShow();
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await CourseLevelDataService.deleteCourseLevel(id) 
            .then(response => {
                setLoading(false)
                LearnToast(success,"Deleted Successfully")
                setSubmitted(!submitted);
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }                    
            })
   }


    const deletecourseLevel= async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }

       
    useEffect(() =>{
        fetchCourseLevel();
    },[submitted]) 


    const fetchCourseLevel= async () =>{        
        setLoading(true);
        await CourseLevelDataService.allCourseLevel() 
        .then(response => {
            setCourseLevels(response.data.result)
            setLoading(false)
       })
       .catch(error => {
           setLoading(false)       
       })
    }
 

    const submitForm = async () => {
        var data = {
            courseLevelId: courseLevelId,
            courseLevelName: courseLevel,
            levelOrder: parseInt(displayOrder)
        };
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)

        if (validate.isValid) {
            setLoading(true)
            isEdit ? (
                    await CourseLevelDataService.updateCourseLevel(data)
                        .then(response => {
                           setLoading(false)
                           LearnToast(success,"Updated Successfully")
                        })
                        .catch(error => {
                            let errorMsg=[]
                            let msgText=""
                            setLoading(false)      
                            if(error.response.status==500)
                            {
                                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                            }
                            else
                            {
                                errorMsg=error.response.data.errors
                                errorMsg.map((item,index)=>{
                                    if(msgText.length>0)
                                    {
                                    msgText = msgText+","
                                    }
                                    msgText=msgText+item
                                })            
                                    LearnToast(danger,msgText)
                            
                            }          
                        })
                    ) : (
                            await CourseLevelDataService.createCourseLevel(data)
                            .then(response => {
                               setLoading(false)
                               LearnToast(success,"Created Successfully")
                            })
                            .catch(error => {
                                let errorMsg=[]
                                let msgText=""
                                setLoading(false)      
                                if(error.response.status==500)
                                {
                                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                                }
                                else
                                {
                                    errorMsg=error.response.data.errors
                                    errorMsg.map((item,index)=>{
                                        if(msgText.length>0)
                                        {
                                        msgText = msgText+","
                                        }
                                        msgText=msgText+item
                                    })            
                                        LearnToast(danger,msgText)
                                
                                }          
                            })
                    )
            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }

    const resetForm = () => 
    {
        setCourseLevelLevelId(0)
        setCourseLevel("")
        setDisplayOrder("")
        setValidation(validator.valid());
        setValidateSubmitted(false);
        setIsEdit(false)
    }


    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 



  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{formTitle} Course Level</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form>
                <div className="form-group">
                    <label htmlFor="displayname">Course Level</label>
                    <input onChange={event=>setCourseLevel(event.target.value)} value={courseLevel} name="courseLevel" id="courseLevel" type="text" className={validateOutput.courseLevel.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Difficulty Level" />                          
                    <span className="text-danger">{validateOutput.courseLevel.message}</span>
                </div>
                <div className="form-group">
                    <label htmlFor="fromdate">Level Order</label>
                    <input type="number" onChange={event=>setDisplayOrder(event.target.value)} value={displayOrder} name="displayOrder" id="displayOrder" className={validateOutput.displayOrder.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Display Order" />
                    <span className="text-danger">{validateOutput.displayOrder.message}</span>
                </div>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button type="submit" variant="primary" onClick={submitForm}>
                {buttonName}
            </Button>
        </Modal.Footer>
    </Modal>          
    <div className="container">
        <div className="jumbotron">
            <div className="card card-primary">
                <div className="card-header">                            
                    <div className="row">
                        <div className="col-md-6" style={{verticalAlign:"middle"}}>
                            <h5 style={{marginBottom:"0.4rem",marginTop:"0.4rem"}}>Course Level</h5>
                        </div>
                        <div className="col-md-6 text-right">
                            <button className="btn btn-light" style={{marginBottom:"0.1rem",marginTop:"0.1rem"}} onClick={createCourseLevel}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>                
                {loading? (<Loader/>) : 
                    <div className="card-body">         
                    {courseLevels.length>0?
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    <th scope="col">#</th>
                                    <th scope="col">Course Level</th>
                                    <th scope="col">Level Order</th>
                                    <th className="text-right">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(courseLevels.map((item,index) =>(
                                <tr key={index}>
                                    <th scope="row">{index+1}</th>
                                    <td>{item.courseLevelName}</td>
                                    <td>{item.levelOrder}</td>
                                    <td className="text-right">
                                        <div className="d-flex justify-content-end">
                                            <button onClick={() => editCourseLevel(item.courseLevelId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>
                                            <button onClick={() => deletecourseLevel(item.courseLevelId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                        </div>
                                    </td>
                                </tr>
                            )))}
                            </tbody>
                        </table>
                        :
                        <div className="alert alert-info alert-dismissible">
                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                        </div>
                    }
                    </div>
                }
            </div>
        </div>
    </div>
</>
  )
}

export default CourseLevel
