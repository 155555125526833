import React, { useState, useEffect } from 'react';
import ExamEntry from './ExamEntry'
import AnswerSheet from './AnswerSheet'
import Loader from '../../ui/loader/Loader';
import StudentDataService from "../../../services/StudentDataService"
import ExamDataService from "../../../services/ExamDataService"
import LearnPagination from '../../ui/pagination/LearnPagination';


let subjectListIds = ""
const OnlineExamStudent = () => {
    const [isExam, setIsExam] = useState(false)
    const [ExamId, setExamId] = useState(0)
    const [isResult, setIsResult] = useState(false)
    const [loading,setLoading] = useState(false);
    const [subjects, setSubjects] = useState([])
    const [exams, setExams] =useState([])
    const [searchExam, setSearchExam] = useState("")
    const [isfilterModified, setisFilterModified] = useState(false)

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        academicYearId:0,
        examId:0,
        examName:searchExam,
        isTimeboundOnly:false,
        gradeSchemes:"",
        classSubjects:subjectListIds 
         })

    useEffect(() =>{
        fetchSubjects();
    },[]) 

    useEffect(() => {
        setParametes()
    }, [isfilterModified])

    useEffect(() => {
        fetchExam()
    }, [params])  

    const setParametes = () => {
        setParams({
            academicYearId:0,
            examId:0,
            examName:searchExam,
            isTimeboundOnly:false,
            gradeSchemes:"",
            classSubjects:subjectListIds  
        })
    }

    
    const fetchExam = async () =>{
        setLoading(true)
        await ExamDataService.findStudentExam(params) 
        .then(response => {
           setExams(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false)
      })
       .catch(error => {
        setLoading(false)          
      })
    }


    const fetchSubjects = async () => {
        let SubjectList = []
        await StudentDataService.getStudentSubjects()
        .then(response => {
            response.data.result.map((subjectdata, index) => (
                SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
            ));
            setSubjects(SubjectList)

        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }

    const handleCheckSubjects = (event) => {
        let chksubject = subjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }

    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }




    const AttendExam = (id) => {
        setExamId(id)
        setIsResult(false)
        setIsExam(true)
    }

    const viewResult = (id) => {
        setExamId(id)
        setIsExam(false)
        setIsResult(true)
    }

    return (
        <>{isExam ? (<ExamEntry ExamId={ExamId} />) :(<>{isResult? <AnswerSheet ExamId={ExamId}/>:(
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Exam</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card card-outline card-primary">
                                        <div className="text-center">
                                            <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                        </div>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="input-group">
                                                <div className="input-group input-group-sm">
                                                    <input onChange={event => setSearchExam(event.target.value)} value={searchExam} name="searchExam" id="searchExam" className="form-control form-control-navbar" type="text" placeholder="Exam Name.." aria-label="Search" />
                                                    <div className="input-group-append">
                                                        <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <br />
                                        <div className="col-md-12">
                                            <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                {subjects.map((subjectdata, index) => (
                                                    <li key={index} className="list-group-item">
                                                        <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                    </li>

                                                ))}
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-9">  
                                <div className="row">
                                    <div className="col-md-12">
                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                    </div>
                                </div>
                                {loading?<Loader/>:<>                            
                                    {exams.map((item, index)=>(                                  
                                    <div className="card card-outline card-primary" key={index}>
                                        <div className="card-header" id="headingOne">
                                            <i className="d-flex justify-content-between">
                                            <p className="text-muted text-xs">Subject: {item.classSubjectName}</p>
                                                {item.isTimeBound?
                                                <>
                                                    <p className="text-justify">Exam Date: {item.scheduleDate}</p>
                                                    <p className="text-justify">Exam Time:  {item.scheduleTime+" - "+ item.endTime}</p>
                                                    </>
                                                 :  <p className="text-justify"></p>
                                                 }   
                                            </i>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="d-flex justify-content-between">
                                                    <h5>{item.examName}</h5>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        {/* {item.isActiveExam?
                                                            <>
                                                            {item.isFile?
                                                                    <a href={item.documentId} className="btn btn-link"><span className="text-success"><i className="fa fa-id-card"></i> Download Question</span></a>
                                                                    :
                                                                    <button className="btn btn-link" onClick={() => AttendExam(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                        <span className="text-success"><i className="fa fa-id-card"></i> Attend</span>
                                                                    </button>
                                                                }
                                                            </>
                                                            :<>
                                                                
                                                            </>
                                                        } */}

                                                        {item.isTimeBound && !item.isActiveExam && !item.isScheduleExceeded?
                                                            <p className="text-info">Upcoming</p>
                                                            :
                                                            <>
                                                                {item.isTimeBound && item.isActiveExam && !item.isScheduleExceeded?
                                                                     <>
                                                                     {item.isFile?
                                                                        <a href={item.documentId} className="btn btn-link"><span className="text-success"><i className="fa fa-id-card"></i> Download Question</span></a>
                                                                        :
                                                                        <>
                                                                            {item.isSubmitted?
                                                                             <span className="text-success">Attended</span>
                                                                             :
                                                                             <span className="text-success">Exam In Progress</span>
                                                                            }
                                                                           
                                                                        </>
                                                                    }
                                                                     </>
                                                                    :
                                                                    <>
                                                                        {item.isTimeBound &&!item.isActiveExam && item.isScheduleExceeded?
                                                                            <>
                                                                                {item.isAttended?
                                                                                    <p className="text-success">Attended</p>
                                                                                    :
                                                                                    <p className="text-danger">Not Attended</p>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {!item.isTimeBound && item.isAttended && !item.isAttendanceInProgress?
                                                                                <>
                                                                                    {item.isAttended?
                                                                                        <p className="text-success">Attended</p>
                                                                                    :
                                                                                        <p className="text-danger">Not Attended</p>
                                                                                    }
                                                                                </>
                                                                                    
                                                                                :
                                                                                <>
                                                                                    {!item.isResultPublished && !item.isTimeBound?
                                                                                         <>
                                                                                         {item.isFile?
                                                                                            <a href={item.documentId} className="btn btn-link"><span className="text-success"><i className="fa fa-id-card"></i> Download Question</span></a>
                                                                                            :
                                                                                            
                                                                                                <>
                                                                                                    {item.isSubmitted?
                                                                                                        <span className="text-success"> Attended</span>
                                                                                                        :
                                                                                                        <span className="text-success"> Exam In Progress</span>
                                                                                                    }
                                                                                                </>
                                                                                        }
                                                                                         </>
                                                                                         :<></>
                                                                                    }
                                                                                </>
                                                                            }

                                                                            </>
                                                                        }
                                                                    </>

                                                                }
                                                            </>    
                                                        }


                                                       

                                                        {item.isResultPublished && item.isAttended?
                                                        <button className="btn btn-link collapsed" onClick={()=>viewResult(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            <span className="text-info"><i className="fa fa-search"></i> View Result</span>
                                                        </button>
                                                        :<></>}
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="row">
                                                        <div className="col-md-6 border-left">
                                                            <div className="description-block">
                                                            <h5 className="description-header">{item.isResultPublished?item.markScored:"-"}</h5>
                                                                <span className="description-text">Scored</span>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6 border-left">
                                                            <div className="description-block">
                                                            <h5 className="description-header">{item.maxMarks}</h5>
                                                                <span className="description-text">Total</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               ))}</>}
                               <div className="row">
                                    <div className="col-md-12">
                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                    </div>
                                </div>
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}</>)}</>
    )
}

export default OnlineExamStudent
