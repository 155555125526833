import React, { useState, useEffect } from 'react'
import Loader from '../../ui/loader/Loader';
import ExaminationStudentDataService from '../../../services/ExaminationStudentDataService';
import StudentExamReport from './report/StudentExamReport';

const StudentResultView = (props) => {
    const [masterResult, setMasterResult] = useState([])
    const [resultDetail, setResultDetail] = useState({})
    const [loading,setLoading] = useState(false);  
    const [reportData, setReportdata] = useState([])
    const [reportTitle, setReportTitle] = useState("")
    const [reportSubTitle, setReportSubTitle] = useState("")
    const [isReporeView, setisReportView] = useState(false)

    useEffect(() => {
        fetchResultMasterView()
    }, [])  

    const fetchResultMasterView= async ()=>{
        setLoading(true)
        await ExaminationStudentDataService.getStudentResult(props.match.params.id)
        .then(response => {  
        setLoading(false)   
        setResultDetail(response.data.result)
        setMasterResult(response.data.result.studentMarkDetailViews)
        setReportdata(response.data.result.studentMarkDetailViews)                
        setReportTitle("Report of "+response.data.result.examName)             
        setReportSubTitle("")
        })
        .catch(error => { 
        let msgText=""
        setLoading(false)      
        
        })

    }

  return (
    <>
    {loading?<Loader/>:
  <div className="container">
  <div className="jumbotron">
  <div className="card card-primary">
      <div className="card-header">
      <div className="row">
          <div className="col-md-6" style={{ verticalAlign: "middle" }}>
          <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Report of {resultDetail.examName}</h5>
          </div>
          <br />
          <br />
      </div>
      </div>
      <div className="card-body">    
      {isReporeView?
       <StudentExamReport 
       reportData={reportData} 
       reportTitle={reportTitle} 
       reportSubTitle={reportSubTitle}
       resultDetail={resultDetail}/>  
      :
      <>
      <div className="col-md-1 float-right">
          <button className="btn btn-primary btn-block" onClick={()=>setisReportView(true)}>Print</button>
      </div>
      <br/>
      <br/>
      <table className="table table-bordered table-hover">
          <thead>
          <tr>
              <th scope="col">#</th>
              <th scope="col">Subject</th>
              <th scope="col">Scored Mark</th>
              <th scope="col">Maximum Mark</th>
              <th scope="col">Percentage</th>
          </tr>
          </thead>
          <tbody>
              {masterResult.map((item, index)=>(

                  <tr key={index}>
                  <th scope="row">{index+1}</th>
                  <td>{item.subjectName}</td>
                  <td>{item.markScored}</td>
                  <td>{item.maxMaks}</td>
                  <td>{item.markPercent+"%"}</td>
                  </tr>

              ))}
          </tbody>
      </table>
      <br/>
                <div className="d-flex justify-content-between">
                    <p><b>Total Scored: {resultDetail.markScored}</b></p>
                    <p><b> Maximum  Mark: {resultDetail.maxMarks}</b></p> 
                    <p><b>Mark Percent: {resultDetail.markPercent+"%"}</b></p>  
                </div>
            </>}
      </div>
      {isReporeView &&
        <div className="card-footer">
            <div className="text-right">
                <button className="btn btn-primary" onClick={()=>window.location.reload(false)}>Back</button>
            </div>
        </div>
        }
  </div>
  </div>
</div>
}
</>
  )
}

export default StudentResultView
