import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'yearName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Year Name is Required'
    },
    { 
      field: 'fromDate', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide From Date'
    },
    { 
        field: 'toDate', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Pleave provide To Date'
      }
  ])

  