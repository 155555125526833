import React, { useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import QuestionBankDataService from '../../../services/QuestionBankDataService'
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import CommonDataService from '../../../services/CommonDataService'
import ClassSubjectDataService from '../../../services/ClassSubjectDataService';
import Loader from '../../ui/loader/Loader';
import parse from 'html-react-parser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';


let QuestionTypeIds=""
const AdminQuestionList = () => {
    const [questions,setQuestions] = useState([])
    const [submitted,setSubmitted] =useState(false)
    const [questionTypes,setQuestionTypes]=useState([])
    const [searchQus,setSearchQus]=useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [loading,setLoading] = useState(false);
    
    const [allClasses,setAllClasses]=useState([])
    const [classSubjects,setClassSubjects]=useState([])
    const [teachers, setTeachers] = useState([])
    const [classId, setClassId] = useState("")
    const [subjectId, setSubjectId] = useState("")
    const [teacherId, setTeacherId] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
                                    isUserPosts:false,
                                    questionText:searchQus,
                                    questionTypeList:QuestionTypeIds,
                                    classesList:classId.toString(),
                                    subjectList:subjectId.toString(),
                                    postedbyList:teacherId.toString()   
                                })
    const [teacherFilterData, setTeacherFilterData]= useState({
                                                        classesId:0,
                                                        classSubjectId:0})

    useEffect(() =>{
        fetchQuestionTypes()
    },[]) 


    const fetchQuestionTypes = async () =>{
        let QuesTypes=[]
        await CommonDataService.allQuestionTypes() 
        .then(response => {
            response.data.result.map((qusType,index)=>(
                QuesTypes.push({id:qusType.questionTypeId,value:qusType.questionTypeName,isChecked:false})
            )); 
            setQuestionTypes(QuesTypes)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchClasses = async () =>{
        await CommonDataService.allClasses() 
          .then(response => {
              setAllClasses(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
       }

       const fetchClassSubjects = async () =>{
           setClassSubjects([])
        await ClassSubjectDataService.getUserSubjects(classId,"") 
          .then(response => {
              setClassSubjects(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         }) 
       }

       const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }


    const fetchAllTeachers = async () =>{
        setTeachers([])
        await TeacherAssociationDataService.getTeachers(teacherFilterData) 
        .then(response => {
            setTeachers(response.data.result)
        })
        .catch(error => {
            const errorMsg=error.message;            
        }) 
    }

    const changeClass = async (value) =>{
        setClassSubjects([])
        setTeachers([])
        setSubjectId("")
        setTeacherId("")
        setClassId(value)      
        setTeacherFilterData({classesId:(value=="")?0:parseInt(value),
                                 classSubjectId:0})
    }

    const changeSubject = async (value) =>{
        setSubjectId(value) 
        setTeachers([])  
        setTeacherId("")  
        setTeacherFilterData({classesId:(classId=="")?0:parseInt(classId),
            classSubjectId:(value=="")?0:parseInt(value)}) 
    }
    useEffect(() =>{
        fetchClasses();
     },[]) 

    useEffect(() =>{
        fetchAllTeachers()
     },[teacherFilterData]) 

     useEffect(() =>{
        fetchClassSubjects();
     },[classId])  

    useEffect(() => {
        setParametes()
    }, [submitted,isfilterModified,classId,subjectId,teacherId])

    useEffect(() => {
        fetchQuestions()
    }, [params])
    

    const setParametes = () => {
        setParams({
            isUserPosts:false,
            questionText:searchQus,
            questionTypeList:QuestionTypeIds,
            classesList:classId.toString(),
            subjectList:subjectId.toString(),
            postedbyList:teacherId.toString()  
        })
    }

    const fetchQuestions = async () =>{
        setLoading(true);
        await QuestionBankDataService.findQuestion(params) 
        .then(response => {
        setQuestions(response.data.result.itemList)
        setTotalPages(response.data.result.totalPages)
        setActivePage(response.data.result.activePageNumber)
        setLoading(false)
       })
       .catch(error => {
        setLoading(false)   
                    
       })
    }
 

    const handleClickDelete = async id =>{
        setLoading(true)
        await QuestionBankDataService.deleteQuestion(id) 
        .then(response => {
            setSubmitted(!submitted);
            setLoading(false)
            LearnToast(success, "Question Deleted Successfully")
        })
        .catch(error => {
            setLoading(false)
                    LearnToast(danger, "Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                })
     }
      
      const deleteQuestion = async id => {
          confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>You want to delete this?</p>
                    <button  onClick={onClose}>No</button>
                    <button className="btn-alert-danger"
                      onClick={() =>{handleClickDelete(id);
                        onClose();
                      }}
                    >
                      Yes, Delete it!
                    </button>
                  </div>
                );
              }
            });
         
      }


     

      const handleCheckQuestionTypes = (event) =>{
        let chkQuestionTypes =questionTypes
        QuestionTypeIds=""
        chkQuestionTypes.forEach(qusType => {
           if (qusType.value === event.target.value)
           {
            qusType.isChecked =  event.target.checked
               
           }
           if(qusType.isChecked)
           {
               if(QuestionTypeIds.length >0 || QuestionTypeIds !="")
               {
                QuestionTypeIds+=","
               }
               QuestionTypeIds+=qusType.id
           }
        })
        setQuestionTypes(chkQuestionTypes)
        setisFilterModified(!isfilterModified)
      }


  
  return (
      <>
      <ToastContainer/>
    <div className="container">
    <div className="jumbotron">
        <div className="card card-primary">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>List Questions </h5>
                    </div>
                    <div className="col-md-6 text-right">
                      
                    </div>
                    <br />
                    <br />
                </div>
            </div>
            <div className="card-body">      
            <div className="row">
                <div className="col-md-3">
                    <div className="card card-outline card-primary">
                        <div className="text-center">
                            <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                        </div>
                        <hr/>
                        <div className="col-md-12">
                            <div className="input-group">
                                <div className="input-group input-group-sm">
                                    <input onChange={event => setSearchQus(event.target.value)} value={searchQus} name="searchQus" id="searchQus" className="form-control form-control-navbar" type="text" placeholder="Type Question" aria-label="Search"/>
                                    <div className="input-group-append">
                                        <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br/>
                        <div className="col-md-12 ">
                            <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Question type</b></h6>
                            <ul className="list-group list-group-unbordered mb-3">
                            {questionTypes.map((quesType,index)=>(
                                    <li key={index} className="list-group-item">
                                        <input key={quesType.id} onClick={event=>handleCheckQuestionTypes(event)} type="checkbox" checked={quesType.isChecked} value={quesType.value} /> {quesType.value}
                                    </li>

                                ))}
                            </ul>
                        </div>
                         
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Class</label>
                        <select onChange={event => changeClass(event.target.value)} value={classId} name="classId" className="form-control">
                            <option value="">-- select class --</option>
                            {allClasses.map(item => (
                                <option 
                                key={item.classesId} 
                                value={item.classesId}
                                >                                                
                                    {item.classesName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>  

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Class Subject</label>
                        <select onChange={event => changeSubject(event.target.value)} value={subjectId} name="subjectId" className="form-control">
                            <option value="">-- select subject --</option>
                            {classSubjects.map(item => (
                                <option 
                                key={item.classSubjectId} 
                                value={item.classSubjectId}
                                >                                                
                                    {item.subjectName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>  

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Teacher</label>
                        <select onChange={event => setTeacherId(event.target.value)} value={teacherId} name="teacherId" className="form-control">
                            <option value="">-- select teacher --</option>
                            {teachers.map(item => (
                                <option 
                                key={item.teacherId} 
                                value={item.teacherId}
                                >                                                
                                    {item.teacherName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div> 
                    </div>
                </div>
                <div className="col-md-9">
                {questions.length>0?
                <>
                <div className="row">
                    <div className="col-md-12">
                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                    </div>
                </div>
                {loading? (<Loader/>) : (
                <div className="accordion" id="accordionExample">
                    {questions.map((question,index) =>(

                            <div className="card card-outline card-primary" key={index}>
                                <div className="card-header" id="headingOne">
                                        <i className="d-flex justify-content-between"> 
                                            <p className="text-muted text-xs">Class: {question.classesName}</p>
                                            <p className="text-muted text-xs">Subject: {question.classSubjectName}</p>
                                            <p className="text-muted text-xs">Question Type: {question.questionTypeName}</p>
                                            {/* <button onClick={()=>deleteQuestion(question.questionId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                            </button> */}
                                        </i>
                                    <div className="text-justify">{parse(question.questionText)} </div>
                                    <i className="d-flex justify-content-between"> 
                                        <p className="text-muted text-xs">Posted:{question.postedBy} on {question.postedOn}</p>
                                        <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={"#collapse"+question.questionId} aria-expanded="false" aria-controls={"collapse"+question.questionId}>
                                            <i className="fa fa-edit"></i> Answer
                                        </button>
                                    </i>
                                    
                                </div>
                                <div id={"collapse"+question.questionId} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                    <div className="card-body">
                                        {question.hasMultipleOptions?<>
                                            {question.answerOptionList.map((options,index) =>(
                                            <p key={index} className={options.isCorrectAnswer?"text-success":""}><i>{options.answerText}</i></p>
                                        ))}                                                    
                                        </>: <p><i>{parse(question.answerText)}</i></p>}
                                        
                                    </div>
                                </div>
                            </div>
                    ))}
                            </div>
                )}
                <div className="row">
                    <div className="col-md-12">
                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                    </div>
                </div>
                </>
                :
                <div className="alert alert-info alert-dismissible">
                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                </div>
            }
                </div>
            </div>
  </div>
  </div>
</div>
</div>
  </>
  )
}

export default AdminQuestionList
