import React, { useEffect, useState } from 'react'
import StudentTimeline from './StudentTimeline'
import StudentDataService from "../../../services/StudentDataService"
import AttendanceDetail from './AttendanceDetail';
import Loader from '../../ui/loader/Loader';
import AboutStudent from './AboutStudent';
import StudentAssignment from './StudentAssignment';
import StudentExam from './StudentExam';
import ChangePassword from './ChangePassword';
import { connect } from 'react-redux';
import {Link } from 'react-router-dom';

const StudentProfile = (props) => {

    const [profile, setProfile] = useState({})    
    const [attendanceProfileSummaries, setAttendanceProfileSummaries] = useState([])  
    const [assignmentProfileSummaries, setAssignmentProfileSummaries] = useState([])  
    const [examProfileSummaries, setExamProfileSummaries] = useState([])  
    const [loading,setLoading] = useState(false);


    useEffect(() =>{
        fetchStudentProfile()
    },[])  

    const fetchStudentProfile = async () =>{
        setLoading(true)
        await StudentDataService.getStudentProfile(0) 
          .then(response => {
              setProfile(response.data.result)
              setAttendanceProfileSummaries(response.data.result.attendanceProfileSummaries)
              setAssignmentProfileSummaries(response.data.result.assignmentProfileSummaries)
              setExamProfileSummaries(response.data.result.examProfileSummaries)
              setLoading(false)
         })
         .catch(error => {
            setLoading(false)         
         })
    }
    


    return (
        <div className="container">
            <div className="jumbotron">
                <div className="row">
                    <div className="col-md-3">
                        <div className="card card-primary card-outline">
                            <div className="card-body box-profile">
                                <div className="text-center">
                                    <Link to={{ pathname: '/app/update-profile/' }} 
                                            className="d-block">     
                                        <img style={{ border: "3px solid #055ab5" }} className="profile-user-img img-fluid img-circle" src={props.profilePicUrl} alt="User profile picture" />                      
                                    </Link>                        
                                </div>
                                <h3 className="profile-username text-center">{profile.studentName}</h3>
                                    <p className="text-muted text-center">{profile.classSectionName}</p>
                                <ul className="list-group list-group-unbordered mb-3">
                                    <li className="list-group-item">
                                        <b>Gender</b> <a className="float-right">{profile.genderName}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Mobile</b> <a className="float-right">{profile.mobile}</a>
                                    </li>
                                    <li className="list-group-item">
                                        <b>Email</b> <a className="float-right">{profile.eMail}</a>
                                    </li>
                                </ul>
                            </div>
                            </div>
                            <div className="card card-primary card-outline">
                            <div className="card-header">
                                <h3 className="card-title">Parent Info</h3>
                            </div>
                            <div className="card-body box-profile">
                                <h3 className="profile-username text-center">{profile.parentName}</h3>
                                <p className="text-muted text-center"><i className="fa fa-mobile"></i>{" " + profile.parentMobile}<br/>
                                <i className="fa fa-envelope"></i> {" " + profile.parentEmail}</p>                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="card card-primary card-outline">
                            <div className="card-header p-2">
                                <ul className="nav nav-pills">
                                    <li className="nav-item"><a className="nav-link active" href="#about" data-toggle="tab">About</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#assignment" data-toggle="tab">Assignment</a></li>
                                    <li className="nav-item"><a className="nav-link " href="#activity" data-toggle="tab">Attendance</a></li>
                                    <li className="nav-item"><a className="nav-link " href="#exam" data-toggle="tab">Exam</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#timeline" data-toggle="tab">Timeline</a></li>
                                    <li className="nav-item"><a className="nav-link" href="#settings" data-toggle="tab">Account</a></li>
                                </ul>
                            </div>
                            <div className="card-body">
                            {loading?<Loader/>:
                                <>
                                <div className="tab-content">
                                    <div className="active tab-pane" id="about">
                                        <AboutStudent profile={profile}/>
                                    </div>
                                    <div className="tab-pane" id="assignment">
                                        <StudentAssignment assignmentProfileSummaries={assignmentProfileSummaries} profile={profile}/>
                                    </div>
                                    <div className="tab-pane" id="activity">                                       
                                        {attendanceProfileSummaries !==null?
                                            <AttendanceDetail attendanceProfileSummaries={attendanceProfileSummaries} profile={profile}/>
                                            :<></>
                                        }                                       
                                    </div>
                                    <div className="tab-pane" id="exam">
                                        <StudentExam examProfileSummaries={examProfileSummaries} profile={profile}/>
                                    </div>
                                    <div className="tab-pane" id="timeline">
                                        <StudentTimeline profile={profile}/>
                                    </div>
                                    <div className="tab-pane" id="settings">
                                       <ChangePassword/>
                                    </div>
                                    {/* /.tab-pane */}
                                </div>
                                </>
                            } 
                                {/* /.tab-content */}
                            </div>{/* /.card-body */}
                        </div>
                        {/* /.nav-tabs-custom */}
                    </div>
                    {/* /.col */}
                </div>


            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
      Name: state.login.user.name,
      profilePicUrl:state.login.profileUrl,
      userTypeName:state.login.user.userTypeName
    }
  }

export default connect(mapStateToProps)(StudentProfile);
