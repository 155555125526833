import  ApiDataService  from './ApiDataService'

const createExamQuestion = data => {
  return ApiDataService.post("examquestion", data);
};

const allExamQuestion = (id) => {
    let values={'examQuestionSettingId':id}
    return ApiDataService.getWithParam("examquestion/list",values);
  };

  const findExamQuestion = (id,questionText) => {
    let values={'examQuestionSettingId':id,'questionText':questionText}
    return ApiDataService.getWithParam("examquestion/find",values);
  };

  const deleteExamQuestion = (id) => {
    let values={'examQuestionId':id}
    return ApiDataService.remove("examquestion",values);
  };

export default {createExamQuestion,
                allExamQuestion,
                findExamQuestion,
                deleteExamQuestion}