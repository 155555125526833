import  ApiDataService  from './ApiDataService'

const createAcademicYear = data => {
  return ApiDataService.post("academicyear", data);
};

const allAcademicYear = () => {
  return  ApiDataService.get("academicyear/all");
};

const getAcademicYear = id => {
  let values={'id':id}
  return  ApiDataService.getWithParam("academicyear",values);
};

const UpdateAcademicYear = data => {
  return ApiDataService.put("academicyear", data);
}

const deleteAcademicYear = id => {
  let values={'id':id}
  return  ApiDataService.remove("academicyear",values);
};

const currentAcademicYear = () => {
  return ApiDataService.get("academicyear/current");
};

export default {createAcademicYear,
                allAcademicYear,
                getAcademicYear,
                UpdateAcademicYear,
                deleteAcademicYear,
                currentAcademicYear};