import  ApiDataService  from './ApiDataService'

const createAssignment= data => {
  return ApiDataService.post("assignment", data);
};

const findAssignment = (values) => {
    return  ApiDataService.getWithParam("assignment/find",values);
  };

const deleteAssignment = id => {
  let values={'assignmentId':id}
  return  ApiDataService.remove("assignment",values);
};

const evaluateExam = data => {
  return ApiDataService.put("/assignment", data);
}


//StudentAssignment
const uploadAssignment= data => {
  return ApiDataService.post("studentassignment", data);
};

const getStudentAssignment = (values) => {
  return  ApiDataService.getWithParam("studentassignment/find",values);
};

const assignmentStudents = (assignmentId) => {
  let values={"assignmentId":assignmentId}
  return  ApiDataService.getWithParam("studentassignment/students",values);
};

//Assignment Instruction

const createAssignmentInstruction= data => {
  return ApiDataService.post("assignmentinstruction", data);
};

const getAssignmentInstruction = (id) => {
    let values={"assignmentId":id}
    return  ApiDataService.getWithParam("assignmentinstruction",values);
  };

const deleteAssignmentInstruction = id => {
  let values={'id':id}
  return  ApiDataService.remove("assignmentinstruction",values);
};

export default {createAssignment,
                findAssignment,
                deleteAssignment,
                evaluateExam,
                getStudentAssignment,
                uploadAssignment,
                assignmentStudents,
                createAssignmentInstruction,
                getAssignmentInstruction,
                deleteAssignmentInstruction};