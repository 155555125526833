import React, { useState, useEffect } from 'react';
import CommonDataService from '../../../services/CommonDataService';
import ClassSectionDataService from "../../../services/ClassSectionDataService"
import Loader from '../../ui/loader/Loader';
import StudentDataService from "../../../services/StudentDataService"
import { Link } from 'react-router-dom';
import LearnPagination from '../../ui/pagination/LearnPagination';

const StudentList = (props) => {
 
    const [genders,setGenders] = useState([]);
    const [classSection,setClassSection] = useState([]);
    const [allStudent, setAllStudent] = useState([]);
    const [loading,setLoading] = useState(false);

    const [searchName, setSearchName]= useState("")
    const [searchMobile, setSearchMobile]= useState("")
    const [searchEmail, setSearchEmail]= useState("")
    const [searchGenderId, setSearchGenderId]= useState("")
    const [filterclassSectionId, setFilterClassSectionId] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        studentName:searchName,
        mobile:searchMobile,
        email:searchEmail,
        genderList:searchGenderId.toString(),
        classSectionList:filterclassSectionId.toString()
    })
  

    useEffect(() =>{        
        fetchClassSection();
        fetchallGenders();
    },[]) 

    useEffect(() => {
        setParametes()
    }, [])

    useEffect(() => {
        fetchStudent()
    }, [params])

    const setParametes = () => {
        setParams({
        studentName:searchName,
        mobile:searchMobile,
        email:searchEmail,
        genderList:searchGenderId.toString(),
        classSectionList:filterclassSectionId.toString()
        })
    }


   const fetchStudent = async () =>{
        setLoading(true);
        await StudentDataService.findStudent(params) 
        .then(response => {
            setAllStudent(response.data.result.itemList)
            setTotalPages(response.data.result.totalPages)
            setActivePage(response.data.result.activePageNumber)
            setLoading(false);
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchallGenders= async () =>
    {
        await CommonDataService.allGender() 
        .then(response => {
            setGenders(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchClassSection= async () =>
    {
        await ClassSectionDataService.allClassSection() 
        .then(response => {
            setClassSection(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

 

    const searchStudent = () =>{
        setParametes()
    }

    
    return (
        <>
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}> Student </h5>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                        <div className="card card-outline card-primary">
                                            <div className="text-center">
                                                <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                            </div>
                                            <hr/>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control form-control-navbar" type="text" placeholder="Name"/>                                                                                               
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input onChange={event => setSearchMobile(event.target.value)} value={searchMobile} name="searchMobile" id="searchMobile" className="form-control form-control-navbar" type="text" placeholder="Mobile"/>  
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <input onChange={event => setSearchEmail(event.target.value)} value={searchEmail} name="searchEmail" id="searchEmail" className="form-control form-control-navbar" type="text" placeholder="Email"/>  
                                                </div>
                                            </div>                                    
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <select onChange={event => setFilterClassSectionId(event.target.value)} value={filterclassSectionId} name="filterclassSectionId" className="form-control">
                                                        <option value="">-- select class section --</option>
                                                        {classSection.map(item => (
                                                            <option 
                                                            key={item.classSectionId} 
                                                            value={item.classSectionId}
                                                            >                                                
                                                                {item.classesName + " " + item.sectionName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <select className="form-control" onChange={event => setSearchGenderId(event.target.value)} value={searchGenderId} name="searchGenderId"  >                      
                                                        <option value="">-- select gender --</option>
                                                        {genders.map(item => (
                                                            <option
                                                                key={item.genderId}
                                                                value={item.genderId}
                                                            >
                                                                {item.genderName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <button className="btn btn-primary btn-block" onClick={()=>searchStudent()}>
                                                    Search
                                                </button>
                                            </div>
                                        </div>
                                </div>
                                {loading?(<Loader/>):
                                    <div className="col-md-9">
                                    {allStudent.length>0?
                                    <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">Name</th>
                                                    {/* <th scope="col">Gender</th> */}
                                                    <th scope="col">Mobile</th>
                                                    <th scope="col">Class Section</th>
                                                    <th className="text-right">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {(allStudent.map((student, index) => (
                                                    <tr key={student.studentId}>
                                                        <th scope="row">{index + 1}</th>
                                                        <td>{student.studentName}</td>
                                                        {/* <td>{student.genderId}</td> */}
                                                        <td>{student.mobile}</td>
                                                        <td>{student.currentClassSection}</td>

                                                        <td className="text-right">
                                                            <Link className="btn btn-link" to={{pathname: '/app/student-profile-view/'+student.studentId,props:{ id: student.studentId}}} data-toggle="tooltip" data-placement="top" title="Profile"><i className="fa fa-user text-primary"></i></Link>&nbsp;&nbsp;&nbsp;                                                   
                                                        </td>
                                                    </tr>
                                                )))}
                                            </tbody>
                                        </table>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                        </>
                                        :
                                        <div className="alert alert-info alert-dismissible">
                                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                                        </div>
                                    }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default StudentList;