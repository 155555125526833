import ApiDataService from './ApiDataService'

const createExamInstruction = data => {
    return ApiDataService.post("examinstruction", data);
};
const getExamInstructions = (examId) => {
    let values = { "examId": examId }
    return ApiDataService.getWithParam("examinstruction/examinstructions", values);
};

const removeExamInstruction = id => {
    let values = { 'id': id }
    return ApiDataService.remove("examinstruction", values);
};

export default {
    createExamInstruction,
    getExamInstructions,
    removeExamInstruction
}