import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import { PieChart } from 'react-minimal-pie-chart';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loader from '../../ui/loader/Loader';
import ClassSectionDataService from "../../../services/ClassSectionDataService"
import ClassSubjectDataService from "../../../services/ClassSubjectDataService"
import ClassScheduleDataService from "../../../services/ClassScheduleDataService"
import StudentAttendanceDataservice from "../../../services/StudentAttendanceDataservice"
import ClassSheduleAssosiationDataService from "../../../services/ClassSheduleAssosiationDataService"
import ValidationRule from './ValidationRule'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination'


let subjectListIds = ""
let classSectionIds=""
const AdminSchedule = () => {
    const [show, setShow] = useState(false);
    const [classSections, setClassSections]= useState([])
    const [classSectionId, setClassSectionId] = useState("")
    const [subjects, setSubjects] = useState([])
    const [subjectId, setSubjectId] = useState("")
    const [teachers, setTeachers] = useState([])
    const [teacherId, setTeacherId] = useState("")
    const [scheduleDate, setScheduleDate]= useState("")
    const [scheduleToTime, setScheduleToTime] = useState("")
    const [scheduleFromTime, setScheduleFromTime] = useState("")    
    const [loading,setLoading] = useState(false);
    const [classShedules, setClassSchedules] = useState([])
    const [submitted, setSubmitted] = useState(false)
    const [isCreate, setIsCreate] = useState(true)
    const [formTitle, setFormTitle] = useState("")
    const [students, setStudents] = useState([])
    const [isAssociation, setIsAssociation] = useState(false)

    
    
    const [allsubjects,setAllSubjects]=useState([])   
    const [searchDate,setSearchDate]=useState(new Date().toLocaleDateString())
    const [filterclassSectionId, setFilterClassSectionId] = useState("")
    const [isfilterModified,setisFilterModified]=useState(false)

    const [eligibleClassAssociations, setEligibleClassAssociation] = useState([])
    const [classAssociations, setClassAssociations] = useState([])
    const [classScheduleId, setClassScheduleId] = useState(0)

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        scheduleDate:searchDate,
        classList:filterclassSectionId.toString(),
        subjectList:subjectListIds,
        teacherList:""
    })

    const validator= ValidationRule.AdminScheduleRule();
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)   
    
    var validationData={
        classSectionId:classSectionId,
        subjectId:subjectId,
        teacherId:teacherId,
        scheduleDate:scheduleDate,
        scheduleFromTime:scheduleFromTime,
        scheduleToTime:scheduleToTime,
        validation:validation,
    }
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() =>{
        fetchClassSections()
    },[])    

    useEffect(() =>{
        fetchfilterClassSubjects()
    },[filterclassSectionId])  

    useEffect(() =>{
        fetchClassSubjects()
    },[classSectionId])  

    useEffect(() =>{
        fetchTeachers(subjectId)
    },[subjectId]) 

    const serachByDate=()=>{
        setisFilterModified(!isfilterModified)
      }

      useEffect(() => {        
        setParametes()
    }, [submitted,isfilterModified,filterclassSectionId])

    useEffect(() => {
        fetchClassSchedules()
    }, [params])

    const setParametes = () => {
        setParams({
            scheduleDate:searchDate,
            classList:filterclassSectionId.toString(),
            subjectList:subjectListIds,
            teacherList:""
        })
    }

    const fetchClassSchedules = async () =>{
        setLoading(true)
        await ClassScheduleDataService.findAdminClassSchedule(params) 
        .then(response => {
            setClassSchedules(response.data.result.itemList)
            setTotalPages(response.data.result.totalPages)
            setActivePage(response.data.result.activePageNumber)
            setLoading(false)
       })
       .catch(error => {
           const errorMsg=error.message;   
           setLoading(false)         
       })

    }

    const fetchClassSections = async () =>{
        setTeachers([])
        await ClassSectionDataService.allClassSection() 
          .then(response => {
              setClassSections(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
    }

    

    const fetchClassSubjects = async () =>{
        setTeachers([])
        await ClassSubjectDataService.getClassSubjectWithClassSection(classSectionId===""?0:parseInt(classSectionId)) 
        .then(response => {
            setSubjects(response.data.result)
        })
        .catch(error => {
            const errorMsg=error.message;            
        })
    }

    const fetchfilterClassSubjects = async () =>{
        let SubjectList = []
        setAllSubjects([])
        await ClassSubjectDataService.getClassSubjectWithClassSection(filterclassSectionId===""?0:parseInt(filterclassSectionId)) 
        .then(response => {
            response.data.result.map((subjectdata, index) => (
                SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
            ));
            setAllSubjects(SubjectList)
        })
        .catch(error => {
            const errorMsg=error.message;            
        })
    }

    const handleCheckSubjects = (event) => {
        let chksubject = allsubjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setAllSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }

    const fetchTeachers = async () =>{
        await ClassSubjectDataService.getsubjectTeachers(classSectionId===""?0:parseInt(classSectionId),subjectId===""?0:parseInt(subjectId)) 
        .then(response => {
           setTeachers(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const newShedule = () =>{
        setFormTitle("New Schedule")
        setIsAssociation(false)
        setIsCreate(true)
        handleShow()
    }
    const submitShedule = async () =>{
        var data = {
            classSectionId: parseInt(classSectionId),
            classSubjectId:parseInt(subjectId),
            teacherId: parseInt(teacherId),
            scheduleDate: scheduleDate,
            startDateTime:(scheduleDate+"T"+scheduleFromTime+":00.000"),
            endDateTime:(scheduleDate+"T"+scheduleToTime+":00.000")
        };
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        if (validate.isValid) {
            setLoading(true)
            await ClassScheduleDataService.createClassSchedule(data)
            .then(response => {
                setLoading(false)
                LearnToast(success, "Schedule Posted Successfully")
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }   
            })

            setSubmitted(!submitted)
            resetForm()
            handleClose()
        }

    }

    const resetForm = () =>{        
        setClassSectionId("");
        setSubjectId("")
        setTeacherId("")
        setScheduleDate("")
        setScheduleFromTime("")
        setScheduleToTime("")
        setValidation(validator.valid());
        setValidateSubmitted(false);
    }

    const viewAttendanceDetail = async (id) =>{
        setFormTitle("Attendance Info")
        setIsCreate(false)
        setIsAssociation(false)
        await StudentAttendanceDataservice.getStudentAttendanceList(id) 
        .then(response => {
            setStudents(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
        handleShow()
    }


    const newSheduleAssociation = async (id) =>{
        setFormTitle("Shedule Association")
        setEligibleClassAssociation([])
        setClassAssociations([])
        setIsCreate(false)
        setIsAssociation(true)
        setClassScheduleId(id)
        fetchClassSheduleAssociations(id)
        fetchEligibleClassSections(id)        
        handleShow()
    }


    const fetchClassSheduleAssociations = async (id) =>{
        await ClassSheduleAssosiationDataService.getClassSheduleAssociations(id) 
          .then(response => {
              setClassAssociations(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
    }

    const fetchEligibleClassSections = async (id) =>{
        let sectionList=[]
        await ClassSheduleAssosiationDataService.eligibleClassSections(id) 
          .then(response => {
              response.data.result.map((classsection, index) => (
                sectionList.push({ id: classsection.classSectionId, value: classsection.classSectionName})
            ));
              setEligibleClassAssociation(sectionList)
         })
         .catch(error => {
             const errorMsg=error.message; 
             setEligibleClassAssociation([])           
         })
    }

    const handleCheckEligibleSections = (event) => {
        let chksection = eligibleClassAssociations
        classSectionIds = ""
        chksection.forEach(sectiondata => {
            if (sectiondata.value === event.target.value) {
                sectiondata.isChecked = event.target.checked

            }
            if (sectiondata.isChecked) {
                if (classSectionIds.length > 0 || classSectionIds != "") {
                    classSectionIds += "|"
                }
                classSectionIds += sectiondata.id
            }
        })
        setEligibleClassAssociation(chksection)
    }

    const saveClassSheduleAssociation = async () =>{
        let data={
            classScheduleId:classScheduleId,
            classSectionIdList:classSectionIds.toString()
        }
        setLoading(true)  
        await ClassSheduleAssosiationDataService.classSheduleAssociation(data) 
          .then(response => {
              fetchClassSheduleAssociations(classScheduleId)
              fetchEligibleClassSections(classScheduleId)
              setisFilterModified(!isfilterModified)
              setLoading(false)    
              LearnToast(success, "Schedule Association Saved Successfully")
         })
         .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }          
         })
    }

    const deleteSheduleAssociation = async (id) =>{      
        setLoading(true) 
        await ClassSheduleAssosiationDataService.deleteClassSheduleAssociations(id) 
          .then(response => {
              fetchClassSheduleAssociations(classScheduleId)
              fetchEligibleClassSections(classScheduleId)
              setisFilterModified(!isfilterModified)
              setLoading(false)    
              LearnToast(success, "Schedule Association Deleted")
         })
         .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }   
         })
    }


    const closeWindow = () =>{
        handleClose()
        setEligibleClassAssociation([])
        setClassAssociations([])
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await ClassScheduleDataService.deleteClassSchedule(id) 
            .then(response => {
                setLoading(false)
                setSubmitted(!submitted);                
                LearnToast(success, "Schedule Deleted Successfully")
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }   
            })
   }


    const deleteShedule = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
       
    }


    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 

  return (
    <>
    <ToastContainer />
    {loading ? <Loader /> :
        <>
            <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>{formTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    {isCreate?
                        <>
                        <div className="form-group">
                            <label>Class Section</label>
                            <select onChange={event => setClassSectionId(event.target.value)} value={classSectionId} name="classSectionId" className={validateOutput.classSectionId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                <option value="">-- select class section --</option>
                                {classSections.map(item => (
                                    <option 
                                    key={item.classSectionId} 
                                    value={item.classSectionId}
                                    >                                                
                                        {item.classesName + " " + item.sectionName}
                                    </option>
                                ))}
                            </select>
                            <span className="text-danger">{validateOutput.classSectionId.message}</span>
                        </div>
                        <div className="form-group">
                            <label>Class Subject</label>
                            <select onChange={event => setSubjectId(event.target.value)} value={subjectId} name="subjectId" className={validateOutput.subjectId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                <option value="">-- select subject --</option>
                                {subjects.map(item => (
                                    <option 
                                    key={item.classSubjectId} 
                                    value={item.classSubjectId}
                                    >                                                
                                        {item.subjectName}
                                    </option>
                                ))}
                            </select>
                            <span className="text-danger">{validateOutput.subjectId.message}</span>
                        </div>
                        <div className="form-group">
                            <label>Teacher</label>
                            <select onChange={event => setTeacherId(event.target.value)} value={teacherId} name="teacherId" className={validateOutput.teacherId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                <option value="">-- select teacher --</option>
                                {teachers.map(item => (
                                    <option 
                                    key={item.teacherId} 
                                    value={item.teacherId}
                                    >                                                
                                        {item.teacherName}
                                    </option>
                                ))}
                            </select>
                            <span className="text-danger">{validateOutput.teacherId.message}</span>
                        </div>
                        <div className="form-row">
                            <div className="col">
                                <label>Date</label>
                                <input type="date" onChange={event => setScheduleDate(event.target.value)} value={scheduleDate} name="scheduleDate" id="scheduleDate" className={validateOutput.scheduleDate.isInvalid ? 'form-control is-invalid':'form-control'}/>
                                <span className="text-danger">{validateOutput.scheduleDate.message}</span>
                            </div>
                            <div className="col">
                                <label>From Time</label>
                                <input type="time" onChange={event => setScheduleFromTime(event.target.value)} value={scheduleFromTime} name="scheduleFromTime" id="scheduleFromTime" className={validateOutput.scheduleFromTime.isInvalid ? 'form-control is-invalid':'form-control'}/>
                                <span className="text-danger">{validateOutput.scheduleFromTime.message}</span>
                            </div> 
                            <div className="col">
                                <label>To Time</label>
                                <input type="time" onChange={event => setScheduleToTime(event.target.value)} value={scheduleToTime} name="scheduleToTime" id="scheduleToTime" className={validateOutput.scheduleToTime.isInvalid ? 'form-control is-invalid':'form-control'} />
                                <span className="text-danger">{validateOutput.scheduleToTime.message}</span>
                            </div>
                        </div>
                        </>
                        :
                        <>
                        {!isAssociation?
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Name</th>
                                        <th className="text-right"></th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {students.map((item, index)=>(
                                        <tr>
                                        <th scope="row">{index+1}</th>
                                        <td>{item.studentName}</td>
                                        <td className="text-right">
                                            {item.isPresent?
                                                <span className="text-success"><i className="fa fa-check"></i> </span>
                                            :
                                                <span className="text-danger"><i className="fa fa-times"></i> </span>
                                            }                        
                                        </td>
                                    </tr>
                                    ))}                                
                                </tbody>
                            </table>
                            :
                            <>
                            {eligibleClassAssociations.length>0?
                                <div className="col-md-12">
                                    <div className="form-group">
                                    <ul className="list-group list-group-unbordered mb-3">
                                        {eligibleClassAssociations.map((sectiondata, index) => (
                                            <li key={index} className="list-group-item">
                                                <input key={sectiondata.id} onClick={event => handleCheckEligibleSections(event)} type="checkbox" checked={sectiondata.isChecked} value={sectiondata.value} /> {sectiondata.value}
                                            </li>

                                        ))}
                                    </ul>
                                    </div>
                                    <div className="form-group">
                                        <button className="btn btn-primary" onClick={()=>saveClassSheduleAssociation()}> Save</button>
                                    </div>
                                </div>
                                :<></>}
                                <br/>
                                <div className="col-md-12">
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">Class Section</th>

                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {classAssociations.map((sections, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{sections.classSectionName}</td>
                                                <td className="text-right">
                                                <button onClick={() => deleteSheduleAssociation(sections.classScheduleAssociationId)} className="btn btn-danger badge-pill" style={{ width: '80px' }}>Delete</button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                </div>
                            </>
                        }   
                            
                        </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>closeWindow()}>
                            Close
                        </Button>
                        {isCreate?
                            <Button type="submit" variant="primary" onClick={()=>submitShedule()}>
                                Submit
                            </Button>
                        :<></>}
                    </Modal.Footer>
                </Modal>
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Class Schedule</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{marginBottom:"0.1rem",marginTop:"0.1rem"}} onClick={()=>newShedule()}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-3">
                                    <div className="card card-outline card-primary">
                                        <div className="text-center">
                                            <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                        </div>
                                        <hr/>
                                        <div className="col-md-12">
                                            <div className="input-group">
                                                <div className="input-group input-group-sm">
                                                    <input onChange={event => setSearchDate(event.target.value)} value={searchDate} name="searchDate" id="searchDate" className="form-control form-control-navbar" type="date" aria-label="Search"/>
                                                    <div className="input-group-append">
                                                        <button onClick={()=>serachByDate()} className="btn btn-primary" type="submit">
                                                            <i className="fas fa-search"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Classes</b></h6>
                                            <select onChange={event => setFilterClassSectionId(event.target.value)} value={filterclassSectionId} name="filterclassSectionId" className="form-control">
                                                <option value="">-- select class section --</option>
                                                {classSections.map(item => (
                                                    <option 
                                                    key={item.classSectionId} 
                                                    value={item.classSectionId}
                                                    >                                                
                                                        {item.classesName + " " + item.sectionName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        {allsubjects.length>0 &&
                                        <div className="col-md-12">
                                            <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                            <ul className="list-group list-group-unbordered mb-3">
                                                {allsubjects.map((subjectdata, index) => (
                                                    <li key={index} className="list-group-item">
                                                        <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                        }
                                        <br/>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    {classShedules.length>0?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                            </div>
                                            {classShedules.map((item,index)=>(
                                                    <div className="card card-outline card-primary">
                                                        <div className="card-header" id="headingOne">
                                                            <i className="d-flex justify-content-between"> 
                                                                <p className="text-muted text-xs">Scheduled By:  {item.createdBy}</p>
                                                                <p className="text-muted text-xs">Posted On:  {item.createdOn} </p>
                                                            </i>
                                                            <div className="row">
                                                                <div className="col-md-7">
                                                                    <h5>Class: {item.classSectionName}</h5> 
                                                                    <h6>Subject:  {item.classSubjectName}</h6>   
                                                                    <h6>Teacher: {item.teacherName} </h6>
                                                                    {item.isAttendanceMarked?
                                                                        <div className="d-flex justify-content-between">                                  
                                                                            <i> 
                                                                                <p className="text-muted">Date:  {item.scheduleDate} <br/> Time:  {item.fromTime + " - " + item.toTime}</p>  
                                                                            </i>                                                                    
                                                                        </div>  
                                                                    :<></>
                                                                    }
                                                                </div>
                                                                {item.isAttendanceMarked?
                                                                        <>
                                                                <div className="col-md-3 text-center" style={{verticalAlign:"top"}}>                                                   
                                                                    <div>
                                                                        <PieChart
                                                                            data={[
                                                                                { title: 'Absent', value: item.absentStudents, color: '#FF0000' },
                                                                                { title: 'Present', value: item.presentStudents, color: '#008000' }
                                                                            ]}
                                                                            label={({ dataEntry }) => dataEntry.value}
                                                                            labelStyle={(index) => ({
                                                                                fill: "#ffffff",
                                                                                fontSize: '10px',
                                                                                fontFamily: 'sans-serif',                                                                    
                                                                                })}
                                                                                radius={45}
                                                                        />
                                                                        </div>                                                       
                                                                </div>                                       
                                                                <div className="col-md-2">
                                                                    <div style={{marginBottom:"10px",marginTop:"10px"}}>
                                                                        <span className="mr-2">
                                                                            <i className="fas fa-square text-success"></i> Present - {item.presentStudents}
                                                                        </span>
                                                                        <br/>
                                                                        <span className="mr-2">
                                                                            <i className="fas fa-square text-danger"></i> Absent - {item.absentStudents}
                                                                        </span> 
                                                                    </div>                                               
                                                                    <div className="text-left">                                                            
                                                                        <button className="btn btn-link" onClick={()=>viewAttendanceDetail(item.classScheduleId)}>
                                                                            <span className="text-success"><i className="fa fa-search"></i> View</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                </> :
                                                                <></>
                                                            }
                                                            </div>     
                                                            {!item.isAttendanceMarked?
                                                            <div className="d-flex justify-content-between">                                  
                                                                        <i> 
                                                                            <p className="text-muted">Date:  {item.scheduleDate} <br/> Time:  {item.fromTime + " - " + item.toTime}</p>  
                                                                        </i>
                                                                        {item.isRemovable?
                                                                        <>
                                                                            <button className="btn btn-link" onClick={()=>newSheduleAssociation(item.classScheduleId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                                                    <span className="text-info"><i className="fa fa-plus"></i> Associate Class Secton </span>
                                                                            </button>                                                   
                                                                            <button className="btn btn-link" data-toggle="tooltip"  onClick={()=>deleteShedule(item.classScheduleId)}  data-placement="top" title="Delete">
                                                                                <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                                            </button>
                                                                        </>
                                                                        :<></>
                                                                        }
                                                                    </div>  
                                                            :<></>
                                                            }
                                                        </div>
                                                    </div>
                                                ))}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                            </div>
                                        </>
                                         :
                                         <div className="alert alert-info alert-dismissible">
                                             <div className="text-center text-white"><h4>No Records Found</h4></div>
                                         </div>
                                     }
                                </div>                           
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }
    </>
  )
}

export default AdminSchedule
