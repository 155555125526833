import React, { useState, useEffect } from 'react'
import ExaminationScheduleDataSevice from "../../../services/ExaminationScheduleDataSevice"
import Loader from '../../ui/loader/Loader';
import AnswerSheet from './answerSheet/AnswerSheet'

const StudentTodaysExaminations = () => {
    const [exams, setExams] = useState([])
    const [loading,setLoading] = useState(false);
    const [examScheduleId, setExamScheduleId] = useState(0)
    const [isExamAttend, setisExamAttend] = useState(false)

    useEffect(() => {
        fetchTodaysExams()
    }, [])  

    const fetchTodaysExams = async () =>
    {
        await ExaminationScheduleDataSevice.getStudentTodayExams() 
        .then(response => {
            setExams(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const AttendExam = (id) => {
        setExamScheduleId(id)
        setisExamAttend(true)
    }

  return (
      <>
    {isExamAttend? <AnswerSheet examScheduleId={examScheduleId}/>
    :
    <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Todays Exam</h5>
                                </div>
                            </div>
                        </div>
                        {loading?<Loader/>:<>  
                        <div className="card-body">
                            <div className="row">  
                            {exams.length>0?
                                <>  
                                <div className="col-md-12">
                                    <div className="alert alert-warning alert-dismissible">
                                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                    {/* <h5><i className="icon fas fa-exclamation-triangle" /> Alert!</h5> */}
                                    You have to referesh the page on Time.
                                </div>

                                </div>                         
                                <div className="col-md-12">  
                               
                                                
                                    {exams.map((item, index)=>(                                  
                                    <div className="card card-outline card-primary" key={index}>
                                        <div className="card-header" id="headingOne">
                                            <i className="d-flex justify-content-between">
                                                <p className="text-muted text-xs">Subject: {item.subjectName}</p>                                              
                                                <p className="text-muted text-xs">Exam Date: {item.scheduleDate}</p>
                                                <p className="text-muted text-xs">Exam Time:  {item.fromTime+" - "+ item.toTime}</p>                                                     
                                            </i>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="d-flex justify-content-between">
                                                    <h5>{item.examName}</h5>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                    {item.attendable?
                                                        <button className="btn btn-link" onClick={() => AttendExam(item.examScheduleId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            <span className="text-success"><i className="fa fa-id-card"></i> Attend</span>
                                                        </button>
                                                    :<p className="text-info">Upcoming</p>
                                                    }
                                                    
                                                     
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="row">
                                                        <div className="col-md-6 border-left">
                                                            <div className="description-block">
                                                            <h5 className="description-header">{item.maxMarks}</h5>
                                                                <span className="description-text">Total</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               ))}                             
                               
                               </div>                               
                                </>
                                :
                                    <div className="col-md-12">
                                        <div className="alert alert-info alert-dismissible">
                                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        </>}
                    </div>
                </div>
            </div>
    }
    </>
 )
}

export default StudentTodaysExaminations
