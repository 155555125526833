import  ApiDataService  from './ApiDataService'

const createQuestionPaperSection = data => {
  return ApiDataService.post("questionpapersection", data);
};

const getQuestionPaperSection = questionPaperMstId => {
  let values={'questionPaperMstId':questionPaperMstId}
  return  ApiDataService.getWithParam("questionpapersection",values);
};

const getQuestionPaperSectionCombo = questionPaperMstId => {
  let values={'questionPaperMstId':questionPaperMstId}
  return  ApiDataService.getWithParam("questionpapersection",values);
};

const deleteQuestionPaperSection = id => {
  let values={'id':id}
  return  ApiDataService.remove("questionpapersection",values);
};


export default {createQuestionPaperSection,
                getQuestionPaperSection,
                getQuestionPaperSectionCombo,
                deleteQuestionPaperSection};