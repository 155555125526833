import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'classesId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Name is Required'
    },
    { 
      field: 'subjectName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    }
  ])

  