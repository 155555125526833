import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import CourseLevelDataService from "../../../services/CourseLevelDataService"
import CommonDataService from '../../../services/CommonDataService'
import ClassSubjectDataService from '../../../services/ClassSubjectDataService'
import CourseItemDataService from '../../../services/CourseItemDataService'
import Loader from '../../ui/loader/Loader';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import LearnPagination from '../../ui/pagination/LearnPagination'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const CourseItem = () => {
    const validator= ValidationRule;
    
    const [show, setShow] = useState(false);   
    const [courseItemId, setCourseItemId] = useState(0);  
    const [courseLevelId, setCourseLevelId] = useState("");
    const [parentCourseItemId, setParentCourseItemId] = useState(0);
    const [courseItemName, setCourseItemName] = useState("");    

    
    const [allClasses,setAllClasses]=useState([])
    const [classSubjects,setClassSubjects]=useState([])
    const [courseLevels, setCourseLevels] = useState([]);
    const [parentCouseItem, setparentCouseItem] = useState([]);
    const [classId, setClassId] = useState("")
    const [subjectId, setSubjectId] = useState("")

    const [courseItems, setCourseItems] = useState([]);
    
    const [filtersubjectId, setFilterSubjectId] = useState("")
    const [filterclassId, setFilterClassId] = useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [filterSubjects, setFilterSubjects] = useState([])
    const [filterClasses, setFilterClasses] = useState([])
    const [searchName, setSearchName] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
            itemName: searchName,
            classesList: filterclassId.toString(),
            subjectList:filtersubjectId.toString()
    })

    const [isEdit, setIsEdit] =useState(false);
    const [submitted,setSubmitted]=useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [buttonName, setButtonName] = useState('');
    
    const [loading,setLoading] = useState(false);
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)   
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        courseLevelId:courseLevelId,
        subjectId:subjectId,
        parentCourseItemId:parentCourseItemId,
        courseItemName:courseItemName,
        validation:validation,
    }

    const createCourseItem = () => {
        setIsEdit(false)
        setFormTitle('Add');
        setButtonName('Save');
        handleShow();
    }

    const editCourseItem = id => {
        CourseItemDataService.getCourseItem(id)
        .then(response => {
            setSubjectId(response.data.result.classSubjectId)
            setCourseLevelId(response.data.result.courseLevelId)
            setCourseItemName(response.data.result.courseItemName)
            setParentCourseItemId(response.data.result.parentCourseItemId)
            // setDispla(response.data.result.levelOrder)
            setCourseItemId(id)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
        setIsEdit(true);
        setFormTitle('Edit');
        setButtonName('Update');
        handleShow();
    }

    useEffect(() => {
        setParametes()
    }, [isfilterModified,submitted,filterclassId,filtersubjectId])
    
    useEffect(() =>{
        fetchCourseItems();
    },[params]) 

    const setParametes = () => {
        setParams({
            itemName: searchName,
            classesList: filterclassId.toString(),
            subjectList:filtersubjectId.toString()
        })
    }

    const fetchCourseItems = async () =>{
        setLoading(true)
        await CourseItemDataService.findCourseitem(params) 
          .then(response => {
              setCourseItems(response.data.result.itemList)
              setTotalPages(response.data.result.totalPages)
              setActivePage(response.data.result.activePageNumber)
              setLoading(false)
         })
         .catch(error => {
            setLoading(false)        
         })
       }

    useEffect(() =>{
        fetchClasses();
        fetchCourseLevel()
     },[]) 

     useEffect(() =>{
        fetchClassSubjects();
     },[classId])   
      

     useEffect(() =>{
        fetchParentCourseItem();
     },[courseLevelId,subjectId]) 
     
     useEffect(() =>{
        fetchFilterClassSubjects();
     },[filterclassId])  

     const fetchClasses = async () =>{
        await CommonDataService.allClasses() 
          .then(response => {
              setAllClasses(response.data.result)
              setFilterClasses(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
       }

    const fetchClassSubjects = async () =>{
        setClassSubjects([])
        await ClassSubjectDataService.getUserSubjects(classId,"") 
        .then(response => {
            setClassSubjects(response.data.result)
        })
        .catch(error => {
            const errorMsg=error.message;            
        }) 
    }

    const fetchFilterClassSubjects = async () =>{
        await ClassSubjectDataService.getUserSubjects(parseInt(filterclassId),"") 
        .then(response => {
            setFilterSubjects(response.data.result)
        })
        .catch(error => {
            const errorMsg=error.message;            
        }) 
    }

    const fetchCourseLevel= async () =>{ 
        await CourseLevelDataService.allCourseLevel() 
        .then(response => {
            setCourseLevels(response.data.result)
       })
       .catch(error => {
           setLoading(false)       
       })
    }

    const fetchParentCourseItem= async () =>{ 
        await CourseItemDataService.getParentCourseitem(parseInt(subjectId)==NaN?0:parseInt(subjectId),parseInt(courseLevelId)==NaN?0:parseInt(courseLevelId))
        .then(response => {   
            setparentCouseItem(response.data.result)
       })
       .catch(error => {
           setLoading(false)       
       })
    }

    const submitForm = async () => {
        var data = {
            courseItemId:courseItemId,
            classSubjectId:parseInt(subjectId),
            courseLevelId: parseInt(courseLevelId),
            parentCourseItemId:parseInt(parentCourseItemId),
            courseItemName: courseItemName,
        };
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)

        if (validate.isValid) {
            setLoading(true)
            isEdit ? (
                    await CourseItemDataService.updateCourseitem(data)
                        .then(response => {
                            setLoading(false)
                            LearnToast(success,"Updated Successfully")
                        })
                        .catch(error => {
                            let errorMsg=[]
                            let msgText=""
                            setLoading(false)      
                            if(error.response.status==500)
                            {
                                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                            }
                            else
                            {
                                errorMsg=error.response.data.errors
                                errorMsg.map((item,index)=>{
                                    if(msgText.length>0)
                                    {
                                    msgText = msgText+","
                                    }
                                    msgText=msgText+item
                                })            
                                    LearnToast(danger,msgText)
                            
                            }   
                        })
                    ) : (
                            await CourseItemDataService.createCourseitem(data)
                            .then(response => {
                                setLoading(false)
                                LearnToast(success,"Created Successfully")
                            })
                            .catch(error => {
                                let errorMsg=[]
                                let msgText=""
                                setLoading(false)      
                                if(error.response.status==500)
                                {
                                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                                }
                                else
                                {
                                    errorMsg=error.response.data.errors
                                    errorMsg.map((item,index)=>{
                                        if(msgText.length>0)
                                        {
                                        msgText = msgText+","
                                        }
                                        msgText=msgText+item
                                    })            
                                        LearnToast(danger,msgText)
                                
                                }   
                            })
                    )
            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }

    
    const resetForm = () => 
    {
        setCourseLevelId("")
        setSubjectId("")
        setClassId("")
        setCourseItemName("")
        setValidation(validator.valid());
        setValidateSubmitted(false);
        setIsEdit(false)
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await CourseItemDataService.deleteCourseitem(id) 
            .then(response => {
                setLoading(false)
                LearnToast(success,"Deleted Successfully")
                setSubmitted(!submitted);
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }              
            })
   }


    const deletecourseItem= async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }
    
    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }


    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 

    

  return (
    <>
    <ToastContainer/>
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <Modal.Title>{formTitle} Course Item</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="form-group">
            <label>Class</label>
            <select onChange={event => setClassId(event.target.value)} value={classId} name="classId" className="form-control">
                <option value="">-- select class --</option>
                {allClasses.map(item => (
                    <option 
                    key={item.classesId} 
                    value={item.classesId}
                    >                                                
                        {item.classesName}
                    </option>
                ))}
            </select>
        </div>
        <div className="form-group">
            <label>Subject</label>
            <select onChange={event => setSubjectId(event.target.value)} value={subjectId} name="subjectId" className={validateOutput.subjectId.isInvalid ? 'form-control is-invalid':'form-control'}>
                <option value="">-- select subject --</option>
                {classSubjects.map(item => (
                    <option 
                    key={item.classSubjectId} 
                    value={item.classSubjectId}
                    >                                                
                        {item.subjectName}
                    </option>
                ))}
            </select>            
            <span className="text-danger">{validateOutput.subjectId.message}</span>
        </div>
        <div className="form-group">
            <label>Course Level</label>
            <select onChange={event => setCourseLevelId(event.target.value)} value={courseLevelId} name="courseLevelId" className={validateOutput.courseLevelId.isInvalid ? 'form-control is-invalid':'form-control'}>
                <option value="">-- select level --</option>
                {courseLevels.map(item => (
                    <option 
                    key={item.courseLevelId} 
                    value={item.courseLevelId}
                    >                                                
                        {item.courseLevelName}
                    </option>
                ))}
            </select>
            <span className="text-danger">{validateOutput.courseLevelId.message}</span>
        </div>
        <div className="form-group">
            <label>Parent Course Item (not mandatory)</label>
            <select onChange={event => setParentCourseItemId(event.target.value)} value={parentCourseItemId} name="parentCourseItemId" className='form-control'>
                <option value="">-- select parent --</option>
                {parentCouseItem.map(item => (
                    <option 
                    key={item.comboValue} 
                    value={item.comboValue}
                    >                                                
                        {item.comboText}
                    </option>
                ))}
            </select>
        </div>
        <div className="form-group">
            <label htmlFor="displayname">Course Item Name</label>
            <input onChange={event=>setCourseItemName(event.target.value)} value={courseItemName} name="courseItemName" id="courseItemName" type="text" className={validateOutput.courseItemName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Course Item Name" />                          
            <span className="text-danger">{validateOutput.courseItemName.message}</span>
        </div>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
                Close
            </Button>
            <Button type="submit" variant="primary" onClick={submitForm}>
                {buttonName}
            </Button>
        </Modal.Footer>
    </Modal>          
    <div className="container">
        <div className="jumbotron">
            <div className="card card-primary">
                <div className="card-header">                            
                    <div className="row">
                        <div className="col-md-6" style={{verticalAlign:"middle"}}>
                            <h5 style={{marginBottom:"0.4rem",marginTop:"0.4rem"}}>Course Item</h5>
                        </div>
                        <div className="col-md-6 text-right">
                            <button className="btn btn-light" style={{marginBottom:"0.1rem",marginTop:"0.1rem"}} onClick={createCourseItem}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                        </div>
                        <br/>
                        <br/>
                    </div>
                </div>
                <div className="card-body">
                   <div className="row">
                   <div className="col-md-3">
            <div className="card card-outline card-primary">
                <div className="text-center">
                    <h5 style={{marginTop:"10px"}}>Filtering Options</h5>
                </div>
                <hr/>  
                <div className="col-md-12">
                    <div className="input-group">
                        <div className="input-group input-group-sm">
                            <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control form-control-navbar" type="text" placeholder="Name..." aria-label="Topic.." />
                            <div className="input-group-append">
                                <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />   
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Class</label>
                        <select onChange={event => setFilterClassId(event.target.value)} value={filterclassId} name="filterclassId" className="form-control">
                            <option value="">-- select class --</option>
                            {filterClasses.map(item => (
                                <option 
                                key={item.classesId} 
                                value={item.classesId}
                                >                                                
                                    {item.classesName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>  

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Class Subject</label>
                        <select onChange={event => setFilterSubjectId(event.target.value)} value={filtersubjectId} name="filtersubjectId" className="form-control">
                            <option value="">-- select subject --</option>
                            {filterSubjects.map(item => (
                                <option 
                                key={item.classSubjectId} 
                                value={item.classSubjectId}
                                >                                                
                                    {item.subjectName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>  

                
                  
            </div>
            
        </div>
                       <div className="col-md-9">
                       {loading?<Loader/>:
                        <>
                        {courseItems.length>0?
                        <>
                        <div className="row">
                            <div className="col-md-12">
                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                            </div>
                        </div>
                       
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Classs</th>
                                <th scope="col">Course Item Name</th>
                                <th scope="col">Subject</th>
                                {/* <th scope="col">Level</th> */}
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {loading? (<Loader/>) : (courseItems.map((item,index) =>(
                            <tr key={index}>
                                <th scope="row">{index+1}</th>
                                <td>{item.classesName}</td>
                                <td>{item.courseItemName}</td>
                                <td>{item.classSubjectName}</td>
                                {/* <td>{item.courseLevelName}</td> */}
                                <td className="text-right">
                                    <div className="d-flex justify-content-end">
                                        <button onClick={() => editCourseItem(item.courseItemId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>
                                        <button onClick={() => deletecourseItem(item.courseItemId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                    </div>
                                </td>
                            </tr>
                        )))}
                        </tbody>
                    </table>
                    <div className="row">
                            <div className="col-md-12">
                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                            </div>
                        </div>
                        </>
                        :
                        <div className="alert alert-info alert-dismissible">
                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                        </div>
                    }
                        </>
                        }
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</>
  )
}

export default CourseItem
