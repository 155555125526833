import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import { Link } from 'react-router-dom';
import CommonDataService from '../../../services/CommonDataService';
import TeacherDataSevice from '../../../services/TeacherDataSevice';
import Loader from '../../ui/loader/Loader';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import LearnPagination from '../../ui/pagination/LearnPagination';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const Teacher = (props) => {
    const passwordMatch = (confirmation, state) => (password === confirmation)
    const validator = ValidationRule.CreateRule(passwordMatch);

    const [show, setShow] = useState(false);
    const [teacherId,setTeacherId]=useState(0);
    const [titleId,setTitleId] = useState("");
    const [teacherName, setTeacherName] = useState('');
    const [genderId, setGenderId] = useState("");
    const [eMail, setEmail] = useState('');
    const [profilePicUrl, setProfilePicUrl] = useState('');
    const [password, setPassword] = useState('');
    const [buttonName, setButtonName] = useState('');
    const [mobile,setMobile]=useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [allTeacher, setAllTeacher] = useState([]);
    const [formTitle, setFormTitle] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [submitted,setSubmitted]=useState(false);
    const [loading,setLoading] = useState(false);
    const [genders,setGenders] = useState([])
    const [titles,setTitles] = useState([])
    const [isfilterModified,setisFilterModified]=useState(false)
    const [searchName, setSearchName] = useState('')

    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)    

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        teacherName:searchName
    })
    
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        titleId:titleId,
        teacherName:teacherName,
        genderId:genderId,
        mobile:mobile,
        eMail:eMail,
        password:password,
        confirmpassword:confirmpassword,
        validation:validation,
    }

    const createTeacher = () => {
        resetForm();
        setTeacherId(0)
        setIsEdit(false);
        setFormTitle('Add');
        setButtonName('Save');
        handleShow();
    }

    const editTeacher = Id => {
        TeacherDataSevice.getTeacher(Id)
        .then(response => {
            setTeacherName(response.data.result.teacherName)
            setTitleId(response.data.result.titleId)
            setGenderId(response.data.result.genderId)
            setMobile(response.data.result.mobile)
            // To make validation true in edit
            setEmail("dummy@email.com")
            setPassword("123")
            setConfirmPassword("123")
       })
       .catch(error => {
           const errorMsg=error.message;            
       })

        setTeacherId(Id);
        setIsEdit(true);
        setFormTitle('Edit');
        setButtonName('Update');
        handleShow();
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await TeacherDataSevice.deleteTeacher(id) 
        .then(response => {
            setLoading(false)            
            LearnToast(success,"Deleted Successfully")
            setSubmitted(!submitted);
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }          
        })
   }

    const deleteTeacher = async id => {

        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }

    const resetForm = () =>{
        setTitleId("");
        setTeacherName('');
        setGenderId("");
        setEmail('');
        setProfilePicUrl('');
        setMobile('');
        setTeacherId(0);
        setPassword('');
        setConfirmPassword('');
        setValidation(validator.valid());    
        setValidateSubmitted(false);   
    }

    useEffect(() => {
        setParametes()
    }, [submitted,isfilterModified])

    useEffect(() => {
        fetchalltitles();
        fetchallGenders();
        fetchTeachers();
    }, [params])

    const setParametes = () => {
        setParams({
            teacherName:searchName
        })
    }


   const fetchTeachers = async () =>{
    setLoading(true);
      await TeacherDataSevice.findTeacher(params) 
        .then(response => {
           setAllTeacher(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false);
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchallGenders= async () =>
    {
        await CommonDataService.allGender() 
        .then(response => {
            setGenders(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchalltitles= async () =>
    {
        await CommonDataService.allTitle() 
        .then(response => {
            setTitles(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }
 

    const submitForm = async () => {
        var data = {
            teacherId: teacherId,
            titleId: parseInt(titleId),
            teacherName: teacherName,
            genderId: parseInt(genderId),
            mobile: mobile,
            eMail: eMail,
            profilePicUrl: "",
            password: password
        };
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)

        if (validate.isValid) {
            setLoading(true)
            isEdit ? (
                await TeacherDataSevice.updateTeacher(data)
                    .then(response => {
                        setLoading(false)                        
                        LearnToast(success,"Updated Successfully")
                    })
                    .catch(error => {
                        let errorMsg=[]
                        let msgText=""
                        setLoading(false)      
                        if(error.response.status==500)
                        {
                            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                        }
                        else
                        {
                            errorMsg=error.response.data.errors
                            errorMsg.map((item,index)=>{
                                if(msgText.length>0)
                                {
                                msgText = msgText+","
                                }
                                msgText=msgText+item
                            })            
                                LearnToast(danger,msgText)
                        
                        }     
                    })
            ) : (
                    await TeacherDataSevice.createTeacher(data)
                        .then(response => {
                            setLoading(false)                            
                            LearnToast(success,"Created Successfully")
                        })
                        .catch(error => {
                            let errorMsg=[]
                            let msgText=""
                            setLoading(false)      
                            if(error.response.status==500)
                            {
                                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                            }
                            else
                            {
                                errorMsg=error.response.data.errors
                                errorMsg.map((item,index)=>{
                                    if(msgText.length>0)
                                    {
                                    msgText = msgText+","
                                    }
                                    msgText=msgText+item
                                })            
                                    LearnToast(danger,msgText)
                            
                            }     
                        })
                )

            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }

    const serachByName=()=>{
        setisFilterModified(!isfilterModified)
      }

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    
    return (
        <>
        <ToastContainer/>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle} Teacher</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label htmlFor="titleId"> Title</label>
                            <select  className={validateOutput.titleId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setTitleId(event.target.value)} value={titleId} name="titleId" >
                            <option>-- select Title --</option>
                                {titles.map(item => (
                                        <option
                                            key={item.titleId}
                                            value={item.titleId}
                                        >
                                            {item.titleName}
                                        </option>
                                    ))}
                            </select>
                            <span className="text-danger">{validateOutput.titleId.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="teacherName">Teacher Name</label>
                            <input type="text" onChange={event => setTeacherName(event.target.value)} value={teacherName} name="teacherName" id="teacherName" className={validateOutput.teacherName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Teacher Name" />
                            <span className="text-danger">{validateOutput.teacherName.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="genderId"> Gender</label>
                            <select className={validateOutput.genderId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setGenderId(event.target.value)} value={genderId} name="genderId"  >
                            <option value="">-- select Gender --</option>
                                {genders.map(item => (
                                        <option
                                            key={item.genderId}
                                            value={item.genderId}
                                        >
                                            {item.genderName}
                                        </option>
                                    ))}
                            </select>
                            <span className="text-danger">{validateOutput.genderId.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile</label>
                            <input type="text" onChange={event => setMobile(event.target.value)} value={mobile} className={validateOutput.mobile.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Mobile" />
                            <span className="text-danger">{validateOutput.mobile.message}</span>
                        </div>
                        {!isEdit?(
                            <>
                                <div className="form-group">
                                    <label htmlFor="eMail">E-mail</label>
                                    <input type="text" onChange={event => setEmail(event.target.value)} value={eMail} className={validateOutput.eMail.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="E-mail" />
                                    <span className="text-danger">{validateOutput.eMail.message}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password</label>
                                    <input type="password" onChange={event => setPassword(event.target.value)} value={password} className={validateOutput.password.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Password" />
                                    <span className="text-danger">{validateOutput.password.message}</span>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="confirmpassword">Confirm Password</label>
                                    <input type="password" onChange={event => setConfirmPassword(event.target.value)} value={confirmpassword} className={validateOutput.confirmpassword.isInvalid ? 'form-control is-invalid':'form-control'} placeholder=" Confirm Password" />
                                    <span className="text-danger">{validateOutput.confirmpassword.message}</span>
                                </div> 
                            </>):(<></>)
                            }

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={submitForm}>
                        {buttonName}
                    </Button>
                </Modal.Footer>
            </Modal>

            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Teacher</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={createTeacher}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                        <div className="row">
                            <br/>
                            <div className="col-md-12">
                                
                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-between">
                                                <div className="col-md-3">
                                                    <div className="input-group">
                                                        <div className="input-group input-group-sm">
                                                            <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control form-control-navbar" type="text" placeholder="Type Name" aria-label="Search"/>
                                                            <div className="input-group-append">
                                                                <button onClick={()=>serachByName()} className="btn btn-primary" type="submit">
                                                                    <i className="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                        </div>
                                    </div>
                                    {allTeacher.length>0?
                                <>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Gender</th>
                                                <th scope="col">Mobile</th>
                                                <th scope="col">Email</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {loading?(<Loader/>):(allTeacher.map((teacher, index) => (
                                                <tr key={teacher.teacherId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{teacher.fullName}</td>
                                                    <td>{teacher.gender}</td>
                                                    <td>{teacher.mobile}</td>
                                                    <td>{teacher.eMail}</td>
                                                    <td className="text-right">
                                                        <div className="d-flex justify-content-end">
                                                        {/* <Link className="btn btn-link" to={{pathname: '/app/staff-profile-view/'+teacher.teacherId,props:{ id: teacher.teacherId}}} data-toggle="tooltip" data-placement="top" title="Profile"><i className="fa fa-user text-primary"></i></Link>&nbsp;&nbsp;&nbsp; */}
                                                            <button onClick={() => editTeacher(teacher.teacherId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>
                                                            <button onClick={() => deleteTeacher(teacher.teacherId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )))}
                                            
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                </>
                                 :
                                 <>
                                    <br/>
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                 </>
                             }
                            </div>                        
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Teacher;