import React, { useState, useEffect } from 'react'
import LoadSingleQuestion from './LoadSingleQuestion'

const LoadQuestions = (props) => {

    const [allQuestios, setAllQuestions] = useState([])
    useEffect(() =>{
        setAllQuestions(props.questions)
    },[]) 


  return (
    <>
      {allQuestios.map((item,index)=>(
        <LoadSingleQuestion question={item} questionTypeName={props.questionTypeName} slNo={index+1} examScheduleId={props.examScheduleId}/>

      ))}
    </>
  )
}

export default LoadQuestions
