import {RECEIVE_MESSAGE,
        REQUEST_MESSAGES_FAILED,
        REQUEST_MESSAGES_PENDING,
        REQUEST_MESSAGES_SUCCESS} from "./MessageListType"
import ClassRoomDataService from "../../../services/ClassRoomDataService"

export const fetchMessagePending = () => {
    return {
        type: REQUEST_MESSAGES_PENDING
    }
}

export const fetchMessageSuccess = data => {
    return {
        type: REQUEST_MESSAGES_SUCCESS,
        payload: data
    }
}

export const fetchMessageFailed = error => {
    return {
        type: REQUEST_MESSAGES_FAILED,
        payload: error
    }
}

export const requestMessages = (roomId) => dispatch => {
    dispatch(fetchMessagePending);
    ClassRoomDataService.allMessages(roomId)
        .then(response => {
            dispatch(fetchMessageSuccess(response.data.result));
            console.log("result",response.data.result)})
        .catch(error =>
            dispatch(fetchMessageFailed(error))
        );
};

export function receiveMessage(
    userName,
    messageContent,
    chatRoomId,
    chatMessageId,
    postedAt,
    displayName,
    gender,
    userTypeName
) {
    console.log(messageContent,displayName,gender,userTypeName);
    const filteredMessage = messageContent
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;");
    return {
        type: RECEIVE_MESSAGE,
        payload: {
            userName,
            messageContent:filteredMessage,
            chatRoomId,
            chatMessageId,
            postedAt,
            displayName,
            gender,
            userTypeName
        }
    };
}

