import  FormValidator  from '../../../helpers/form-validator'

const NewExamRule = () =>  new FormValidator([
    {
      field: 'examName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Title is Required'
    },
    { 
      field: 'examGrade', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Exam Grade is Required'
    },
    { 
      field: 'maxMarks', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Remark is Required'
    }
  ])

  
const ScheduleRule = () =>  new FormValidator([
    {
      field: 'classSubjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    {
    field: 'scheduleDate', 
    method: 'isEmpty', 
    validWhen: false, 
    message: 'Date is Required'
    },
    { 
    field: 'scheduleFromTime', 
    method: 'isEmpty', 
    validWhen: false, 
    message: 'From Time is Required'
    },
    { 
    field: 'scheduleToTime', 
    method: 'isEmpty', 
    validWhen: false, 
    message: 'To Time is Required'
    },    
    { 
        field: 'questionPaperMstId', 
        method: 'isEmpty', 
        validWhen: false, 
        message: 'Question Paper is Required'
        }
  ])

  const StudentSettingRule = () =>  new FormValidator([
    {
      field: 'examScheduleId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Schedule is Required'
    },
    { 
      field: 'classSectionId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Section is Required'
    }
  ])

  const AppliedtoRule = () =>  new FormValidator([
    {
      field: 'studentIds', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'select at least one student'
    }
  ])


  export default {NewExamRule,ScheduleRule,StudentSettingRule,AppliedtoRule}