import React, { useState, useEffect } from 'react'
import ExamDataService from "../../../services/ExamDataService"
import LoadQuestions from './studentExam/LoadQuestions'
import ExamInstructionDataService from '../../../services/ExamInstructionDataService'
import { confirmAlert } from 'react-confirm-alert'

let messageText=""
const ExamEntry = (props) => {

    const [exams, setExams]= useState({})
    const [questionSettings, setQuestionSettings] = useState([])
    const [examInstructions, setExamInstructions] = useState([])
    useEffect(() =>{
        fetchExam()
        fetchExamInstruction()
    },[]) 

    const fetchExam = async () =>
    {
        await ExamDataService.getUserQuestionPaper(props.ExamId) 
        .then(response => {
            setExams(response.data.result)
            setQuestionSettings(response.data.result.questionSettings)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchExamInstruction = async () =>
    {
        await ExamInstructionDataService.getExamInstructions(props.ExamId) 
        .then(response => {
            setExamInstructions(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const options = {
        title: '',
        message: '',
        buttons: [
          {
            label: 'Yes',
            onClick: () => submitExamWithPendingQuestion()
          },
          {
            label: 'No',
            onClick: () => {}
          }
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => {},
        onKeypressEscape: () => {}
      };

      const ExamfinshOptions = {
        title: '',
        message: 'Congratulations, You have completed the examination successfully. The result will be published soon.',
        buttons: [
          {
            label: 'OK',
            onClick: () => window.location.reload(false)
          },
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => window.location.reload(false),
        onKeypressEscape: () => {}
      };

    const FinishExam = async () =>
    {
        await ExamDataService.getSubmissionStatus(props.ExamId) 
        .then(response => {
            response.data.result.questionsToAnswer==1?
            options.message='Pending '+ response.data.result.questionsToAnswer +' Question to Answer, Are You Sure Want to Finish Exam?'
            :
            options.message='Pending '+ response.data.result.questionsToAnswer +' Questions to Answer, Are You Sure Want to Finish Exam?'
            confirmaionExam(response.data.result.isAllQuestionsAnswered)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const submitExamWithPendingQuestion = async () =>
    {
        await submitExams()
        window.location.reload(false)
        
    }

    const submitExams = async () =>
    {
        await ExamDataService.submitExam(props.ExamId,exams.examSerialNumber) 
        .then(response => {
        })
        .catch(error => {
            const errorMsg=error.message;            
        })
    }

    const confirmaionExam= async isAllQuestionsAnswered=>{

        if(isAllQuestionsAnswered===true)
        {
          await  submitExams()
        confirmAlert(ExamfinshOptions)
        }
        else{
            confirmAlert(options)
        }
    }


    return (
        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary card-outline">
                    <div className="card-header">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <p>{exams.examSerialNumber}</p>
                                <h5>{exams.examName}</h5>
                                <p>{exams.examDate}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p>{exams.classSectionName}<br/>{exams.studentName}</p>
                                <h6>{exams.classSubjectName}</h6>
                                <p>Max Marks:{exams.maxMarks}<br />Time :{exams.durationInMinutes} Mins</p>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            {examInstructions.length>0?
                                <div className="col-md-12" style={{border:"1px solid #B2B2B5",padding:"5px",marginBottom:"10px"}}>
                                    <p className="text-center"><b>Instructions to the Candidate</b></p>
                                    <ol>
                                        {examInstructions.map((item, index)=>(
                                            <li key={index}><i>{item.instructionText}</i></li>
                                        )) }
                                    </ol>
                                </div>
                            :<></>}

                            {/* <div className="col-md-12 scrollbar scrollbar-primary">
                                <div className="force-overflow"> */}
                                <div className="col-md-12">
                                    {questionSettings.map((item,index)=>(
                                    <>
                                        <div className="d-flex justify-content-between mt-1">
                                            <p></p>
                                            {(item.questionsToAnswer==item.availableQuestions)?
                                            <p className="text-md"><i>(Answer all {item.questionsToAnswer + " "+ item.questionTypeName} Questions) </i></p>
                                            :<p className="text-md"><i>(Answer Any {item.questionsToAnswer + " "+ item.questionTypeName} Question out of {item.availableQuestions}) </i></p>}
                                            
                                            <p className="text-md muted"> {item.questionsToAnswer +" X " + item.markPerQuestion + " = " + item.totalMark} </p>
                                        </div>
                                        <LoadQuestions questions={item.questions} questionTypeName={item.questionTypeName}/>
                                    </>
                                    ))}
                                </div>
                                {/* </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="text-center">
                            <button className="btn btn-primary btn-block" onClick={()=>FinishExam()}>Finish Exam</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExamEntry
