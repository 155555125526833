import React, { useState, useEffect } from 'react'
import ExamDataService from "../../../services/ExamDataService"
import ExamAnswerDataService from '../../../services/ExamAnswerDataService'
import LoadEvalQuestions from './evaluateExam/LoadEvalQuestions'

const EvaluateExam = (props) => {
    const [isList, setIsList]=useState(true)
    const [studentlist, setStudentList] = useState([]) 
    const [exams, setExams]= useState({})
    const [questionSettings, setQuestionSettings] = useState([])
    const [mark, setMark] = useState(0)
    const [studentID, setStudenID]=useState(0)

    useEffect(() =>{
        fetchExamStudents()
    },[]) 


    const fetchExamStudents = async () =>
    {
        await ExamDataService.getExamStudenList(props.match.params.id) 
        .then(response => {    
            setStudentList(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchStudentExamDetail= async (studentId) =>
    {
        setStudenID(studentId)
        setExams({})
        setQuestionSettings([])
        await ExamDataService.getStudentQuestionPaper(props.match.params.id,studentId) 
        .then(response => {
            setExams(response.data.result)
            setQuestionSettings(response.data.result.questionSettings)            
            setIsList(false)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const valuateStudent = (id) => {
        fetchStudentExamDetail(id)
    }

    const backToStudentList =()=>
    {
        setStudenID(0)
        window.location.reload();
    }

    
  return (
      <>{isList?(
        <div className="container">
        <div className="jumbotron">
          <div className="card card-primary">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                  <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>{props.match.params.examName} </h5>
                </div>
                <br />
                <br />
              </div>
            </div>
            <div className="card-body">    
              <table className="table table-bordered table-hover">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Student Name</th>
                    <th scope="col">Status</th>
                    <th scope="col">Marks</th>
                    <th className="text-right">Actions</th>
                  </tr>
                </thead>
                <tbody>
                    {studentlist.map((item, index)=>(

                        <tr key={index}>
                        <th scope="row">{index+1}</th>
                        <td>{item.studentName}</td>
                        {item.isAttended?(
                            (item.isInProgress && !item.isValuated)?
                                <>
                                    <td className="text-info">Evaluation In Progress</td>
                                    <td>-</td>
                                    <td className="text-right">
                                        <button  onClick={()=>valuateStudent(item.studentID)} className="btn btn-info"> View</button>
                                    </td>
                                </>
                                :(!item.isInProgress && !item.isValuated)?
                                    <>
                                        <td className="text-warning">Not Valuated</td>
                                        <td>-</td>
                                        <td className="text-right">
                                            <button  onClick={()=>valuateStudent(item.studentID)} className="btn btn-info"> View</button>
                                        </td>
                                    </>
                                    :
                                    <>
                                    <td className="text-success">Valuated</td>
                                    <td>{item.markScored}</td>
                                    <td className="text-right">
                                        <button  onClick={()=>valuateStudent(item.studentID)} className="btn btn-info"> View</button>
                                    </td>
                                    </>
                                )
                        :
                        <>
                        <td className="text-danger">Not Attended</td>
                        <td>-</td>
                        <td className="text-right">
                            <button disabled className="btn btn-info"> View</button>
                        </td>
                        </>} 
                        </tr>

                    ))}
                </tbody>
              </table>
    
            </div>
          </div>
        </div>
      </div>
    
      ):(
    <div className="container">
            <div className="jumbotron">
                <div className="card card-primary card-outline">

                <div className="card-header">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between">
                                <p>{exams.examSerialNumber}</p>
                                <h5>{exams.examName}</h5>
                                <p>{exams.examDate}</p>
                            </div>
                            <div className="d-flex justify-content-between">
                                <p>{exams.classSectionName}<br/>{exams.studentName}</p>
                                <h6>{exams.classSubjectName}</h6>
                                <p>Max Marks:{exams.maxMarks}<br />Time :{exams.durationInMinutes} Mins</p>
                            </div>
                        </div>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            {/* <div className="col-md-12 scrollbar scrollbar-primary">
                                <div className="force-overflow"> */}
                                <div className="col-md-12">
                                    {questionSettings.map((item,index)=>(
                                    <>
                                        <div className="d-flex justify-content-between mt-1">
                                            <p></p>
                                            {(item.questionsToAnswer==item.availableQuestions)?
                                            <p className="text-md"><i>(Answer all {item.questionsToAnswer + " "+ item.questionTypeName} Questions) </i></p>
                                            :<p className="text-md"><i>(Answer Any {item.questionsToAnswer + " "+ item.questionTypeName} Question out of {item.availableQuestions}) </i></p>}
                                            
                                            <p className="text-md muted"> {item.questionsToAnswer +" X " + item.markPerQuestion + " = " + item.totalMark} </p>
                                        </div>
                                        <LoadEvalQuestions 
                                        questions={item.questions} 
                                        maxMark={item.markPerQuestion} 
                                        studentID={studentID}
                                        questionTypeName={item.questionTypeName}/>
                                    </>
                                    ))}
                                </div>
                                {/* </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="card-footer">
                        <div className="text-right">
                            <button className="btn btn-primary" onClick={()=>backToStudentList()}>Back To Student List</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      )}</>
  )
}

export default EvaluateExam
