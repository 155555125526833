import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { fetchlogin } from "../../redux/login/loginActions";
import { connect } from "react-redux";
import Loader from '../../ui/loader/Loader'
import storage from 'redux-persist/lib/storage'

const Login = ({loginData,fetchLogin}) => {
  //console.warn("Before Login:", loginData)
  const initialLoginState = {
    userName: "",
    password: ""
  };

  const [loading, setLoading] = useState(false)
  const [authData, setAuthData] = useState(initialLoginState);

  const handleInputChange = event => {
    const { name, value } = event.target;
    setAuthData({ ...authData, [name]: value });
  };

  const authenticateUser = async () => {
    var data = {
      userName: authData.userName,
      password: authData.password
    };
   
    setLoading(true)
    await fetchLogin(data);
    setLoading(false)
  };

  const callRedirect = () =>{
    let role = JSON.parse(localStorage.getItem('roles'));
        console.log(role[0])
        if(role[0]=="Admin")
        {
          return(
              <Redirect to="/app"/>
          )
        }
        else if(role[0]=="Teacher")
        {
          return(
              <Redirect to="/app/staff-profile"/>
          )
        }
        else if(role[0]=="Student")
        {
          return(
              <Redirect to="/app/student-profile"/>
          )
        }
  }


  storage.removeItem('persist:root'); 

  return (
    <>
      {loading ? <Loader/>:
       loginData.isAuthenticated ? callRedirect() : (
         <>         
          <div className="login-content">
            <div className="log-container">
              <img src="assets/logintemplate/img/avatar.svg" alt="login" />
              <h2 className="title">Welcome</h2>          
              <div className="input-div one">
                <div className="i">
                  <i className="fas fa-user" />
                </div>        
                <div className="div">
                  <h5>Username</h5>
                  <input
                    type="text"
                    className="input"
                    id="input"
                    required
                    value={authData.userName}
                    onChange={handleInputChange}
                    name="userName"
                  />
                </div>
              </div>
              <div className="input-div pass">
                <div className="i">
                  <i className="fas fa-lock" />
                </div>
                <div className="div">
                  <h5>Password</h5>
                  <input
                    type="password"
                    className="input"
                    id="password"
                    required
                    value={authData.password}
                    onChange={handleInputChange}
                    name="password"
                  />
                </div>
              </div>
              <a href="/">Forgot Password?</a>           
              <div>
                {loginData.error? 
               <div className="text-danger"> Incorrect Username or Password</div>:<></>}
              </div> 
              <button onClick={authenticateUser} className="btn btn-success">
                Login
          </button>
            </div>
          </div>
        </>


        )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    loginData: state.login
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchLogin: data => dispatch(fetchlogin(data))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Login);