import  ApiDataService  from './ApiDataService'

const getStudentQuestionPaper = (examScheduleId) => {
    let values={"examScheduleId":examScheduleId}
    return ApiDataService.getWithParam("examinationanswersheet/studentquestionpaper",values);
};

const getEvaluationAnswerSheet = (examStudentId) => {
  let values={"examStudentId":examStudentId}
  return ApiDataService.getWithParam("examinationanswersheet/evaluationanswersheet",values);
};

const examAnswerPost = data => {
  return ApiDataService.post("examinationanswersheet", data);
};

const evaluateExamAnswer = data => {
  return ApiDataService.put("examinationanswersheet", data);
};

const finsihExam = data => {
  return ApiDataService.put("examinationanswersheet/submitanswersheet", data);
};

export default {getStudentQuestionPaper,
                getEvaluationAnswerSheet,
                examAnswerPost,
                finsihExam,
                evaluateExamAnswer}