import React, { useEffect, useState } from 'react'
import StudentDataService from "../../../services/StudentDataService"
import Loader from '../../ui/loader/Loader';
import TimelineItem from './TimelineItem';

const StudentTimeline = (props) => {

    const [timelineData, setTimelineData] = useState([])
    const [loading,setLoading] = useState(false);

    useEffect(() =>{
        fetchStudentTimeline()
    },[])  

    const fetchStudentTimeline = async () =>{
        setLoading(true)
        await StudentDataService.getStudentTimeline(parseInt(props.profile.studentId)) 
          .then(response => {
            setTimelineData(response.data.result.studentActivityLogs)
            setLoading(false)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
    }

    return (
        <>
            <div class="timeline">
                {timelineData !==null?
                    <>
                        {timelineData.map((item,index)=>(    
                        <>
                            <div class="time-label">
                                <span class="bg-red">{item.formattedDate}</span>
                            </div>
                            <TimelineItem studentActivities={item.studentActivities}/>
                        </>
                        ))}
                    </>
                    :<></>
                }
            </div>
        </>
    )
}

export default StudentTimeline
