import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'
import Loader from '../../ui/loader/Loader';
import StudentDataService from "../../../services/StudentDataService"
import AssignmentDataService from "../../../services/AssignmentDataService"
import CommonDataService from "../../../services/CommonDataService"
import ValidationRule from './ValidationRule'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';


let subjectListIds = ""
const Assignments = () => {
    
    const [show, setShow] = useState(false);
    const [loading,setLoading] = useState(false);
    const [subjects, setSubjects] = useState([])
    const [assignments, setAssignments] =useState([])
    const [searchTopic, setSearchTopic] = useState("")
    const [isfilterModified, setisFilterModified] = useState(false)   
    
    const [assignmentId, setAssignmentId] = useState(0)
    const [documentId, setDocumentId] = useState(0)
    const [documentFile, setDocumentFile] =useState("")
    const [uploadProgress,setuploadProgress] =useState("0")    
    
    const [isUpload, setisUpload] = useState(false)
    const [FormTitle, setFormTitle] = useState("Upload Assignment")
    const [instructions, setInstructions] = useState([])

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        topicName: searchTopic,
        classSubjectList:subjectListIds
    })

    const validatorDocument=ValidationRule.DocumentRule();
    const [validationDocument, setValidationDocument] = useState(validatorDocument.valid());
    const [validateSubmittedDocument, setValidateSubmittedDocument]=useState(false)

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var DocumentValidationData={
        documentFile:documentFile,
        validation:validationDocument
    }

    const uploadAssignment = (id) =>
    {
        setFormTitle("Upload Assignment")
        setAssignmentId(id)
        setisUpload(true)
        handleShow()
    }

    const viewInstruction = (id) =>{
        setFormTitle("View Instructions")
        setisUpload(false)
        fetchInstruction(id)
        handleShow()

    }

    useEffect(() =>{
        fetchSubjects();
    },[]) 

    useEffect(() => {
        setParametes()
    }, [isfilterModified])

    useEffect(() => {
        fetchAssignments()
    }, [params])

    const setParametes = () => {
        setParams({
            topicName: searchTopic,
            classSubjectList:subjectListIds
        })
    }
    
    const fetchAssignments = async () =>{       
        setLoading(true)
        await AssignmentDataService.getStudentAssignment(params) 
        .then(response => {
           setAssignments(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false)
      })
       .catch(error => {
        setLoading(false)         
      })
    }


    const fetchSubjects = async () => {
        let SubjectList = []
        await StudentDataService.getStudentSubjects()
        .then(response => {
            response.data.result.map((subjectdata, index) => (
                SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
            ));
            setSubjects(SubjectList)

        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }

    const handleCheckSubjects = (event) => {
        let chksubject = subjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }

    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }

    const submitAssignment =async () =>{
        const validate = validatorDocument.validate(DocumentValidationData)
        setValidationDocument({ validate });
        setValidateSubmittedDocument(true)
        if (validate.isValid) 
        { 
            var file =new FormData()
            file.append('file',documentFile)
            await CommonDataService.fileUpload(file,{
                onUploadProgress: progressEvent => {
                    setuploadProgress(progressEvent.loaded / progressEvent.total*100)
                }})
            .then(response => {  
                setuploadProgress(0)        
                setDocumentId(response.data.result)   
                let data={
                    assignmentId:assignmentId,
                    documentId:response.data.result
                }
                setLoading(true)
                AssignmentDataService.uploadAssignment(data) 
                .then(response => {                
                    setDocumentFile(""); 
                    setAssignmentId(0)
                    handleClose()
                    setisFilterModified(!isfilterModified)
                    setLoading(false)
                    LearnToast(success,"Uploaded Successfully")
                })
                .catch(error => {
                    let errorMsg=[]
                    let msgText=""
                    setLoading(false)      
                    if(error.response.status==500)
                    {
                        LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                    }
                    else
                    {
                        errorMsg=error.response.data.errors
                        errorMsg.map((item,index)=>{
                            if(msgText.length>0)
                            {
                            msgText = msgText+","
                            }
                            msgText=msgText+item
                        })            
                            LearnToast(danger,msgText)
                    
                    }            
                })
 
            })
            .catch(error => {
                const errorMsg=error.message;            
            })
        }
    }

    const fetchInstruction = async (id) =>{
        setLoading(true)
        await AssignmentDataService.getAssignmentInstruction(id)
        .then(response => {
            setInstructions(response.data.result)
            setLoading(false)
        })
        .catch(error => {
            setLoading(false)

        })
        
    }

  
   
    let validateOutputDocument= validateSubmittedDocument ? validatorDocument.validate(DocumentValidationData) : DocumentValidationData.validation 
    return (
        <>
            <ToastContainer />    
            {loading?<Loader/>:
                <>
                    <Modal show={show} onHide={handleClose} size="xs">
                        <Modal.Header closeButton>
                            <Modal.Title>{FormTitle}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {isUpload?
                            <>
                                <div className="form-group">
                                    <label htmlFor="todate">Upload File</label>
                                    {/* <div className="input-group"> */}
                                        <input type="file" onChange={event => setDocumentFile(event.target.files[0])} name="documentFile" id="documentFile" className={validateOutputDocument.documentFile.isInvalid ? 'form-control is-invalid':'form-control'} />
                                        {/* <span className="input-group-append">
                                            <button type="button" onClick={()=>} className="btn btn-primary">Add</button>
                                        </span> */}
                                    {/* </div>                             */}
                                    <span className="text-danger">{validateOutputDocument.documentFile.message}</span>
                                </div>
                                <br/>
                                <div className="progress progress-sm active">
                                    <div className="progress-bar bg-success progress-bar-striped" role="progressbar"
                                        aria-valuemin="0" aria-valuemax="100" style={{width: uploadProgress+"%"}}>
                                        <span className="sr-only">{uploadProgress+"%"} Complete</span>
                                    </div>
                                </div>
                            </>
                            :
                            <>
                            {instructions.length>0?
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl</th>
                                            <th scope="col">Instruction</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {instructions.map((item,index)=>(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{item.instructionText}</td>
                                            </tr>
                                        ))}                                    
                                    </tbody>
                                </table>
                            :
                                <div className="alert alert-info alert-dismissible">
                                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                                </div>
                            }
                            </>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                        {isUpload?
                            <>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                                <Button type="submit" variant="primary" onClick={()=>submitAssignment()}>
                                    Submit
                                </Button>
                            </>
                            :<>
                                <Button variant="secondary" onClick={handleClose}>
                                    Close
                                </Button>
                            </>
                        }                
                        </Modal.Footer>
                    </Modal>
                    <div className="container">
                        <div className="jumbotron">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Assignments</h5>
                                        </div>                               
                                    </div>
                                </div>
                                <div className="card-body">    
                                    <div className="row">
                                    <div className="col-md-3">
                                            <div className="card card-outline card-primary">
                                                <div className="text-center">
                                                    <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                                </div>
                                                <hr />
                                                <div className="col-md-12">
                                                    <div className="input-group">
                                                        <div className="input-group input-group-sm">
                                                            <input onChange={event => setSearchTopic(event.target.value)} value={searchTopic} name="searchTopic" id="searchTopic" className="form-control form-control-navbar" type="text" placeholder="Topic.." aria-label="Search" />
                                                            <div className="input-group-append">
                                                                <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                                    <i className="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="col-md-12">
                                                    <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        {subjects.map((subjectdata, index) => (
                                                            <li key={index} className="list-group-item">
                                                                <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                            </li>

                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            {assignments.length>0?
                                                <>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                        </div>
                                                    </div>
                                                    {loading?<Loader/>:
                                                    <>
                                                    {assignments.map((item, index)=>(
                                                        <div className="card card-outline card-primary" key={index}>
                                                            <div className="card-header">
                                                                <div class="row">
                                                                    <div className="col-md-8">
                                                                        <h2 className="card-title">
                                                                            <span class="info-box-icon text-success"><i class="far fa-bookmark"></i> <b>{item.topicName}</b></span> 
                                                                        </h2>
                                                                    </div>
                                                                    <div className="col-md-4 text-right">
                                                                        <p><i>Due On {item.dueDate}</i></p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-body">                                       
                                                                <div className="row">
                                                                    <div className="col-md-9">
                                                                        <p>Posted On {item.postedOn}</p>
                                                                        <h5>Subject {item.classSubjectName}</h5>
                                                                        <p>{item.topicDetail}</p>
                                                                    </div>
                                                                    <div className="col-md-3">
                                                                        <div className="row">
                                                                            {item.isGraded?
                                                                                <>
                                                                                    <div className="col-md-6 border-left">
                                                                                        <div className="description-block">
                                                                                            <h5 className="description-header">{item.maxMarks}</h5>
                                                                                            <span className="description-text">Total</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-md-6 border-left">
                                                                                        <div className="description-block">
                                                                                            <h5 className="description-header">{item.isEvaluated && item.markAwarded>-1?<>{item.markAwarded}</>:<>-</>}</h5>
                                                                                            <span className="description-text">Gained</span>
                                                                                        </div>
                                                                                    </div> 
                                                                                </>
                                                                            :<></>}                                                  
                                                                            <div className="col-md-12 text-right">
                                                                            <br/>
                                                                            <br/>
                                                                                {!item.isDueDateExceeded && !item.isSubmitted?
                                                                                <span class="info-box-icon text-warning"><i class="fa fa-hourglass-half"></i> Pending Submission</span>
                                                                                :item.isDueDateExceeded && !item.isSubmitted?
                                                                                <span class="info-box-icon text-danger"><i class="fa fa-times"></i> Not Submitted</span>
                                                                                :item.isSubmitted?
                                                                                <span class="info-box-icon text-success"><i class="fa fa-check"></i> Submitted Successfully</span>
                                                                                :<></>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between">                                                        
                                                                        <button className="btn btn-link" onClick={()=>viewInstruction(item.assignmentId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                                            <span className="text-info"><i className="fa fa-search"></i> View Instruction</span>
                                                                        </button>
                                                                        {item.isSubmitted?
                                                                            <a href={item.submittedDocumentName} target="_blank" className="btn btn-link"><span className="text-info"><i className="fa fa-download"></i> Submitted Document</span></a>
                                                                            :<></>
                                                                        }
                                                                        {item.hasCorrectedDocument?
                                                                            <a href={item.correctedDocumentName} target="_blank" className="btn btn-link"><span className="text-success"><i className="fa fa-download"></i> Corrected Document</span></a>
                                                                            :<></>
                                                                        }                                                           
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-footer">
                                                                {!item.isDueDateExceeded && !item.isSubmitted?
                                                                <button className="btn btn-info" onClick={()=>uploadAssignment(item.assignmentId)}><i className="fas fa-upload"> &nbsp; Upload Assignment</i></button>
                                                                :<></>}
                                                            </div>
                                                        </div>
                                                    ))}
                                                    </>
                                                    }   
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                        </div>
                                                    </div>                         
                                                </>
                                             :
                                            <div className="alert alert-info alert-dismissible">
                                                <div className="text-center text-white"><h4>No Records Found</h4></div>
                                            </div>
                                         }
                                        </div>
                                    </div>
                            
                            </div>
                        </div>
                    </div>
                    </div>
                </>
            }
        </>
    );
}

export default Assignments
