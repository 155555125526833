import React, { useState, useEffect } from 'react'
import QuestionBankDataService from "../../../services/QuestionBankDataService"
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import DifficultyLevelDataService from "../../../services/DifficultyLevelDataService"
import CourseItemDataService from "../../../services/CourseItemDataService"
import ValidationRule from './ValidationRule'
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import Loader from '../../ui/loader/Loader';
import Select from 'react-select';

let options = []
let cousetItemIds = ""
const OneWordQuestion = () => {
    const [question, setQuestion] = useState("")
    const [oneWordAns, setOneWordAns] = useState("")
    const [classesId, setClassesId] = useState(0)
    const [classSubjectId, setClassSubjectId] = useState("")
    const [classes, setClasses] = useState([])
    const [allClassSubject, setAllClassSubject] = useState([]);    
    const [difficultyLevelId, setDifficultyLevelId] = useState("");
    const [difficultyLevels, setDifficultyLevels] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);    
    const [selectedCourseItems, setSelectedCourseItems] = useState([])

    const validatorOneWord = ValidationRule.OneWordForm();
    const [validationOneWord, setValidationOneWord] = useState(validatorOneWord.valid());
    const [validateSubmittedOneWord, setValidateSubmittedOneWord] = useState(false)

    var oneWordValidationData = {
        classSubjectId: classSubjectId,
        question: question,
        oneWordAns: oneWordAns,
        difficultyLevelId:difficultyLevelId,
        validation: validationOneWord
    }

    useEffect(() => {
        fetchDifficultyLevel()
        fetchTeacherCurrentClasses()
    }, [submitted])

    useEffect(() => {
        fetchTeacherCurrentSubjects()
    }, [classesId])

    const fetchTeacherCurrentClasses = async () => {
        await TeacherAssociationDataService.getTeacherCurrentClasses()
            .then(response => {
                setClasses(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchTeacherCurrentSubjects = async () => {
        await TeacherAssociationDataService.getTeacherCurrentSubjects(parseInt(classesId))
            .then(response => {
                setAllClassSubject(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchDifficultyLevel= async () =>{        
        setLoading(true);
        await DifficultyLevelDataService.allDifficultyLevel() 
        .then(response => {
            setDifficultyLevels(response.data.result)
            setLoading(false)
       })
       .catch(error => {
           setLoading(false)       
       })
    }

    useEffect(() => {
        fetchCourseItem()
    }, [classSubjectId])

    const fetchCourseItem= async () =>{ 
        await CourseItemDataService.getComboViewCourseitem(parseInt(classSubjectId),0)
        .then(response => {
            response.data.result.map((item, index)=>(
                options.push({value:item.comboValue,label:item.comboText})
            ));
       })
       .catch(error => {
           setLoading(false)       
       })
    }



    const saveOneWord = async () => {
        cousetItemIds=""
        if(selectedCourseItems !==null)
        {
            selectedCourseItems.map((item, index)=>(
                (cousetItemIds==="")?
                    cousetItemIds =item.value:
                    cousetItemIds =cousetItemIds+"," + item.value  
            ))
        }

        var data = {
            questionId: 0,
            classSubjectId: parseInt(classSubjectId),
            questionText: question,
            answerText: oneWordAns,
            difficultyLevelId:parseInt(difficultyLevelId),
            courseItemList:cousetItemIds.toString()

        }
        const validate = validatorOneWord.validate(oneWordValidationData)
        setValidationOneWord({ validate });
        setValidateSubmittedOneWord(true)
        if (validate.isValid) {
            setLoading(true)
            await QuestionBankDataService.createOneword(data)
                .then(response => {
                    setLoading(false)
                    LearnToast(success, "Question Added Successfully")
                })
                .catch(error => {
                    let errorMsg=[]
                    let msgText=""
                    setLoading(false)      
                    if(error.response.status==500)
                    {
                        LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                    }
                    else
                    {
                        errorMsg=error.response.data.errors
                        errorMsg.map((item,index)=>{
                            if(msgText.length>0)
                            {
                            msgText = msgText+","
                            }
                            msgText=msgText+item
                        })            
                            LearnToast(danger,msgText)
                    
                    }            
                })
            setSubmitted(!submitted)
            resetForm()
        }

    }

    const resetForm = () => {
        setClassesId("")
        setDifficultyLevelId("")
        fetchDifficultyLevel()
        setClasses([])
        fetchTeacherCurrentClasses()
        setClassSubjectId("")
        setAllClassSubject([])
        setQuestion("")
        setSelectedCourseItems([])
        options=[]
        setOneWordAns("")
        setValidationOneWord(validatorOneWord.valid())
        setValidateSubmittedOneWord(false)
    }

    let validateOutputOneWord = validateSubmittedOneWord ? validatorOneWord.validate(oneWordValidationData) : oneWordValidationData.validation

    return (
        <>
            <ToastContainer />
            {loading ? <Loader /> :
                <>
                    <div className="container">
                        <div className="jumbotron">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>One Word Question </h5>
                                        </div>
                                        <div className="col-md-6 text-right">

                                        </div>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3 card">
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Class</label>
                                                    <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className="form-control">
                                                        <option value="">-- select class --</option>
                                                        {classes.map(item => (
                                                            <option
                                                                key={item.classesId}
                                                                value={item.classesId}
                                                            >
                                                                {item.classesName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label>Subject</label>
                                                    <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className="form-control">
                                                        <option value="">-- select subject --</option>
                                                        {allClassSubject.map(item => (
                                                            <option
                                                                key={item.classSubjectId}
                                                                value={item.classSubjectId}
                                                            >
                                                                {item.subjectName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger">{validateOutputOneWord.classSubjectId.message}</span>
                                                </div>
                                            </div>
                                            <div className="form-row">                                               
                                                <div className="col">
                                                    <label>Difficulty Levels</label>
                                                    <select onChange={event => setDifficultyLevelId(event.target.value)} value={difficultyLevelId} name="difficultyLevelId" className="form-control">
                                                        <option value="">-- select level --</option>
                                                        {difficultyLevels.map(item => (
                                                            <option
                                                                key={item.difficultyLevelId}
                                                                value={item.difficultyLevelId}
                                                            >
                                                                {item.difficultyLevelName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger">{validateOutputOneWord.difficultyLevelId.message}</span>
                                                </div>
                                                
                                            </div>
                                            <div className="form-row"> 
                                                <div className="col">
                                                    <label>Select Course Item</label>
                                                    <Select
                                                        value={selectedCourseItems}
                                                        isMulti
                                                        onChange={selectedCourseItems => setSelectedCourseItems(selectedCourseItems)}
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Question?</label>
                                                    <CKEditor
                                                        onInit={editor => {
                                                            console.log('Editor is ready to use!', editor);

                                                            // Insert the toolbar before the editable area.
                                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                                editor.ui.view.toolbar.element,
                                                                editor.ui.getEditableElement()
                                                            );
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            console.log({ event, editor, data });
                                                            setQuestion(data);
                                                        }}
                                                        editor={DecoupledEditor}
                                                        data={question}
                                                        config={{
                                                            ckfinder: { uploadUrl: "https://cla.maclms.org/api/fileupload/editorupload" }
                                                        }}
                                                    />
                                                    {/* <input type="text" onChange={event => setQuestion(event.target.value)} value={question} name="question" id="question" className="form-control"/> */}
                                                    <span className="text-danger">{validateOutputOneWord.question.message}</span>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Answer</label>
                                                    <input type="text" onChange={event => setOneWordAns(event.target.value)} value={oneWordAns} name="oneWordAns" id="oneWordAns" className="form-control" />
                                                    <span className="text-danger">{validateOutputOneWord.oneWordAns.message}</span>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="form-group text-right">
                                                {/* <button onClick={resetform} className="btn btn-light">close</button>&nbsp;&nbsp;&nbsp; */}
                                                <button onClick={saveOneWord} className="btn btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default OneWordQuestion
