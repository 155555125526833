import React, { Component } from 'react'
import {HubConnectionBuilder } from "@aspnet/signalr";
import AddMessage from './AddMessage';
import MessageList from './MessageList';
import { connect } from 'react-redux';


class StaffClassRoom extends Component {
    constructor(props) {
        super(props);

        this.connection = new HubConnectionBuilder().withUrl("http://live.campuslte.com/chatHub").build();
    }

    componentDidMount() {
        this.connection
            .start({ withCredentials: false })
            .catch(err => console.error(err.toString()));
    }

    render() {
        return (
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6">
                                    <h6>Section: BCA I Year A  </h6>
                                    <p>Teacher: Fr. Alan Thomas</p>
                                </div>
                                <div className="col-md-6 text-right">
                                    <h6>Subject: C++ Programming</h6>
                                    <p>Topic: Operators in C++</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-9">
                                    <MessageList roomId="1" connection={this.connection} />
                                    <AddMessage roomId="1" userName={this.props.username} connection={this.connection} />
                                </div>
                                <div className="col-md-3">
                                    <div className="scrollbar scrollbar-primary">
                                        <div className="force-overflow">
                                            <div className="chatuser">
                                                <div className="image">
                                                    <img src="assets/avatar/student-male.png" className="img-circle elevation-2" alt="User Image" />
                                                </div>
                                                <div className="info">
                                                    <p className="d-block text-success">Student 1</p>
                                                </div>
                                            </div>
                                            <div className="chatuser">
                                                <div className="image">
                                                    <img src="assets/avatar/student-female.png" className="img-circle elevation-2" alt="User Image" />
                                                </div>
                                                <div className="info">
                                                    <p className="d-block text-success">Student 2</p>
                                                </div>
                                            </div>

                                            <div className="chatuser">
                                                <div className="image">
                                                    <img src="assets/avatar/student-male.png" className="img-circle elevation-2" alt="User Image" />
                                                </div>
                                                <div className="info">
                                                    <p className="d-block text-success">Student 3</p>
                                                </div>
                                            </div>
                                            <div className="chatuser">
                                                <div className="image">
                                                    <img src="assets/avatar/student-female.png" className="img-circle elevation-2" alt="User Image" />
                                                </div>
                                                <div className="info">
                                                    <p className="d-block text-danger">Student 4</p>
                                                </div>
                                            </div>
                                            <div className="chatuser">
                                                <div className="image">
                                                    <img src="assets/avatar/student-female.png" className="img-circle elevation-2" alt="User Image" />
                                                </div>
                                                <div className="info">
                                                    <p className="d-block text-success">Student 5</p>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <button type="button" style={{ marginLeft: "5px", paddingRight: "5px" }} class="btn btn-primary btn-block">Attendance</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      username: state.login.user.userName
    }
  }

export default connect(mapStateToProps)(StaffClassRoom)