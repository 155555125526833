import React, { useState, useEffect } from 'react'
import Loader from '../../ui/loader/Loader';
import { confirmAlert } from 'react-confirm-alert'
import QuestionPaperSectionDataService from '../../../services/QuestionPaperSectionDataService'
import QuestionPaperDetailDataService from '../../../services/QuestionPaperDetailDataService'
import history from "../../../util/history"
import parse from 'html-react-parser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const QuestionSettings = (props) => {
    const [allQuestionSections, setAllQuestionSections] = useState([])
    const [questionArray, setQuestionArray] = useState([])
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const [questionPaperSectionId, setQuestionPaperSectionId] = useState(0)
    const [searchQues, setSearchQues] =useState("") 
    const [submitted, setSubmitted] =useState(false)
    const [questionOrder, setQuestionOrder] = useState(0)


    useEffect(() => { 
        allQuestionSection()
    }, [])

    useEffect(() => { 
        if(questionPaperSectionId>0)
        {
            findAssignableQuestions()  
            listQuestions()
        }
    }, [questionPaperSectionId,submitted])


    const allQuestionSection = async () => {
        await QuestionPaperSectionDataService.getQuestionPaperSectionCombo(props.match.params.id)
        .then(response => {           
            setAllQuestionSections(response.data.result)
        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }
    

    const findAssignableQuestions = async () => {
        await QuestionPaperDetailDataService.getQuestionPaperdDetailAssignable(questionPaperSectionId,searchQues)
        .then(response => {
            setQuestionArray(response.data.result)
        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }

    const listQuestions = async () => {
        await QuestionPaperDetailDataService.getQuestionPaperdDetail(questionPaperSectionId)
        .then(response => {
            setSelectedQuestions(response.data.result)
            let order=0
            response.data.result.map((item, index)=>(
                order=item.questionOrder
            ))
            setQuestionOrder(order+1)
        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }

    const searchQuestions = () =>{
        findAssignableQuestions()
    }

    const addToSelectedQuestions = async (questionId) =>{
        var data={
            questionPaperDetId:0,
            questionPaperSectionId:parseInt(questionPaperSectionId),
            questionId:questionId,
            questionOrder:questionOrder
        }
        await QuestionPaperDetailDataService.createQuestionPaperdDetail(data)
        .then(response => {
            LearnToast(success,"Added Successfully")
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""     
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }
        })
        setSubmitted(!submitted)
    }


    const handleClickDelete = async id =>{
        await QuestionPaperDetailDataService.deleteQuestionPaperdDetail(id) 
            .then(response => {                                 
                LearnToast(success,"Removed Successfully")
                setSubmitted(!submitted);
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""   
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }          
            })
   }


    const removeQuestion = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
       
    }

    return (
        <>
        <ToastContainer/>
        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Question Settings</h5>
                            </div>
                            <br />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-row">
                                    <div className="col-md-4">                                   
                                        <select className="form-control" onChange={event => setQuestionPaperSectionId(event.target.value)} value={questionPaperSectionId} name="questionPaperSectionId" id="questionPaperSectionId">
                                            <option value="">--section title --</option>
                                            {allQuestionSections.map((item,index)=>(
                                                <option 
                                                key={index}
                                                value={item.questionPaperSectionId} 
                                                >
                                                    {item.sectionTitle}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="input-group">
                                            <input type="text" onChange={event => setSearchQues(event.target.value)} value={searchQues} name="searchQues" id="searchQues" className="form-control" placeholder="Serach Question" />
                                            <div className="input-group-append">
                                                <button onClick={()=>searchQuestions()} className="btn btn-primary" type="submit">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                     </div>
                                     <div className="col-md-4">
                                        <label>Question Order</label>
                                        <input type="number" onChange={event => setQuestionOrder(event.target.value)} value={questionOrder} name="questionOrder" id="questionOrder" className="form-control" placeholder="Question Order" />                                        
                                    </div>
                                </div>
                                <br/>
                            </div>
                            <div className="col-md-12 scrollbar scrollbar-primary card" style={{ height: "150px" }}>
                                <div className="force-overflow">
                                    <ul class="list-group">
                                        {questionArray.map((item, index) => (
                                            <li key={index} class="list-group-item" style={{ padding: "0 .5rem" }}>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <h6 style={{ lineHeight: "2" }}>{parse(item.comboText)}</h6>
                                                    </div>
                                                    <div>
                                                        <button type="button" onClick={()=>addToSelectedQuestions(item.comboValue)} class="btn btn-link" data-toggle="tooltip" data-placement="top" title="Add">
                                                            <span className="text-success"><i className="fa fa-plus"></i></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {allQuestionSections.map((item,index)=>(

                                    (item.questionPaperSectionId==questionPaperSectionId)?
                                    <h5 className="text-center text-info">
                                            {"Selected '" + selectedQuestions.length +"' " + item.questionTypeName+
                                            " Question out of '" + item.availableQuestions +"'"}
                                    </h5>:<></>))}
                             </div>
                            <div className="col-md-12 scrollbar scrollbar-primary card" style={{ height: "150px" }}>
                                <div className="force-overflow">
                                    <ul class="list-group">
                                        {selectedQuestions.map((item, index) => (
                                        <li key={index} class="list-group-item" style={{ padding: "0 .5rem" }}>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h6 style={{ lineHeight: "2" }}>{parse(item.questionText)}</h6>
                                                </div>
                                                <div>
                                                    <button type="button" onClick={()=>removeQuestion(item.questionPaperDetId)} class="btn btn-link" data-toggle="tooltip" data-placement="top" title="Add">
                                                        <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default QuestionSettings
