import  ApiDataService  from './ApiDataService'

const createExamination = data => {
  return ApiDataService.post("examination", data);
};

const findExamination = data => {
  return  ApiDataService.getWithParam("examination/find",data);
};

const publishExamination = examMstId => {
  let data={"examMstId":examMstId}
  return ApiDataService.put("examination/publishexam", data);
};


const resultMasterView = examMstId => {
  let data={"examMstId":examMstId}
  return ApiDataService.getWithParam("examination/examresultmasterview", data);
};

const publishExaminationResult = examMstId => {
  let data={"examMstId":examMstId}
  return ApiDataService.put("examination/publishresult", data);
};

const deleteExamination = id => {
  let values={'id':id}
  return  ApiDataService.remove("examination",values);
};


export default {createExamination,
                findExamination,
                resultMasterView,
                publishExamination,
                publishExaminationResult,
                deleteExamination};