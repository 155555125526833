import React, { useState, useEffect } from 'react'
import ReactSpeedometer from "react-d3-speedometer"

const StaffAssignment = (props) => {
 
  return (
   
      <div className="row">
        <div className="col-md-4 text-center">
        <div className="card card-outline">
            <div className="card-header">
                <h5 className="card-title">Overall Assignment</h5>
            </div>
            <div className="card-body box-profile">
                <ReactSpeedometer
                    maxValue={100}
                    value={props.profile.assignmentPerformancePercent}
                    needleColor="blue"
                    startColor="red"
                    segments={10}
                    width={200}
                    height={150}
                    endColor="green"
                    />
                </div>   
            </div>                                           
        </div>
        <div className="col-md-8"> 

        <div className="card card-outline">
            <div className="card-header">
                <h5 className="card-title">Subject Assignment</h5>
            </div>
            <div className="card-body box-profile">  
            {/* <h5 className="text-center">Subject Attendance</h5>  */}
            <br/>
                {props.assignmentProfileSummaries.map((item,index)=>(
                    <div  className="row" key={index}>
                        <div className="col-md-12">
                            <div className="progress-group">
                                {item.classSubjectName} - {item.totalPercent}%
                                <div className="progress progress-sm">
                                    <div className={item.progressbarClassName} style={{ width: item.totalPercent+"%" }} />
                                </div>
                            </div>
                        </div>                      
                    </div>
                ))}
                </div>
                <div className="card-footer">
                <div className="d-flex justify-content-between">
                    <span className="mr-2">
                        <i className="fas fa-square text-success"></i> Above 80%
                    </span>
                    <span className="mr-2">
                        <i className="fas fa-square text-warning"></i> Between 60% and 80%
                    </span>
                    <span className="mr-2">
                        <i className="fas fa-square text-danger"></i> Lessthan 60%
                    </span>
                </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StaffAssignment
