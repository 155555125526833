import React from 'react'
import LoadSingleQuestionAnswer from './LoadSingleQuestionAnswer';

const LoadQuestionAnswerSheet = (props) => {
  return (
    <>
    {props.item.map((item,index)=>(    <>
        
        <LoadSingleQuestionAnswer item={item} examStudentId={props.examStudentId} slNo={index} answerStatuses={props.answerStatuses}/>
      </>
    ))}
  </>
  )
}

export default LoadQuestionAnswerSheet
