import { 
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    FETCH_LOGIN_FAILURE, 
    LOGOUT_SUCCESS,
    CHANGE_IMAGE
    }from "./loginTypes"

const intialState ={
    loading:false,
    isAuthenticated:false,
    profileUrl:'',
    user:[],
    error:''
}

const reducer = (state = intialState, action) => {
    switch (action.type) {
        case FETCH_LOGIN_REQUEST:
            return {
            ...state,
            loading:true
            }
        case FETCH_LOGIN_SUCCESS:
           return {
                loading:false,
                profileUrl:action.payload.profilePicUrl,
                user:action.payload,
                isAuthenticated:true,
                error:''
            }
        case FETCH_LOGIN_FAILURE:
            return {
                loading:false,
                profileUrl:'',
                user:[],
                isAuthenticated:false,
                error:action.payload
            }
        case LOGOUT_SUCCESS:
            return {
                loading:false,
                profileUrl:'',
                user:[],
                isAuthenticated:false,
                error:''
            }
            case CHANGE_IMAGE:
                return {
                    ...state,
                    profileUrl:action.payload
                }
        default: return state
        }
}

export default reducer