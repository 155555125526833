import  ApiDataService  from './ApiDataService'

const createCourseitem = data => {
  return ApiDataService.post("courseitem", data);
};

const getCourseItem = (id) => {
  let values={'id':id}
  return  ApiDataService.getWithParam("courseitem",values);
};

const getComboViewCourseitem = (classSubjectId,courseLevelId) => {
    let values={'classSubjectId':classSubjectId,"courseLevelId":courseLevelId}
    return  ApiDataService.getWithParam("courseitem/comboview",values);
  };
  

const getParentCourseitem = (classSubjectId,courseLevelId) => {
  let values={'classSubjectId':classSubjectId,"courseLevelId":courseLevelId}
  return  ApiDataService.getWithParam("courseitem/parentitems",values);
};

const findCourseitem  = (data) => {
  return ApiDataService.getWithParam("courseitem/find",data);
};

const updateCourseitem = data => {
  return ApiDataService.put("courseitem", data);
}

const deleteCourseitem = id => {
  let values={'id':id}
  return  ApiDataService.remove("courseitem",values);
};

export default {createCourseitem,
                getCourseItem,
                getComboViewCourseitem,
                getParentCourseitem,
                findCourseitem,
                updateCourseitem,
                deleteCourseitem};