import React, { useState, useEffect } from 'react';
import CommonDataService from '../../../../services/CommonDataService';
import ClassSubjectDataService from '../../../../services/ClassSubjectDataService'
import ClassSectionDataService from "../../../../services/ClassSectionDataService"
import StudentDataService from "../../../../services/StudentDataService"
import StudentAttendanceReportDataService from '../../../../services/StudentAttendanceReportDataService'
import Loader from '../../../ui/loader/Loader';
import LearnPagination from '../../../ui/pagination/LearnPagination';
import IndividualPdfReport from './IndividualPdfReport';

const AttendanceReportStudentView = () => {
    const [fromDate, setFormDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [classSubjects, setClassSubjects] = useState([])
    const [subjectId, setSubjectId] = useState(0)
    const [isReporeView, setisReportView] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reportData, setReportdata] = useState([])
    const [reportTitle, setReportTitle] = useState("")
    const [reportSubTitle, setReportSubTitle] = useState("")
    const [headerImage, setHeaderImage] = useState("")
    const [isGetSubmitted, setIsGetSubmitted] = useState(false)

    
    useEffect(() => {
        fetchClassSubjects()
    }, [])

    const fetchClassSubjects = async () => {
        await StudentDataService.getStudentSubjects()
            .then(response => {
                setClassSubjects(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const getAttendance = async () => {
        setIsGetSubmitted(true)
        let data={
            fromDate:fromDate,
            toDate:toDate,
            studentId:0,
            classSubjectId:parseInt(subjectId)
        }
        setIsLoading(true)
        await StudentAttendanceReportDataService.getSingleStudentAttendanceReport(data)
            .then(response => {   
                setReportdata(response.data.result.reportData)                
                setReportTitle(response.data.result.reportTitle)             
                setReportSubTitle(response.data.result.reportSubTitle)
                setHeaderImage(response.data.result.headerFileName)
                setIsLoading(false)
                setisReportView(true)
            })
            .catch(error => {
                setIsLoading(false)
            })
    }

    const changeSubject = async (value) => {
        setisReportView(false)
        setReportdata([])
        setReportTitle("")
        setReportSubTitle("")
        setSubjectId(value)
    }

  return (
    <div className="container">
        <div className="jumbotron">
        <div className="card card-primary">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}> Student Attendance Report </h5>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                            <div className="card card-outline card-primary">
                                <div className="text-center">
                                    <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                </div>
                                <hr/>
                                <div className="col-md-12">
                                    <div className="form-group">                                                
                                    <label>From</label>
                                    <input onChange={event => setFormDate(event.target.value)} value={fromDate} name="fromDate" id="fromDate" className="form-control" type="date"  aria-label="Topic.." />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                    <label>To</label>
                                    <input onChange={event => setToDate(event.target.value)} value={toDate} name="toDate" id="toDate" className="form-control" type="date"  aria-label="Topic.." />
                                    </div>
                                </div> 
                                <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class Subject</label>
                                            <select onChange={event => changeSubject(event.target.value)} value={subjectId} name="subjectId" className="form-control">
                                                <option value="0">-- select subject --</option>
                                                {classSubjects.map(item => (
                                                    <option
                                                        key={item.classSubjectId}
                                                        value={item.classSubjectId}
                                                    >
                                                        {item.subjectName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                <div className="col-md-12">
                                    <button onClick={()=>getAttendance()} className="btn btn-primary btn-block">Get Report</button>
                                </div>  
                                <br/>
                                <br/>
                            </div>
                    </div>
                    {isLoading? <Loader/>:
                    <>
                    {(isGetSubmitted && reportData.length>0) || !isGetSubmitted ?
                            <div className="col-md-9">
                                {isReporeView && 
                                <IndividualPdfReport 
                                    reportData={reportData} 
                                    reportTitle={reportTitle} 
                                    reportSubTitle={reportSubTitle}
                                    headerImage={headerImage}/>
                                }
                            </div>
                    :
                    <div className="col-md-9">
                        <div className="alert alert-info alert-dismissible">
                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                        </div>
                    </div>
                    }
                    </>
                        }
                </div>
            </div>
        </div>
    </div>
    </div>
  )
}

export default AttendanceReportStudentView
