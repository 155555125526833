import React, { useState, useEffect } from 'react'
import StudentAttendanceDataservice from "../../../services/StudentAttendanceDataservice"

const StudentAttendance = () => {
    const [classSchedules, setClassSchedules] = useState([])

    useEffect(() => {
        const interval = setInterval(() => {
            fetchClassShedules()
        }, 1000);
        return () => clearInterval(interval);
      }, []);   
   

    const fetchClassShedules = async () =>{ 
        await StudentAttendanceDataservice.getUserShedules() 
        .then(response => {    
            setClassSchedules(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const postAttendance = async (id) =>{     
        let data={
            studentAttendanceId:0,
            studentId:0,
            classScheduleId:id,
            isPresent:true,
            isLate:false,
            isAdjusted:false,
            remarks:""
        } 
        await StudentAttendanceDataservice.markStudentAttendance(data) 
        .then(response => {
            alert(response.data.result)
            // fetchClassShedules()
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

  return (
    <div className="container">
        <div className="jumbotron">
            <div className="card card-primary">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Attendnace</h5>
                        </div>                    
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {classSchedules.length>0?
                        <div className="col-md-12">
                        {classSchedules.map((item,index)=>(
                                    <div className="card card-outline card-primary">
                                        <div className="card-header" id="headingOne">
                                            <i className="d-flex justify-content-between"> 
                                                {/* <p className="text-muted text-xs">Scheduled By:  {item.createdBy}</p>
                                                <p className="text-muted text-xs">Posted On:  {item.createdOn} </p> */}
                                                <p></p>  
                                                <p className="text-muted text-danger"> Time:  {item.scheduleFromTime + " - " + item.scheduleEndTime}</p>  
                                            </i>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <h5>Class: {item.classSectionName}</h5> 
                                                    <h6>Subject:  {item.classSubjectName}</h6>   
                                                    <h6>Teacher: {item.teacherName} </h6>
                                                    <div className="d-flex justify-content-between">                                  
                                                       <div></div> 
                                                        {(item.isActiveSchedule && item.isAttendanceInitiated && !item.isUpdated)?
                                                             <button className="btn btn-link" onClick={()=>postAttendance(item.classScheduleId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                                    <span className="text-info"><i className="fa fa-plus"></i> Mark Your Attendance </span>
                                                            </button>
                                                        :<></>}                                                              
                                                    </div>  
                                                </div>
                                            </div>  
                                        </div>
                                    </div>
                                ))}
                        </div>
                        :
                            <div className="alert alert-info alert-dismissible">
                                <div className="text-center text-white"><h4>No Records Found</h4></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default StudentAttendance
