import axios from 'axios';


let baseUrl = "https://cla.maclms.org/api/"
// let baseUrl = "http://cla.campuslte.com/api/"
// let baseUrl = "https://cla.impetorslearning.com/api/"  --hide optional subject
// let baseUrl = "http://demoapi.campuslte.com/api/"
//let baseUrl = "http://schoolapi.campuslte.com/api/"


//let baseUrl = "http://11plusapi.campuslte.com/api/"

const apiDataService = {
    get,
    getWithParam,
    post,
    postWithProgress,
    put,
    remove
};
function get(apiEndpoint){
    return axios.get(baseUrl + apiEndpoint, getOptions());
}
function getWithParam(apiEndpoint, values){
    return axios.get(baseUrl + apiEndpoint, {params:values, 
        headers: getHeaders()
        });
}

function post(apiEndpoint, payload){
    return axios.post(baseUrl + apiEndpoint, payload, getOptions());
}

function postWithProgress(apiEndpoint, payload, ...args){
    let api = axios.create({baseURL: baseUrl,headers: getHeaders()})
    return api.post(apiEndpoint, payload, ...args);
}

function put(apiEndpoint, payload){
   return axios.put(baseUrl + apiEndpoint, payload, getOptions());
}
function remove(apiEndpoint, values){
    return axios.delete(baseUrl + apiEndpoint, {params:values, 
        headers: getHeaders()
        });
}
function getOptions(){
    let token=localStorage.getItem('token');
    let options = {};
    if(localStorage.getItem('token')){
        options.headers = { 
            'Authorization': 'Bearer ' + token,
            "Content-type": "application/json"
         };
    }
    else{
        options.headers = { "Content-type": "application/json" };
    }
    return options;
}

function getHeaders()
{
    let token=localStorage.getItem('token');
    let headers = {};
    if(localStorage.getItem('token')){
        headers = { 
            'Authorization': 'Bearer ' + token,
            "Content-type": "application/json"
         };
    }
    else{
        headers = { "Content-type": "application/json" };
    }
    return headers;
}

export default apiDataService