import  FormValidator  from '../../../helpers/form-validator'

  const QuestionPaperMasterRule = () =>  new FormValidator([
    {
      field: 'questionPaperName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Question Paper Name is Required'
    },
    { 
      field: 'classSubjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'maxMark', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Maximum Mark is Required'
    }
  ])

  const QuestionSettingRule = () =>  new FormValidator([
    { 
      field: 'questionType', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Question Type is Required'
    },
    { 
      field: 'requiredQuestion', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Required Questions is Required'
    },
    { 
      field: 'totalQuestion', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Total Question is Required'
    },
    { 
      field: 'questionTypemark', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Mark/Question is Required'
    },
    { 
      field: 'sectionOrder', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Section Order is Required'
    },
    { 
      field: 'sectionTitle', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Section Title is Required'
    }
  ])

  export default {QuestionPaperMasterRule,
                  QuestionSettingRule}