import React, { useState, useEffect } from 'react'
import ExamAnswerSheetDataService from "../../../../services/ExamAnswerSheetDataService"
import LoadQuestions from './LoadQuestions'
import { confirmAlert } from 'react-confirm-alert'

const AnswerSheet = (props) => {
    const [questionPaper, setQuestionpaper]= useState({})
    const [questionPaperSections, setQuestionPaperSections] = useState([])
    useEffect(() =>{
        fetchExam()
    },[]) 

    const fetchExam = async () =>
    {
        await ExamAnswerSheetDataService.getStudentQuestionPaper(props.examScheduleId) 
        .then(response => {
            setQuestionpaper(response.data.result)
            setQuestionPaperSections(response.data.result.questionPaperSections)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const FinishExam = async (id) =>
    {
        let data={
            examScheduleId:id,
            isSubmitted:true,
            isCompleted:true
        }
        await ExamAnswerSheetDataService.finsihExam(data) 
        .then(response => {
            confirmAlert(ExamfinshOptions)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const ExamfinshOptions = {
        title: '',
        message: 'Congratulations, You have completed the examination successfully. The result will be published soon.',
        buttons: [
          {
            label: 'OK',
            onClick: () => window.location.reload(false)
          },
        ],
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        willUnmount: () => {},
        afterClose: () => {},
        onClickOutside: () => window.location.reload(false),
        onKeypressEscape: () => {}
      };
  return (
    <div className="container">
    <div className="jumbotron">
        <div className="card card-primary card-outline">
            <div className="card-header">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                            <p></p>
                            <h5>{questionPaper.examName}</h5>
                            <p></p>
                            {/* <p>{questionPaper.examDate}</p> */}
                        </div>
                        <div className="d-flex justify-content-between">
                            <p>{questionPaper.classesName}</p>
                            <h6>{questionPaper.subjectName}</h6>
                            <p>Max Marks:{questionPaper.maxMarks}</p>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {/* <div className="col-md-12 scrollbar scrollbar-primary">
                            <div className="force-overflow"> */}
                            <div className="col-md-12">
                                {questionPaperSections.map((item,index)=>(
                                <>
                                    <div className="d-flex justify-content-between mt-1">
                                        <p></p>                                       
                                        <p className="text-md"><i>{item.sectionTitle}</i></p>
                                        
                                        <p className="text-md muted"> {item.requiredQuestions +" X " + item.markPerQuestion + " = " + item.sectionMarks} </p>
                                    </div>
                                    <LoadQuestions questions={item.questionPaperDetails} questionTypeName={item.questionTypeName} examScheduleId={questionPaper.examScheduleId}/>
                                </>
                                ))}
                            </div>
                            {/* </div>
                        </div> */}
                    </div>
                </div>
            
                <div className="card-footer">
                    <div className="text-right">
                        <button className="btn btn-success"  onClick={()=>FinishExam(questionPaper.examScheduleId)}>Finish Exam</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AnswerSheet
