import React, { useState, useEffect } from 'react'
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import ClassScheduleDataService from "../../../services/ClassScheduleDataService"
import StudentAttendanceDataservice from "../../../services/StudentAttendanceDataservice"
import AttendanceEntry from './AttendanceEntry'



const Attendance = () => {

    const [classSections, setClassSections]= useState([])
    const [classSectionId, setClassSectionId] = useState(0)
    const [classShedule, setClassSchedule] = useState([])
    const [selectedSchedule, setSelectedSchedule] =useState(0)
    const [students, setStudents] = useState([])
    const [subjctName, setSubjectName] = useState("")
    const [isGetSubmitted, setIsgetSubmitted]= useState(false)

    useEffect(() =>{
        fetchTeacherCurrentSections()
    },[])    

    useEffect(() =>{
        fetchClasssShedules()
    },[classSectionId])   

    const fetchTeacherCurrentSections = async () =>{
        setStudents([])
        await TeacherAssociationDataService.getTeacherCurrentSections() 
        .then(response => {
            setClassSections(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchClasssShedules = async () =>{
        setStudents([])
        setSubjectName("")
        setSelectedSchedule(0)
        let classesListIds=classSectionId.toString()
        let today = new Date();
        let dd = today.getDate();

        let mm = today.getMonth()+1; 
        const yyyy = today.getFullYear();
        if(dd<10) 
        {
            dd=`0${dd}`;
        } 

        if(mm<10) 
        {
            mm=`0${mm}`;
        } 
        let values={
                periodFrom:yyyy+'-'+mm+'-'+dd,
                periodTo:yyyy+'-'+mm+'-'+dd,
                classList:classesListIds,
                subjectList:""
            }
        await ClassScheduleDataService.findTeacherClassSchedule(values) 
        .then(response => {
            setClassSchedule(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const onValueChange = (id) =>{
        setIsgetSubmitted(false)
        setStudents([])
        setSelectedSchedule(id)
    }


    const getStudentList = async () =>{        
        setIsgetSubmitted(true)
        await StudentAttendanceDataservice.getStudentAttendanceEntry(selectedSchedule) 
        .then(response => {
            setStudents(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

  return (
    <div className="container">
        <div className="jumbotron">
            <div className="card card-primary">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Attendnace</h5>
                        </div>                    
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card card-outline card-primary">
                                <div className="text-center">
                                    <h5 style={{marginTop:"10px"}}>Attendance Options</h5>
                                </div>
                                <hr/>     
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Class Section</label>
                                        <select onChange={event => setClassSectionId(event.target.value)} value={classSectionId} name="classSectionId" className="form-control">
                                            <option value="">-- select class section --</option>
                                            {classSections.map(item => (
                                                <option 
                                                key={item.classSectionId} 
                                                value={item.classSectionId}
                                                >                                                
                                                    {item.classSectionName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>  
                                <div className="col-md-12">
                                    <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Shedules </b></h6>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        {classShedule.map((item, index)=>(
                                            <li className="list-group-item" key={index}>
                                                <div className="form-check">
                                                    <input className="form-check-input" 
                                                        type="radio" 
                                                        name="radio"
                                                        value={item.classScheduleId}
                                                        checked={selectedSchedule === item.classScheduleId}
                                                        onClick={()=>onValueChange(item.classScheduleId)} />
                                                    <label className="form-check-label">{item.fromTime + " : "+ item.toTime}</label>
                                                </div>
                                             </li>
                                        ))}
                                    </ul>
                                </div>     
                                <div className="col-md-12">
                                    <button onClick={()=>getStudentList()} className="btn btn-primary btn-block">Get Students</button>
                                </div>   
                                <br/>    
                            </div>
                            
                        </div>
                        <div className="col-md-9">
                            <div className="card card-outline card-primary">
                                <div className="card-header">
                                    <div class="row">
                                        <div className="col-md-8">
                                            <h2 className="card-title">
                                        <span class="info-box-icon text-success"><i class="far fa-bookmark"></i> <b>{subjctName}</b></span>
                                            </h2>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {(students.length>0 && isGetSubmitted) || !isGetSubmitted?
                                            <table className="table table-bordered table-hover">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Name</th>
                                                        <th className="text-right"></th>
                                                    </tr>
                                                </thead>
                                                <tbody>  
                                                    {students.map((item, index)=>(                          
                                                       <AttendanceEntry key={index} student={item}/>
                                                    ))}
                                                </tbody>
                                            </table>
                                             :
                                             <div className="alert alert-info alert-dismissible">
                                                 <div className="text-center text-white"><h4>No Records Found</h4></div>
                                             </div>
                                         }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Attendance
