import React from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

// ClassicEditor
//     .create( document.querySelector( '#editor' ), {
//         simpleUpload: {
//             uploadUrl: 'http://cla.campuslte.com/api/fileupload/editorupload',
//         }
//     } );

const DemoEditor = () => {
  return (
    <div className="container">
        <div className="jumbotron">
            <div className="card card-primary">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>New Exam</h5>
                        </div>
                        <br />
                        <br />
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <h2>Using CKEditor 5 build in React</h2>
                        <div className="col-md-12">
                        <CKEditor
                            onInit={ editor => {
                                console.log( 'Editor is ready to use!', editor );

                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                            } }
                            onChange={ ( event, editor ) => console.log( { event, editor } ) }
                            editor={ DecoupledEditor }
                            data="<p>Hello from CKEditor 5's DecoupledEditor!</p>"
                            config={{
                                ckfinder:{uploadUrl: "http://cla.campuslte.com/api/fileupload/editorupload"}
                            }}
                        />
                        </div>
                    </div>
                </div>
            </div>
        </div>      
    </div>
  )
}

export default DemoEditor
