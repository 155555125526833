import  ApiDataService  from './ApiDataService'

const createExaminationStudent = data => {
  return ApiDataService.post("examinationstudent", data);
};

const getExaminationStudent = examScheduleId => {
    let values={"examScheduleId":examScheduleId}
  return  ApiDataService.getWithParam("examinationstudent",values);
};

const getExaminationStudentAssignable = (examScheduleId,classSectionId) => {
    let values={"examScheduleId":examScheduleId,"classSectionId":classSectionId}
  return  ApiDataService.getWithParam("examinationstudent/assignablestudents",values);
};

const getEvaluationStudents = (examScheduleId) => {
  let values={"examScheduleId":examScheduleId}
return  ApiDataService.getWithParam("examinationstudent/evaluationstudents",values);
};

const getStudentResult = (examMstId) => {
  let values={"examMstId":examMstId}
return  ApiDataService.getWithParam("examinationstudent/studentmark",values);
};


const deleteExaminationStudent = id => {
  let values={'id':id}
  return  ApiDataService.remove("examinationstudent",values);
};


export default {createExaminationStudent,
                getExaminationStudent,
                getExaminationStudentAssignable,
                getEvaluationStudents,
                getStudentResult,
                deleteExaminationStudent};