import  FormValidator  from '../../../helpers/form-validator'

const CreateRule = (passwordMatch) => new FormValidator([
    { 
       field: 'titleId', 
       method: 'isEmpty', 
       validWhen: false, 
       message: 'Title is Required'
    },
    { 
      field: 'teacherName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Name is Required'
    },
    { 
      field: 'genderId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Gender is Required'
    },
    { 
      field: 'mobile', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Mobile is Required'
    },
    { 
      field: 'eMail', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Email is Required'
    },
    { 
      field: 'eMail',
      method: 'isEmail', 
      validWhen: true, 
      message: 'That is not a valid email.'
    },
    { 
      field: 'password', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Password is Required'
    },
    { 
      field: 'confirmpassword', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Confirm Password is Required'
    },
    { 
      field: 'confirmpassword', 
      method: passwordMatch, 
      validWhen: true, 
      message: 'password mismatch'
    }
  ])

  const EditRule = () => new FormValidator([
    { 
       field: 'titleId', 
       method: 'isEmpty', 
       validWhen: false, 
       message: 'Title is Required'
    },
    { 
      field: 'teacherName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Name is Required'
    },
    { 
      field: 'genderId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Gender is Required'
    },
    { 
      field: 'mobile', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Mobile is Required'
    }
  ])

export default {CreateRule,
                EditRule}
  