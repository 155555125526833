import React, { useState, useEffect } from 'react';
import TeacherDataSevice from '../../../services/TeacherDataSevice';
import TeacherEmailDataService from '../../../services/TeacherEmailDataService';
import Loader from '../../ui/loader/Loader';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ckeditor from '@ckeditor/ckeditor5-react';
import './emailStyle.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import ValidationRule from './ValidationRule'

let teacherIds = ""
const SendTeacherNotification = () => {
    const validator= ValidationRule;
    const [allTeacher, setAllTeacher] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchName, setSearchName] = useState('')
    const [message, setMessage] = useState("")
    const [selectedOption, setSelectedOption] = useState("All")
    const [isAllTeacher, setIsAllTeacher] = useState(true)
    const [isAllChecked, setIsAllChecked] = useState(false)
    const [subject,setSubject] = useState("")
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)    

    var validationData={
        subject:subject,
        message:message,
        validation:validation,
    }

    useEffect(() => {
        if (selectedOption == "All") {
          setIsAllTeacher(true)
        }
        else {
            setIsAllTeacher(false)
          fetchTeachers()
        }
      }, [selectedOption])


    useEffect(() => {
        fetchTeachers();
    }, [])

    const fetchTeachers = async () => {
        let teachers = []
        setLoading(true);
        await TeacherDataSevice.allTeacherWithFilter(searchName)
            .then(response => {           
                response.data.result.map((item, index) => (
                    teachers.push({ id: item.teacherId, fname: item.fullName, name: "teacher" + item.teacherId, mobile: item.mobile, isChecked: false })
                ));
                setLoading(false);
                setAllTeacher(teachers)
            })
            .catch(error => {
                setLoading(false);
            })
    }

    const serachByName = () => {
        fetchTeachers()
    }

    const checkTeacher = (e) => {
        let itemName = e.target.name;
        let checked = e.target.checked;
        let ischecked = isAllChecked
        let teacherList = allTeacher
        teacherIds = ""
        if (itemName === "allCheck") {
            ischecked = checked;
            teacherList = teacherList.map(item => ({ ...item, isChecked: checked })
            );
        } else {
            teacherList = teacherList.map(item =>
                item.name === itemName ? { ...item, isChecked: checked } : item
            );
            ischecked = teacherList.every(item => item.isChecked)
        }

        teacherList.forEach(item => {
            if (item.isChecked) {
                if (teacherIds.length > 0 || teacherIds !== "") {
                    teacherIds += ","
                }
                teacherIds += item.id
            }
        })
        setAllTeacher(teacherList)
        setIsAllChecked(ischecked)
    }

    const sendEmail = async () => {
        let data={
          sendToIdList:teacherIds,
          mailSubject:subject,
          mailBody:message,
          isSendToAll:isAllTeacher
        }
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)

        if (validate.isValid) {
        if(isAllTeacher || (!isAllTeacher && teacherIds.length>0))
        {
            setLoading(true)
            await TeacherEmailDataService.sendTeacherEmail(data)
            .then(response => {    
                resetForm()
                setLoading(false)
                LearnToast(success, "Message Sent")
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }            
            })
        }
        else
        { 
            LearnToast(warning, "select at least one recipient")
        }
    }
      }

      const resetForm=()=>
      {
        teacherIds=""
        setSubject("")
        setAllTeacher([])
        setSearchName("")
        setIsAllChecked(false)
        setMessage("")
        ckeditor.data = ""
        setValidation(validator.valid());
        setValidateSubmitted(false);
      }
    
      let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    return (
        <>
         <ToastContainer />
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}> Teacher Notification </h5>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card card-outline card-primary">
                                        <div className="text-center">
                                            <h5 style={{ marginTop: "10px" }}>Message Options</h5>
                                        </div>
                                        <hr />

                                        <div className="">
                                            <div className="col-md-12">
                                                <label>Subject</label>
                                                <input type="text" onChange={event => setSubject(event.target.value)} value={subject} name="subject" id="subject" className={validateOutput.message.isInvalid ? 'form-control is-invalid':'form-control'}/>
                                                <span className="text-danger">{validateOutput.subject.message}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <label>Message</label>
                                                <CKEditor
                                                    onInit={editor => {
                                                        console.log('Editor is ready to use!', editor);

                                                        // Insert the toolbar before the editable area.
                                                        editor.ui.getEditableElement().parentElement.insertBefore(
                                                            editor.ui.view.toolbar.element,
                                                            editor.ui.getEditableElement()
                                                        );
                                                        editor.on('insertElement', function (event) {
                                                            var element = event.data;
                                                            if (element.getName() == 'figure') {
                                                                element.addClass('img-responsive');
                                                            }
                                                        });
                                                    }}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        console.log({ event, editor, data });
                                                        setMessage(data);
                                                    }}
                                                    editor={DecoupledEditor}
                                                    data={message}
                                                    config={{
                                                        ckfinder: { uploadUrl: "https://cla.maclms.org/api/fileupload/editorupload" }
                                                    }}



                                                />
                                                 <span className="text-danger">{validateOutput.message.message}</span>
                                            </div>
                                            <div className="col-md-12">
                                                <hr />
                                            </div>
                                            <div className="col-md-12">
                                                <div className="d-flex justify-content-between">
                                                    <div className="col-md-8">
                                                        <div className="d-flex justify-content-between" style={{ marginLeft: "30px" }}>
                                                            <div className="col">
                                                                <label>
                                                                    <input type="radio" value="All" onClick={() => setSelectedOption("All")} checked={selectedOption === 'All'} />
                                                                        Send to All Teachers
                                                                    </label>
                                                            </div>
                                                            <div className="col">
                                                                <label>
                                                                    <input type="radio" value="Specific" onClick={() => setSelectedOption("Specific")} checked={selectedOption === 'Specific'} />
                                                                    Specific Teachers
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <button className="btn btn-primary btn-block" onClick={() => sendEmail()}>
                                                                <i className="fa fa-paper-plane">&nbsp;&nbsp; Send</i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {!isAllTeacher ?
                                    <>
                                        <div className="col-md-12" style={{ overflow: "scroll", height: "400px" }}>
                                            <div className="card card-outline card-primary">
                                                <br/>
                                                <div className="col-md-12 d-flex justify-content-between">
                                                    <div className="col-md-3">
                                                        <div className="input-group">
                                                            <div className="input-group input-group-sm">
                                                                <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control form-control-navbar" type="text" placeholder="Type Name" aria-label="Search" />
                                                                <div className="input-group-append">
                                                                    <button onClick={() => serachByName()} className="btn btn-primary" type="submit">
                                                                        <i className="fas fa-search"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-9">
                                                        
                                                    </div>
                                                </div>
                                                <br/>
                                                <table className="table table-bordered table-hover">
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Name</th>
                                                            <th scope="col">Mobile</th>
                                                            <th className="text-right">
                                                                <input onChange={(e) => checkTeacher(e)} name="allCheck" type="checkbox" checked={isAllChecked} />
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {loading ? (<Loader />) : (allTeacher.map((item, index) => (
                                                            <tr key={item.id}>
                                                                <th scope="row">{index + 1}</th>
                                                                <td>{item.fname}</td>
                                                                <td>{item.mobile}</td>
                                                                <td className="text-right">
                                                                    <input key={item.id} onChange={event => checkTeacher(event)} type="checkbox" checked={item.isChecked} name={item.name} value={item.id} />
                                                                </td>
                                                            </tr>
                                                        )))}

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </>
                                    : <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SendTeacherNotification
