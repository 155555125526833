import React, { useState, useEffect } from 'react';
import ExamEntry from './ExamEntry'
import AnswerSheet from './AnswerSheet'
import Loader from '../../ui/loader/Loader';
import ExamDataService from "../../../services/ExamDataService"

let subjectListIds = ""
const TodaysExam = () => {
    const [isExam, setIsExam] = useState(false)
    const [ExamId, setExamId] = useState(0)
    const [isResult, setIsResult] = useState(false)
    const [loading,setLoading] = useState(false);
    const [exams, setExams] =useState([])

    // useEffect(() =>{
    //     setLoading(true)
    //     fetchExam();
    // },[]) 

    useEffect(() => {
        // const interval = setInterval(() => {
        //     fetchExam();
        // }, 1000);
        // return () => clearInterval(interval);
        fetchExam();
      }, []);

    
    const fetchExam = async () =>{
        await ExamDataService.TodaysStudentExam() 
        .then(response => {
           setExams(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const AttendExam = (id) => {
        setExamId(id)
        setIsResult(false)
        setIsExam(true)
    }

    return (
        <>{isExam ? (<ExamEntry ExamId={ExamId} />) :(<>{isResult? <AnswerSheet ExamId={ExamId}/>:(
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Exam</h5>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">      
                            <div className="col-md-12">
                                <div className="alert alert-warning alert-dismissible">
                                    <button type="button" className="close" data-dismiss="alert" aria-hidden="true">×</button>
                                    {/* <h5><i className="icon fas fa-exclamation-triangle" /> Alert!</h5> */}
                                    You have to referesh the page on Time.
                                </div>

                                </div>                         
                                <div className="col-md-12">  
                                {loading?<Loader/>:<>                            
                                    {exams.map((item, index)=>(                                  
                                    <div className="card card-outline card-primary" key={index}>
                                        <div className="card-header" id="headingOne">
                                            <i className="d-flex justify-content-between">
                                            <p className="text-muted text-xs">Subject: {item.classSubjectName}</p>
                                                {item.isTimeBound?
                                                      <>
                                                      <p className="text-justify">Exam Date: {item.scheduleDate}</p>
                                                      <p className="text-justify">Exam Time:  {item.scheduleTime+" - "+ item.endTime}</p>
                                                      </>
                                                 :  <p className="text-justify"></p>
                                                 }   
                                            </i>
                                            <div className="row">
                                                <div className="col-md-9">
                                                    <div className="d-flex justify-content-between">
                                                    <h5>{item.examName}</h5>
                                                    </div>
                                                    <div className="d-flex justify-content-between">
                                                        {/* {item.isActiveExam?
                                                            <>
                                                            {item.isFile?
                                                                    <a href={item.documentId} className="btn btn-link"><span className="text-success"><i className="fa fa-id-card"></i> Download Question</span></a>
                                                                    :
                                                                    <button className="btn btn-link" onClick={() => AttendExam(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                        <span className="text-success"><i className="fa fa-id-card"></i> Attend</span>
                                                                    </button>
                                                                }
                                                            </>
                                                            :<>
                                                                
                                                            </>
                                                        } */}

                                                        {item.isTimeBound && !item.isActiveExam && !item.isScheduleExceeded?
                                                            <p className="text-info">Upcoming</p>
                                                            :
                                                            <>
                                                                {item.isTimeBound && item.isActiveExam && !item.isScheduleExceeded?
                                                                     <>
                                                                     {item.isFile?
                                                                        <a href={item.documentId} className="btn btn-link"><span className="text-success"><i className="fa fa-id-card"></i> Download Question</span></a>
                                                                        :
                                                                        <>
                                                                            {item.isSubmitted?
                                                                                <span className="text-success">Attended</span>
                                                                            :
                                                                                <button className="btn btn-link" onClick={() => AttendExam(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                                    <span className="text-success"><i className="fa fa-id-card"></i> Attend</span>
                                                                                </button>
                                                                            }
                                                                        </>
                                                                    }
                                                                     </>
                                                                    :
                                                                    <>
                                                                        {item.isTimeBound &&!item.isActiveExam && item.isScheduleExceeded?
                                                                            <>
                                                                                {item.isAttended?
                                                                                    <p className="text-success">Attended</p>
                                                                                    :
                                                                                    <p className="text-danger">Not Attended</p>
                                                                                }
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {!item.isTimeBound && item.isAttended && !item.isAttendanceInProgress?
                                                                                    <p className="text-success">Exam Completed</p>
                                                                                :
                                                                                <>
                                                                                    {!item.isResultPublished && !item.isTimeBound?
                                                                                         <>
                                                                                         {item.isFile?
                                                                                            <a href={item.documentId} className="btn btn-link"><span className="text-success"><i className="fa fa-id-card"></i> Download Question</span></a>
                                                                                            :
                                                                                            <>
                                                                                                {item.isSubmitted?
                                                                                                    <span className="text-success">Attended</span>
                                                                                                :
                                                                                                    <button className="btn btn-link" onClick={() => AttendExam(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                                                        <span className="text-success"><i className="fa fa-id-card"></i> Attend</span>
                                                                                                    </button>
                                                                                                }
                                                                                            </>
                                                                                        }
                                                                                         </>
                                                                                         :<></>
                                                                                    }
                                                                                </>
                                                                            }

                                                                            </>
                                                                        }
                                                                    </>

                                                                }
                                                            </>    
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="row">
                                                        {/* <div className="col-md-6 border-left">
                                                            <div className="description-block">
                                                            <h5 className="description-header">{item.isResultPublished?item.markScored:"-"}</h5>
                                                                <span className="description-text">Scored</span>
                                                            </div>
                                                        </div> */}
                                                        <div className="col-md-6 border-left">
                                                            <div className="description-block">
                                                            <h5 className="description-header">{item.maxMarks}</h5>
                                                                <span className="description-text">Total</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                               ))}</>}
                               </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}</>)}</>
    )
}

export default TodaysExam
