import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'courseLevelId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Course Level is Required'
    },
    { 
      field: 'subjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide subject'
    },
    { 
      field: 'courseItemName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide course item name'
    }
  ])

  