import React, { useState, useEffect } from 'react'
import ExamAnswerSheetDataService from '../../../services/ExamAnswerSheetDataService';
import LoadQuestionAnswerSheet from './LoadQuestionAnswerSheet';
import CommonDataService from '../../../services/CommonDataService';

let a="a"
const ValuationSheet = (props) => {
    const [answerSheet, setAnswerSheet] = useState([])
    const[answerStatuses, setAnswerStatuses] = useState([])

    useEffect(() => {
        fetchAnswerSheet()
        fetchAnswerStatuses()
    }, [])

    const fetchAnswerStatuses = async () => {
        await CommonDataService.answerStatuses()
            .then(response => {
                setAnswerStatuses(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchAnswerSheet = async () => {
        await ExamAnswerSheetDataService.getEvaluationAnswerSheet(props.examStudentId)
            .then(response => {                
                console.log(response.data.result)
                setAnswerSheet(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

   
    return (
        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Valuation </h5>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="card-body">
                        {answerSheet.map((item, index) => (
                            <>
                             <div className="d-flex justify-content-between mt-1">
                                    <p></p>                                       
                                    <p className="text-md"><i>{item.sectionTitle}</i></p>
                                    
                                    <p className="text-md muted"> {item.markPerQuestion+"Marks/Question"} </p>
                                </div>
                           <LoadQuestionAnswerSheet item={item.answerSheetEvaluationViews} examStudentId={props.examStudentId} slNo={index} answerStatuses={answerStatuses}/>
                           </>
                        ))}

                    </div>
                    <div className="card-footer">
                        <div className="text-right">
                            <button className="btn btn-primary" onClick={()=>window.location.reload(false)}>Back</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ValuationSheet
