import React, { useState, useEffect } from 'react'
import QuestionBankDataService from "../../../services/QuestionBankDataService"
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import DifficultyLevelDataService from "../../../services/DifficultyLevelDataService"
import CourseItemDataService from "../../../services/CourseItemDataService"
import ValidationRule from './ValidationRule'
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ckeditor from '@ckeditor/ckeditor5-react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import Loader from '../../ui/loader/Loader';
import Select from 'react-select';

let options = []
let cousetItemIds = ""
const EssayQuestion = () => {
    const [question, setQuestion] = useState("")
    const [shortAnswer, setShortAnswer] = useState("")
    const [classesId, setClassesId] = useState(0)
    const [classSubjectId, setClassSubjectId] = useState("")
    const [classes, setClasses] = useState([])
    const [allClassSubject, setAllClassSubject] = useState([]); 
    const [difficultyLevelId, setDifficultyLevelId] = useState("");
    const [difficultyLevels, setDifficultyLevels] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [selectedCourseItems, setSelectedCourseItems] = useState([])

    const validatorShortAnswer = ValidationRule.ShorAnswerForm();
    const [validationShortAnswer, setValidationShortAnswer] = useState(validatorShortAnswer.valid());
    const [validateSubmittedShortAnswer, setValidateSubmittedShortAnswer] = useState(false)

    var shortAnswerValidationData = {
        classSubjectId: classSubjectId,
        question: question,
        shortAnswer: shortAnswer,
        difficultyLevelId:difficultyLevelId,
        validation: validationShortAnswer
    }

    useEffect(() => {
        fetchDifficultyLevel()
        fetchTeacherCurrentClasses()
    }, [submitted])

    useEffect(() => {
        fetchTeacherCurrentSubjects()
    }, [classesId])

    const fetchTeacherCurrentClasses = async () => {
        await TeacherAssociationDataService.getTeacherCurrentClasses()
            .then(response => {
                setClasses(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchTeacherCurrentSubjects = async () => {
        await TeacherAssociationDataService.getTeacherCurrentSubjects(parseInt(classesId))
            .then(response => {
                setAllClassSubject(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchDifficultyLevel= async () =>{        
        setLoading(true);
        await DifficultyLevelDataService.allDifficultyLevel() 
        .then(response => {
            setDifficultyLevels(response.data.result)
            setLoading(false)
       })
       .catch(error => {
           setLoading(false)       
       })
    }
    useEffect(() => {
        fetchCourseItem()
    }, [classSubjectId])

    const fetchCourseItem= async () =>{ 
        await CourseItemDataService.getComboViewCourseitem(parseInt(classSubjectId),0)
        .then(response => {
            response.data.result.map((item, index)=>(
                options.push({value:item.comboValue,label:item.comboText})
            ));
       })
       .catch(error => {
           setLoading(false)       
       })
    }


    const saveShorAnswer = async () => {
        cousetItemIds=""
        if(selectedCourseItems !==null)
        {
            selectedCourseItems.map((item, index)=>(
                (cousetItemIds==="")?
                    cousetItemIds =item.value:
                    cousetItemIds =cousetItemIds+"," + item.value  
            ))
        }
        var data = {
            questionId: 0,
            classSubjectId: parseInt(classSubjectId),
            questionText: question,
            answerText: shortAnswer,
            difficultyLevelId:parseInt(difficultyLevelId),
            courseItemList:cousetItemIds.toString()
        }
        const validate = validatorShortAnswer.validate(shortAnswerValidationData)
        setValidationShortAnswer({ validate });
        setValidateSubmittedShortAnswer(true)
        if (validate.isValid) {
            setLoading(true)
            await QuestionBankDataService.createEssayQuestion(data)
                .then(response => {
                    setLoading(false)
                    LearnToast(success, "Question Added Successfully")
                })
                .catch(error => {
                    let errorMsg=[]
                    let msgText=""
                    setLoading(false)      
                    if(error.response.status==500)
                    {
                        LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                    }
                    else
                    {
                        errorMsg=error.response.data.errors
                        errorMsg.map((item,index)=>{
                            if(msgText.length>0)
                            {
                            msgText = msgText+","
                            }
                            msgText=msgText+item
                        })            
                            LearnToast(danger,msgText)
                    
                    }            
                })
            setSubmitted(!submitted)
            resetForm()
        }
    }

    const resetForm = () => {
        setClassesId("")
        setClasses([])
        fetchTeacherCurrentClasses()
        setDifficultyLevelId("")
        fetchDifficultyLevel()
        setClassSubjectId("")
        setAllClassSubject([])
        setQuestion("")
        setSelectedCourseItems([])
        options=[]
        setShortAnswer("")
        setValidationShortAnswer(validatorShortAnswer.valid())
        setValidateSubmittedShortAnswer(false)
        ckeditor.data = ""
    }

    let validateOutputShortAnswer = validateSubmittedShortAnswer ? validatorShortAnswer.validate(shortAnswerValidationData) : shortAnswerValidationData.validation


    return (
        <>
            <ToastContainer />
            {loading ? <Loader /> :
                <>
                    <div className="container">
                        <div className="jumbotron">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Essay Question </h5>
                                        </div>
                                        <div className="col-md-6 text-right">

                                        </div>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3 card">
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Class</label>
                                                    <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className="form-control">
                                                        <option value="">-- select class --</option>
                                                        {classes.map(item => (
                                                            <option
                                                                key={item.classesId}
                                                                value={item.classesId}
                                                            >
                                                                {item.classesName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label>Subject</label>
                                                    <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className="form-control">
                                                        <option value="">-- select subject --</option>
                                                        {allClassSubject.map(item => (
                                                            <option
                                                                key={item.classSubjectId}
                                                                value={item.classSubjectId}
                                                            >
                                                                {item.subjectName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger">{validateOutputShortAnswer.classSubjectId.message}</span>
                                                </div>
                                            </div>
                                            <div className="form-row">                                               
                                                <div className="col">
                                                    <label>Difficulty Levels</label>
                                                    <select onChange={event => setDifficultyLevelId(event.target.value)} value={difficultyLevelId} name="difficultyLevelId" className="form-control">
                                                        <option value="">-- select level --</option>
                                                        {difficultyLevels.map(item => (
                                                            <option
                                                                key={item.difficultyLevelId}
                                                                value={item.difficultyLevelId}
                                                            >
                                                                {item.difficultyLevelName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger">{validateOutputShortAnswer.difficultyLevelId.message}</span>
                                                </div>
                                            </div>
                                            <div className="form-row"> 
                                                <div className="col">
                                                    <label>Select Course Item</label>
                                                    <Select
                                                        value={selectedCourseItems}
                                                        isMulti
                                                        onChange={selectedCourseItems => setSelectedCourseItems(selectedCourseItems)}
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Question?</label>
                                                    <CKEditor
                                                        onInit={editor => {
                                                            console.log('Editor is ready to use!', editor);

                                                            // Insert the toolbar before the editable area.
                                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                                editor.ui.view.toolbar.element,
                                                                editor.ui.getEditableElement()
                                                            );
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            console.log({ event, editor, data });
                                                            setQuestion(data);
                                                        }}
                                                        editor={DecoupledEditor}
                                                        data={question}
                                                        config={{
                                                            ckfinder: { uploadUrl: "https://cla.maclms.org/api/fileupload/editorupload" }
                                                        }}
                                                    />
                                                    {/* <input type="text"  onChange={event => setQuestion(event.target.value)} value={question} name="question" id="question" className="form-control"/> */}
                                                    <span className="text-danger">{validateOutputShortAnswer.question.message}</span>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Answer</label>
                                                    <CKEditor
                                                        onInit={editor => {
                                                            console.log('Editor is ready to use!', editor);

                                                            // Insert the toolbar before the editable area.
                                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                                editor.ui.view.toolbar.element,
                                                                editor.ui.getEditableElement()
                                                            );
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            console.log({ event, editor, data });
                                                            setShortAnswer(data);
                                                        }}
                                                        editor={DecoupledEditor}
                                                        data={shortAnswer}
                                                        config={{
                                                            ckfinder: { uploadUrl: "https://cla.maclms.org/api/fileupload/editorupload" }
                                                        }}
                                                    />
                                                    {/* <textarea  onChange={event => setShortAnswer(event.target.value)} value={shortAnswer} name="shortAnswer" id="shortAnswer"  className="form-control" placeholder="Place some text here"
                                        style={{
                                            width: "100%", height: "200px", fontSize: "14px", lineHeight: "18px",
                                            border: "1px solid #dddddd", padding: "10px"
                                        }} /> */}
                                                    <span className="text-danger">{validateOutputShortAnswer.shortAnswer.message}</span>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="form-group text-right">
                                                {/* <button onClick={closeWindow} className="btn btn-light">close</button>&nbsp;&nbsp;&nbsp; */}
                                                <button onClick={saveShorAnswer} className="btn btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>

    )
}
export default EssayQuestion
