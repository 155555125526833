import  ApiDataService  from './ApiDataService'

const createQandAPost = data => {
  return ApiDataService.post("qapost", data);
};

const allTeacherQandAPost = (values) => {
  return  ApiDataService.getWithParam("qapost/teacher",values);
};

const allStudentQandAPost = (values) => {
    return  ApiDataService.getWithParam("qapost/student",values);
  };

const replayQandAPost = data => {
  return ApiDataService.put("qapost", data);
}

const deleteQandAPost = id => {
  let values={'id':id}
  return  ApiDataService.remove("qapost",values);
};

export default {createQandAPost,
                allTeacherQandAPost,
                allStudentQandAPost,
                replayQandAPost,
                deleteQandAPost};