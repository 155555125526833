import React, { useState, useEffect } from 'react'
import QuestionPaperMasterDataService from '../../../../services/QuestionPaperMasterDataService'
import LoadQuestion from './LoadQuestion'

const ViewQuestionPaper = (props) => {
    const [questionPaper, setQuestionpaper]= useState({})
    const [questionPaperSections, setQuestionPaperSections] = useState([])

    useEffect(() => { 
        fetchQustionPaper()
    }, [])

    const fetchQustionPaper = async () => {
        await QuestionPaperMasterDataService.viewQuestionPaper(props.match.params.id)
        .then(response => {         
            setQuestionpaper(response.data.result)
            setQuestionPaperSections(response.data.result.questionPaperSections)
        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }

  return (
    <div className="container">
    <div className="jumbotron">
        <div className="card card-primary card-outline">
            <div className="card-header">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                            <p>{questionPaper.serialNumber}</p>
                            <h5>{questionPaper.questionPaperName}</h5>
                            <p>{questionPaper.subjectName}</p>
                        </div>
                        <div className="d-flex justify-content-between">
                           <p></p>
                           <p></p>
                            <p>Max Marks:{questionPaper.maxMarks}</p>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {/* <div className="col-md-12 scrollbar scrollbar-primary">
                            <div className="force-overflow"> */}
                            <div className="col-md-12">
                                {questionPaperSections.map((item,index)=>(
                                <>
                                    <div className="d-flex justify-content-between mt-1">
                                        <p></p>
                                       <p className="text-md"><i>{item.sectionTitle}</i></p>
                                        
                                        <p className="text-md muted"> {item.requiredQuestions +" X " + item.markPerQuestion + " = " + item.sectionMarks} </p>
                                    </div>
                                    <LoadQuestion questionPaperDetails={item.questionPaperDetails} sl={index+1}/>
                                </>
                                ))}
                            </div>
                            {/* </div>
                        </div> */}
                    </div>
                </div>
            
                {/* <div className="card-footer">
                    <div className="text-right">
                        <button className="btn btn-primary" onClick={()=>window.location.reload(false)}>Back To Exam</button>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
  )
}

export default ViewQuestionPaper
