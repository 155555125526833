import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'classesName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Name is Required'
    },
    { 
      field: 'sectionName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Section Name is Required'
    }
  ])

  