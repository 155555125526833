import React, { useState, useEffect} from 'react'
import LearnPagination from '../../ui/pagination/LearnPagination'
import CommonDataService from '../../../services/CommonDataService'
import ClassSubjectDataService from '../../../services/ClassSubjectDataService'
import ClassNoteDataService from '../../../services/ClassNoteDataService'
import TeacherAssociationDataService from '../../../services/TeacherAssociationDataService'
import Loader from '../../ui/loader/Loader'

const AdminClassNote = () => {
    const [searchTopic,setSearchTopic]=useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [loading,setLoading] = useState(false);
    const [allClasses,setAllClasses]=useState([])
    const [classSubjects,setClassSubjects]=useState([])
    const [teachers, setTeachers] = useState([])
    const [classId, setClassId] = useState("")
    const [subjectId, setSubjectId] = useState("")
    const [classNotes, setClassNotes] = useState([])
    const [teacherId, setTeacherId] = useState("")

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
            classNoteName: searchTopic,
            classNoteDetail: "",
            classList:classId.toString(),
            subjectList:subjectId.toString(),
            teacherList:teacherId.toString()
    })
    const [teacherFilterData, setTeacherFilterData]= useState({
                                                                classesId:0,
                                                                classSubjectId:0})

    useEffect(() =>{
        fetchClasses();
        fetchAllTeachers()
     },[]) 

     useEffect(() =>{
        fetchAllTeachers()
     },[teacherFilterData]) 

     useEffect(() =>{
        fetchClassSubjects();
     },[classId])    

     
    const fetchClasses = async () =>{
        await CommonDataService.allClasses() 
          .then(response => {
              setAllClasses(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
       }

       const fetchClassSubjects = async () =>{
           setClassSubjects([])
        await ClassSubjectDataService.getUserSubjects(classId,"") 
          .then(response => {
              setClassSubjects(response.data.result)
         })
         .catch(error => {
             const errorMsg=error.message;            
         }) 
       }

       const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }


    const fetchAllTeachers = async () =>{
        setTeachers([])
        await TeacherAssociationDataService.getTeachers(teacherFilterData) 
        .then(response => {
            setTeachers(response.data.result)
        })
        .catch(error => {
            const errorMsg=error.message;            
        }) 
    }

    const changeClass = async (value) =>{
        setClassSubjects([])
        setTeachers([])
        setSubjectId("")
        setTeacherId("")
        setClassId(value)      
        setTeacherFilterData({classesId:(value=="")?0:parseInt(value),
                                 classSubjectId:0})
    }

    const changeSubject = async (value) =>{
        setSubjectId(value) 
        setTeachers([])  
        setTeacherId("")  
        setTeacherFilterData({classesId:(classId=="")?0:parseInt(classId),
            classSubjectId:(value=="")?0:parseInt(value)}) 
    }

    useEffect(() => {
        setParametes()
    }, [isfilterModified,classId,subjectId,teacherId])
    
    useEffect(() =>{
        setLoading(true)
        fetchClassNote();
    },[params]) 

    const setParametes = () => {
        setParams({
            classNoteName: searchTopic,
            classNoteDetail: "",
            classList:classId.toString(),
            subjectList:subjectId.toString(),
            teacherList:teacherId.toString()
        })
    }

    const fetchClassNote = async () =>{
        setLoading(true)
        await ClassNoteDataService.findClassNote(params) 
        .then(response => {
           setClassNotes(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false)
      })
       .catch(error => {
        setLoading(false)            
      })
    }


  return (
    <div className="container">
    <div className="jumbotron">
        <div className="card card-primary">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Class Note</h5>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                <div className="col-md-3">
            <div className="card card-outline card-primary">
                <div className="text-center">
                    <h5 style={{marginTop:"10px"}}>Filtering Options</h5>
                </div>
                <hr/>  
                <div className="col-md-12">
                    <div className="input-group">
                        <div className="input-group input-group-sm">
                            <input onChange={event => setSearchTopic(event.target.value)} value={searchTopic} name="searchTopic" id="searchTopic" className="form-control form-control-navbar" type="text" placeholder="Topic..." aria-label="Topic.." />
                            <div className="input-group-append">
                                <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <br />   
                <div className="col-md-12">
                    <div className="form-group">
                        <label>Class</label>
                        <select onChange={event => changeClass(event.target.value)} value={classId} name="classId" className="form-control">
                            <option value="">-- select class --</option>
                            {allClasses.map(item => (
                                <option 
                                key={item.classesId} 
                                value={item.classesId}
                                >                                                
                                    {item.classesName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>  

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Class Subject</label>
                        <select onChange={event => changeSubject(event.target.value)} value={subjectId} name="subjectId" className="form-control">
                            <option value="">-- select subject --</option>
                            {classSubjects.map(item => (
                                <option 
                                key={item.classSubjectId} 
                                value={item.classSubjectId}
                                >                                                
                                    {item.subjectName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>  

                <div className="col-md-12">
                    <div className="form-group">
                        <label>Teacher</label>
                        <select onChange={event => setTeacherId(event.target.value)} value={teacherId} name="teacherId" className="form-control">
                            <option value="">-- select teacher --</option>
                            {teachers.map(item => (
                                <option 
                                key={item.teacherId} 
                                value={item.teacherId}
                                >                                                
                                    {item.teacherName}
                                </option>
                            ))}
                        </select>
                    </div>
                </div> 
                  
            </div>
            
        </div>
                    <div className="col-md-9">
                        {loading?<Loader/>:
                        <>
                        {classNotes.length>0?
                            <>
                                <div className="row">
                                    <div className="col-md-12">
                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                    </div>
                                </div>
                                {classNotes.map((item, index)=>(                                    
                                            <div className="card card-outline card-primary" key={index}>
                                                <div className="card-header" id="headingOne">
                                                    <i className="d-flex justify-content-between">
                                                    <p className="text-muted text-xs">Subject: {item.classSubjectName}</p>
                                                    <p className="text-muted text-xs">Posted By: {item.teacherName}</p>
                                                    <p className="text-muted text-xs">{item.publishDate}</p>
                                                            </i>
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                            <div className="d-flex justify-content-between">
                                                                <h5>{item.classNoteName}</h5>
                                                            </div>
                                                            <p className="text-justify">
                                                                {item.classNoteDetail}
                                                            </p>
                                                        </div>
                                                        {(item.classNoteImages !=null || item.classNoteFiles !=null)?
                                                            <div className="col-md-12 d-flex justify-content-start">
                                                                {item.classNoteFiles.map((file,fileindex)=>(
                                                                    <>
                                                                        <i className="fa fa-paperclip text-info" key={fileindex}></i> 
                                                                        <a href={file.documentUrl}  target="_blank">{file.documentName}</a> &nbsp;&nbsp;
                                                                    </>
                                                                ))}
                                                                {item.classNoteImages.map((image, imageindex)=>(
                                                                    <>
                                                                        <i className="fa fa-paperclip text-info" key={imageindex}></i> 
                                                                        <a href={image.imageUrl} target="_blank">{image.displayName}</a> &nbsp;&nbsp;
                                                                    </>
                                                                ))}
                                                            </div>
                                                        :<></>}
                                                        
                                                        <div className="col-md-12 d-flex justify-content-start mt-3" >
                                                            {item.classNoteLinks.map((links, linkindex)=>(
                                                                <>
                                                                    <i className="fa fa-link text-info" key={linkindex}></i> 
                                                                    <a href={links.linkUrl} target="_blank" style={{borderRight:"2px solid #ddd",paddingRight:"5px"}}>{links.linkName}</a> &nbsp;&nbsp;
                                                                </>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ))}
                                <div className="row">
                                    <div className="col-md-12">
                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                    </div>
                                </div>
                            </>
                         :
                            <div className="alert alert-info alert-dismissible">
                                <div className="text-center text-white"><h4>No Records Found</h4></div>
                            </div>
                        }
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default AdminClassNote

