import  FormValidator  from '../../../helpers/form-validator'

const AdminScheduleRule = () =>  new FormValidator([
    { 
      field: 'classSectionId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Section is Required'
    },
    { 
      field: 'subjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'teacherId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Teacher is Required'
    },
    { 
      field: 'scheduleToTime', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'To Time is Required'
    },
    { 
      field: 'scheduleDate', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Date is Required'
    },
    { 
      field: 'scheduleFromTime', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'From Time is Required'
    }
  ])

  const StaffScheduleRule = () =>  new FormValidator([
    { 
      field: 'classSectionId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Section is Required'
    },
    { 
      field: 'subjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'scheduleToTime', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'To Time is Required'
    },
    { 
      field: 'scheduleDate', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Date is Required'
    },
    { 
      field: 'scheduleFromTime', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'From Time is Required'
    }
  ])

  export default {AdminScheduleRule,
                StaffScheduleRule}
