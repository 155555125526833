import  FormValidator  from '../../../helpers/form-validator'

export default new FormValidator([
    { 
      field: 'difficultyLevel', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Diffiulty Level is Required'
    },
    { 
      field: 'displayOrder', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Pleave provide Display Order'
    }
  ])

  