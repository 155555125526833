import React, { useState, useEffect } from 'react'
import Loader from '../../ui/loader/Loader';
import ExaminationDataService from '../../../services/ExaminationDataService';
import MasterResultReport from './report/MasterResultReport';


const MasterResultView = (props) => {
    const [masterResult, setMasterResult] = useState([])
    const [loading,setLoading] = useState(false);
    const [reportData, setReportdata] = useState([])
    const [reportTitle, setReportTitle] = useState("")
    const [reportSubTitle, setReportSubTitle] = useState("")
    const [isReporeView, setisReportView] = useState(false)

    useEffect(() => {
        fetchResultMasterView()
    }, [])  

    const fetchResultMasterView= async ()=>{
        setLoading(true)
        await ExaminationDataService.resultMasterView(props.match.params.id)
        .then(response => {  
        setLoading(false)   
        setMasterResult(response.data.result)
        setReportdata(response.data.result)
        setReportTitle("Report of "+props.match.params.examName)
        setReportSubTitle("")
        })
        .catch(error => { 
        let msgText=""
        setLoading(false)      
        
        })

    }

  return (
      <>
      {loading?<Loader/>:
    <div className="container">
    <div className="jumbotron">
    <div className="card card-primary">
        <div className="card-header">
        <div className="row">
            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
      <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}> {"Result of "+props.match.params.examName}</h5>
            </div>
            <br />
            <br />
        </div>
        </div>
        <div className="card-body">   
        {isReporeView?
            <MasterResultReport 
            reportData={reportData} 
            reportTitle={reportTitle} 
            reportSubTitle={reportSubTitle}/>  
            :
            <>
            <div className="col-md-1 float-right">
                <button className="btn btn-primary btn-block" onClick={()=>setisReportView(true)}>Print</button>
            </div>
            <br/>
            <br/> 
                <table className="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Student Name</th>
                        <th scope="col">Scored Mark</th>
                        <th scope="col">Maximum Mark</th>
                        <th scope="col">Percentage</th>
                        {/* <th className="text-right">Actions</th> */}
                    </tr>
                    </thead>
                    <tbody>
                        {masterResult.map((item, index)=>(

                            <tr key={index}>
                            <th scope="row">{index+1}</th>
                            <td>{item.studentName}</td>
                            <td>{item.markScored}</td>
                            <td>{item.maxMarks}</td>
                            <td>{item.markPercent+"%"}</td>
                            {/* <td className="text-right">
                                <button  onClick={()=>valuateStudent(item.examStudentId)} className="btn btn-info"> View</button>
                                <button  className="btn btn-info"> View</button>
                            </td> */}
                            </tr>

                        ))}
                    </tbody>
                </table>
             </>
        }
        </div>
        {isReporeView &&
        <div className="card-footer">
            <div className="text-right">
                <button className="btn btn-primary" onClick={()=>window.location.reload(false)}>Back</button>
            </div>
        </div>
        }
    </div>
    </div>
</div>
}
</>
  )
}

export default MasterResultView
