import  FormValidator  from '../../../helpers/form-validator'

const OneWordForm = () =>  new FormValidator([
    { 
      field: 'classSubjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'question', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Question is Required'
    },
    { 
      field: 'oneWordAns', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Answer is Required'
    },
    { 
      field: 'difficultyLevelId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Difficulty Level is Required'
    }
  ])

  const ShorAnswerForm = () =>  new FormValidator([
    { 
      field: 'classSubjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'question', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Question is Required'
    },
    { 
      field: 'shortAnswer', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Answer is Required'
    },
    { 
      field: 'difficultyLevelId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Difficulty Level is Required'
    }
  ])

  const MultipleChoiceForm = () =>  new FormValidator([
    { 
      field: 'classSubjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'question', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Question is Required'
    },
    { 
      field: 'difficultyLevelId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Difficulty Level is Required'
    }
  ])

  export default {OneWordForm,
                ShorAnswerForm,
                MultipleChoiceForm}

  