import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    page: {
      flexDirection: 'row'
    },
    pageSize:{   
    },
   
      title:{
          fontSize:"14",
         textAlign:"center"
        },
        subtitle:{
            fontSize:"10",
            textAlign:"center",
            marginBottom:"10",
            marginTop:"2"
        },
        image: {
          marginVertical: 15,
          marginHorizontal: 10,
          textAlign:"center"
        }


     });

    
  

const DetailedPdfReport = (props) => {
  const [loading, setLoading] = useState([])
  
  return (
    <PDFViewer width="100%" height="450px">
    <>
        <Document>
          <Page size="A4" wrap={true} style={{padding:20}}>
              <View style={styles.pageSize}>
                  <Image                          
                      style={styles.image}
                      src="/assets/dist/img/reportHeader.jpg"
                  fixed/>
                  <View style={styles.title}>
                    <Text>{props.reportTitle}</Text>
                </View>
                <View style={styles.subtitle}>
                    <Text>{props.reportSubTitle}</Text>
                </View>
                {props.reportData.map((item, index) => (
                              <>                             
                                <View key={index} style={{marginBottom:10,paddingHorizontal:10,paddingVertical:0,
                                border:"3",borderTopColor:"#007bff",
                                borderLeftColor:"#F1F1F1", borderRightColor:"#F1F1F1",
                                borderBottomColor:"#F1F1F1",height:"auto",
                                 width:"100%", borderRadius:"5",fontSize:"12"}}>
                                   <View style={{flexDirection:"row", justifyContent:"space-between"}}>                                     
                                      <Text style={{padding:"5"}}>{"Date: "+item.scheduleDate}</Text>
                                      <Text style={{padding:"5"}}>{"Time: " + item.fromTime+ " - " + item.toTime}</Text>
                                   </View>
                                   <View style={{flexDirection:"row", justifyContent:"space-between"}}>
                                    <Text style={{padding:"5"}}>{"Subject: "+item.classSubjectName}</Text>
                                    <Text style={{padding:"5"}}>{"Teacher: "+item.teacherName}</Text>
                                   </View>
                                   <View style={{flexDirection:"row", justifyContent:"space-between"}}>
                                      <Text style={{padding:"5"}}>{"Presents - " + item.presentStudents}</Text>
                                      <Text style={{padding:"5"}}>{"Absents - " + item.absentStudents}</Text>
                                      <Text style={{padding:"5"}}>{"Total - " + item.totalStudents}</Text>
                                   </View>
                                  
                                <Text style={{borderTop:"1",borderTopColor:"#E8E8E8",marginTop:"10"}}></Text>
                                  <View style={styles.page}>
                                      <View style={{width:"5%", border:1,borderColor:"black",padding:7,alignContent:"center"}}>
                                          <Text>Sl</Text>
                                      </View>
                                      <View style={{width:"85%", border:1,borderColor:"black",padding:7,alignContent:"center"}}>
                                          <Text>Name</Text>
                                      </View>
                                      <View style={{width:"10%", border:1,borderColor:"black",padding:7,alignContent:"center"}}>
                                          <Text>Status</Text>
                                      </View>
                                  </View>
                                   
                                  {item.studentAttendances.map((att, index1)=>(
                                       <View style={styles.page} key={index1}>
                                          <View style={{width:"5%", border:1,borderColor:"black",padding:7}}>
                                              <Text>{index1+1}</Text>
                                          </View>
                                          <View style={{width:"85%", border:1,borderColor:"black",padding:7}}>
                                              <Text>{att.studentName}</Text>
                                          </View>
                                          <View style={{width:"10%", border:1,borderColor:"black",padding:7,textAlign:"center", alignContent:"center"}}>
                                          {att.isPresent?
                                              <Text style={{color:"green"}}>
                                                  P
                                              </Text>
                                            :
                                              <Text style={{color:"red"}}>
                                                  A
                                              </Text>
                                            }
                                          </View>
                                      </View>
                                  ))}
                                </View>
                               </>
                            ))}
            </View>
          </Page>
        </Document>
    </>
</PDFViewer>

    // <PDFDownloadLink document={<PDFdemo />} fileName="somename.pdf">
    //   {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
    // </PDFDownloadLink>\
  )
}

export default DetailedPdfReport
