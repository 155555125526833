import React, { useState, useEffect } from 'react'
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import QuestionBankDataService from "../../../services/QuestionBankDataService"
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import DifficultyLevelDataService from "../../../services/DifficultyLevelDataService"
import CourseItemDataService from "../../../services/CourseItemDataService"
import ValidationRule from './ValidationRule'
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import Loader from '../../ui/loader/Loader';
import Select from 'react-select';

let choices = []
let options = []
let cousetItemIds = ""
const MultipleChoiceQuestion = () => {
    const [question, setQuestion] = useState("")
    const [classesId, setClassesId] = useState(0)
    const [classSubjectId, setClassSubjectId] = useState("")
    const [classes, setClasses] = useState([])
    const [allClassSubject, setAllClassSubject] = useState([]);
    const [submitted, setSubmitted] = useState(false);
    const [choice, setChoice] = useState("")
    const [multiChoices, setMultiChoices] = useState([])  
    const [difficultyLevelId, setDifficultyLevelId] = useState("");
    const [difficultyLevels, setDifficultyLevels] = useState([]);
    const [choiceValidationText, setChoiceValidationText] = useState("")
    const [validateOptionText, setValidateOptionText] = useState("")
    const [isAddChoice, setIsAddChoice] = useState(false)
    const [isChoiceValid, setIsChoiceValid] = useState(false)
    const [loading, setLoading] = useState(false); 
    const [selectedCourseItems, setSelectedCourseItems] = useState([])

    const validatorMultipleChoice = ValidationRule.MultipleChoiceForm();
    const [validationMultipleChoice, setValidationMultipleChoice] = useState(validatorMultipleChoice.valid());
    const [validateSubmittedMultipleChoice, setValidateSubmittedMultipleChoice] = useState(false)

    var multipleChoiceValidationData = {
        classSubjectId: classSubjectId,
        question: question,
        difficultyLevelId:difficultyLevelId,
        validation: validationMultipleChoice
    }


    useEffect(() => {
        fetchDifficultyLevel()
        fetchTeacherCurrentClasses()
    }, [submitted])

    useEffect(() => {
        fetchTeacherCurrentSubjects()
    }, [classesId])

    const fetchTeacherCurrentClasses = async () => {
        await TeacherAssociationDataService.getTeacherCurrentClasses()
            .then(response => {
                setClasses(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchTeacherCurrentSubjects = async () => {
        await TeacherAssociationDataService.getTeacherCurrentSubjects(parseInt(classesId))
            .then(response => {
                setAllClassSubject(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchDifficultyLevel= async () =>{        
        setLoading(true);
        await DifficultyLevelDataService.allDifficultyLevel() 
        .then(response => {
            setDifficultyLevels(response.data.result)
            setLoading(false)
       })
       .catch(error => {
           setLoading(false)       
       })
    }

    useEffect(() => {
        fetchCourseItem()
    }, [classSubjectId])

    const fetchCourseItem= async () =>{ 
        await CourseItemDataService.getComboViewCourseitem(parseInt(classSubjectId),0)
        .then(response => {
            response.data.result.map((item, index)=>(
                options.push({value:item.comboValue,label:item.comboText})
            ));
       })
       .catch(error => {
           setLoading(false)       
       })
    }

    useEffect(() => {
        setChoice("")
        setMultiChoices(choices)
    }, [isAddChoice])

    const addChoices = () => {
        if (choice == "") {
            setValidateOptionText("Please type a choice")
        }
        else {
            setValidateOptionText("");
            setChoiceValidationText("")
            choices.push({ answerOptionId: 0, answerText: choice, isCorrectAnswer: false })
            setIsAddChoice(!isAddChoice)
        }
    }

    const addAnswer = () => {
        if (choice == "") {
            setValidateOptionText("Please type a choice")
        }
        else {
            setValidateOptionText("");
            choices.map((chice, index) => (
                choices[index].isCorrectAnswer = false
            ));
            setChoiceValidationText("")
            choices.push({ answerOptionId: 0, answerText: choice, isCorrectAnswer: true })
            setIsAddChoice(!isAddChoice)
        }
    }

    const removeChoice = (index) => {
        choices.splice(index, 1)
        setIsAddChoice(!isAddChoice)
    }

    const submitMultiChoice = async () => {
        cousetItemIds=""
        if(selectedCourseItems !==null)
        {
            selectedCourseItems.map((item, index)=>(
                (cousetItemIds==="")?
                    cousetItemIds =item.value:
                    cousetItemIds =cousetItemIds+"," + item.value  
            ))
        }
        var valid = validateChoice()
        const validate = validatorMultipleChoice.validate(multipleChoiceValidationData)
        setValidationMultipleChoice({ validate });
        setValidateSubmittedMultipleChoice(true)
        if (validate.isValid) {
            if (parseInt(valid) == 1) {
                var data = {
                    questionId: 0,
                    classSubjectId: parseInt(classSubjectId),
                    questionText: question,
                    answerOptions: multiChoices,
                    difficultyLevelId:parseInt(difficultyLevelId),
                    courseItemList:cousetItemIds.toString()
                }
                setLoading(true)
                await QuestionBankDataService.createMultipleChocie(data)
                    .then(response => {
                        setLoading(false)
                        LearnToast(success, "Question Added Successfully")
                    })
                    .catch(error => {
                        let errorMsg=[]
                        let msgText=""
                        setLoading(false)      
                        if(error.response.status==500)
                        {
                            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                        }
                        else
                        {
                            errorMsg=error.response.data.errors
                            errorMsg.map((item,index)=>{
                                if(msgText.length>0)
                                {
                                msgText = msgText+","
                                }
                                msgText=msgText+item
                            })            
                                LearnToast(danger,msgText)
                        
                        }            
                    })
                setSubmitted(!submitted)
                resetForm()
            }
        }

    }

    const validateChoice = () => {
        setIsChoiceValid(true)
        setChoiceValidationText("")
        let valid = 0
        if (multiChoices.length > 0) {
            multiChoices.map((item, index) => (
                item.isCorrectAnswer ? (valid = valid + 1) : (valid = valid + 0)
            ));
            if (parseInt(valid) == 0) {
                setIsChoiceValid(false)
                setChoiceValidationText("Option does not contains right answer")
            }
        }
        else {
            setIsChoiceValid(false)
            setChoiceValidationText("Please Add Answer Choices")
        }
        return valid
    }

    const resetForm = () => {
        choices = []
        setMultiChoices([])
        setDifficultyLevelId("")
        fetchDifficultyLevel()
        setClassesId("")
        setClasses([])
        setClassSubjectId("")
        setAllClassSubject([])
        setQuestion("")
        setSelectedCourseItems([])
        options=[]
        setValidateOptionText("")
        setValidationMultipleChoice(validatorMultipleChoice.valid())
        setValidateSubmittedMultipleChoice(false)
        setSubmitted(!submitted)
    }
    let validateOutputMultipleChoice = validateSubmittedMultipleChoice ? validatorMultipleChoice.validate(multipleChoiceValidationData) : multipleChoiceValidationData.validation

    return (
        <>
            <ToastContainer />
            {loading ? <Loader /> :
                <>
                    <div className="container">
                        <div className="jumbotron">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Multiple Choice Question </h5>
                                        </div>
                                        <div className="col-md-6 text-right">

                                        </div>
                                        <br />
                                        <br />
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6 offset-md-3 card">
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Class</label>
                                                    <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className="form-control">
                                                        <option value="">-- select class --</option>
                                                        {classes.map(item => (
                                                            <option
                                                                key={item.classesId}
                                                                value={item.classesId}
                                                            >
                                                                {item.classesName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>
                                                <div className="col">
                                                    <label>Subject</label>
                                                    <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className="form-control">
                                                        <option value="">-- select subject --</option>
                                                        {allClassSubject.map(item => (
                                                            <option
                                                                key={item.classSubjectId}
                                                                value={item.classSubjectId}
                                                            >
                                                                {item.subjectName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger">{validateOutputMultipleChoice.classSubjectId.message}</span>
                                                </div>
                                            </div>
                                            <div className="form-row">                                               
                                                <div className="col">
                                                    <label>Difficulty Levels</label>
                                                    <select onChange={event => setDifficultyLevelId(event.target.value)} value={difficultyLevelId} name="difficultyLevelId" className="form-control">
                                                        <option value="">-- select level --</option>
                                                        {difficultyLevels.map(item => (
                                                            <option
                                                                key={item.difficultyLevelId}
                                                                value={item.difficultyLevelId}
                                                            >
                                                                {item.difficultyLevelName}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <span className="text-danger">{validateOutputMultipleChoice.difficultyLevelId.message}</span>
                                                </div>
                                            </div>
                                            <div className="form-row"> 
                                                <div className="col">
                                                    <label>Select Course Item</label>
                                                    <Select
                                                        value={selectedCourseItems}
                                                        isMulti
                                                        onChange={selectedCourseItems => setSelectedCourseItems(selectedCourseItems)}
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Question?</label>
                                                    <CKEditor
                                                        onInit={editor => {
                                                            console.log('Editor is ready to use!', editor);

                                                            // Insert the toolbar before the editable area.
                                                            editor.ui.getEditableElement().parentElement.insertBefore(
                                                                editor.ui.view.toolbar.element,
                                                                editor.ui.getEditableElement()
                                                            );
                                                        }}
                                                        onChange={(event, editor) => {
                                                            const data = editor.getData();
                                                            console.log({ event, editor, data });
                                                            setQuestion(data);
                                                        }}
                                                        editor={DecoupledEditor}
                                                        data={question}
                                                        config={{
                                                            ckfinder: { uploadUrl: "https://cla.maclms.org/api/fileupload/editorupload" }
                                                        }}
                                                    />
                                                    {/* <input type="text" onChange={event => setQuestion(event.target.value)} value={question} name="question" id="question" className="form-control"/> */}
                                                    <span className="text-danger">{validateOutputMultipleChoice.question.message}</span>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Option</label>
                                                    <div className="input-group">
                                                        <input type="text" style={{ height: "2.16rem", borderRadius: "0", marginTop: ".03rem" }} onChange={event => setChoice(event.target.value)} value={choice} name="choice" id="choice" className="form-control" placeholder="Type Choice" />
                                                        <span className="input-group-append">
                                                            <ButtonGroup>
                                                                <DropdownButton variant="primary btn-flat" style={{ color: "black" }} title="Add" id="bg-nested-dropdown">
                                                                    <Dropdown.Item onClick={addChoices} style={{ color: "black" }}>Choice</Dropdown.Item>
                                                                    <Dropdown.Item onClick={addAnswer} style={{ color: "black" }}>Right Choice</Dropdown.Item>
                                                                </DropdownButton>
                                                            </ButtonGroup>
                                                            {/* <button type="button" onClick={addChoices} className="btn btn-primary">Add</button> */}
                                                        </span>
                                                    </div>
                                                    <span className="text-danger">{validateOptionText}</span>
                                                    <span className="text-danger">{choiceValidationText}</span>
                                                </div>
                                            </div>
                                            <div className="form-row">
                                                <div className="col">
                                                    <label>Answer Choices</label>
                                                    <ul class="list-group">
                                                        {multiChoices.map((item, index) => (
                                                            <li key={index} class="list-group-item" style={{ padding: "0 .5rem" }}>
                                                                <div className="d-flex justify-content-between">
                                                                    <div className={item.isCorrectAnswer ? ("text-success") : ("")}>
                                                                        <h6 style={{ lineHeight: "2" }}>{item.answerText}</h6>
                                                                    </div>
                                                                    <div>
                                                                        <button type="button" onClick={() => removeChoice(index)} class="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                                            <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="form-group text-right">
                                                {/* <button onClick={closeWindow} className="btn btn-light">close</button>&nbsp;&nbsp;&nbsp; */}
                                                <button onClick={submitMultiChoice} className="btn btn-primary">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default MultipleChoiceQuestion
