import React, { Component } from 'react'

class AddMessage extends Component {
    constructor() {
        super();

        this.state = {
            newMessage: ""
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(e) {
        this.setState({
            newMessage: e.target.value
        });
    }

    handleSubmit(e) {
        e.preventDefault();
        console.log("connection add", this.props.connection)
        console.log(this.props.roomId, this.props.userName, this.state.newMessage)
        this.props.connection.invoke("SendMessage", parseInt(this.props.roomId), this.props.userName, this.state.newMessage)
            .catch(err => console.error(err.toString()));

        this.setState({
            newMessage: ""
        });
    }

    render() {
        return (
            <form onSubmit={this.handleSubmit}>
                <div className="input-group">
                    <input
                        type="text"
                        onChange={this.handleChange}
                        value={this.state.newMessage}
                        placeholder="Type Message ..."
                        className="form-control" />
                    <span className="input-group-append">
                        <button type="button" onClick={this.handleSubmit} className="btn btn-primary">Send</button>
                    </span>
                </div>
            </form>
        );
    }
}


export default AddMessage
