import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import TeacherDataSevice from '../../../services/TeacherDataSevice';
import Loader from '../../ui/loader/Loader';
import LearnPagination from '../../ui/pagination/LearnPagination';

const Teacher = (props) => {

    const [allTeacher, setAllTeacher] = useState([]);
    const [loading,setLoading] = useState(false);
    const [isfilterModified,setisFilterModified]=useState(false)
    const [searchName, setSearchName] = useState('')
    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        teacherName:searchName
    })
    

    useEffect(() => {
        setParametes()
    }, [isfilterModified])

    useEffect(() => {
        fetchTeachers();
    }, [params])

    const setParametes = () => {
        setParams({
            teacherName:searchName
        })
    }


   const fetchTeachers = async () =>{
    setLoading(true);
      await TeacherDataSevice.findTeacher(params) 
        .then(response => {
           setAllTeacher(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false);
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

   

    const serachByName=()=>{
        setisFilterModified(!isfilterModified)
      }

  
    
    return (
        <>          
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Teacher</h5>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                        {loading?(<Loader/>):
                            <div className="row">
                                <br/>
                              
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12 d-flex justify-content-between">
                                                <div className="col-md-3">
                                                    <div className="input-group">
                                                        <div className="input-group input-group-sm">
                                                            <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control form-control-navbar" type="text" placeholder="Type Name" aria-label="Search"/>
                                                            <div className="input-group-append">
                                                                <button onClick={()=>serachByName()} className="btn btn-primary" type="submit">
                                                                    <i className="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-9">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                        </div>
                                    </div>
                                    {allTeacher.length>0?
                                    <>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Gender</th>
                                                <th scope="col">Mobile</th>
                                                <th scope="col">Email</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {(allTeacher.map((teacher, index) => (
                                                <tr key={teacher.teacherId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{teacher.fullName}</td>
                                                    <td>{teacher.gender}</td>
                                                    <td>{teacher.mobile}</td>
                                                    <td>{teacher.eMail}</td>
                                                    <td className="text-right">
                                                        <div className="d-flex justify-content-end">
                                                            <Link className="btn btn-link" to={{pathname: '/app/staff-profile-view/'+teacher.teacherId,props:{ id: teacher.teacherId}}} data-toggle="tooltip" data-placement="top" title="Profile"><i className="fa fa-user text-primary"></i></Link>&nbsp;&nbsp;&nbsp;                                                       
                                                        </div>
                                                    </td>
                                                </tr>
                                            )))}
                                            
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                    </>
                                     :
                                     <>
                                        <br/>
                                        <div className="alert alert-info alert-dismissible">
                                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                                        </div>
                                     </>
                                 }      
                                </div>    
                                             
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Teacher;