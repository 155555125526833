import  FormValidator  from '../../../../helpers/form-validator'
  
  const OneWordAnswerRule = () =>  new FormValidator([
    { 
      field: 'oneWordAnswer', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Answer is Required'
    }
   
  ])

  const ShortAnswerRule = () =>  new FormValidator([
    { 
      field: 'shortAnswer', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Answer is Required'
    }
   
  ])


  export default {OneWordAnswerRule,
                 ShortAnswerRule}

  