import React from 'react'
import parse from 'html-react-parser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../../ui/alert/toast/ToastType"
import {LearnToast} from "../../../ui/alert/toast/LearnToast"


const LoadQuestion = (props) => {
  return (
    <>
    {props.questionPaperDetails.map((item,index)=>(
        <div className="d-flex justify-content-between mt-1">
        <div className="">
                {index+1}. &nbsp;
        </div>
        <div className="flex-grow-1" style={{fontSize:"18px"}}>
            <p style={{ textAlign: "justify", marginBottom: "3px" }}>{parse(""+ item.questionText)}</p>
        </div>
    </div>
    ))}
    </>
  )
}

export default LoadQuestion
