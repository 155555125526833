import React, { useState, useEffect } from 'react'
import ExaminationStudentDataService from '../../../services/ExaminationStudentDataService';
import ValuationSheet from './ValuationSheet';

const ExamStudents = (props) => {
    const [evalStudents, setEvalStudents]= useState([])
    const [isResultView, setIsResultView] = useState(false)
    const [examStudentId, setExamStudentId] = useState([])


    

    useEffect(() => {
        fetchEvalStudents()
    }, [])

    const fetchEvalStudents = async () => {
        await ExaminationStudentDataService.getEvaluationStudents(props.match.params.id)
            .then(response => { 
                setEvalStudents(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const valuateStudent = async(id)=>{       
        setExamStudentId(id)
        setIsResultView(true)
    }
  return (
      <>
    {isResultView?
        <ValuationSheet examStudentId={examStudentId}/>
    :

    <div className="container">
        <div className="jumbotron">
        <div className="card card-primary">
            <div className="card-header">
            <div className="row">
                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>{props.match.params.examName} </h5>
                </div>
                <br />
                <br />
            </div>
            </div>
            <div className="card-body">    
            <table className="table table-bordered table-hover">
                <thead>
                <tr>
                    <th scope="col">#</th>
                    <th scope="col">Student Name</th>
                    <th className="text-right">Actions</th>
                </tr>
                </thead>
                <tbody>
                    {evalStudents.map((item, index)=>(

                        <tr key={index}>
                        <th scope="row">{index+1}</th>
                        <td>{item.studentName}</td>
                        <td className="text-right">
                            <button  onClick={()=>valuateStudent(item.examStudentId)} className="btn btn-info"> View</button>
                        </td>
                        </tr>

                    ))}
                </tbody>
            </table>

            </div>
        </div>
        </div>
    </div>
    }
  </>
  )
}

export default ExamStudents
