import FormValidator from '../../../helpers/form-validator'

const ChangePasswordForm = (passwordMatch) => new FormValidator([
    {
        field: 'currentPassword',
        method: 'isEmpty',
        validWhen: false,
        message: 'Current Password is Required'
    },
    {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password is Required'
    },
    {
        field: 'confirmpassword',
        method: 'isEmpty',
        validWhen: false,
        message: 'Confirm Password is Required'
    },
    {
        field: 'confirmpassword',
        method: passwordMatch,
        validWhen: true,
        message: 'password mismatch'
    },
])

const ResetPasswordForm = (passwordMatch) => new FormValidator([
    {
        field: 'email',
        method: 'isEmpty',
        validWhen: false,
        message: 'Email is Required'
    },
    {
        field: 'email',
        method: 'isEmail',
        validWhen: true,
        message: 'That is not a valid email.'
    },
    {
        field: 'password',
        method: 'isEmpty',
        validWhen: false,
        message: 'Password is Required'
    },
    {
        field: 'confirmpassword',
        method: 'isEmpty',
        validWhen: false,
        message: 'Confirm Password is Required'
    },
    {
        field: 'confirmpassword',
        method: passwordMatch,
        validWhen: true,
        message: 'password mismatch'
    },
])

export default {
    ChangePasswordForm,
    ResetPasswordForm
};