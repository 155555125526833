import React, { useState, useEffect } from 'react';
import CommonDataService from '../../../services/CommonDataService';
import Loader from '../../ui/loader/Loader';
import StudentDataService from "../../../services/StudentDataService"
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import StudentEmailDataService from "../../../services/StudentEmailDataService"
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ckeditor from '@ckeditor/ckeditor5-react';
import './emailStyle.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import ValidationRule from './ValidationRule'


let studentIds = ""
const SendStudentNotificationTeacher = () => {
  const validator= ValidationRule;
  const [genders, setGenders] = useState([]);
  const [classSection, setClassSection] = useState([]);
  const [allStudent, setAllStudent] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("")
  const [searchName, setSearchName] = useState("")
  const [searchMobile, setSearchMobile] = useState("")
  const [searchEmail, setSearchEmail] = useState("")
  const [searchGenderId, setSearchGenderId] = useState("")
  const [filterclassSectionId, setFilterClassSectionId] = useState(0)
  const [selectedOption, setSelectedOption] = useState("All")
  const [isAllStudent, setIsAllStudent] = useState(true)
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [subject, setSubject] = useState("")
  const [validation, setValidation] = useState(validator.valid());
  const [validateSubmitted, setValidateSubmitted]=useState(false)    

  var validationData={
      subject:subject,
      message:message,
      validation:validation,
  }


  useEffect(() => {
    fetchClassSection();
    fetchallGenders();
  }, [])

  useEffect(() => {
    if (selectedOption == "All") {
      setIsAllStudent(true)
    }
    else {
      setIsAllStudent(false)
      fetchStudent()
    }
  }, [selectedOption])



  const fetchStudent = async () => {
    let students = []
    let data = {
      studentName: searchName,
      mobile: searchMobile,
      email: searchEmail,
      genderList: searchGenderId.toString(),
      classSectionList: filterclassSectionId.toString()
    }
    setLoading(true);
    await StudentDataService.findStudentWithoutPagination(data)
      .then(response => {
        response.data.result.map((item, index) => (
          students.push({ id: item.studentId, value: item.studentName, name: "stu" + item.studentId, section: item.currentClassSection, isChecked: false })
        ));
        setAllStudent(students)
        setLoading(false);
      })
      .catch(error => {
        const errorMsg = error.message;
      })
  }


  const checkStudent = (e) => {
    let itemName = e.target.name;
    let checked = e.target.checked;
    let ischecked = isAllChecked
    let studentList = allStudent
    studentIds = ""
    if (itemName === "allCheck") {
      ischecked = checked;
      studentList = studentList.map(item => ({ ...item, isChecked: checked })
      );
    } else {
      studentList = studentList.map(item =>
        item.name === itemName ? { ...item, isChecked: checked } : item
      );
      ischecked = studentList.every(item => item.isChecked)
    }

    studentList.forEach(item => {
      if (item.isChecked) {
        if (studentIds.length > 0 || studentIds !== "") {
          studentIds += ","
        }
        studentIds += item.id
      }
    })
    setAllStudent(studentList)
    setIsAllChecked(ischecked)
  }




  const fetchallGenders = async () => {
    await CommonDataService.allGender()
      .then(response => {
        setGenders(response.data.result)
      })
      .catch(error => {
        const errorMsg = error.message;
      })
  }

  const fetchClassSection = async () => {
    await TeacherAssociationDataService.getTeacherCurrentSections()
      .then(response => {
        setClassSection(response.data.result)
      })
      .catch(error => {
        const errorMsg = error.message;
      })
  }



  const searchStudent = () => {
    fetchStudent()
  }

  const sendEmail = async () => {
    let data = {
      sendToIdList: studentIds,
      mailSubject: subject,
      mailBody: message,
      isSendToAll: isAllStudent
    }
    const validate = validator.validate(validationData)
    setValidation({ validate });
    setValidateSubmitted(true)

    if (validate.isValid) {
    if(isAllStudent || (!isAllStudent && studentIds.length>0))
    {
    setLoading(true)
    await StudentEmailDataService.sendStudentEmail(data)
      .then(response => {
        console.log(response.data.result)
        resetForm()
        setLoading(false)
        LearnToast(success, "Message Sent")
      })
      .catch(error => {
        let errorMsg=[]
        let msgText=""
        setLoading(false)      
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }            
      })
    }
    else
    { 
        LearnToast(warning, "select at least one recipient")
    }
}
  }

  const resetForm = () => {
    studentIds = ""
    setSubject("")
    setAllStudent([])
    setSearchMobile("")
    setFilterClassSectionId("")
    setSearchGenderId("")
    setSearchName("")
    setSearchEmail("")
    setIsAllChecked(false)
    setMessage("")
    ckeditor.data = ""
    setValidation(validator.valid());
    setValidateSubmitted(false);
  }

  let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 




  return (
    <>
      <ToastContainer />
      <div className="container">
        <div className="jumbotron">
          <div className="card card-primary">
            <div className="card-header">
              <div className="row">
                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                  <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}> Student Notification </h5>
                </div>
                <br />
                <br />
              </div>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-outline card-primary">
                    <div className="text-center">
                      <h5 style={{ marginTop: "10px" }}>Message Options</h5>
                    </div>
                    <hr />

                    <div className="">
                      <div className="col-md-12">
                        <label>Subject</label>
                        <input type="text" onChange={event => setSubject(event.target.value)} value={subject} name="subject" id="subject" className={validateOutput.message.isInvalid ? 'form-control is-invalid' : 'form-control'} />
                        <span className="text-danger">{validateOutput.subject.message}</span>
                      </div>
                      <div className="col-md-12">
                        <label>Message</label>
                        <CKEditor
                          onInit={editor => {
                            console.log('Editor is ready to use!', editor);

                            // Insert the toolbar before the editable area.
                            editor.ui.getEditableElement().parentElement.insertBefore(
                              editor.ui.view.toolbar.element,
                              editor.ui.getEditableElement()
                            );
                            editor.on('insertElement', function (event) {
                              var element = event.data;
                              if (element.getName() == 'figure') {
                                element.addClass('img-responsive');
                              }
                            });
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            setMessage(data);
                          }}
                          editor={DecoupledEditor}
                          data={message}
                          config={{
                            ckfinder: { uploadUrl: "https://cla.maclms.org/api/fileupload/editorupload" }
                          }}



                        />
                        <span className="text-danger">{validateOutput.message.message}</span>
                      </div>
                      <div className="col-md-12">
                        <hr />
                      </div>
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <div className="col-md-8">
                            <div className="d-flex justify-content-between" style={{ marginLeft: "30px" }}>
                              <div className="col">
                                <label>
                                  <input type="radio" value="All" onClick={() => setSelectedOption("All")} checked={selectedOption === 'All'} />
                                      Send to All Students
                                  </label>
                              </div>
                              <div className="col">
                                <label>
                                  <input type="radio" value="Specific" onClick={() => setSelectedOption("Specific")} checked={selectedOption === 'Specific'} />
                                                                Specific Students
                                                            </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-2">
                            <div className="form-group">
                              <button className="btn btn-primary btn-block" onClick={() => sendEmail()}>
                                <i className="fa fa-paper-plane">&nbsp;&nbsp; Send</i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {!isAllStudent ?
                  <>
                    <div className="col-md-3">
                      <div className="card card-outline card-primary">
                        <div className="text-center">
                          <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                        </div>
                        <hr />
                        <div className="col-md-12">
                          <div className="form-group">
                            <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control" type="text" placeholder="Name" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input onChange={event => setSearchMobile(event.target.value)} value={searchMobile} name="searchMobile" id="searchMobile" className="form-control" type="text" placeholder="Mobile" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input onChange={event => setSearchEmail(event.target.value)} value={searchEmail} name="searchEmail" id="searchEmail" className="form-control" type="text" placeholder="Email" />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <select onChange={event => setFilterClassSectionId(event.target.value)} value={filterclassSectionId} name="filterclassSectionId" className="form-control">
                              <option value="">-- select class section --</option>
                              {classSection.map(item => (
                                <option
                                  key={item.classSectionId}
                                  value={item.classSectionId}
                                >
                                   {item.classSectionName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <select className="form-control" onChange={event => setSearchGenderId(event.target.value)} value={searchGenderId} name="searchGenderId"  >
                              <option value="">-- select gender --</option>
                              {genders.map(item => (
                                <option
                                  key={item.genderId}
                                  value={item.genderId}
                                >
                                  {item.genderName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="form-group">
                          <button className="btn btn-primary btn-block" onClick={() => searchStudent()}>
                            Search
                                                </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-9" style={{ overflow: "scroll", height: "400px" }}>
                      <table className="table table-bordered table-hover">
                        <thead>
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            <th scope="col">Class Section</th>
                            <th className="text-right">
                              <input onChange={(e) => checkStudent(e)} name="allCheck" type="checkbox" checked={isAllChecked} />
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (<Loader />) : (allStudent.map((item, index) => (
                            <tr key={item.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{item.value}</td>
                              <td>{item.section}</td>

                              <td className="text-right">
                                <input key={item.id} onChange={event => checkStudent(event)} type="checkbox" checked={item.isChecked} name={item.name} value={item.id} />
                              </td>
                            </tr>
                          )))}
                        </tbody>
                      </table>
                    </div>
                  </>
                  : <></>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SendStudentNotificationTeacher

