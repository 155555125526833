import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import OptionalSubjectDataService from "../../../services/OptionalSubjectDataService"
import OptionalSubjectbatchDataService from "../../../services/OptionalSubjectbatchDataService"
import ClassSectionDataService from "../../../services/ClassSectionDataService"
import CommonDataService from "../../../services/CommonDataService"
import TeacherDataSevice from "../../../services/TeacherDataSevice"
import Loader from '../../ui/loader/Loader'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';
import OptionalSubjectStudentDataService from '../../../services/OptionalSubjectStudentDataService';

let studentListIds=""
const OptionalSubjectBatch = () => {
    
    const validator= ValidationRule;
    const [show, setShow] = useState(false);
    const [classesId, setClassesId] = useState(0);
    const [batchName, setbatchName] = useState('');
    const [optSubjectId, setOptSubjectId] = useState('')
    const [classSectionId, setClassSectionId] = useState("")
    const [teacherId, setTeacherId] = useState("")
    const [classes,setClasses]=useState([]);
    const [classSections, setClassSections] = useState([])
    const [optionalSubjects,setOptionalSubjects]=useState([]);    
    const [optionalSubjectBatch,setOptionalSubjectsBatch]=useState([]);
    const [teachers,setTeachers]=useState([]);    
    const [searchText, setSearchText] = useState('');
    const [submitted,setSubmitted]=useState(false);
    const [loading,setLoading] = useState(false);
    const [isCreate, setisCreate] = useState(false)
    const [isStuList, setIsStuList] = useState(false)
    const [formTitle, setformTitle] = useState("")

    const [assClassSectionId, setAssClassSectionId] = useState(0)
    const [assClassSections, setAssClassSections] = useState([])
    const [assStudents, setAssStudents] = useState([])
    const [students, setStudents] = useState([])
    const [optionalSubjectBatchId, setOptionalSubjectBatchId] = useState(0)

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        subjectName:searchText,
        classSectionList:''

    })

    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)   

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        batchName:batchName,
        optionalSubjectId:optSubjectId,
        classSectionId:classSectionId,
        teacherId:teacherId,
        validation:validation
    }

    useEffect(() => {
        setParametes()
    }, [submitted])

    useEffect(() => {
        fetchOptionalSubject()
    }, [params])


    const setParametes = () => {
        setParams({
            subjectName: searchText,
            classSectionList:''
        })
    }

    const fetchOptionalSubject= async () => {
        setLoading(true)
        await OptionalSubjectbatchDataService.findOptonalSubjectBatch(params)
            .then(response => {
                setOptionalSubjectsBatch(response.data.result.itemList)
                setTotalPages(response.data.result.totalPages)
                setActivePage(response.data.result.activePageNumber)
                setLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message;
                setLoading(false)
            })
    }

    useEffect(() => {
        fetchAllClasses();
        fetchTeachers();
    }, [])


    useEffect(() => {
        fetchClassSections();
        fetchOptionalSubjects()
    }, [classesId])

    
    const fetchAllClasses = async () =>
    {
        await CommonDataService.allClasses() 
        .then(response => {
            setClasses(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchOptionalSubjects = async () =>
    {
        await OptionalSubjectDataService.findOptonalSubjectWithClass(parseInt(classesId)==""?0:parseInt(classesId)) 
        .then(response => {         
            setOptionalSubjects(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchClassSections = async () =>
    {
        await ClassSectionDataService.getClassSectionWithClassId(parseInt(classesId)==""?0:parseInt(classesId)) 
        .then(response => {
            setClassSections(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchTeachers = async () =>
    {
        await TeacherDataSevice.allTeacher() 
        .then(response => {
            setTeachers(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    
    const openCreateForm = () => {
        setformTitle("Add Optional Subject Batch")
        setIsStuList(false)
        setisCreate(true)
        handleShow()
    }

  

    const submitForm = async () => {
        var data = {
            optionalSubjectBatchId:0,
            batchName: batchName,
            optionalSubjectId: parseInt(optSubjectId),
            classSectionId: parseInt(classSectionId),
            teacherId:parseInt(teacherId),
          };
          const validate = validator.validate(validationData)
          setValidation({ validate });
          setValidateSubmitted(true)
            if (validate.isValid) {
                setLoading(true)
                await OptionalSubjectbatchDataService.createOptonalSubjectBatch(data)
                    .then(response => {
                        setSubmitted(!submitted)
                        setLoading(false)
                        LearnToast(success, "Saved Successfully")
                        resetForm()
                    })
                    .catch(error => { 
                        let errorMsg=[]
                        let msgText=""
                        setLoading(false)      
                        if(error.response.status==500)
                        {
                            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                        }
                        else
                        {
                            errorMsg=error.response.data.errors
                            errorMsg.map((item,index)=>{
                                if(msgText.length>0)
                                {
                                msgText = msgText+","
                                }
                                msgText=msgText+item
                            })            
                                LearnToast(danger,msgText)
                        
                        }     
                    })
            }
    }

    const resetForm=()=>{        
        setValidation(validator.valid());
        setValidateSubmitted(false)
        setClassesId(0)
        setbatchName("")
        setClassSectionId("")
        setOptSubjectId('')
        fetchClassSections()
        fetchOptionalSubjects()
        handleClose()
      }

    
    const serachByName=()=>{
        setSubmitted(!submitted)
      }

    const handleClickDelete = async id =>{
        setLoading(true)
        await OptionalSubjectbatchDataService.deleteOptonalSubjectBatch(id) 
        .then(response => {
            setSubmitted(!submitted);
            setLoading(false)
            LearnToast(success, "Deleted Successfully")
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }               
        })
   }

    const deleteClassSubjectBatch = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }


    const associateStudent = (id) =>{
        setAssStudents([])
        setAssClassSectionId("")
        setOptionalSubjectBatchId(id)
        fetchAssocatedClassSection(id)
        setformTitle("Associate Student")
        setIsStuList(false)
        setisCreate(false)
        handleShow()
    }

    useEffect(() => {
        fetchClassSectionStudents()
    }, [assClassSectionId])

    const fetchAssocatedClassSection = async (id) =>
    {
        await OptionalSubjectbatchDataService.getOptonalSubjectBatchSections(id) 
        .then(response => {
            setAssClassSections(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchClassSectionStudents = async () =>
    {
        let sudentList=[]
        setLoading(true)
        await OptionalSubjectStudentDataService.getOptonalSubjectEntryStudents(optionalSubjectBatchId,assClassSectionId) 
        .then(response => {
            response.data.result.map((item, index) => (
                sudentList.push({ id: item.studentId, value: item.studentName})
            ));
            setAssStudents(sudentList)
            setLoading(false)
       })
       .catch(error => { 
           setLoading(false)       
       })
    }

    const handleCheckStudents = (event) => {
        let chkstudents = assStudents
        studentListIds = ""
        chkstudents.forEach(item => {
            if (item.value === event.target.value) {
                item.isChecked = event.target.checked

            }
            if (item.isChecked) {
                if (studentListIds.length > 0 || studentListIds != "") {
                    studentListIds += "|"
                }
                studentListIds += item.id
            }
        })
        setAssStudents(chkstudents)
    }

    const saveAssociation = async () =>
    {
        let data={
            optionalSubjectBatchId:optionalSubjectBatchId,
            studentIdList:studentListIds.toString()
        }
        setLoading(true)
        await OptionalSubjectStudentDataService.createOptonalSubjectStudent(data) 
        .then(response => {
            studentListIds=""
            fetchClassSectionStudents()
            setLoading(false)
            LearnToast(success, "Saved Successfully")
       })
       .catch(error => {
        let errorMsg=[]
        let msgText=""
        setLoading(false)      
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }          
       })
    }
    const viewAssociatedStudents = (id) =>{
        setOptionalSubjectBatchId(id)
        setformTitle("Associated Student")
        fetchAsociatedStudents(id)
        setisCreate(false)
        setIsStuList(true)
        handleShow()
    }

    const fetchAsociatedStudents = async (id) =>
    {
        setLoading(true)
        await OptionalSubjectStudentDataService.findAssociaedSubjectStudent(id) 
        .then(response => {
            setStudents(response.data.result)
            setLoading(false)
       })
       .catch(error => {
        setLoading(false)          
       })
    }

    const deleteAssociatedStudent = async (id) =>
    {
        setLoading(true)
        await OptionalSubjectStudentDataService.deleteOptonalSubjectStudent(id) 
        .then(response => {
            fetchAsociatedStudents(optionalSubjectBatchId)
            setLoading(false)
            LearnToast(success, "Deleted Successfully")
       })
       .catch(error => {
        let errorMsg=[]
        let msgText=""
        setLoading(false)      
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }               
       })
    }
    
    

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 


  return (
    <>
    <ToastContainer/>
        <Modal show={show}>
            <Modal.Header >
                <Modal.Title>{formTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isCreate?
                <form>
                    <div className="form-group">
                        <label htmlFor="classesId"> Class Name</label>
                        <select className="form-control" onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" >
                            <option value="">-- select class --</option>
                            {classes.map(item => (
                                <option
                                    key={item.classesId}
                                    value={item.classesId}
                                >
                                    {item.classesName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group">
                        <label htmlFor="classesId"> Class Section</label>
                        <select className={validateOutput.classSectionId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setClassSectionId(event.target.value)} value={classSectionId} name="classSectionId" >
                            <option value="">-- select class section --</option>
                            {classSections.map(item => (
                                <option
                                    key={item.classSectionId}
                                    value={item.classSectionId}
                                >
                                    {item.classesName + " " + item.sectionName}
                                </option>
                            ))}
                        </select>
                        <span className="text-danger">{validateOutput.classSectionId.message}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="classesId"> Subject</label>
                        <select className={validateOutput.optionalSubjectId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setOptSubjectId(event.target.value)} value={optSubjectId} name="optSubjectId" >
                            <option value="">-- select Subject --</option>
                            {optionalSubjects.map(item => (
                                <option
                                    key={item.optionalSubjectId}
                                    value={item.optionalSubjectId}
                                >
                                    {item.optionalSubjectName}
                                </option>
                            ))}
                        </select>
                        <span className="text-danger">{validateOutput.optionalSubjectId.message}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="classesId">Teacher</label>
                        <select className={validateOutput.teacherId.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setTeacherId(event.target.value)} value={teacherId} name="teacherId" >
                            <option value="">-- select teacher --</option>
                            {teachers.map(item => (
                                <option
                                    key={item.teacherId}
                                    value={item.teacherId}
                                >
                                    {item.fullName}
                                </option>
                            ))}
                        </select>
                        <span className="text-danger">{validateOutput.teacherId.message}</span>
                    </div>
                    <div className="form-group">
                        <label htmlFor="subjectName">Batch Name</label>
                        <input type="text" onChange={event => setbatchName(event.target.value)} name="batchName" id="batchName" value={batchName} className={validateOutput.batchName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Batch Name" />
                        <span className="text-danger">{validateOutput.batchName.message}</span>
                    </div>
                </form>
                :
                <>
                {isStuList?
                    <>
                        <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Student Name</th>
                                <th className="text-right">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {students.map((item, index)=>
                            <tr key={index}>
                                <th>{index+1}</th>
                                <td>{item.studentName}</td>
                                <td className="text-right">
                                    <button onClick={() => deleteAssociatedStudent(item.optionalSubjectStudentId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                </td>
                            </tr>
                            )}
                        </tbody>
                    </table>
                    </>
                    :
                    <>
                        <div className="form-group">
                        <label htmlFor="classesId"> Class Section</label>
                        <select className="form-control" onChange={event => setAssClassSectionId(event.target.value)} value={assClassSectionId} name="assClassSectionId" >
                            <option value="">-- select class section --</option>
                            {assClassSections.map(item => (
                                <option
                                    key={item.classSectionId}
                                    value={item.classSectionId}
                                >
                                    {item.classSectionName}
                                </option>
                            ))}
                        </select>
                    </div>
                        <br/>
                        <table className="table table-bordered table-hover">
                            <thead>
                                <tr>
                                    {/* <th scope="col">#</th> */}
                                    <th scope="col"></th>
                                    <th scope="col">Student Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {assStudents.map((item, index)=>
                                <tr key={index}>
                                    {/* <th>{index+1}</th> */}
                                    <td><input onClick={event => handleCheckStudents(event)} type="checkbox" checked={item.isChecked} value={item.value} /></td>
                                    <td>{item.value}</td>
                                </tr>
                                )}
                            </tbody>
                        </table>
                    </>
                    }
                </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                {isCreate?
                <Button type="submit" variant="primary" onClick={submitForm}>
                    Save
                </Button>
                :
                <>
                    {isStuList?
                        <></>
                        :
                        <Button type="submit" variant="primary" onClick={saveAssociation}>
                            Save
                        </Button>
                    }   
               
                </>
                }
                
            </Modal.Footer>
        </Modal>

        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Optional Subject</h5>
                            </div>
                            <div className="col-md-6 text-right">
                                <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={()=>openCreateForm()}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">  
                        {loading? (<Loader/>) :                        
                            <div className="col-md-12">
                            <div className="row">
                                    <div className="col-md-12 d-flex justify-content-between">
                                            <div className="col-md-3">
                                                <div className="input-group">
                                                    <div className="input-group input-group-sm">
                                                        <input onChange={event => setSearchText(event.target.value)} value={searchText} name="searchText" id="searchText" className="form-control form-control-navbar" type="text" placeholder="Type Subjet Name" aria-label="Search"/>
                                                        <div className="input-group-append">
                                                            <button onClick={()=>serachByName()} className="btn btn-primary" type="submit">
                                                                <i className="fas fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-9">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                    </div>
                                </div>
                                <br/>
                                {optionalSubjectBatch.length>0?
                                <>
                                <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Class Section</th>
                                                <th scope="col">Batch</th>
                                                <th scope="col">Teacher</th>

                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(optionalSubjectBatch.map((item, index) => (
                                                <tr key={item.optionalSubjectBatchId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{item.optionalSubjectName}</td>
                                                    <td>{item.classSectionName}</td>
                                                    <td>{item.batchName}</td>
                                                    <td>{item.teacherName}</td>
                                                    <td className="text-right">
                                                    <div className="d-flex justify-content-end">
                                                        <button onClick={() => associateStudent(item.optionalSubjectBatchId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Associate Student"><i className="fa fa-plus text-info"></i></button>
                                                        <button onClick={() => viewAssociatedStudents(item.optionalSubjectBatchId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Associated Students"><i className="fa fa-users text-info"></i></button>
                                                        <button onClick={() => deleteClassSubjectBatch(item.optionalSubjectBatchId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                                    </div>
                                                    </td>
                                                </tr>
                                            )))}
                                        </tbody>
                                    </table>
                                <div className="row">
                                    <div className="col-md-12">
                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                    </div>
                                </div>
                                </>
                                :
                                <div className="alert alert-info alert-dismissible">
                                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                                </div>
                            }
                            </div>
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
);
}

export default OptionalSubjectBatch
