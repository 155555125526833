import React from 'react';
import Login from './components/pages/login/Login';
import LoginLayout from './components/templates/LoginLayout'
import { Switch, Route,HashRouter, BrowserRouter } from 'react-router-dom';
import AppRoute from './appRoute/AppRoute';
import PrivateRoutes from './routes/PrivateRoute';
import history from './util/history';
import { Provider } from 'react-redux';
import {store, persistor} from './components/redux/store'
import {PersistGate} from 'redux-persist/integration/react'
import './App.css'
import PageNotFound from './components/pages/error/PageNotFound'

function App() {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <HashRouter history={history}>
          <Switch>
              <AppRoute exact path="/" component={Login} layout={LoginLayout}/>
              <Route  path="/app" component={PrivateRoutes}/>  
              <Route component={PageNotFound} />    
          </Switch>
        </HashRouter>
      </PersistGate>
    </Provider>
  );
}
export default App;
