import React, { Component } from 'react';
import { Switch, Route,Link, Redirect } from 'react-router-dom';
import { uniqBy } from 'lodash';
import { rolesConfig, moduleConfig } from '../config/roles';
import * as Routes from './index';
import Navigation from '../components/ui/navigation/Navigation';
import Navbar from '../components/ui/navbar/Navbar';
import { connect } from 'react-redux';

class PrivateRoutes extends Component {
    state = { allowedRoutes: [],allowedModules: [] };   

    componentDidMount()
    {
        console.log("first")
        document.body.classList.add("hold-transition");
        document.body.classList.add("sidebar-mini");
        document.body.classList.add("layout-fixed");
        document.body.classList.add("text-sm");
        
        // this.loadScript("/assets/plugins/jquery/jquery.min.js");
        // this.loadScript("/assets/plugins/jquery-ui/jquery-ui.min.js")
        // this.loadScript("/assets/plugins/bootstrap/js/bootstrap.bundle.min.js")
        // this.loadScript("/assets/plugins/bootstrap/js/bootstrap.bundle.min.js")
        // this.loadScript("/assets/plugins/chart.js/Chart.min.js")
        // this.loadScript("/assets/plugins/sparklines/sparkline.js")
        // this.loadScript("/assets/plugins/jqvmap/jquery.vmap.min.js")
        // this.loadScript("/assets/plugins/jqvmap/maps/jquery.vmap.usa.js")
        // this.loadScript("/assets/plugins/jquery-knob/jquery.knob.min.js")
        // this.loadScript("/assets/plugins/moment/moment.min.js")
        // this.loadScript("/assets/plugins/daterangepicker/daterangepicker.js")
        // this.loadScript("/assets/plugins/tempusdominus-bootstrap-4/js/tempusdominus-bootstrap-4.min.js")
        // this.loadScript("/assets/plugins/summernote/summernote-bs4.min.js")
        // this.loadScript("/assets/plugins/overlayScrollbars/js/jquery.overlayScrollbars.min.js")
        // this.loadScript("/assets/dist/js/adminlte.js")
        // this.loadScript("/assets/dist/js/pages/dashboard.js")
        // this.loadScript("/assets/dist/js/demo.js")
     
		/*
      TODO: Replace hardcoded roles with redux,
       localStorage, or get from server.
     */
  
		let roles = JSON.parse(localStorage.getItem('roles'));
		if (roles) {
			roles = ['common', ...roles];

			let allowedRoutes = roles.reduce((acc, role) => {
				return [...acc, ...rolesConfig[role].routes];
            }, []);

            let allowedModules = roles.reduce((acc, role) => {
				return [...acc, ...moduleConfig[role].routes];
            }, []);
           
            // let allowedModules= roles.reduce((acc, role) => {
			// 	return [...acc, ...moduleConfig[role].parentMenus];
            // }, []);

			// For removing duplicate entries, compare with 'url'.
			allowedRoutes = uniqBy(allowedRoutes, 'url');
            this.setState({ allowedRoutes });
            allowedModules = uniqBy(allowedModules, 'url');
            this.setState({ allowedModules });
		} else {
			this.props.history.push('/');
		}
    }
    
    loadScript = function(src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        document.body.appendChild(tag);
      }
    
  
	render() {
		return (
                <>
                    <div className="wrapper">
                        <Navbar/>
                        <aside className="main-sidebar sidebar-dark-primary elevation-4">
                            {/* Brand Logo */}
                            <Link to="/" className="brand-link">
                                <img src="/assets/dist/img/AdminLTELogo.png" alt="Clave Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
                                <span className="brand-text font-weight-light">CLAVE LEARN</span>
                            </Link>
                            {/* Sidebar */}
                            <div className="sidebar">
                                {/* Sidebar user panel (optional) */}
                                <div className="user-panel mt-3 pb-3 mb-3 d-flex">
                                    <div className="image">
                                    <Link to={{ pathname: '/app/update-profile/' }} 
                                                    className="d-block">     
                                            {(this.props.userTypeName !="Admin")?
                                            <img src={this.props.profilePicUrl} className="img-circle elevation-2" alt="UserImage" />
                                            :
                                            <img src="/assets/dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="UserImage" />
                                            }                       
                                    </Link>                                       
                                    </div>
                                    <div className="info">
                                    <Link to={{ pathname: '/app/update-profile/' }} 
                                                    className="d-block">     
                                                    {this.props.Name}                       
                                    </Link>
                                    {/* <a href="/" className="d-block">{this.props.Name}</a> */}
                                    </div>
                                </div>
                                {/* Sidebar Menu */}
                                <Navigation
                                    routes={this.state.allowedModules}
                                    // modules={this.state.allowedModules}
                                    path={this.props.match.path}
                                />
                            </div>
                            {/* /.sidebar */}
                        </aside>
                        <div className="content-wrapper">
                            {/* <div className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1 className="m-0 text-dark">Dashboard</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                                <li className="breadcrumb-item active">Dashboard v1</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            <section className="content">
                                <div className="container-fluid">
                                    <div className="row">
                                        <Switch>
                                            {this.state.allowedRoutes.map((route) => (
                                                <Route
                                                    exact
                                                    key={route.url}
                                                    component={ Routes[route.component]}
                                                    path={`${this.props.match.path}${route.url}`}
                                                />
                                            ))}
                                             {/* <Route component={PageNotFound} />  */}
                                            {/* <Route component={Login} /> */}
                                        </Switch>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    {/* <script>
                        $.widget.bridge('uibutton', $.ui.button)
                    </script>         */}
		        </>
		);
	}
}

const mapStateToProps = state => {
    return {
      Name: state.login.user.name,
      profilePicUrl:state.login.profileUrl,
      userTypeName:state.login.user.userTypeName
    }
  }

export default connect(mapStateToProps)(PrivateRoutes);
