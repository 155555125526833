import  ApiDataService  from './ApiDataService'

const allClasses = () => {
    return ApiDataService.get("common/classes");
  };

const allGender = () => {
  return ApiDataService.get("common/genders");
};

const allTitle = () => {
  return ApiDataService.get("common/titles");
};


const allQuestionTypes = () => {
  return ApiDataService.get("common/questiontypes");
};


const allGradeSchemes = () => {
  return ApiDataService.get("common/gradeschemes");
};

const answerStatuses = () => {
  return ApiDataService.get("common/answerstatuses");
};

const fileUpload = (data,...args) => {
  return ApiDataService.postWithProgress("fileupload", data,...args);
};


export default {allClasses,
                allGender,
                fileUpload,
                allTitle,
                allQuestionTypes,
                allGradeSchemes,
                answerStatuses
              };