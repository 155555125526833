import  FormValidator  from '../../../helpers/form-validator'

const FormRule = (passwordMatch) =>  new FormValidator([
    { 
      field: 'studentName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Name is Required'
    },
    { 
      field: 'genderId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Gender is Required'
    },
    { 
      field: 'mobile', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Mobile is Required'
    },
    { 
      field: 'eMail', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Email is Required'
    },
    { 
      field: 'eMail',
      method: 'isEmail', 
      validWhen: true, 
      message: 'That is not a valid email.'
    },
    { 
      field: 'password', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Password is Required'
    },
    { 
      field: 'confirmpassword', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Confirm Password is Required'
    },
    { 
      field: 'confirmpassword', 
      method: passwordMatch, 
      validWhen: true, 
      message: 'password mismatch'
    },
    { 
      field: 'parentName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Parent Name is Required'
    },
    { 
      field: 'parentMobile', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Parent Mobile is Required'
    },
    { 
      field: 'parentEMail', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Parent EMail is Required'
    },
    { 
      field: 'parentEMail',
      method: 'isEmail', 
      validWhen: true, 
      message: 'That is not a valid email.'
    },
    { 
      field: 'classSectionId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Section is Required'
    }
  ])

  export default {FormRule}

  