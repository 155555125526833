import React from 'react'
import ReactSpeedometer from "react-d3-speedometer"

const AboutStudent = (props) => {
  return (
    <div className="row">
    <div className="col-md-4 text-center">
    <div className="card card-outline">
        <div className="card-header">
            <h5 className="card-title">Overall Summmary</h5>
        </div>
        <div className="card-body box-profile">
             <ReactSpeedometer
                    maxValue={100}
                    value={props.profile.overallPerformancePercent}
                    needleColor="blue"
                    startColor="red"
                    segments={10}
                    width={200}
                    height={150}
                    endColor="green"
                    />
        </div>   
        </div>                                           
    </div>
    <div className="col-md-8"> 
    <div className="card card-outline">
              <div className="card-header">
                  <h5 className="card-title">Summary Details</h5>
              </div>
                <div className="card-body box-profile">  
                    <div className="col-md-12">
                        <div className="progress-group">
                            Assignment - {props.profile.assignmentPerformancePercent}%
                            <div className="progress progress-sm">
                                <div className={props.profile.overallAssignmentProgressBarName} style={{ width: props.profile.assignmentPerformancePercent+"%" }} />
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-12">
                        <div className="progress-group">
                            Attendance - {props.profile.attendancePerformancePercent}%
                            <div className="progress progress-sm">
                                <div className={props.profile.overallAttendanceProgressBarName} style={{ width: props.profile.attendancePerformancePercent+"%" }} />
                            </div>
                        </div>
                    </div> 
                    <div className="col-md-12">
                        <div className="progress-group">
                            Exam - {props.profile.examPerformancePercent}%
                            <div className="progress progress-sm">
                                <div className={props.profile.overallExamProgressBarName} style={{ width: props.profile.examPerformancePercent+"%" }} />
                            </div>
                        </div>
                    </div>   
                    <div className="card-footer">
                        <div className="d-flex justify-content-between">
                            <span className="mr-2">
                                <i className="fas fa-square text-success"></i> Above 80%
                            </span>
                            <span className="mr-2">
                                <i className="fas fa-square text-warning"></i> Between 60% and 80%
                            </span>
                            <span className="mr-2">
                                <i className="fas fa-square text-danger"></i> Lessthan 60%
                            </span>
                        </div>
                    </div>               
                </div>
            </div>
        </div>
    </div>
  )
}

export default AboutStudent
