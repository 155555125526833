import React, { useState, useEffect } from 'react';
import CommonDataService from '../../../../services/CommonDataService';
import TeacherAssociationDataService from "../../../../services/TeacherAssociationDataService"
import StudentDataService from "../../../../services/StudentDataService"
import StudentAttendanceReportDataService from '../../../../services/StudentAttendanceReportDataService'
import Loader from '../../../ui/loader/Loader';
import LearnPagination from '../../../ui/pagination/LearnPagination';
import IndividualPdfReport from './IndividualPdfReport';

const IndividualAttendanceReportStaff = () => {
    const [fromDate, setFormDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [allStudent, setAllStudent] = useState([]);
    const [genders,setGenders] = useState([]);
    const [classSection,setClassSection] = useState([]);
    const [loading,setLoading] = useState(false);
    const [searchName, setSearchName]= useState("")
    const [searchMobile, setSearchMobile]= useState("")
    const [searchEmail, setSearchEmail]= useState("")
    const [searchGenderId, setSearchGenderId]= useState("")
    const [filterclassSectionId, setFilterClassSectionId] = useState("")
    const [isFilterView, setIsFilterView] = useState(true)
    const [studentId, setStudentId] = useState(0) 
    const [classSubjects, setClassSubjects] = useState([])
    const [subjectId, setSubjectId] = useState(0)
    const [isReporeView, setisReportView] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reportData, setReportdata] = useState([])
    const [reportTitle, setReportTitle] = useState("")
    const [reportSubTitle, setReportSubTitle] = useState("")
    const [headerImage, setHeaderImage] = useState("")
    const [isGetSubmitted, setIsGetSubmitted] = useState(false)

    
    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        studentName:"",
        mobile:"",
        email:"",
        genderList:0,
        classSectionList:0
    })

    useEffect(() =>{        
        fetchClassSection();
        fetchallGenders();
    },[]) 


    // useEffect(() => {
    //     setParametes()
    // }, [])

    useEffect(() => {
        fetchStudent()
    }, [params])

    const setParametes = () => {
        setParams({
        studentName:searchName,
        mobile:searchMobile,
        email:searchEmail,
        genderList:searchGenderId.toString(),
        classSectionList:filterclassSectionId.toString()
        })
    }

    const fetchStudent = async () =>{
        setLoading(true);
        await StudentDataService.findStudent(params) 
        .then(response => {
            setAllStudent(response.data.result.itemList)
            setTotalPages(response.data.result.totalPages)
            setActivePage(response.data.result.activePageNumber)
            setLoading(false);
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchallGenders= async () =>
    {
        await CommonDataService.allGender() 
        .then(response => {
            setGenders(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchClassSection= async () =>
    {
        await TeacherAssociationDataService.getTeacherCurrentSections()
        .then(response => {
            setClassSection(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchClassSubjects = async (studentId) => {
        await StudentDataService.getSubjectsByStudent(parseInt(studentId))
            .then(response => {
                setClassSubjects(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const changeSubject = async (value) => {
        setisReportView(false)
        setReportdata([])
        setReportTitle("")
        setReportSubTitle("")
        setSubjectId(value)
    }

    const searchStudent = () =>{
        setParametes()
    }

    const viewReport = (studentId) =>{
        setStudentId(studentId)
        fetchClassSubjects(studentId)
        getAttendance(studentId)
        setIsFilterView(false)
    }
    

    const getAttendance = async (studentId) => {
        setIsGetSubmitted(true)
        let data={
            fromDate:fromDate,
            toDate:toDate,
            studentId:studentId,
            classSubjectId:parseInt(subjectId)
        }
        setIsLoading(true)
        await StudentAttendanceReportDataService.getSingleStudentAttendanceReport(data)
            .then(response => { 
                setReportdata(response.data.result.reportData)                
                setReportTitle(response.data.result.reportTitle)             
                setReportSubTitle(response.data.result.reportSubTitle)
                setHeaderImage(response.data.result.headerFileName)
                setIsLoading(false)
                setisReportView(true)
            })
            .catch(error => {
                setIsLoading(false)
            })
    }

    const backToStudentList = () =>{
        setIsFilterView(true)
    }


  return (
   <>
   {isFilterView?
    <div className="container">
        <div className="jumbotron">
        <div className="card card-primary">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}> Student Attendance Report </h5>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                <div className="col-md-12">
                            <div className="card card-outline card-primary">
                                <div className="text-left">
                                    <h5 style={{marginTop:"10px",paddingLeft:"10px"}}>Report Filter</h5>
                                </div>
                                <hr/>
                                <div className="col-md-12">
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">                                                
                                                <label>From</label>
                                                <input onChange={event => setFormDate(event.target.value)} value={fromDate} name="fromDate" id="fromDate" className="form-control" type="date" />                                                                                               
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label>To</label>
                                                <input onChange={event => setToDate(event.target.value)} value={toDate} name="toDate" id="toDate" className="form-control" type="date" />  
                                            </div>
                                        </div>  
                                    </div>  
                                </div>
                            </div>
                    </div>
                    <div className="col-md-12">
                            <div className="card card-outline card-primary">
                                <div className="text-left">
                                    <h5 style={{marginTop:"10px",paddingLeft:"10px"}}>Student Filter</h5>
                                </div>
                                <hr/>
                                <div className="col-md-12">
                                    <div className="form-row">
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Name</label>
                                                <input onChange={event => setSearchName(event.target.value)} value={searchName} name="searchName" id="searchName" className="form-control" type="text" placeholder="Name"/>                                                                                               
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Mobile</label>
                                                <input onChange={event => setSearchMobile(event.target.value)} value={searchMobile} name="searchMobile" id="searchMobile" className="form-control" type="text" placeholder="Mobile"/>  
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div className="form-group">
                                                <label>Email</label>
                                                <input onChange={event => setSearchEmail(event.target.value)} value={searchEmail} name="searchEmail" id="searchEmail" className="form-control" type="text" placeholder="Email"/>  
                                            </div>
                                        </div>       
                                    </div>  
                                    <div className="form-row">                     
                                        <div className="col">       
                                            <div className="form-group">                                     
                                                <label>Class Section</label>
                                                <select onChange={event => setFilterClassSectionId(event.target.value)} value={filterclassSectionId} name="filterclassSectionId" className="form-control">
                                                    <option value="">-- select class section --</option>
                                                    {classSection.map(item => (
                                                        <option 
                                                        key={item.classSectionId} 
                                                        value={item.classSectionId}
                                                        >                                                
                                                            {item.classSectionName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col">   
                                            <div className="form-group">                                         
                                                <label>Gender</label>
                                                <select className="form-control" onChange={event => setSearchGenderId(event.target.value)} value={searchGenderId} name="searchGenderId"  >                      
                                                    <option value="">-- select gender --</option>
                                                    {genders.map(item => (
                                                        <option
                                                            key={item.genderId}
                                                            value={item.genderId}
                                                        >
                                                            {item.genderName}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div className="form-row">
                                        <div className="col"></div>
                                        <div className="col"></div>
                                        <div className="col"></div>
                                        <div className="col">
                                            <div className="form-group">
                                            {fromDate.length>0 && toDate.length>0?
                                                <button className="btn btn-primary btn-block" onClick={()=>searchStudent()}>
                                                    Search
                                                </button>
                                            :
                                                <button className="btn btn-primary btn-block" disabled>
                                                    Search
                                                </button>
                                            }
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                    <div className="col-md-12">
                    <div className="row">
                        <div className="col-md-12">
                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                        </div>
                    </div>
                    <table className="table table-bordered table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Name</th>
                                {/* <th scope="col">Gender</th> */}
                                <th scope="col">Mobile</th>
                                <th scope="col">Class Section</th>
                                <th className="text-right">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {loading?(<Loader/>):(allStudent.map((student, index) => (
                                <tr key={student.studentId}>
                                    <th scope="row">{index + 1}</th>
                                    <td>{student.studentName}</td>
                                    {/* <td>{student.genderId}</td> */}
                                    <td>{student.mobile}</td>
                                    <td>{student.currentClassSection}</td>

                                    <td className="text-right">
                                        <button onClick={() => viewReport(student.studentId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="View Report"><i className="fa fa-search text-primary"></i></button>
                                    </td>
                                </tr>
                            )))}
                        </tbody>
                    </table>
                    <div className="row">
                        <div className="col-md-12">
                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
    :
    <div className="container">
        <div className="jumbotron">
        <div className="card card-primary">
            <div className="card-header">
                <div className="row">
                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}> Student Attendance Report </h5>
                    </div>
                    <br />
                    <br />
                </div>
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-3">
                            <div className="card card-outline card-primary">
                                <div className="text-center">
                                    <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                </div>
                                <hr/>
                                <div className="col-md-12">
                                    <div className="form-group">                                                
                                    <label>From</label>
                                    <input onChange={event => setFormDate(event.target.value)} value={fromDate} name="fromDate" id="fromDate" className="form-control" type="date"  aria-label="Topic.." />
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                    <label>To</label>
                                    <input onChange={event => setToDate(event.target.value)} value={toDate} name="toDate" id="toDate" className="form-control" type="date"  aria-label="Topic.." />
                                    </div>
                                </div> 
                                <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class Subject</label>
                                            <select onChange={event => changeSubject(event.target.value)} value={subjectId} name="subjectId" className="form-control">
                                                <option value="0">-- select subject --</option>
                                                {classSubjects.map(item => (
                                                    <option
                                                        key={item.classSubjectId}
                                                        value={item.classSubjectId}
                                                    >
                                                        {item.subjectName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>
                                <div className="col-md-12">
                                    <button onClick={()=>getAttendance(studentId)} className="btn btn-primary btn-block">Get Report</button>
                                </div>  
                                <br/>
                                <br/>
                            </div>
                    </div>
                    {isLoading? <Loader/>:
                    
                    <>
                    {(isGetSubmitted && reportData.length>0) || !isGetSubmitted ?
                            <div className="col-md-9">
                                {isReporeView && 
                                <IndividualPdfReport 
                                    reportData={reportData} 
                                    reportTitle={reportTitle} 
                                    reportSubTitle={reportSubTitle}
                                    headerImage={headerImage}/>
                                }
                            </div>
                    :
                    <div className="col-md-9">
                        <div className="alert alert-info alert-dismissible">
                            <div className="text-center text-white"><h4>No Records Found</h4></div>
                        </div>
                    </div>
                    }
                    </>
                        }
                </div>
            </div>
            <div className="card-footer">
                <div className="text-right">
                    <button className="btn btn-primary" onClick={()=>backToStudentList()}>Back To Student List</button>
                </div>
            </div>
        </div>
    </div>
    </div>
   }
</>
  )
}

export default IndividualAttendanceReportStaff
