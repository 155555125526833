import  ApiDataService  from './ApiDataService'

const createClassSection = data => {
  return ApiDataService.post("classsection", data);
};

const allClassSection = () => {
    return ApiDataService.get("classsection/all");
  };

  const findClassSection = (data) => {
    return ApiDataService.getWithParam("classsection/find",data);
  };

const getClassSection = (id) => {
  let values={'id':id}
    return ApiDataService.getWithParam("classsection", values);
};

const getClassSectionWithClassId = (classesId) => {
  let values={'classesId':classesId}
    return ApiDataService.getWithParam("classsection/allwithfilter", values);
};

const updateClassSection = data => {
    return ApiDataService.put("classsection", data);
};

const deleteClassSection = (id) => {
  let values={'id':id}
  return ApiDataService.remove("classsection",values);
};

export default {createClassSection,
                allClassSection,
                findClassSection,
                getClassSection,
                getClassSectionWithClassId,
                updateClassSection,
                deleteClassSection};