import  ApiDataService  from './ApiDataService'

const createOptonalSubjectBatch = data => {
  return ApiDataService.post("optionalsubjectbatch", data);
};
  
const findOptonalSubjectBatch = (data) => {
    return ApiDataService.getWithParam("optionalsubjectbatch/find",data);
  };

  const getOptonalSubjectBatchSections = (optionalSubjectBatchId) => {
    let data={"optionalSubjectBatchId":optionalSubjectBatchId}
    return ApiDataService.getWithParam("optionalsubjectbatch/batchsections",data);
  };

  const deleteOptonalSubjectBatch = id => {
    let data={"id":id}
    return ApiDataService.remove("optionalsubjectbatch", data);
  };


export default {createOptonalSubjectBatch,
                findOptonalSubjectBatch,
                getOptonalSubjectBatchSections,
                deleteOptonalSubjectBatch};