import React from 'react'

const ChatRoom = () => {
  return (
    <div className="container">
      <div className="jumbotron">
        <div className="card card-primary">
          <div className="card-header">
            <div className="row">
              <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Topic Name </h5>
              </div>
              {/* <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{marginBottom:"0.1rem",marginTop:"0.1rem"}} onClick={createAcademicYear}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div> */}
              <br />
              <br />
            </div>
          </div>
          <div className="card-body">

            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Student Name</th>
                  <th scope="col">Status</th>
                  <th scope="col">Mark</th>
                  <th className="text-right">Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Rahul</td>
                  <td className="text-danger">Not Submitted</td>
                  <td>
                    <div className="input-group input-group-sm">
                      <input disabled type="text" className="form-control" />
                      <span className="input-group-append">
                        <button disabled type="button" className="btn btn-info btn-flat">Go!</button>
                      </span>
                    </div>
                  </td>
                  <td className="text-right">
                    <button disabled className="btn btn-info"><i className="fas fa-download"></i></button>
                  </td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob Thomas</td>
                  <td className="text-warning">Submitted</td>
                  <td>
                    <div className="input-group input-group-sm">
                      <input type="text" className="form-control" />
                      <span className="input-group-append">
                        <button type="button" className="btn btn-info btn-flat">Go!</button>
                      </span>
                    </div>
                  </td>
                  <td className="text-right">
                    <button enabled className="btn btn-info"><i className="fas fa-download"></i></button>
                    </td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Manu Mohan</td>
                  <td className="text-success">Valuated</td>
                  <td>20</td>
                  <td className="text-right">
                    <button enabled className="btn btn-info"><i className="fas fa-download"></i></button>
                  </td>
                </tr>
              </tbody>
            </table>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatRoom
