import  ApiDataService  from './ApiDataService'

  const getStudentAttendanceReport = (values) => {
      return ApiDataService.getWithParam("studentattendancereport/overall", values);
  };

  const getDetailedStudentAttendanceReport = (values) => {
    return ApiDataService.getWithParam("studentattendancereport/detail", values);
};

const getSingleStudentAttendanceReport = (values) => {
  return ApiDataService.getWithParam("studentattendancereport/student", values);
};

  export default{getStudentAttendanceReport,
                getDetailedStudentAttendanceReport,
                getSingleStudentAttendanceReport}
