import  ApiDataService  from './ApiDataService'

  const createClassSchedule = data => {
    return ApiDataService.post("classschedule", data);
  };

  const findAdminClassSchedule = (values) => {
    return ApiDataService.getWithParam("classschedule/find",values);
  };

  const findTeacherClassSchedule = (values) => {
    return ApiDataService.getWithParam("classschedule/teacherschedule",values);
  };

  const findTeacherClassSchedulePaginated = (values) => {
    return ApiDataService.getWithParam("classschedule/paginatedteacherschedule",values);
  };

  const findStudentClassSchedule = (values) => {
    return ApiDataService.getWithParam("classschedule/studentschedule",values);
  };

  const findStudentCalendar = () => {
    return ApiDataService.get("classschedule/studentcalendar");
  };

  const deleteClassSchedule = (id) => {
    let values={'classScheduleId':id}
    return ApiDataService.remove("classschedule", values);
  };


  
export default {createClassSchedule,
                findAdminClassSchedule,
                deleteClassSchedule,
                findTeacherClassSchedule,
                findTeacherClassSchedulePaginated,
                findStudentClassSchedule,
                findStudentCalendar}