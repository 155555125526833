import  ApiDataService  from './ApiDataService'

const createQuestionPaperMaster = data => {
  return ApiDataService.post("questionpapermaster", data);
};

const getQuestionPaperMaster = id => {
  let values={'id':id}
  return  ApiDataService.getWithParam("questionpapermaster",values);
};

const getQuestionPaperMasterCombo = classSubjectId => {
  let values={'classSubjectId':classSubjectId}
  return  ApiDataService.getWithParam("questionpapermaster/combo",values);
};

const findQuestionPaperMaster = data => {
    return  ApiDataService.getWithParam("questionpapermaster/find",data);
  };

const publishQuestionPaperMaster = questionPaperMstId => {
  let data={"questionPaperMstId":questionPaperMstId}
  return ApiDataService.put("questionpapermaster/publish", data);
}

const archiveQuestionPaperMaster = data => {
    return ApiDataService.put("questionpapermaster/archive", data);
  }

const viewQuestionPaper = questionPaperMstId => {
  let values={'questionPaperMstId':questionPaperMstId}
  return  ApiDataService.getWithParam("questionpapermaster/questionpaper",values);
};

const deleteQuestionPaperMaster = id => {
  let values={'id':id}
  return  ApiDataService.remove("questionpapermaster",values);
};



export default {createQuestionPaperMaster,
                getQuestionPaperMaster,
                getQuestionPaperMasterCombo,
                findQuestionPaperMaster,
                publishQuestionPaperMaster,
                archiveQuestionPaperMaster,
                viewQuestionPaper,
                deleteQuestionPaperMaster};