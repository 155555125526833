import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../../ui/loader/Loader';
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import CommonDataService from "../../../services/CommonDataService"
import ExamDataService from "../../../services/ExamDataService"
import ValidationRule from './ValidationRule'
import ExamQuestionSettingsDataService from '../../../services/ExamQuestionSettingsDataService'
import ExamInstructionDataService from '../../../services/ExamInstructionDataService'
import ExamEvalDataService from '../../../services/ExamEvalDataService'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';

let documentID=0
let classSectionListIds=""
let examGradeListIds=""
const OnlineExamStaff = () => {

    const [show, setShow] = useState(false);
    const [timeBound, setTimeBound] = useState(false)
    const [isCreate, setIsCreate] = useState(false)
    const [isMarkSetting, setIsmarkSetting] = useState(false)
    const [isInstruction, setIsInstruction] = useState(false);
    const [formTitle, setFormTitle] = useState("")
    const [modalSize, setModalSize] = useState("lg")
    const [submitted,setSubmitted]=useState(false);
    const [classes,setClasses] =useState([])
    const [allClassSubject, setAllClassSubject]= useState([]);
    const [examGrades, setExamGrades] =useState([])
    const [examTitle, setExamTitle] = useState("")
    const [classesId,setClassesId] = useState("")
    const [classSubjectId,setClassSubjectId]= useState("")
    const [examGrade, setExamGrade] =useState("")
    const [maxMarks, setMaxMarks] = useState("")
    const [isFile, setIsFile] =useState("")
    const [documentId, setDocumentId] = useState(0)
    const [documentFile, setDocumentFile] =useState("")
    const [scheduleDate, setScheduleDate] =useState("")
    const [scheduleTime, setScheduleTime] =useState("")
    const [duration, setDuration] =useState("")
    const [uploadProgress,setuploadProgress] =useState("0")

    const [timeBoundFilter, setTimeBoundFilter] = useState(false)
    const [searchExam,setSearchExam]=useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [loading,setLoading] = useState(false);
    const [allexamGrades, setAllExamGrades] =useState([])
    const [allClassSection,setAllClassSection]=useState([])
    const [exams,setExams]=useState([])

    const [examInstructions, setExamInstructions] = useState([])
    const [examInstruction, setExamInstruction] = useState("")

    
    const [questionTypes,setQuestionTypes]=useState([])
    const [allQuestionSettings,setAllQuestionSettings]=useState([])
    const [examId, setExamId]= useState(0)
    const [questionType, setQuestionType] = useState("")
    const [requiredQuestion, setRequiredQuestion] = useState("")
    const [totalQuestion, setTotalQuestion] = useState("")
    const [questionTypemark, setQuestionTypeMark] = useState("")
    const [totalMark, setTotalMark] = useState(0)
    const [isAutoAnswer,setIsAutoAnswer] =useState("Manual")

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        examName:searchExam,
        isTimeboundOnly:timeBoundFilter,
        gradeSchemes:examGradeListIds,
        classSections:classSectionListIds,
        classSubjects:"" 
         })

    const validator= ValidationRule.NewExamStaffRule();    
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false);

    const validatorTimeBound= ValidationRule.TimeBoundRule();    
    const [validationTimeBound, setValidationTimeBound] = useState(validatorTimeBound.valid());
    const [validateTimeBoundSubmitted, setValidateTimeBoundSubmitted]=useState(false);

    
    const validatorDocument=ValidationRule.DocumentRule();
    const [validationDocument, setValidationDocument] = useState(validatorDocument.valid());
    const [validateSubmittedDocument, setValidateSubmittedDocument]=useState(false)

    const validatorMarkSetting=ValidationRule.MarkSettingRule();
    const [validationMarkSetting, setValidationMarkSetting] = useState(validatorMarkSetting.valid());
    const [validateSubmittedMarkSetting, setValidateSubmittedMarkSetting]=useState(false)

    var validationData={
        examTitle:examTitle,
        examGrade:examGrade,
        classesId:classesId,
        maxMarks:maxMarks,
        classSubjectId:classSubjectId,
        validation:validation,
    }

    var validationTimeBoundData={
        scheduleDate:scheduleDate,
        scheduleTime:scheduleTime,
        duration:duration,
        validation:validationTimeBound,
    }
    var DocumentValidationData={
        documentFile:documentFile,
        validation:validationDocument
    }

    var markSettingValiationData={
        questionType:questionType,
        requiredQuestion:requiredQuestion,
        totalQuestion:totalQuestion,
        questionTypemark:questionTypemark,
        validation:validationMarkSetting,
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(() =>{
        fetchTeacherCurrentSections()
        fetchAllGrades()
        fetchClassSection()
        fetchGrades()
        fetchQuestionTypes()
    },[]) 

    useEffect(() =>{
        fetchTeacherCurrentSubjects()
    },[classesId])    

    const fetchAllGrades = async () =>
    {
        await CommonDataService.allGradeSchemes() 
        .then(response => {
            setExamGrades(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchTeacherCurrentSections = async () =>{
        await TeacherAssociationDataService.getTeacherCurrentSections() 
        .then(response => {
            setClasses(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchTeacherCurrentSubjects = async () =>{
        await TeacherAssociationDataService.getTeacherCurrentSubjectbySection((classesId=="")?0:parseInt(classesId)) 
        .then(response => {
            setAllClassSubject(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }


    const handleTimeBoundClick = () => {
        setTimeBound(!timeBound)
    }

    const handleisFileClick = () => {
        setIsFile(!isFile)
    }

    const createExam = () => {           
        setValidation(validator.valid());
        setValidateSubmitted(false);        
        setValidationTimeBound(validatorTimeBound.valid());
        setValidateTimeBoundSubmitted(false); 
        setValidationDocument(validatorDocument.valid())
        setValidateSubmittedDocument(false)    
        setModalSize("lg")
        setIsInstruction(false)
        setIsmarkSetting(false)
        setFormTitle("Create Exam")
        setIsCreate(true)
        handleShow();
    }

    const saveExam= ()=>{
        submitExam("save")
        setExamId(0);
    }

    const submitExam = async (mode) => {
        let timeboundValidate=true
        let documentFileValidate=true
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        if(timeBound)
        {
            const validateTimeBound = validatorTimeBound.validate(validationTimeBoundData)
            setValidationTimeBound({ validateTimeBound });
            setValidateTimeBoundSubmitted(true)
            timeboundValidate=validateTimeBound.isValid
        }
        else
        {            
            setValidationTimeBound(validatorTimeBound.valid());
            setValidateTimeBoundSubmitted(false); 
            timeboundValidate=true  
        }
        if(isFile)
        {
            const validatedocument = validatorDocument.validate(DocumentValidationData)
            setValidationDocument({ validatedocument });
            setValidateSubmittedDocument(true)
            documentFileValidate=validatedocument.isValid
        }
        else
        {            
            setValidationDocument(validatorDocument.valid())
            setValidateSubmittedDocument(false)    
            documentFileValidate=true
        }

        if (validate.isValid && timeboundValidate && documentFileValidate) {
            if(isFile)
            {
                await addDocument();
            }
            else
            {
                handleCreateExam()
            }
            handleClose()
            setSubmitted(!submitted)
            setuploadProgress(0)
            resetForm()            
            fetchExams();
            if(mode=="continue")
            {                
                setModalSize("lg")
                setIsCreate(false)
                setFormTitle("Mark Settings")
                setIsmarkSetting(true);
                handleShow()
            }
        }
    }

    const  handleCreateExam = async () => {
        var data = {
            examId: 0,
            examName: examTitle,
            classSectionId:parseInt(classesId),
            classSubjectId:parseInt(classSubjectId),
            gradeSchemeId:parseInt(examGrade),
            maxMarks:parseInt(maxMarks),
            isFile:(isFile=="")?false:isFile,
            documentId:(documentID=="")?0:parseInt(documentID),            
            isTimeBound:timeBound,
            scheduleDate:(scheduleDate=="")?"1900-01-01T00:00:00.000Z":scheduleDate,
            examTime:timeBound?(scheduleDate+"T"+scheduleTime+":00.000"):"1900-01-01T00:00:00.000Z",
            durationInMinutes:(duration=="")?0:parseInt(duration)
          };
          setLoading(true)
          await ExamDataService.createExam(data)
          .then(response => {  
            setLoading(false)                    
            LearnToast(success,"Exam Created Successfully")
            setExamId(response.data.result.id)              
            allExamQuestionSettings(response.data.result.id)
            fetchExams()
          })
          .catch(error => { 
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }
          })
    }

    const openMarkSettings= (id)=>{
        setExamId(id)
        allExamQuestionSettings(id)        
        setModalSize("lg")        
        setIsInstruction(false)
        setIsCreate(false)
        setFormTitle("Mark Settings")
        setIsmarkSetting(true);
        handleShow()
    }

    const continueCreateExam = () => {        
        submitExam("continue")
    }

    const resetForm = () => {
        setClassSubjectId("")
        setClassesId("")
        setExamTitle("")
        setExamGrade("")
        setMaxMarks("")
        documentID=0
        setTimeBound(false)
        setIsFile(false)
        setValidation(validator.valid());
        setValidateSubmitted(false);        
        setValidationTimeBound(validatorTimeBound.valid());
        setValidateTimeBoundSubmitted(false); 
        setValidationDocument(validatorDocument.valid())
        setValidateSubmittedDocument(false)        
        setValidationMarkSetting(validatorMarkSetting.valid())
        setValidateSubmittedMarkSetting(false)     
    }

    

    
    const addDocument= ()=>{
            var file =new FormData()
            file.append('file',documentFile)
            CommonDataService.fileUpload(file,{
                onUploadProgress: progressEvent => {
                    setuploadProgress(progressEvent.loaded / progressEvent.total*100)
                 }})
            .then(response => {  
                setDocumentId(response.data.result) 
                documentID=response.data.result
                handleCreateExam()
               
           })
           .catch(error => {
               const errorMsg=error.message;            
           })
 
    }

    const openInstructions = (ExamID) =>
    {
        setIsCreate(false)
        setFormTitle("Instructions")
        setIsmarkSetting(false);
        setIsInstruction(true)
        fetchExamInstruction(ExamID)
        handleShow()
    }

    const fetchExamInstruction = async (examID) =>
    {
        setExamId(examID)
        setLoading(true)
        await ExamInstructionDataService.getExamInstructions(examID) 
        .then(response => {
            setExamInstructions(response.data.result)
            setLoading(false)
       })
       .catch(error => {
        setLoading(false)           
       })
    }

    const saveExamInstruction = async () =>
    {
        let data={
            examInstructionId:0,
            examId:parseInt(examId),
            instructionText:examInstruction
        }
       if(examInstruction.length>0)
       {
        setLoading(true)
            await ExamInstructionDataService.createExamInstruction(data) 
            .then(response => {
                fetchExamInstruction(examId)
                setExamInstruction("")
                setLoading(false)
                LearnToast(success,"Instruction Saved Successfully")
            })
            .catch(error => {
                setLoading(false)    
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")           
            })
        }
    }

    const deleteExamInstruction = async (examInstructionID) =>
    {
        setLoading(true)
        await ExamInstructionDataService.removeExamInstruction(examInstructionID) 
        .then(response => {
            fetchExamInstruction(examId) 
            setLoading(false)
            LearnToast(success,"Instruction Deleted Successfully")
        })
        .catch(error => {
            setLoading(false)    
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")            
        })
    }

    

    //Filter Section
    const handleTimeBoundClickFilter = () => {
        setTimeBoundFilter(!timeBoundFilter)
    }


    useEffect(() => {
        setParametes()
    }, [submitted,isfilterModified,timeBoundFilter])

    useEffect(() => {
        fetchExams()
    }, [params])  

    const setParametes = () => {
        setParams({
        examName:searchExam,
        isTimeboundOnly:timeBoundFilter,
        gradeSchemes:examGradeListIds,
        classSections:classSectionListIds,
        classSubjects:""   
        })
    }

    const fetchExams = async () =>{
        setLoading(true)
        await ExamDataService.findExam(params) 
        .then(response => {
        setExams(response.data.result.itemList)
        setTotalPages(response.data.result.totalPages)
        setActivePage(response.data.result.activePageNumber)
        setLoading(false)
       })
       .catch(error => {
           const errorMsg=error.message; 
           setLoading(false)                      
       })
    }

    const fetchClassSection = async () =>{
        let classSectionList=[]
        await TeacherAssociationDataService.getTeacherCurrentSections() 
          .then(response => {
            response.data.result.map((item,index)=>(
                classSectionList.push({id:item.classSectionId,value:item.classSectionName,isChecked:false})
            )); 
              setAllClassSection(classSectionList)
         })
         .catch(error => {
             const errorMsg=error.message;            
         })
       }

    const fetchGrades = async () => {
        let gradeList = []
        await CommonDataService.allGradeSchemes()
            .then(response => {
                response.data.result.map((item, index) => (
                    gradeList.push({ id: item.gradeSchemeId, value: item.gradeSchemeName, isChecked:false })
                ));
                setAllExamGrades(gradeList)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const handleCheckClassSections = (event) => {
        let chkclassSections = allClassSection
        classSectionListIds = ""
        chkclassSections.forEach(sectiondata => {
            if (sectiondata.value === event.target.value) {
                sectiondata.isChecked = event.target.checked

            }
            if (sectiondata.isChecked) {
                if (classSectionListIds.length > 0 || classSectionListIds != "") {
                    classSectionListIds += ","
                }
                classSectionListIds += sectiondata.id
            }
        })
        setAllClassSection(chkclassSections)
        setisFilterModified(!isfilterModified)
    }

    const handleCheckGrades = (event) => {
        let chkGrades = allexamGrades
        examGradeListIds = ""
        chkGrades.forEach(gradedata => {
            if (gradedata.value === event.target.value) {
                gradedata.isChecked = event.target.checked

            }
            if (gradedata.isChecked) {
                if (examGradeListIds.length > 0 || examGradeListIds != "") {
                    examGradeListIds += ","
                }
                examGradeListIds += gradedata.id
            }
        })
        setAllExamGrades(chkGrades)
        setisFilterModified(!isfilterModified)
    }

    const serachByText=()=>{
        setisFilterModified(!isfilterModified)
      }

    //MarkSettings
    useEffect(() => {
        setTotalMark(((requiredQuestion=="")?0:parseInt(requiredQuestion)) * ((questionTypemark=="")?0:parseInt(questionTypemark)))
    }, [questionTypemark, requiredQuestion])

    const fetchQuestionTypes = async () =>{
        let QuesTypes=[]
        await CommonDataService.allQuestionTypes() 
        .then(response => {
            setQuestionTypes(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const saveMarkSetting= async () =>{
        var data = {
            examQuestionSettingId:0,
            examId: parseInt(examId),
            questionTypeId:parseInt(questionType),
            markPerQuestion:parseInt(questionTypemark),
            questionsToAnswer:parseInt(requiredQuestion),
            availableQuestions:parseInt(totalQuestion),
            totalMark:parseInt(totalMark),
            isAutoEvaluation:(isAutoAnswer==="Manual")?false:true
            
        }
        const validateMarksetting = validatorMarkSetting.validate(markSettingValiationData)
        setValidation({ validateMarksetting });
        setValidateSubmittedMarkSetting(true)
        if(validateMarksetting.isValid)
        {
            setLoading(true)
            await ExamQuestionSettingsDataService.createExamQuestionSetting(data) 
                .then(response => {
                    allExamQuestionSettings(examId)
                    setLoading(false)
                    LearnToast(success,"Saved Successfully")
            })
            .catch(error => {
                setLoading(false)
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")         
            })
            resetMarkSetting()
        }
        
    }

    const allExamQuestionSettings = async (id) =>{
        await ExamQuestionSettingsDataService.allExamQuestionSetting(id) 
                .then(response => {
                    setAllQuestionSettings(response.data.result)
            })
            .catch(error => {
                const errorMsg=error.message;            
            })
            resetMarkSetting()

    }

    const resetMarkSetting = () =>{
        setIsAutoAnswer("Manual")
        setQuestionType("")
        setQuestionTypeMark("")
        setTotalMark(0)
        setRequiredQuestion("")
        setTotalQuestion("")    
        setValidationMarkSetting(validatorMarkSetting.valid())
        setValidateSubmittedMarkSetting(false)   
    }


    const handleClickDeleteExamQuestionSetting = async id =>{
        await ExamQuestionSettingsDataService.deleteExamQuestionSetting(id) 
            .then(response => {
                allExamQuestionSettings(examId)
            })
            .catch(error => {
                const errorMsg=error.message;            
            })
   }


    //Publish Exam

    const publishExam = async (examId) =>{
        setLoading(true)
        await ExamDataService.publishExam(examId) 
                .then(response => {
                    fetchExams() 
                    setLoading(false)          
                    LearnToast(success,"Exam Published Successfully")
            })
            .catch(error => {  
                setLoading(false)    
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")            
            })        
    }

    //publish exam result

    const publishExamResult = async (examId) =>{
        setLoading(true)   
        await ExamEvalDataService.publishExamReult(examId) 
                .then(response => {                    
                    fetchExams()
                    setLoading(false)          
                    LearnToast(success,"Exam Result Published Successfully")
            })
            .catch(error => {
                setLoading(false)    
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")         
            })        
    }

    const deleteExam = async (examId) =>{
        setLoading(true)   
        await ExamDataService.deleteExam(examId) 
                .then(response => {
                    fetchExams()
                    setLoading(false)          
                    LearnToast(success,"Exam Deleted Successfully")
            })
            .catch(error => {
                setLoading(false)    
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")             
            })        
    }


    //Validation

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    let validateTimeBoundOutput= validateTimeBoundSubmitted ? validatorTimeBound.validate(validationTimeBoundData) : validationTimeBoundData.validation 
    let validateOutputDocument= validateSubmittedDocument ? validatorDocument.validate(DocumentValidationData) : DocumentValidationData.validation 
    let validateMarkSetting= validateSubmittedMarkSetting ? validatorMarkSetting.validate(markSettingValiationData) : markSettingValiationData.validation 

    return (
        <>
        <ToastContainer />    
            {loading?<Loader/>:
            <>
                <Modal show={show} onHide={handleClose} size={modalSize}>
                    <Modal.Header closeButton>
                        <Modal.Title>{formTitle}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isCreate ?
                            <>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Class Section</label>
                                        <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className={validateOutput.classesId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                            <option value="">-- select class section --</option>
                                            {classes.map(item => (
                                                <option 
                                                key={item.classSectionId} 
                                                value={item.classSectionId}
                                                >                                                
                                                    {item.classSectionName}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutput.classesId.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Subject</label>
                                        <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className={validateOutput.classSubjectId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                            <option value="">-- select subject --</option>
                                            {allClassSubject.map(item => (
                                                <option
                                                    key={item.classSubjectId}
                                                    value={item.classSubjectId}
                                                >
                                                    {item.subjectName}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutput.classSubjectId.message}</span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Grade</label>
                                        <select className={validateOutput.examGrade.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setExamGrade(event.target.value)} value={examGrade} name="examGrade" >
                                            <option value="">--select --</option>
                                            {examGrades.map(item => (
                                            <option
                                                key={item.gradeSchemeId}
                                                value={item.gradeSchemeId}
                                            >
                                                {item.gradeSchemeName}
                                            </option>
                                        ))}
                                        </select>
                                        <span className="text-danger">{validateOutput.examGrade.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Maximum Marks</label>
                                        <input type="number" onChange={event => setMaxMarks(event.target.value)} value={maxMarks} name="maxMarks" id="maxMarks" className={validateOutput.maxMarks.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Maximum Mark" />
                                        <span className="text-danger">{validateOutput.maxMarks.message}</span>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Exam Title</label>
                                    <input type="text" onChange={event => setExamTitle(event.target.value)} value={examTitle} name="examTitle" id="examTitle" className={validateOutput.examTitle.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Exam Title" />
                                    <span className="text-danger">{validateOutput.examTitle.message}</span>
                                </div>
                                <div className="form-check">
                                    <input type="checkbox" onChange={handleTimeBoundClick} checked={timeBound} name="current" id="current" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Time Bound</label>
                                </div>
                                {timeBound ? (
                                    <>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Exam Date</label>
                                                <input type="date" onChange={event => setScheduleDate(event.target.value)} value={scheduleDate} name="scheduleDate" id="scheduleDate" className={validateTimeBoundOutput.scheduleDate.isInvalid ? 'form-control is-invalid':'form-control'} />
                                                <span className="text-danger">{validateTimeBoundOutput.scheduleDate.message}</span>
                                            </div>
                                            <div className="col">
                                                <label>Exam Time</label>
                                                <input type="time" onChange={event => setScheduleTime(event.target.value)} value={scheduleTime} name="scheduleTime" id="scheduleTime" className={validateTimeBoundOutput.scheduleTime.isInvalid ? 'form-control is-invalid':'form-control'} />
                                                <span className="text-danger">{validateTimeBoundOutput.scheduleTime.message}</span>
                                            </div>
                                            <div className="col">
                                                <label>Duration (in Minutes)</label>
                                                <input type="number"  onChange={event => setDuration(event.target.value)} value={duration} name="duration" id="duration" className={validateTimeBoundOutput.duration.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Duration" />
                                                <span className="text-danger">{validateTimeBoundOutput.duration.message}</span>
                                            </div>
                                        </div>
                                    </>
                                ) : (<></>)}
                                <br/>
                                <div className="form-check" style={{display:"none"}}>
                                    <input type="checkbox" onChange={()=>handleisFileClick()} checked={isFile} name="isFile" id="isFile" className="form-check-input" />
                                    <label className="form-check-label" htmlFor="exampleCheck1">Is File Upload</label>
                                </div>
                                {isFile?<>
                                <div className="form-group">
                                        <label>Upload File</label>
                                        <input type="file" onChange={event => setDocumentFile(event.target.files[0])} name="documentFile" id="documentFile" className={validateOutputDocument.documentFile.isInvalid ? 'form-control is-invalid':'form-control'} />
                                        <span className="text-danger">{validateOutputDocument.documentFile.message}</span>
                                </div>

                                </>:<></>}
                            </> : <></>}
                        {isMarkSetting ?
                            <div>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Question Type</label>
                                        <select className={validateMarkSetting.questionType.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setQuestionType(event.target.value)} value={questionType} name="questionType" id="questionType">
                                            <option value="">--select --</option>
                                            {questionTypes.map((item,index)=>(
                                                <option 
                                                key={index}
                                                value={item.questionTypeId} 
                                                >
                                                    {item.questionTypeName}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateMarkSetting.questionType.message}</span>
                                    </div>
                                    {!(questionType == "3" || questionType == "4" || questionType == "5" || questionType == "6" ) ?
                                        <div className="col">
                                            <label>Evaluation Mode</label>
                                            <select className="form-control" onChange={event => setIsAutoAnswer(event.target.value)} value={isAutoAnswer} name="isAutoAnswer" id="isAutoAnswer">
                                                <option value="Manual">Manual</option>
                                                <option value="Auto">Auto</option>
                                            </select>
                                        </div>
                                        : <></>}
                                    <div className="col">
                                        <label>Mark (for single question)</label>
                                        <input type="number" onChange={event => setQuestionTypeMark(event.target.value)} value={questionTypemark} name="questionTypemark" id="questionTypemark" className={validateMarkSetting.questionTypemark.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Mark" />
                                        <span className="text-danger">{validateMarkSetting.questionTypemark.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Total Mark</label>
                                        <input type="number" value={totalMark} disabled className="form-control text-bold " />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Required Questions (Total Questions to be Answered)</label>
                                        <input type="number" onChange={event => setRequiredQuestion(event.target.value)} value={requiredQuestion} name="requiredQuestion" id="requiredQuestion" className={validateMarkSetting.requiredQuestion.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Required Questions" />
                                        <span className="text-danger">{validateMarkSetting.requiredQuestion.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Total Questions (Total Available Questions in Exam)</label>
                                        <div className="input-group">
                                            <input type="number" onChange={event => setTotalQuestion(event.target.value)} value={totalQuestion} name="totalQuestion" id="totalQuestion" className={validateMarkSetting.totalQuestion.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Total Questions" />
                                            <div className="input-group-append">
                                                <button className="btn btn-primary" onClick={()=>saveMarkSetting()}  type="submit">
                                                    Save
                                                </button>
                                            </div>                                        
                                        </div>
                                        <span className="text-danger">{validateMarkSetting.totalQuestion.message}</span>
                                    </div>
                                </div>
                                <br />
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Question Type</th>
                                            <th scope="col">Total Question</th>
                                            <th scope="col">Required</th>
                                            <th scope="col">Mark/Question</th>
                                            <th scope="col">Total mark</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {allQuestionSettings.map((item,index)=>(
                                            <tr key={index}>
                                                <td>{item.questionTypeName}</td>
                                                <td>{item.availableQuestions}</td>
                                                <td>{item.questionsToAnswer}</td>
                                                <td>{item.markPerQuestion}</td>
                                                <td>{item.totalMark}</td>
                                                <td className="text-right">
                                                    <button className="btn btn-link" onClick={()=>handleClickDeleteExamQuestionSetting(item.examQuestionSettingId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}                                    
                                    </tbody>
                                </table>
                            </div> : <></>}
                            {isInstruction ?
                            <div>
                                <div className="form-group">
                                    <label>Instruction</label>
                                    <div className="input-group">
                                        <input type="text" onChange={event => setExamInstruction(event.target.value)} value={examInstruction} name="examInstruction" id="examInstruction" className="form-control" placeholder="Type Instruction.." />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" onClick={()=>saveExamInstruction()}  type="submit">
                                                Save
                                            </button>
                                        </div>                                        
                                    </div>
                                    <span className="text-danger"></span>
                                </div>
                                <br />
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl</th>
                                            <th scope="col">Instruction</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {examInstructions.map((item,index)=>(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{item.instructionText}</td>
                                                <td className="text-right">
                                                    <button className="btn btn-link" onClick={()=>deleteExamInstruction(item.examInstructionId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}                                    
                                    </tbody>
                                </table>
                            </div> : <></>}                  
                    </Modal.Body>
                    <Modal.Footer>
                        {isCreate ? <>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                        </Button>
                            <Button type="submit" onClick={()=>saveExam()} variant="primary">
                                Save Exam
                        </Button>
                        {!isFile?
                            <Button type="submit" onClick={()=>continueCreateExam()} variant="primary">
                                Save & Continue
                        </Button>
                        :<></>
                            }       
                        </> : <></>}
                        {isMarkSetting ? <>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {/* <Button onClick={handleClose} type="submit" variant="primary">
                                Next
                            </Button> */}
                        </> : <></>}   
                        {isInstruction ? <>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            {/* <Button onClick={handleClose} type="submit" variant="primary">
                                Next
                            </Button> */}
                        </> : <></>}   

                    </Modal.Footer>
                </Modal>
                <div className="container">
                    <div className="jumbotron">
                        <div className="card card-primary">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Exam</h5>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={createExam}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-3">
                                <div className="card card-outline card-primary">
                                    <div className="text-center">
                                        <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12">
                                        <div className="input-group">
                                            <div className="input-group input-group-sm">
                                                <input onChange={event => setSearchExam(event.target.value)} value={searchExam} name="searchExam" id="searchExam" className="form-control form-control-navbar" type="text" placeholder="Exam Name" aria-label="Search"/>
                                                <div className="input-group-append">
                                                    <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                                        <i className="fas fa-search"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                    <div className="col-md-12">
                                        <div className="form-check">
                                            <input type="checkbox" onChange={()=>handleTimeBoundClickFilter()} checked={timeBoundFilter} name="timeBoundFilter" id="timeBoundFilter" className="form-check-input" />
                                            <label className="form-check-label" htmlFor="exampleCheck1">Time Bound</label>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Grade Schemes</b></h6>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            {allexamGrades.map((item,index)=>(
                                                <li key={index} className="list-group-item">
                                                    <input key={item.id} onClick={event=>handleCheckGrades(event)} type="checkbox" checked={item.isChecked} value={item.value} /> {item.value}
                                                </li>

                                            ))}
                                        </ul>
                                    </div>
                                    <div className="col-md-12">
                                        <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Class Sections</b></h6>
                                        <ul className="list-group list-group-unbordered mb-3">
                                            {allClassSection.map((item,index)=>(
                                                <li key={index} className="list-group-item">
                                                    <input key={item.id} onClick={event=>handleCheckClassSections(event)} type="checkbox" checked={item.isChecked} value={item.value} /> {item.value}
                                                </li>

                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                </div>
                              
                                    <div className="col-md-9">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                        {exams.map((item,index)=>(
                                        <div className="card card-outline card-primary" key={item.examId}>
                                            <div className="card-header" id="headingOne">
                                                <i className="d-flex justify-content-between"> 
                                                    <p className="text-muted text-xs">Class: {item.classSectionName}</p>
                                                    <p className="text-muted text-xs">Subject: {item.classSubjectName}</p>
                                                    <p className="text-justify">Total Mark : {item.maxMarks}</p>
                                                    {/* <button className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                    </button> */}
                                                </i>
                                                <div className="d-flex justify-content-between">
                                                    <h5>{item.examName}</h5> 
                                                    
                                                </div>                                         
                                                <i className="d-flex justify-content-between"> 
                                                    <p className="text-muted text-xs">Exam Time: {item.scheduleDate+", "+item.scheduleTime}</p>                                               
                                                    {/* <p><i>Pending Valuation</i></p> */}
                                                </i>
                                                <div className="d-flex justify-content-between">
                                                    <Link className="btn btn-link" to={{pathname: '/app/evaluate-exam/'+item.examId+'/'+item.examName,props:{ id: item.examId}}}>
                                                        <span className="text-warning"><i className="fa fa-id-card"></i> Valuate</span>
                                                    </Link>
                                                    {!item.isResultPublished ?
                                                    <button className="btn btn-link" onClick={()=>publishExamResult(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <span className="text-success"><i className="fa fa-envelope"></i> Publish Result</span>
                                                    </button>
                                                    :<></>}
                                                    <button className="btn btn-link" onClick={()=>openInstructions(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <span className="text-info"><i className="fa fa-search"></i> Instructions</span>
                                                    </button>
                                                    {!item.isPublished ?
                                                    <button className="btn btn-link" onClick={()=>publishExam(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            <span className="text-success"><i className="fa fa-cog"></i> Publish</span>
                                                    </button>:<></>
                                                    }
                                                    {item.isFile?
                                                    <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <span className="text-success"><i className="fa fa-cog"></i> View Question File</span>
                                                    </button>
                                                    :
                                                    <>  
                                                    {!item.isPublished ?     
                                                        <>                                          
                                                            <button className="btn btn-link" onClick={()=>openMarkSettings(item.examId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                <span className="text-danger"><i className="fa fa-cog"></i> Mark Setting</span>
                                                            </button>
                                                            <Link className="btn btn-link" to={{pathname: '/app/examquestionsettings/'+item.examId,props:{ id: item.examId}}}>
                                                                <span className="text-warning"><i className="fa fa-cog"></i> Question Setting</span>
                                                            </Link>
                                                            <button onClick={()=>deleteExam(item.examId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                                <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                            </button>
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                        }
                                                    </>
                                                    }
                                                    {/* <button className="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        <span className="text-primary"><i className="fa fa-edit"></i> Edit</span>
                                                    </button> */}

                                                </div>
                                            </div>
                                        </div>
                                    
                                        ))}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                    </div>
                                    
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default OnlineExamStaff
