import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import AcademicYearDataService from "../../../services/AcademicYearDataService"
import Loader from '../../ui/loader/Loader';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const AcademicYear = (props) => {
    const validator= ValidationRule;

    const [show, setShow] = useState(false);
    const [yearName, setYearName] = useState('');
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate]= useState(new Date());
    const [current, setCurrent] = useState(false);
    const [yearID, setYearId] =useState(0);
    const [isEdit, setIsEdit] =useState(false);
    const [submitted,setSubmitted]=useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [buttonName, setButtonName] = useState('');
    const [allAcademicYear, setAllAcademicYear] = useState([]);
    const [loading,setLoading] = useState(false);
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false)    

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        yearName:yearName,
        fromDate:fromDate,
        toDate:toDate,
        validation:validation,
    }

    const createAcademicYear = () => {
        resetForm();
        setYearId(0);
        setFormTitle('Add');
        setButtonName('Save');
        handleShow();
    }

    const editAcademicYear = academicYearId => {
        AcademicYearDataService.getAcademicYear(academicYearId)
        .then(response => {
            setYearName(response.data.result.displayName)
            setFromDate(formatDate(response.data.result.fromDate))
            setToDate(formatDate(response.data.result.toDate))
            setYearId(academicYearId)
            setCurrent(response.data.result.isCurrent)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
        setIsEdit(true);
        setFormTitle('Edit');
        setButtonName('Update');
        handleShow();
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await AcademicYearDataService.deleteAcademicYear(id) 
            .then(response => {
                setLoading(false)
                LearnToast(success,"Academic Year Deleted Successfully")
                setSubmitted(!submitted);
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }     
            })
   }


    const deleteAcademicYear = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
       
    }

    const resetForm = () =>{        
        setYearId(0);
        setYearName('');
        setFromDate('');
        setToDate('');
        setCurrent(false);
        setIsEdit(false);
        setValidation(validator.valid());
        setValidateSubmitted(false);
    }

    const handleCurrentClick = () => 
    {
      setCurrent(!current)
    }

   
    useEffect(() =>{
        setLoading(true);
        fetchAcademicYear();
    },[submitted]) 


    const fetchAcademicYear = async () =>{
        await AcademicYearDataService.allAcademicYear() 
        .then(response => {
            setAllAcademicYear(response.data.result)
            setLoading(false)
       })
       .catch(error => {
           const errorMsg=error.message;   
       })
    }
 

    const submitForm = async () => {
        var data = {
            academicYearId: yearID,
            displayName: yearName,
            fromDate: fromDate,
            toDate: toDate,
            isCurrent: current
        };

        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)

        if (validate.isValid) {
            setLoading(true)
            isEdit ? (
                    await AcademicYearDataService.UpdateAcademicYear(data)
                        .then(response => {
                            setLoading(false)
                            LearnToast(success,"Academic Year Updated Successfully")
                        })
                        .catch(error => {
                            let errorMsg=[]
                            let msgText=""
                            setLoading(false)      
                            if(error.response.status==500)
                            {
                                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                            }
                            else
                            {
                                errorMsg=error.response.data.errors
                                errorMsg.map((item,index)=>{
                                    if(msgText.length>0)
                                    {
                                    msgText = msgText+","
                                    }
                                    msgText=msgText+item
                                })            
                                    LearnToast(danger,msgText)
                            
                            }
                        })
                    ) : (
                            await AcademicYearDataService.createAcademicYear(data)
                            .then(response => {
                                setLoading(false)
                                LearnToast(success,"Academic Year Created Successfully")
                            })
                            .catch(error => {
                                let errorMsg=[]
                                let msgText=""
                                setLoading(false)      
                                if(error.response.status==500)
                                {
                                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                                }
                                else
                                {
                                    errorMsg=error.response.data.errors
                                    errorMsg.map((item,index)=>{
                                        if(msgText.length>0)
                                        {
                                        msgText = msgText+","
                                        }
                                        msgText=msgText+item
                                    })            
                                        LearnToast(danger,msgText)
                                
                                }
                            })
                    )
            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }


    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 

    function formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();
    
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
    
        return [year, month, day].join('-');
    }

    return (
        <>
        <ToastContainer />    
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle} Academic Year</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                        <div className="form-group">
                            <label htmlFor="displayname">Display Name</label>
                            <input onChange={event=>setYearName(event.target.value)} value={yearName} name="yearName" id="yearName" type="text" className={validateOutput.yearName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Display Name" />                          
                            <span className="text-danger">{validateOutput.yearName.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="fromdate">From</label>
                            <input type="date" onChange={event=>setFromDate(event.target.value)} value={fromDate} name="fromDate" id="fromDate" className={validateOutput.fromDate.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="" />
                            <span className="text-danger">{validateOutput.fromDate.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="todate">To</label>
                            <input type="date" onChange={event=>setToDate(event.target.value)} value={toDate} name="toDate" id="toDate" className={validateOutput.toDate.isInvalid ? 'form-control is-invalid':'form-control'}  placeholder="" />
                            <span className="text-danger">{validateOutput.toDate.message}</span>
                        </div>
                        <div className="form-check">
                            <input type="checkbox" onChange={handleCurrentClick} checked={current} name="current" id="current" className="form-check-input" />
                            <label className="form-check-label" htmlFor="exampleCheck1">Current</label>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={submitForm}>
                        {buttonName}
                    </Button>
                </Modal.Footer>
            </Modal>          
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">                            
                            <div className="row">
                                <div className="col-md-6" style={{verticalAlign:"middle"}}>
                                    <h5 style={{marginBottom:"0.4rem",marginTop:"0.4rem"}}>Academic Year</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{marginBottom:"0.1rem",marginTop:"0.1rem"}} onClick={createAcademicYear}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br/>
                                <br/>
                            </div>
                        </div>
                        {loading? (<Loader/>) : 
                        <div className="card-body">
                           {allAcademicYear.length>0?
                            <table className="table table-bordered table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Academic Year</th>
                                        <th scope="col">From</th>
                                        <th scope="col">To</th>
                                        <th className="text-right">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {(allAcademicYear.map((academicYear,index) =>(
                                    <tr key={academicYear.academicYearId}>
                                        <th scope="row">{index+1}</th>
                                        <td>{academicYear.displayName}</td>
                                        <td>{academicYear.fromDate}</td>
                                        <td>{academicYear.toDate}</td>
                                        <td className="text-right">
                                            <div className="d-flex justify-content-end">
                                                <button onClick={() => editAcademicYear(academicYear.academicYearId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>
                                                <button onClick={() => deleteAcademicYear(academicYear.academicYearId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                            </div>
                                        </td>
                                    </tr>
                                )))}
                                </tbody>
                            </table>
                             :
                             <div className="alert alert-info alert-dismissible">
                                 <div className="text-center text-white"><h4>No Records Found</h4></div>
                             </div>
                         }
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    );
};

export default AcademicYear;