import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../../redux/login/loginActions';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

function Navbar({logoutSuccess}) {
    const logoutUser = ()=>{
        console.log("Logout Function")
        logoutSuccess();
    }
    return (
        <nav className="main-header navbar navbar-expand navbar-dark navbar-primary">
            {/* Left navbar links */}
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="/app" role="button"><i className="fas fa-bars" /></a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <a href="#/app" className="nav-link">Home</a>
                </li>
                <li className="nav-item d-none d-sm-inline-block">
                    <a href="/" className="nav-link">Contact</a>
                </li>
            </ul>
            {/* SEARCH FORM */}
            <form className="form-inline ml-3">
                <div className="input-group input-group-sm">
                    <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                    <div className="input-group-append">
                        <button className="btn btn-navbar" type="submit">
                            <i className="fas fa-search" />
                        </button>
                    </div>
                </div>
            </form>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
                {/* Messages Dropdown Menu */}
               
                <li className="nav-item">
                    {/* <Link to="/logout" class="nav-link">Logout</Link> */}
                    {/* <Link onClick={logoutUser} className="nav-link" >Logout</Link> */}
                    <Button onClick={logoutUser} variant="outline-light">Logout</Button>
                </li>
            </ul>
        </nav>
     
    );
}

const mapDispatchToProps = dispatch => {
    return {
      logoutSuccess: () => dispatch(logout())
    }
  }
export default connect(null,mapDispatchToProps)(Navbar);