import React, { useState, useEffect } from 'react'
import { PieChart } from 'react-minimal-pie-chart';
import StudentDataService from "../../../services/StudentDataService"
import Loader from '../../ui/loader/Loader';

const AttendanceDetail = (props) => {

  const [attendanceDetail, setAttendanceDetail]= useState({})
  const [dailyDetail, setDailyDetail] = useState([])
  const [isDetailView, setIsDetailView] = useState(false) 
  const [loading,setLoading] = useState(false);


const ViewAttendanceDetail = async (classSectionId,subjectId) =>{
  setIsDetailView(true)
  setLoading(true)
  await StudentDataService.getSubjectAttendanceProfile(parseInt(props.profile.studentId),parseInt(classSectionId),parseInt(subjectId)) 
    .then(response => {
      setAttendanceDetail(response.data.result)
      setDailyDetail(response.data.result.studentAttendanceDetails)
      setLoading(false)
   })
   .catch(error => {
       const errorMsg=error.message;            
   })
}  



  return (
     
   <>
      {!isDetailView?
        <div className="row">
          <div className="col-md-4 text-center">
          <div className="card card-outline">
              <div className="card-header">
                  <h5 className="card-title">Overall Attendance</h5>
              </div>
              <div className="card-body box-profile">
              {/* <h5>Overall Attendance</h5>                                                 */}
                  <span className="mr-2">
                      <i className="fas fa-square text-success"></i> Present - {props.profile.totalPresent}
                  </span>
                  &nbsp; &nbsp;
                  <span className="mr-2">
                      <i className="fas fa-square text-danger"></i> Absent - {props.profile.totalAbsent}
                  </span> 
              <PieChart
                  data={[                                                        
                      { title: 'Absent', value: props.profile.totalAbsent, color: '#FF0000' },
                      { title: 'Present', value: props.profile.totalPresent, color: '#008000' }
                  ]}
                  label={({ dataEntry }) => dataEntry.value}
                  labelStyle={(index) => ({
                      fill: "#ffffff",
                      fontSize: '10px',
                      fontFamily: 'sans-serif',                                                                    
                      })}
                      radius={40}
              />      
              </div>   
              </div>                                           
          </div>
          <div className="col-md-8"> 

          <div className="card card-outline">
              <div className="card-header">
                  <h5 className="card-title">Subject Attendance</h5>
              </div>
              <div className="card-body box-profile">  
              {/* <h5 className="text-center">Subject Attendance</h5>  */}
              <br/>
                  {props.attendanceProfileSummaries.map((item,index)=>(
                      <div  className="row" key={index}>
                          <div className="col-md-11">
                              <div className="progress-group">
                                  {item.classSubjectName} - {item.presentPercent}%
                                  <span className="float-right"><b>{item.totalPresent}</b>/{item.totalClasses}</span>
                                  <div className="progress progress-sm">
                                      <div className={item.progressbarClassName} style={{ width: item.presentPercent+"%" }} />
                                  </div>
                              </div>
                          </div>
                          <div className="col-md-1">
                            {item.totalClasses>0?
                              <button className="btn btn-link" onClick={()=>ViewAttendanceDetail(props.profile.classSectionId,item.classSubjectId)}>
                                  <span className="text-info"><i className="fa fa-search"></i></span>
                              </button>
                              :
                              <button className="btn btn-link" disabled>
                                <span className="text-info"><i className="fa fa-search"></i></span>
                              </button>
                            }
                          </div>
                      </div>
                  ))}
                  </div>
                  <div className="card-footer">
                  <div className="d-flex justify-content-between">
                      <span className="mr-2">
                          <i className="fas fa-square text-success"></i> Above 80%
                      </span>
                      <span className="mr-2">
                          <i className="fas fa-square text-warning"></i> Between 60% and 80%
                      </span>
                      <span className="mr-2">
                          <i className="fas fa-square text-danger"></i> Lessthan 60%
                      </span>
                  </div>
                  </div>
              </div>
          </div>
      </div>
  :
  <>
  {loading? <Loader/>:
    <div className="row">
        <div className="col-md-12">
            <div className="card card-outline">
                <div className="card-body box-profile">
                    <div className="d-flex justify-content-between">
                        <div className="col-md-8"> 
                             <h6>{attendanceDetail.classSubjectName}</h6>
                        </div>
                        <div className="col-md-4">
                            <button onClick={()=>setIsDetailView(false)} className="btn btn-primary btn-block">Back to Summary</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    <div className="col-md-4 text-center">
        <div className="card card-outline">
            <div className="card-header">
                  <h5 className="card-title">Subject Summary</h5>
            </div>
            <div className="card-body box-profile">
            {/* <h5>Overall Attendance</h5>                                                 */}
                <span className="mr-2">
                    <i className="fas fa-square text-success"></i> Present - {attendanceDetail.totalPresent}
                </span>
                &nbsp; &nbsp;
                <span className="mr-2">
                    <i className="fas fa-square text-danger"></i> Absent - {attendanceDetail.totalAbsent}
                </span> 
            <PieChart
                data={[                                                        
                    { title: 'Absent', value: attendanceDetail.totalAbsent, color: '#FF0000' },
                    { title: 'Present', value: attendanceDetail.totalPresent, color: '#008000' }
                ]}
                label={({ dataEntry }) => dataEntry.value}
                labelStyle={(index) => ({
                    fill: "#ffffff",
                    fontSize: '10px',
                    fontFamily: 'sans-serif',                                                                    
                    })}
                    radius={40}
            />      
            </div>   
            </div>                                           
        </div>
        <div className="col-md-8"> 

          <div className="card card-outline">
              <div className="card-header">
                  <h5 className="card-title">Daily Attendance</h5>
              </div>
              <div className="card-body box-profile">  
                  <table className="table table-hover">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">Date</th>
                                <th className="text-right"></th>
                            </tr>
                        </thead>
                        <tbody> 
                        {dailyDetail.map((item, index)=>(
                              <tr>
                                <th scope="row">{index+1}</th>
                                <td>{item.attendanceDate + "  " + item.fromTime + " - " + item.toTime}</td>
                                <td className="text-right">
                                    {item.isPresent?
                                      <span className="text-success"><i className="fa fa-check"></i> </span>
                                  :
                                      <span className="text-danger"><i className="fa fa-times"></i> </span>
                                    }                        
                                </td>
                            </tr>
                          ))}                                
                      </tbody>
                  </table>
              </div>
          </div>
        </div>
    </div>
   }
   </>
  }
   </>
  )
  }

export default AttendanceDetail
