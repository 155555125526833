import  ApiDataService  from './ApiDataService'

const classSheduleAssociation = data => {
  return ApiDataService.post("classscheduleassociation", data);
};

const eligibleClassSections = classScheduleId => {
    let values={"classScheduleId":classScheduleId}
    return ApiDataService.getWithParam("classscheduleassociation/eligiblesections", values);
  };

  const getClassSheduleAssociations = classScheduleId => {
    let values={"classScheduleId":classScheduleId}
    return ApiDataService.getWithParam("classscheduleassociation", values);
  };

  const deleteClassSheduleAssociations = classScheduleAssociationId => {
    let values={'classScheduleAssociationId':classScheduleAssociationId}
    return  ApiDataService.remove("classscheduleassociation",values);
  };
  

  export default{classSheduleAssociation,
                eligibleClassSections,
                getClassSheduleAssociations,
                deleteClassSheduleAssociations}