import React from 'react';
import AcademicYear from '../components/pages/academicYear/AcademicYear';
import ClassSection from '../components/pages/classSection/ClassSection';
import ClassSubject from '../components/pages/classSubject/ClassSubject';
import OptionalSubject from '../components/pages/optionalSubject/OptionalSubject';
import OptionalSubjectBatch from '../components/pages/optionalSubjectBatch/OptionalSubjectBatch';
import Teacher from '../components/pages/teacher/Teacher';
import TeacherList from '../components/pages/teacher/TeacherList';
import TeacherAssociation from '../components/pages/teacherAssociation/TeacherAssociation';
import Student from '../components/pages/student/Student';
import StudentList from '../components/pages/student/StudentList';
import DifficultyLevel from '../components/pages/difficultyLevel/DifficultyLevel';
import CourseLevel from '../components/pages/courseLevel/CourseLevel';
import CourseItem from '../components/pages/courseItem/CourseItem';
import CourseItemStaff from '../components/pages/courseItem/CourseItemStaff';
import ClassNote from '../components/pages/classNote/ClassNote';
import StaffClassRoom from '../components/pages/classRoom/StaffClassRoom';
import ChatRoom from '../components/pages/chatApp/ChatRoom';
import StaffProfile from '../components/pages/Profile/StaffProfile';
import StaffProfileView from '../components/pages/Profile/StaffProfileView';
import PublishClassNote from '../components/pages/classNote/PublishClassNote';
import StudentForum from '../components/pages/forum/StudentForum';
import StaffForum from '../components/pages/forum/StaffForum';
import AssignmentTask from '../components/pages/assignment/AssignmentTask';
import Assignments from '../components/pages/assignment/Assignments';
import QuestionBank from '../components/pages/questionBank/QuestionBank';
import AdminQuestionList from '../components/pages/questionBank/AdminQuestionList';
import Questions from '../components/pages/questionBank/Questions';
import OneWordQuestion from '../components/pages/questionBank/OneWordQuestion';
import MultipleChoiceQuestion from '../components/pages/questionBank/MultipleChoiceQuestion';
import ParagraphQuestion from '../components/pages/questionBank/ParagraphQuestion';
import EssayQuestion from '../components/pages/questionBank/EssayQuestion';
import OnlineExamStaff from '../components/pages/exam/OnlineExamStaff';
import EvaluateExam from '../components/pages/exam/EvaluateExam';
import ViewResult from '../components/pages/exam/resultView/ViewResult';
import OnlineExamStudent from '../components/pages/exam/OnlineExamStudent';
import OnlineExamAdmin from '../components/pages/exam/OnlineExamAdmin';
import TodaysExam from '../components/pages/exam/TodaysExam';
import StudentClassNote from '../components/pages/classNote/StudentClassNote';
import StudentQandASession from '../components/pages/qandA/StudentQandASession';
import StudentProfile from '../components/pages/Profile/StudentProfile';
import AttendanceDetail from '../components/pages/Profile/AttendanceDetail';
import AssignmentDetail from '../components/pages/assignment/AssignmentDetail';
import NewExam from '../components/pages/exam/NewExam';
import ExamListAdmin from '../components/pages/exam/ExamListAdmin';
import ExamQuestionSetting from '../components/pages/exam/ExamQuestionSetting';
import Attendance from '../components/pages/attendance/Attendance';
import IntiateAttendance from '../components/pages/attendance/IntiateAttendance';
import StudentAttendance from '../components/pages/attendance/StudentAttendance';
import AdminSchedule from '../components/pages/schedule/AdminSchedule';
import StaffSchedule from '../components/pages/schedule/StaffSchedule';
import StudentShedule from '../components/pages/schedule/StudentShedule';
import StudentCalendarDisplay from '../components/pages/schedule/StudentCalendarDisplay';
import StudentProfileView from '../components/pages/Profile/StudentProfileView';
import DemoEditor from '../components/pages/questionBank/DemoEditor'
import AdminClassNote from '../components/pages/classNote/AdminClassNote'
import AdminAssignment from '../components/pages/assignment/AdminAssignment'
import UpdateProfilePicture from '../components/pages/changeImage/UpdateProfilePicture'
import StudentAttenceReport from '../components/pages/report/studentAttendance/StudentAttenceReport'
import StudentAttendenceReportStaff from '../components/pages/report/studentAttendance/StudentAttendenceReportStaff'
import DetailedAttendanceReport from '../components/pages/report/studentAttendance/DetailedAttendanceReport'
import DetailedAttendanceReportStaff from '../components/pages/report/studentAttendance/DetailedAttendanceReportStaff'
import IndividualAttendanceReport from '../components/pages/report/studentAttendance/IndividualAttendanceReport'
import IndividualAttendanceReportStaff from '../components/pages/report/studentAttendance/IndividualAttendanceReportStaff'
import AttendanceReportStudentView from '../components/pages/report/studentAttendance/AttendanceReportStudentView'
import EmailConfiguration from '../components/pages/emailConfiguration/EmailConfiguration'
import SendStudentNotification from '../components/pages/email/SendStudentNotification'
import SendTeacherNotification from '../components/pages/email/SendTeacherNotification'
import SendStudentNotificationTeacher from '../components/pages/email/SendStudentNotificationTeacher'
import QuestionPaper from '../components/pages/questionPaper/QuestionPaper'
import QuestionSettings from '../components/pages/questionPaper/QuestionSettings'
import ViewQuestionPaper from '../components/pages/questionPaper/viewQuestionPaper/ViewQuestionPaper'
import Examination from '../components/pages/examination/Examination'
import StudentTodaysExaminations from '../components/pages/examination/StudentTodaysExaminations'
import ExamEvaluation from '../components/pages/examEvaluation/ExamEvaluation'
import ExamStudents from '../components/pages/examEvaluation/ExamStudents'
import MasterResultView from '../components/pages/examination/MasterResultView'
import AdminExamination from '../components/pages/examination/AdminExamination'
import StudentExamination from '../components/pages/examination/StudentExamination'
import StudentResultView from '../components/pages/examination/StudentResultView'
import AdminQuestionPaper from '../components/pages/questionPaper/AdminQuestionPaper'



const Dashboard = () => {
    return (
        <div>
            <h1>Dashboard</h1>
        </div>
    );
};


const QandASession = () => {
    return (
        <div>
            <h1>Q and A Session</h1>
        </div>
    );
};



export {
    AcademicYear,
    ClassSection,
    ClassSubject,
    OptionalSubject,
    OptionalSubjectBatch,
    Student,
    StudentList,
    StaffClassRoom,
    Dashboard,
    Teacher,
    TeacherList,
    DifficultyLevel,
    CourseLevel,
    CourseItem,
    CourseItemStaff,
    TeacherAssociation,
    SendTeacherNotification,
    SendStudentNotification,
    StaffProfile,
    StaffProfileView,
    ClassNote,
    PublishClassNote,
    AssignmentTask,
    QuestionBank,
    Questions,
    OneWordQuestion,
    MultipleChoiceQuestion,
    ParagraphQuestion,
    EssayQuestion,
    QandASession,
    QuestionPaper,
    QuestionSettings,
    ViewQuestionPaper,
    OnlineExamStaff,
    OnlineExamStudent,
    OnlineExamAdmin,
    TodaysExam,
    EvaluateExam,
    ViewResult,
    StaffForum,
    StudentForum,
    ChatRoom,
    Assignments,
    StudentQandASession,
    StudentProfile,
    AttendanceDetail,
    AssignmentDetail,
    StudentClassNote,
    NewExam,
    ExamListAdmin,
    ExamQuestionSetting,
    DemoEditor,
    Attendance,
    IntiateAttendance,
    StudentAttendance,
    AdminSchedule,
    StaffSchedule,
    StudentShedule,
    StudentCalendarDisplay,
    StudentProfileView,
    AdminClassNote,
    AdminAssignment,
    AdminQuestionList,
    UpdateProfilePicture,
    StudentAttenceReport,
    DetailedAttendanceReport,
    StudentAttendenceReportStaff,
    DetailedAttendanceReportStaff,
    IndividualAttendanceReport,
    AttendanceReportStudentView,
    IndividualAttendanceReportStaff,
    EmailConfiguration,
    SendStudentNotificationTeacher,
    Examination,
    StudentTodaysExaminations,
    ExamEvaluation,
    ExamStudents,
    MasterResultView,
    AdminExamination,
    AdminQuestionPaper,
    StudentExamination,
    StudentResultView}