import React, { useState, useEffect } from 'react'
import ExamDataService from "../../../services/ExamDataService"
import LoadAnsweredQuestions from './studentResult/LoadAnsweredQuestions'

const AnswerSheet = (props) => {
    const [exams, setExams]= useState({})
    const [questionSettings, setQuestionSettings] = useState([])
    useEffect(() =>{
        fetchExam()
    },[]) 

    const fetchExam = async () =>
    {
        await ExamDataService.getUserQuestionPaper(props.ExamId) 
        .then(response => {
            setExams(response.data.result)
            setQuestionSettings(response.data.result.questionSettings)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

  return (
    <div className="container">
            <div className="jumbotron">
                <div className="card card-primary card-outline">
                    <div className="card-header">
                            <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                    <p>{exams.examSerialNumber}</p>
                                    <h5>{exams.examName}</h5>
                                    <p>{exams.examDate}</p>
                                </div>
                                <div className="d-flex justify-content-between">
                                    <p>{exams.classSectionName}<br/>{exams.studentName}</p>
                                    <h6>{exams.classSubjectName}</h6>
                                    <p>Marks:{exams.marksObtained+"/"+exams.maxMarks}<br />Time :{exams.durationInMinutes} Mins</p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                {/* <div className="col-md-12 scrollbar scrollbar-primary">
                                    <div className="force-overflow"> */}
                                    <div className="col-md-12">
                                        {questionSettings.map((item,index)=>(
                                        <>
                                            <div className="d-flex justify-content-between mt-1">
                                                <p></p>
                                                {(item.questionsToAnswer==item.availableQuestions)?
                                                <p className="text-md"><i>(Answer all {item.questionsToAnswer + " "+ item.questionTypeName} Questions) </i></p>
                                                :<p className="text-md"><i>(Answer Any {item.questionsToAnswer + " "+ item.questionTypeName} Question out of {item.availableQuestions}) </i></p>}
                                                
                                                <p className="text-md muted"> {item.questionsToAnswer +" X " + item.markPerQuestion + " = " + item.totalMark} </p>
                                            </div>
                                            <LoadAnsweredQuestions questions={item.questions} questionTypeName={item.questionTypeName}/>
                                        </>
                                        ))}
                                    </div>
                                    {/* </div>
                                </div> */}
                            </div>
                        </div>
                    
                        <div className="card-footer">
                            <div className="text-right">
                                <button className="btn btn-primary" onClick={()=>window.location.reload(false)}>Back To Exam</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
  )
}

export default AnswerSheet
