import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'
import ValidationRule from './ValidationRule'
import QandADataService from "../../../services/QandADataService"
import TeacherAssociationDataService from '../../../services/TeacherAssociationDataService';
import ClassSubjectDataService from '../../../services/ClassSubjectDataService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import Loader from '../../ui/loader/Loader';
import LearnPagination from '../../ui/pagination/LearnPagination';

let classesListIds = ""
let subjectListIds = ""
const StaffForum = () => {

    const validator = ValidationRule.StaffForumRule();
    const [show, setShow] = useState(false);
    const [subjectId, setSubjectId] = useState("")
    const [forumDetail, setForumDetail] = useState("")
    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [QAPosts, setQAPosts] = useState([]);
    const [classes, setClasses] = useState([])
    const [subjects, setSubjects] = useState([])
    const [searchQus, setSearchQus] = useState("")
    const [isfilterModified, setisFilterModified] = useState(false)

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        isUserPosts: false,
        postText: searchQus,
        classesList: classesListIds,
        subjectList: subjectListIds,
         })

    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted] = useState(false)


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    var validationData = {
        forumDetail: forumDetail,
        validation: validation,
    }

    useEffect(() => {
        fetchTeacherCurrentClasses()
        fetchTeacherCurrentSubjects(classesListIds)
    }, [])

    // useEffect(() => {
    //    fetchTeacherCurrentSubjects(classesListIds)
    // }, [isfilterModified])

    const fetchTeacherCurrentClasses = async () => {
        let classesList = []
        await TeacherAssociationDataService.getTeacherCurrentClasses()
            .then(response => {

                response.data.result.map((classdata, index) => (
                    classesList.push({ id: classdata.classesId, value: classdata.classesName, isChecked: false })
                ));
                setClasses(classesList)

            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }


    const fetchTeacherCurrentSubjects = async (classesId) => {
        let SubjectList = []
        await ClassSubjectDataService.getTeacherSubjects(classesId)
            .then(response => {
                response.data.result.map((subjectdata, index) => (
                    SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
                ));
                setSubjects(SubjectList)

            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    useEffect(() => {
        setParametes()
    }, [submitted,isfilterModified,classes])

    useEffect(() => {
        fetchQandPosts()
    }, [params])  

    const setParametes = () => {
        setParams({
            isUserPosts: false,
            postText: searchQus,
            classesList: classesListIds,
            subjectList: subjectListIds, 
        })
    }
    
    const fetchQandPosts = async () => {
        setLoading(true)
        await QandADataService.allTeacherQandAPost(params)
            .then(response => {
                setQAPosts(response.data.result.itemList)
                setTotalPages(response.data.result.totalPages)
                setActivePage(response.data.result.activePageNumber)
                setLoading(false)
            })
            .catch(error => {
                setLoading(false)
            })
    }

 

    const handleCheckClasses = (event) => {
        let chkclasses = classes
        classesListIds = ""
        chkclasses.forEach(classdata => {
            if (classdata.value === event.target.value) {
                classdata.isChecked = event.target.checked

            }
            if (classdata.isChecked) {
                if (classesListIds.length > 0 || classesListIds != "") {
                    classesListIds += ","
                }
                classesListIds += classdata.id
            }
        })
        fetchTeacherCurrentSubjects(classesListIds)
        setClasses(chkclasses)
        setisFilterModified(!isfilterModified)
    }

    const handleCheckSubjects = (event) => {
        let chksubject = subjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }


    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }

    const resetForm = () => {
        setSubjectId("")
        setForumDetail("")
        setValidateSubmitted(false);
        setValidation(validator.valid());
    }

    const submitForm = async (qaPostId) => {
        var data = {
            qaPostId: parseInt(qaPostId),
            answerText: forumDetail
        };

        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        if (validate.isValid) {
            setLoading(true)
            await QandADataService.replayQandAPost(data)
                .then(response => {
                    setLoading(false)
                    LearnToast(success, "Answer Saved Successfully")
                })
                .catch(error => {
                    let errorMsg=[]
                    let msgText=""
                    setLoading(false)      
                    if(error.response.status==500)
                    {
                        LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                    }
                    else
                    {
                        errorMsg=error.response.data.errors
                        errorMsg.map((item,index)=>{
                            if(msgText.length>0)
                            {
                            msgText = msgText+","
                            }
                            msgText=msgText+item
                        })            
                            LearnToast(danger,msgText)
                    
                    }
                })
            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }

    let validateOutput = validateSubmitted ? validator.validate(validationData) : validationData.validation

    return (
        <>
            <ToastContainer />
            {loading ? <Loader /> :
                <>
                    <div className="container">
                        <div className="jumbotron">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <div className="row">
                                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Q and A Session</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3">
                                            <div className="card card-outline card-primary">
                                                <div className="text-center">
                                                    <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                                </div>
                                                <hr />
                                                <div className="col-md-12">
                                                    <div className="input-group">
                                                        <div className="input-group input-group-sm">
                                                            <input onChange={event => setSearchQus(event.target.value)} value={searchQus} name="searchQus" id="searchQus" className="form-control form-control-navbar" type="text" placeholder="Type Question" aria-label="Search" />
                                                            <div className="input-group-append">
                                                                <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                                    <i className="fas fa-search"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="col-md-12">
                                                    <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Classes</b></h6>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        {classes.map((classdata, index) => (
                                                            <li key={index} className="list-group-item">
                                                                <input key={classdata.id} onClick={event => handleCheckClasses(event)} type="checkbox" checked={classdata.isChecked} value={classdata.value} /> {classdata.value}
                                                            </li>

                                                        ))}
                                                    </ul>
                                                </div>
                                                <div className="col-md-12">
                                                    <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                                                    <ul className="list-group list-group-unbordered mb-3">
                                                        {subjects.map((subjectdata, index) => (
                                                            <li key={index} className="list-group-item">
                                                                <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                                            </li>

                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-9">
                                            {QAPosts.length>0?
                                            <>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                    </div>
                                                </div>
                                                <div class="accordion" id="accordionExample">
                                                    {QAPosts.map(item => (
                                                        <div class="card card-outline card-primary" key={item.qaPostId}>
                                                            <div class="card-header" id="headingOne">
                                                                <i className="d-flex justify-content-between">
                                                                    <p className="text-muted text-xs">Class: {item.classesName}</p>
                                                                    <p className="text-muted text-xs">Subject: {item.classSubjectName}</p>
                                                                    <p className="text-muted text-xs">Posted By:{item.postedBy}</p>
                                                                    <p className="text-muted text-xs">{item.postedOn}</p>
                                                                </i>
                                                                <p style={{ textAlign: "justify" }}>{item.postQuestion}</p>
                                                                <button class="btn btn-link btn-block text-right" type="button" data-toggle="collapse" data-target={"#collapse" + item.qaPostId} aria-expanded="true" aria-controls="collapseOne">
                                                                    <i className="fa fa-edit"></i>  Solution
                                                            </button>
                                                            </div>
                                                            <div id={"collapse" + item.qaPostId} class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                                                <div class="card-body">
                                                                    <div style={{ padding: "10px", border: "2px solid rgba(0,0,0,.1)" }}>
                                                                        {item.isAnswered ? (
                                                                            <>
                                                                                <div className="d-flex"><h6 className="text-success">Solution:</h6>&nbsp;&nbsp;<p>  <i>{item.answeredBy}</i></p></div>

                                                                                <p style={{ textAlign: "justify" }}>{item.answerText}</p>
                                                                            </>
                                                                        ) : (
                                                                                <>
                                                                                    <div class="card-body">
                                                                                        <div style={{ padding: "10px", border: "2px solid rgba(0,0,0,.1)" }}>
                                                                                            <form>
                                                                                                <div className="form-group">
                                                                                                    <label htmlFor="question">Reply</label>
                                                                                                    <textarea onChange={event => setForumDetail(event.target.value)} value={forumDetail} name="forumDetail" id="forumDetail" className={validateOutput.forumDetail.isInvalid ? 'form-control is-invalid' : 'form-control'} placeholder="Place some text here"
                                                                                                        style={{
                                                                                                            width: "100%", height: "100px", fontSize: "14px", lineHeight: "18px",
                                                                                                            border: "1px solid #dddddd", padding: "10px"
                                                                                                        }} />
                                                                                                    <span className="text-danger">{validateOutput.forumDetail.message}</span>
                                                                                                </div>
                                                                                                <button type="button" onClick={() => submitForm(item.qaPostId)} class="btn btn-primary btn-block">Reply</button>
                                                                                            </form>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )}

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                    </div>
                                                </div>
                                            </>
                                             :
                                                <div className="alert alert-info alert-dismissible">
                                                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default StaffForum
