import React  from 'react';
import './style.css'
const Loader = () => {
        return ( 
            <div className="loader-container">
                <div className="loader">
                    <img src="/assets/loader/loader.gif" alt="Loader" />
                </div>
            </div>
         );
}



export default Loader;