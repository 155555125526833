import React, { useState, useEffect } from 'react'
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import ExamAnswerDataService from "../../../../services/ExamAnswerDataService"
import ValidationRule from './ValidationRule'
import parse from 'html-react-parser';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../../ui/alert/toast/ToastType"
import {LearnToast} from "../../../ui/alert/toast/LearnToast"

const LoadSingleQuestion = (props) => {

    const [question, setQuestion] = useState({})
    const [answerOptionList, setAnswerOptionList] = useState([])
    const [shortAnswer, setShortAnswer] = useState("")
    const [oneWordAnswer, setOneWordAnswer] =  useState("")
    const [selectedAnswer, setSelectedAnswer] =useState(0)
    const [selectedError, setSelectedError] = useState("")
    const validator= ValidationRule.OneWordAnswerRule();    
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false);
    const [qusNo, setQusNo]= useState(0)

    const validatorShortAnswer= ValidationRule.ShortAnswerRule();    
    const [validationShortAnswer, setValidationShortAnswer] = useState(validatorShortAnswer.valid());
    const [validateSubmittedShortAnswer, setValidateSubmittedShortAnswer]=useState(false);

    var validationData={
        oneWordAnswer:oneWordAnswer,
        validation:validation,
    }

    var validationDataShortAnswer={
        shortAnswer:shortAnswer,
        validation:validationShortAnswer,
    }

    useEffect(() => {
        setQuestion(props.question)
        setQusNo(props.question.questionSerialNo)
        setAnswerOptionList(props.question.answerOptionList)
    }, [])

    const submitOneWordAnswer =(id) =>{
        let data={
            examQuestionId:parseInt(id) ,
            answerText:oneWordAnswer,
            answerOptionId:0
        }
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        if (validate.isValid){       
            saveAnswer(data)
            setOneWordAnswer("")            
            setValidation(validator.valid());
            setValidateSubmitted(false);   
        }
        
    }

    const submitShortAnswer =(id) =>{
        let data={
            examQuestionId:parseInt(id) ,
            answerText:shortAnswer,
            answerOptionId:0
        }
        const validate = validatorShortAnswer.validate(validationDataShortAnswer)
        setValidationShortAnswer({ validate });
        setValidateSubmittedShortAnswer(true)
        if (validate.isValid){ 
            saveAnswer(data)
            setShortAnswer("")            
            setValidationShortAnswer(validatorShortAnswer.valid());
            setValidateSubmittedShortAnswer(false);   
        }
    }

    const onValueChange = (id) =>{
        setSelectedAnswer(id)
        setSelectedError("")
    }

    const submitMultipleQuestionAnswer =(id) =>{
        let data={
            examQuestionId:parseInt(id) ,
            answerText:"",
            answerOptionId:parseInt(selectedAnswer)
        }
        if(selectedAnswer>0)
        {
            saveAnswer(data)
            setSelectedError("")
            setSelectedAnswer(0)  
        }
        else{
            setSelectedError("Choose Any Option")
        }
    }

    const saveAnswer = async (data) =>{
        await ExamAnswerDataService.saveExam(data)
          .then(response => {          
              setQuestion(response.data.result)
              LearnToast(success,"Answer Submitted Successfully")
          })
          .catch(error => {
              LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
          })
    }

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    let validateOutputShortAnswer= validateSubmittedShortAnswer ? validatorShortAnswer.validate(validationDataShortAnswer) : validationDataShortAnswer.validation 

    return (
        <>
        <ToastContainer/>
        <div className="d-flex justify-content-between mt-1">
            <div className="">
            {qusNo}.
     </div>
            <div className="flex-grow-1" style={{fontSize:"18px"}}>
                <div style={{ textAlign: "justify", marginBottom: "3px"}}>{parse(""+question.questionText)}</div>
                {!question.isAnswered?
                <>
                {(props.questionTypeName === "One Word") ?
                    <>
                        <div className="input-group">
                            <input type="text" onChange={event => setOneWordAnswer(event.target.value)} value={oneWordAnswer} name={"oneWordAnswer"+question.examQuestionId} id={"oneWordAnswer"+question.examQuestionId} style={{ height: "2.16rem", borderRadius: "0", marginTop: ".03rem" }} className={validateOutput.oneWordAnswer.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Type Your Answer" />
                            <span className="input-group-append">
                                <button type="button" onClick={()=>submitOneWordAnswer(question.examQuestionId)} className="btn btn-primary">Submit</button>
                            </span>
                        </div>
                        <span className="text-danger">{validateOutput.oneWordAnswer.message}</span>
                    </>:
                    ((props.questionTypeName === "Paragraph") ?
                    <>
                        <div className="form-group">
                        <CKEditor
                            onInit={ editor => {
                                console.log( 'Editor is ready to use!', editor );

                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                            } }
                            onChange={ ( event, editor ) => { 
                                const data = editor.getData();
                                console.log( { event, editor, data } );
                                setShortAnswer(data);
                            }}
                            editor={ DecoupledEditor }
                            data=""
                            config={{
                                ckfinder:{uploadUrl: "https://cla.maclms.org/api/fileupload/editorupload"}
                            }}
                        />
                            {/* <textarea onChange={event => setShortAnswer(event.target.value)} value={shortAnswer} name={"shortAnswer"+question.examQuestionId} id={"shortAnswer"+question.examQuestionId} className={validateOutputShortAnswer.shortAnswer.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Type Your Answer"
                                style={{
                                    width: "100%", height: "100px", fontSize: "14px", lineHeight: "18px",
                                    border: "1px solid #dddddd", padding: "10px"
                                }} />                                */}
                        </div>
                        <span className="text-danger">{validateOutputShortAnswer.shortAnswer.message}</span>
                        <button type="button"  onClick={()=>submitShortAnswer(question.examQuestionId)} class="btn btn-primary btn-block">Submit</button>

                    </> :
                    (props.questionTypeName === "Essay") ?
                    <>
                        <div className="form-group">
                        <CKEditor
                            onInit={ editor => {
                                console.log( 'Editor is ready to use!', editor );

                                // Insert the toolbar before the editable area.
                                editor.ui.getEditableElement().parentElement.insertBefore(
                                    editor.ui.view.toolbar.element,
                                    editor.ui.getEditableElement()
                                );
                            } }
                            onChange={ ( event, editor ) => { 
                                const data = editor.getData();
                                console.log( { event, editor, data } );
                                setShortAnswer(data);
                            }}
                            editor={ DecoupledEditor }
                            data=""
                            config={{
                                ckfinder:{uploadUrl: "https://cla.maclms.org/api/fileupload/editorupload"}
                            }}
                        />
                            {/* <textarea onChange={event => setShortAnswer(event.target.value)} value={shortAnswer} name={"shortAnswer"+question.examQuestionId} id={"shortAnswer"+question.examQuestionId} className={validateOutputShortAnswer.shortAnswer.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Type Your Answer"
                                style={{
                                    width: "100%", height: "100px", fontSize: "14px", lineHeight: "18px",
                                    border: "1px solid #dddddd", padding: "10px"
                                }} />                                */}
                        </div>
                        <span className="text-danger">{validateOutputShortAnswer.shortAnswer.message}</span>
                        <button type="button"  onClick={()=>submitShortAnswer(question.examQuestionId)} class="btn btn-primary btn-block">Submit</button>

                    </> :
                    <div className="ml-5"> 
                        <div className="form-group">
                            {answerOptionList.map((item,index)=>(
                                <div className="form-check">
                                    <input className="form-check-input" 
                                    type="radio" 
                                    name={"radio"+question.examQuestionId}
                                    value={item.answerOptionId}
                                    checked={selectedAnswer === item.answerOptionId}
                                    onClick={()=>onValueChange(item.answerOptionId)} />
                                    <label className="form-check-label">{item.answerText }</label>
                                </div>
                            ))}
                            
                        </div>
                        <span className="text-danger">{selectedError}</span>
                        <div className="form-group">
                            <button onClick={()=>submitMultipleQuestionAnswer(question.examQuestionId)} className="btn btn-primary btn-xs">Submit</button>
                        </div>
                    </div>)}
                    </>:
                    <div  style={{border:"1px solid #e2d9d9",padding:"5px", marginBottom:"10px"}}>                          
                        <p>{parse(question.answeredText)}</p>
                    </div>
                    }
            </div>
        </div>
        </>
    )
}

export default LoadSingleQuestion
