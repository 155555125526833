import  FormValidator  from '../../../helpers/form-validator'

  const AssignmentRule = () =>  new FormValidator([
    {
      field: 'classSectionId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class Section is Required'
    },
    { 
      field: 'subjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'topicName', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Topic is Required'
    },
    { 
      field: 'taskDetail', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Detial is Required'
    },
    { 
      field: 'submissionDate', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Date is Required'
    }
  ])

  const AppliedtoRule = () =>  new FormValidator([
    {
      field: 'studentIds', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'select at least one student'
    }
  ])
  const GrdadeRule = () =>  new FormValidator([
    { 
      field: 'maxMark', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Maxium mark is Required'
    }
  ])

  const DocumentRule = () =>  new FormValidator([
    { 
      field: 'documentFile', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'File is Required'
    }
  ])


  export default  {AssignmentRule,
                    AppliedtoRule,
                    GrdadeRule,
                    DocumentRule}
  