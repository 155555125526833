import React, { useState, useEffect } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import QuestionBankDataService from '../../../services/QuestionBankDataService'
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import CommonDataService from '../../../services/CommonDataService'
import ClassSubjectDataService from '../../../services/ClassSubjectDataService';
import Loader from '../../ui/loader/Loader';
import parse from 'html-react-parser';

let classesListIds=""
let QuestionTypeIds=""
let subjectListIds = ""
const ListQuestion = (changed) => {

    const [questions,setQuestions] = useState([])
    const [submitted,setSubmitted] =useState(false)
    const [classes,setClasses]=useState([])
    const [subjects,setSubjects]=useState([])    
    const [questionTypes,setQuestionTypes]=useState([])
    const [searchQus,setSearchQus]=useState("")
    // const [selectedClasses,setSelectedClasses] = useState("")
    // const [selectedQuestionTypes,setSelectedQuestionTypes] = useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [loading,setLoading] = useState(false);

    useEffect(() =>{
        fetchTeacherCurrentClasses()
        fetchQuestionTypes()
        fetchTeacherCurrentSubjects(classesListIds)
    },[]) 

    const fetchTeacherCurrentClasses = async () =>{
        let classesList=[]
        await TeacherAssociationDataService.getTeacherCurrentClasses() 
        .then(response => {
            
            response.data.result.map((classdata,index)=>(
                classesList.push({id:classdata.classesId,value:classdata.classesName,isChecked:false})
            )); 
            setClasses(classesList)
            
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchTeacherCurrentSubjects = async (classesId) => {
        let SubjectList = []
        await ClassSubjectDataService.getTeacherSubjects(classesId)
            .then(response => {
                response.data.result.map((subjectdata, index) => (
                    SubjectList.push({ id: subjectdata.classSubjectId, value: subjectdata.subjectName, isChecked: false })
                ));
                setSubjects(SubjectList)

            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }
    
    const fetchQuestionTypes = async () =>{
        let QuesTypes=[]
        await CommonDataService.allQuestionTypes() 
        .then(response => {
            response.data.result.map((qusType,index)=>(
                QuesTypes.push({id:qusType.questionTypeId,value:qusType.questionTypeName,isChecked:false})
            )); 
            setQuestionTypes(QuesTypes)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    useEffect(() =>{
        setLoading(true);
        fetchQuestions()
    },[submitted,changed,classes,isfilterModified]) 

    let values={"isUserPosts":true,
     "questionText":searchQus,
     "questionTypeList":QuestionTypeIds,
     "classesList":classesListIds,
     "subjectList":subjectListIds,
     "isPaginate":false,
     "pageSize":0,
     "pageNumber":0
}


    const fetchQuestions = async () =>{
        await QuestionBankDataService.findQuestion(values) 
        .then(response => {
        setQuestions(response.data.result)
        setLoading(false)
       })
       .catch(error => {
           const errorMsg=error.message;   
                    
       })
    }
 

    const handleClickDelete = async id =>{
        await QuestionBankDataService.deleteQuestion(id) 
        .then(response => {
            setSubmitted(!submitted);
        })
        .catch(error => {
            const errorMsg=error.message;            
        })
     }
      
      const deleteQuestion = async id => {
          confirmAlert({
              customUI: ({ onClose }) => {
                return (
                  <div className='custom-ui'>
                    <h1>Are you sure?</h1>
                    <p>You want to delete this?</p>
                    <button  onClick={onClose}>No</button>
                    <button className="btn-alert-danger"
                      onClick={() =>{handleClickDelete(id);
                        onClose();
                      }}
                    >
                      Yes, Delete it!
                    </button>
                  </div>
                );
              }
            });
         
      }


      const handleCheckClasses = (event) =>{
        let chkclasses =classes
        classesListIds=""
        chkclasses.forEach(classdata => {
           if (classdata.value === event.target.value)
           {
                classdata.isChecked =  event.target.checked
               
           }
           if(classdata.isChecked)
           {
               if(classesListIds.length >0 || classesListIds !="")
               {
                   classesListIds+=","
               }
               classesListIds+=classdata.id
           }
        })
        fetchTeacherCurrentSubjects(classesListIds)
        setClasses(chkclasses)
        setisFilterModified(!isfilterModified)
      }

      const handleCheckQuestionTypes = (event) =>{
        let chkQuestionTypes =questionTypes
        QuestionTypeIds=""
        chkQuestionTypes.forEach(qusType => {
           if (qusType.value === event.target.value)
           {
            qusType.isChecked =  event.target.checked
               
           }
           if(qusType.isChecked)
           {
               if(QuestionTypeIds.length >0 || QuestionTypeIds !="")
               {
                QuestionTypeIds+=","
               }
               QuestionTypeIds+=qusType.id
           }
        })
        setQuestionTypes(chkQuestionTypes)
        setisFilterModified(!isfilterModified)
      }


      const handleCheckSubjects = (event) => {
        let chksubject = subjects
        subjectListIds = ""
        chksubject.forEach(subjectdata => {
            if (subjectdata.value === event.target.value) {
                subjectdata.isChecked = event.target.checked

            }
            if (subjectdata.isChecked) {
                if (subjectListIds.length > 0 || subjectListIds != "") {
                    subjectListIds += ","
                }
                subjectListIds += subjectdata.id
            }
        })
        setSubjects(chksubject)
        setisFilterModified(!isfilterModified)
    }

      const serachByText=()=>{
        setisFilterModified(!isfilterModified)
      }
  
  return (
        <div className="row">
            <div className="col-md-3">
                <div className="card card-outline card-primary">
                    <div className="text-center">
                        <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                    </div>
                    <hr/>
                    <div className="col-md-12">
                        <div className="input-group">
                            <div className="input-group input-group-sm">
                                <input onChange={event => setSearchQus(event.target.value)} value={searchQus} name="searchQus" id="searchQus" className="form-control form-control-navbar" type="text" placeholder="Type Question" aria-label="Search"/>
                                <div className="input-group-append">
                                    <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                        <i className="fas fa-search"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="col-md-12 ">
                        <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Question type</b></h6>
                        <ul className="list-group list-group-unbordered mb-3">
                        {questionTypes.map((quesType,index)=>(
                                <li key={index} className="list-group-item">
                                    <input key={quesType.id} onClick={event=>handleCheckQuestionTypes(event)} type="checkbox" checked={quesType.isChecked} value={quesType.value} /> {quesType.value}
                                </li>

                            ))}
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <h6 style={{marginTop:"10px",marginBottom:"10px"}}><b>Classes</b></h6>
                        <ul className="list-group list-group-unbordered mb-3">
                            {classes.map((classdata,index)=>(
                                <li key={index} className="list-group-item">
                                    <input key={classdata.id} onClick={event=>handleCheckClasses(event)} type="checkbox" checked={classdata.isChecked} value={classdata.value} /> {classdata.value}
                                </li>

                            ))}
                        </ul>
                    </div>
                    <div className="col-md-12">
                        <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Subjects</b></h6>
                        <ul className="list-group list-group-unbordered mb-3">
                            {subjects.map((subjectdata, index) => (
                                <li key={index} className="list-group-item">
                                    <input key={subjectdata.id} onClick={event => handleCheckSubjects(event)} type="checkbox" checked={subjectdata.isChecked} value={subjectdata.value} /> {subjectdata.value}
                                </li>

                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-md-9">
            {loading? (<Loader/>) : 
            <>
            {questions.length>0?            
            (
            <div className="accordion" id="accordionExample">
                {questions.map((question,index) =>(

                        <div className="card card-outline card-primary" key={index}>
                            <div className="card-header" id="headingOne">
                                    <i className="d-flex justify-content-between"> 
                                        <p className="text-muted text-xs">Class: {question.classesName}</p>
                                        <p className="text-muted text-xs">Subject: {question.classSubjectName}</p>
                                        <p className="text-muted text-xs">Question Type: {question.questionTypeName}</p>
                                        <button onClick={()=>deleteQuestion(question.questionId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                            <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                        </button>
                                    </i>
                                <div className="text-justify">{parse(question.questionText)} </div>
                                <i className="d-flex justify-content-between"> 
                                    <p className="text-muted text-xs">Posted:{question.postedBy} on {question.postedOn}</p>
                                    <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target={"#collapse"+question.questionId} aria-expanded="false" aria-controls={"collapse"+question.questionId}>
                                        <i className="fa fa-edit"></i> Answer
                                    </button>
                                </i>
                                
                            </div>
                            <div id={"collapse"+question.questionId} className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                                <div className="card-body">
                                    {question.hasMultipleOptions?<>
                                        {question.answerOptionList.map((options,index) =>(
                                        <p key={index} className={options.isCorrectAnswer?"text-success":""}><i>{options.answerText}</i></p>
                                    ))}                                                    
                                    </>: <p><i>{parse(question.answerText)}</i></p>}
                                    
                                </div>
                            </div>
                        </div>
                ))}
                        </div>
            )
            :
                <div className="alert alert-info alert-dismissible">
                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                </div>
            }
        </>
        }
            </div>
        </div>
  )
}

export default ListQuestion
