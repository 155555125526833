import React, { useState, useEffect } from 'react';
import ClassSubjectDataService from '../../../../services/ClassSubjectDataService'
import TeacherAssociationDataService from '../../../../services/TeacherAssociationDataService'
import StudentAttendanceReportDataService from '../../../../services/StudentAttendanceReportDataService'
import DetailedPdfReport from './DetailedPdfReport';
import Loader from '../../../ui/loader/Loader';


const DetailedAttenceReport = () => {
    const [fromDate, setFormDate] = useState('')
    const [toDate, setToDate] = useState('')
    const [allClasses, setAllClasses] = useState([])
    const [classSubjects, setClassSubjects] = useState([])
    const [classId, setClassId] = useState(0)
    const [subjectId, setSubjectId] = useState(0)
    const [isReporeView, setisReportView] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [reportData, setReportdata] = useState([])
    const [reportTitle, setReportTitle] = useState("")
    const [reportSubTitle, setReportSubTitle] = useState("")
    const [headerImage, setHeaderImage] = useState("")
    const [isGetSubmitted, setIsGetSubmitted] = useState(false)

    useEffect(() => {
        fetchClasses();
    }, [])

    useEffect(() => {
        fetchClassSubjects();
    }, [classId])


    const changeClass = async (value) => {
        setisReportView(false)
        setReportdata([])
        setReportTitle("")
        setReportSubTitle("")
        setClassId(value)
    }

    const changeSubject = async (value) => {
        setisReportView(false)
        setReportdata([])
        setReportTitle("")
        setReportSubTitle("")
        setSubjectId(value)
    }

    const changeFromDate= async (value) => {
        setisReportView(false)
        setReportdata([])
        setReportTitle("")
        setReportSubTitle("")
        setFormDate(value)
    }

    const changeToDate = async (value) => {
        setisReportView(false)
        setReportdata([])
        setReportTitle("")
        setReportSubTitle("")
        setToDate(value)
    }

    const fetchClasses = async () => {
        await TeacherAssociationDataService.getTeacherCurrentSections()
            .then(response => {
                setAllClasses(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchClassSubjects = async () => {
        await ClassSubjectDataService.getClassSubjectWithClassSection(parseInt(classId))
            .then(response => {
                setClassSubjects(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const getAttendance = async () => {
        setIsGetSubmitted(true)
        let data={
            fromDate:fromDate,
            toDate:toDate,
            classSectionId:classId,
            classSubjectId:subjectId
        }
        setIsLoading(true)
        await StudentAttendanceReportDataService.getDetailedStudentAttendanceReport(data)
            .then(response => {   
                setReportdata(response.data.result.reportData)                
                setReportTitle(response.data.result.reportTitle)             
                setReportSubTitle(response.data.result.reportSubTitle)
                setHeaderImage(response.data.result.headerFileName)
                setIsLoading(false)
                setisReportView(true)
            })
            .catch(error => {
                setIsLoading(false)
            })
    }

  

    return (
        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Attendance Report</h5>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                        
                        <div className="col-md-3">
                                    <div className="card card-outline card-primary">
                                        <div className="text-center">
                                            <h5 style={{ marginTop: "10px" }}>Filtering Options</h5>
                                        </div>
                                        <hr />
                                        <div className="col-md-12">
                                            <div className="form-group">                                                
                                            <label>From</label>
                                            <input onChange={event => changeFromDate(event.target.value)} value={fromDate} name="fromDate" id="fromDate" className="form-control" type="date"  aria-label="Topic.." />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                            <label>To</label>
                                            <input onChange={event => changeToDate(event.target.value)} value={toDate} name="toDate" id="toDate" className="form-control" type="date"  aria-label="Topic.." />
                                            </div>
                                        </div> 
                                        <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class</label>
                                            <select onChange={event => changeClass(event.target.value)} value={classId} name="classId" className="form-control">
                                                <option value="">-- select class --</option>
                                                {allClasses.map(item => (
                                                    <option
                                                        key={item.classSectionId}
                                                        value={item.classSectionId}
                                                    >
                                                        {item.classSectionName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Class Subject</label>
                                            <select onChange={event => changeSubject(event.target.value)} value={subjectId} name="subjectId" className="form-control">
                                                <option value="">-- select subject --</option>
                                                {classSubjects.map(item => (
                                                    <option
                                                        key={item.classSubjectId}
                                                        value={item.classSubjectId}
                                                    >
                                                        {item.subjectName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>    
                                    <div className="col-md-12">
                                        <button onClick={()=>getAttendance()} className="btn btn-primary btn-block">Get Report</button>
                                </div>   
                                <br/>                                       
                                    </div>
                                </div>
                        {isLoading? <Loader/>:
                    <>
                    {(isGetSubmitted && reportData.length>0) || !isGetSubmitted ?
                            <div className="col-md-9">
                                {isReporeView && 
                                <DetailedPdfReport  
                                    reportData={reportData} 
                                    reportTitle={reportTitle} 
                                    reportSubTitle={reportSubTitle}
                                    headerImage={headerImage}/>
                                }
                            </div>
                        :
                            <div className="col-md-9">
                                <div className="alert alert-info alert-dismissible">
                                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                                </div>
                            </div>
                        }
                    </>
                    }
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default DetailedAttenceReport
