import React, { useState, useEffect } from 'react';
import {Modal,Button} from 'react-bootstrap'
import ClassSectionDataService from "../../../services/ClassSectionDataService"
import Loader from '../../ui/loader/Loader'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import ValidationRule from './ValidationRule'
import LearnPagination from '../../ui/pagination/LearnPagination';
import CommonDataService from '../../../services/CommonDataService';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"
let classesListIds = ""
const ClassSection = (props) => {
    const validator= ValidationRule;

    const [show, setShow] = useState(false);
    const [classesName, setClassesName] = useState('');
    const [sectionName, setSectionName] = useState('');
    const [classSectionId, setclassSectionId] =useState(0)
    const [isEdit, setIsEdit] =useState(false);
    const [formTitle, setFormTitle] = useState('');
    const [buttonName, setButtonName] = useState('');
    const [allclassSection, setAllClassSection] = useState([]);
    const [submitted,setSubmitted]=useState(false);
    const [loading,setLoading] = useState(false);
    
    const [isfilterModified,setisFilterModified]=useState(false)
    const [allclasses, setAllClasses] = useState([])

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        classList:''
    })

    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false);
    
    var validationData={
        classesName:classesName,
        sectionName:sectionName,
        validation:validation,
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const createClassSection = () => {
        resetForm();
        setclassSectionId(0);
        setIsEdit(false);
        setFormTitle('Add');
        setButtonName('Save');
        handleShow();
    }

    const editClassSection = sectionId => {
         ClassSectionDataService.getClassSection(sectionId)
        .then(response => {
            setClassesName(response.data.result.classesName)
            setSectionName(response.data.result.sectionName)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })

        setclassSectionId(sectionId);
        setIsEdit(true);
        setFormTitle('Edit');
        setButtonName('Update');
        handleShow();
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await ClassSectionDataService.deleteClassSection(id) 
        .then(response => {
            LearnToast(success,"Class Section Deleted Successfully")
            setLoading(false)
            setSubmitted(!submitted);
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }              
        })
   }

 const deleteClassSection = id => {
    confirmAlert({
        customUI: ({ onClose }) => {
          return (
            <div className='custom-ui'>
              <h1>Are you sure?</h1>
              <p>You want to delete this?</p>
              <button  onClick={onClose}>No</button>
              <button className="btn-alert-danger"
                onClick={() =>{handleClickDelete(id);
                  onClose();
                }}
              >
                Yes, Delete it!
              </button>
            </div>
          );
        }
      });
      };

   

    const resetForm = () =>{
        setClassesName('');
        setSectionName('');
        setclassSectionId(0);
        setValidation(validator.valid());
        setValidateSubmitted(false);
    }

    useEffect(() => {
        setParametes()
    }, [submitted,isfilterModified])

    useEffect(() => {
        fetchClassSection();
    }, [params])

    const setParametes = () => {
        setParams({
            classList:classesListIds.toString()
        })
    }


    const fetchClassSection = async () =>{
    setLoading(true);
     await ClassSectionDataService.findClassSection(params) 
       .then(response => {
           setAllClassSection(response.data.result.itemList)
           setTotalPages(response.data.result.totalPages)
           setActivePage(response.data.result.activePageNumber)
           setLoading(false)
      })
      .catch(error => {
          const errorMsg=error.message;            
      })
    }
 

    const submitForm  = async () => {
        var data = {
            classSectionId: classSectionId,
            classesName: classesName,
            sectionName: sectionName
          };
          const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)

        if (validate.isValid) {
            setLoading(true)
            isEdit ? (
                await ClassSectionDataService.updateClassSection(data)
                    .then(response => {
                        setLoading(false)
                        LearnToast(success,"Class Section Updated Successfully")
                    })
                    .catch(error => {
                        
                        let errorMsg=[]
                        let msgText=""
                        setLoading(false)      
                        if(error.response.status==500)
                        {
                            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                        }
                        else
                        {
                            errorMsg=error.response.data.errors
                            errorMsg.map((item,index)=>{
                                if(msgText.length>0)
                                {
                                msgText = msgText+","
                                }
                                msgText=msgText+item
                            })            
                                LearnToast(danger,msgText)
                        
                        }     
                    })
            ) : (
                    await ClassSectionDataService.createClassSection(data)
                        .then(response => {
                            setLoading(false)
                            LearnToast(success,"Class Section Created Successfully")
                        })
                        .catch(error => {
                            let errorMsg=[]
                            let msgText=""
                            setLoading(false)      
                            if(error.response.status==500)
                            {
                                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                            }
                            else
                            {
                                errorMsg=error.response.data.errors
                                errorMsg.map((item,index)=>{
                                    if(msgText.length>0)
                                    {
                                    msgText = msgText+","
                                    }
                                    msgText=msgText+item
                                })            
                                    LearnToast(danger,msgText)
                            
                            }     
                        })
                )
            setSubmitted(!submitted);
            resetForm();
            handleClose();
        }
    }

    useEffect(() => {
        fetchTeacherAllCurrentClasses()
    }, [])

    const fetchTeacherAllCurrentClasses = async () => {
        let classesList = []
        await CommonDataService.allClasses() 
            .then(response => {

                response.data.result.map((classdata, index) => (
                    classesList.push({ id: classdata.classesId, value: classdata.classesName, isChecked: false })
                ));
                setAllClasses(classesList)

            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const handleCheckClasses = (event) => {
        let chkclasses = allclasses
        classesListIds = ""
        chkclasses.forEach(classdata => {
            if (classdata.value === event.target.value) {
                classdata.isChecked = event.target.checked

            }
            if (classdata.isChecked) {
                if (classesListIds.length > 0 || classesListIds != "") {
                    classesListIds += ","
                }
                classesListIds += classdata.id
            }
        })
        setAllClasses(chkclasses)
        setisFilterModified(!isfilterModified)
    }

    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 

    return (

        <>
         <ToastContainer />    
            <Modal show={show} onHide={handleClose} dialogClassName="primary">
                <Modal.Header closeButton >
                    <Modal.Title>{formTitle} Class Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form>
                    <div className="form-group">
                            <label htmlFor="classesName">Classes Name</label>
                            <input type="text" onChange={event => setClassesName(event.target.value)} value={classesName} name="classesName" id="classesName" className={validateOutput.classesName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Classes Name" />
                            <span className="text-danger">{validateOutput.classesName.message}</span>
                        </div>
                        <div className="form-group">
                            <label htmlFor="sectionName">Section Name</label>
                            <input type="text" onChange={event => setSectionName(event.target.value)} value={sectionName} name="sectionName" id="sectionName" className={validateOutput.sectionName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Section Name" />
                            <span className="text-danger">{validateOutput.sectionName.message}</span>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button type="submit" variant="primary" onClick={submitForm}>
                        {buttonName}
                    </Button>
                </Modal.Footer>
            </Modal>


            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Class Section</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={createClassSection}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">                        
                            <div className="row">
                            <div className="col-md-3">
                            <div className="card card-outline card-primary">
                                <div className="text-center">
                                    <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                </div>
                                <hr />
                                <div className="col-md-12">
                                    <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Classes</b></h6>
                                    <ul className="list-group list-group-unbordered mb-3">
                                        {allclasses.map((classdata, index) => (
                                            <li key={index} className="list-group-item">
                                                <input key={classdata.id} onClick={event => handleCheckClasses(event)} type="checkbox" checked={classdata.isChecked} value={classdata.value} /> {classdata.value}
                                            </li>

                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                        {loading? (<Loader/>) :
                                <div className="col-md-9">
                                {allclassSection.length>0?
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                    <table className="table table-bordered table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Class Name</th>
                                                <th scope="col">Section Name</th>
                                                <th className="text-right">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(allclassSection.map((classSection, index) => (
                                                <tr key={classSection.classSectionId}>
                                                    <th scope="row">{index + 1}</th>
                                                    <td>{classSection.classesName}</td>
                                                    <td>{classSection.sectionName}</td>
                                                    <td className="text-right">
                                                    <div className="d-flex justify-content-end">
                                                        <button onClick={() => editClassSection(classSection.classSectionId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Edit"><i className="fa fa-edit text-info"></i></button>
                                                        <button onClick={() => editClassSection(classSection.classSectionId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete"><i className="fa fa-trash text-danger"></i></button>
                                                    </div>
                                                    </td>
                                                </tr>
                                            )))}
                                        </tbody>
                                    </table>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                </>
                                :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
);
};

export default ClassSection;