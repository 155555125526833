import  ApiDataService  from './ApiDataService'

const createEmailCofiguration= data => {
  return ApiDataService.post("emailconfiguration", data);
};
  
const defaultEmailCofiguration= () => {
    return ApiDataService.get("emailconfiguration/default");
  };

  const listEmailCofiguration= () => {
    return ApiDataService.get("emailconfiguration/list");
  };

  const getEmailCofiguration = (id) => {
    let data={"id":id}
    return ApiDataService.getWithParam("emailconfiguration",data);
  };

  const updateEmailCofiguration= data => {
    return ApiDataService.put("emailconfiguration", data);
  };

const deleteEmailCofiguration = (id) => {
  let values={'id':id}
  return ApiDataService.remove("emailconfiguration", values);
};


export default {createEmailCofiguration,
                defaultEmailCofiguration,
                listEmailCofiguration,
                getEmailCofiguration,
                updateEmailCofiguration,
                deleteEmailCofiguration};