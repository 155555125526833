import React, { useState, useEffect } from 'react'
import Loader from '../../ui/loader/Loader';
import { confirmAlert } from 'react-confirm-alert'
import ExamQuestionSettingsDataService from '../../../services/ExamQuestionSettingsDataService'
import history from "../../../util/history"
import ExamQuestionDataService from '../../../services/ExamQuestionDataService'
import parse from 'html-react-parser';

const ExamQuestionSetting = (props) => {
    const [allQuestionSettings, setAllQuestionSettings] = useState([])
    const [questionArray, setQuestionArray] = useState([])
    const [selectedQuestions, setSelectedQuestions] = useState([])
    const [examQuestionSettingId, setExamQuestionSettingId] = useState(0)
    const [searchQues, setSearchQues] =useState("") 
    const [submitted, setSubmitted] =useState(false)


    useEffect(() => {  
        //history.go("/app/online-exam");
        allExamQuestionSettings()
    }, [])

    useEffect(() => { 
        if(examQuestionSettingId>0)
        {
            findExamQuestions()  
            listExamQuestions()
        }
    }, [examQuestionSettingId,submitted])

    const allExamQuestionSettings = async () => {
        await ExamQuestionSettingsDataService.allExamQuestionSetting(props.match.params.id)
        .then(response => {
            setAllQuestionSettings(response.data.result)
        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }
    

    const findExamQuestions = async () => {
        await ExamQuestionDataService.findExamQuestion(examQuestionSettingId,searchQues)
        .then(response => {
            setQuestionArray(response.data.result)
        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }

    const listExamQuestions = async () => {
        await ExamQuestionDataService.allExamQuestion(examQuestionSettingId)
        .then(response => {
            setSelectedQuestions(response.data.result)
        })
        .catch(error => {
            const errorMsg = error.message;
        })
    }

    const searchQuestions = () =>{
        findExamQuestions()
    }

    const addToSelectedQuestions = async (questionId, exmQusSettingId) =>{
        var data={
            examQuestionId:0,
            examQuestionSettingId:parseInt(exmQusSettingId),
            questionId:questionId,
            questionText:"",
            displayOrder:0
        }
        await ExamQuestionDataService.createExamQuestion(data)
        .then(response => {
        })
        .catch(error => {
        })
        setSubmitted(!submitted)
    }


    const handleClickDelete = async id =>{
        await ExamQuestionDataService.deleteExamQuestion(id) 
            .then(response => {
                setSubmitted(!submitted);
            })
            .catch(error => {          
            })
   }


    const removeQuestion = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
       
    }

    return (
        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Question Settings</h5>
                            </div>
                            <br />
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-row">
                                    <div className="col-md-4">                                   
                                        <select className="form-control" onChange={event => setExamQuestionSettingId(event.target.value)} value={examQuestionSettingId} name="examQuestionSettingId" id="examQuestionSettingId">
                                            <option value="">--select type --</option>
                                            {allQuestionSettings.map((item,index)=>(
                                                <option 
                                                key={index}
                                                value={item.examQuestionSettingId} 
                                                >
                                                    {item.questionTypeName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="input-group">
                                            <input type="text" onChange={event => setSearchQues(event.target.value)} value={searchQues} name="searchQues" id="searchQues" className="form-control" placeholder="Serach Question" />
                                            <div className="input-group-append">
                                                <button onClick={()=>searchQuestions()} className="btn btn-primary" type="submit">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                     </div>
                                </div>
                                <br/>
                            </div>
                            <div className="col-md-12 scrollbar scrollbar-primary card" style={{ height: "150px" }}>
                                <div className="force-overflow">
                                    <ul class="list-group">
                                        {questionArray.map((item, index) => (
                                            <li key={index} class="list-group-item" style={{ padding: "0 .5rem" }}>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <h6 style={{ lineHeight: "2" }}>{parse(item.questionText)}</h6>
                                                    </div>
                                                    <div>
                                                        <button type="button" onClick={()=>addToSelectedQuestions(item.questionId,item.examQuestionSettingId)} class="btn btn-link" data-toggle="tooltip" data-placement="top" title="Add">
                                                            <span className="text-success"><i className="fa fa-plus"></i></span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </li>))}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-12">
                                {
                                    allQuestionSettings.map((item,index)=>(

                                    (item.examQuestionSettingId==examQuestionSettingId)?
                                    <h5 className="text-center text-info">
                                            {"Selected '" + selectedQuestions.length +"' " + item.questionTypeName+
                                            " Question out of '" + item.availableQuestions +"'"}
                                    </h5>:<></>))}
                             </div>
                            <div className="col-md-12 scrollbar scrollbar-primary card" style={{ height: "150px" }}>
                                <div className="force-overflow">
                                    <ul class="list-group">
                                        {selectedQuestions.map((item, index) => (
                                        <li key={index} class="list-group-item" style={{ padding: "0 .5rem" }}>
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <h6 style={{ lineHeight: "2" }}>{parse(item.questionText)}</h6>
                                                </div>
                                                <div>
                                                    <button type="button" onClick={()=>removeQuestion(item.examQuestionId)} class="btn btn-link" data-toggle="tooltip" data-placement="top" title="Add">
                                                        <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                    </button>
                                                </div>
                                            </div>
                                        </li>))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ExamQuestionSetting
