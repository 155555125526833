import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser';

const LoadEvalSingleQuestion = (props) => {
    const [question, setQuestion] = useState({})


    useEffect(() => {
        setQuestion(props.question)
    }, [])

  
  return (
    <div className="d-flex justify-content-between mt-1">
        <div className="">
                {question.questionSerialNo}. &nbsp;
        </div>
        <div className="flex-grow-1" style={{fontSize:"18px"}}>
            <p style={{ textAlign: "justify", marginBottom: "3px" }}>{parse(""+ question.questionText)}</p>
            
            <div className="d-flex justify-content-between">
            {question.isAnswered?
                <div style={{border:"1px solid #e2d9d9", padding:"5px", width:"95%"}}> 
                    <i>Answer Text:</i>&nbsp;&nbsp;   
                    <p className={((question.isCorrectAnswer && question.isEvaluated)?"text-success":
                    (!question.isCorrectAnswer && question.isEvaluated)?"text-danger":"")}> 
                        {parse(""+question.answeredText)}
                    </p>
                </div>
                :
                <p className="text-danger">Not Answered</p>
            }
                <p className="text-right">{question.marksAwarded}</p>               
            </div>
            <div className="row">
                <p><i>Answer Key:  </i></p>&nbsp;&nbsp;
                <p className="text-success">{parse(""+question.answerText)}</p>
            </div>
        </div>
    </div>
  )
}

export default LoadEvalSingleQuestion
