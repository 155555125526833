import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../ui/loader/Loader';
import ExaminationDataService from "../../../services/ExaminationDataService"
import LearnPagination from '../../ui/pagination/LearnPagination';

let option = [];
let studentIds=""
const StudentExamination = () => {
    const [searchExam,setSearchExam]=useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [loading,setLoading] = useState(false);

    const [exams, setExams]=useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        examName:searchExam
         })

   
    useEffect(() => {
        setParametes()
    }, [isfilterModified])

    useEffect(() => {
        fetchExams()
    }, [params])  

    const setParametes = () => {
        setParams({
        examName:searchExam
        })
    }

    const fetchExams = async () =>{
        setLoading(true)
        await ExaminationDataService.findExamination(params) 
        .then(response => {
        setExams(response.data.result.itemList)
        setTotalPages(response.data.result.totalPages)
        setActivePage(response.data.result.activePageNumber)
        setLoading(false)
       })
       .catch(error => {
           setLoading(false)                      
       })
    }
 

   


    const serachByText=()=>{
        setisFilterModified(!isfilterModified)
      }


  return (
    <>
        {loading?<Loader/>:
        <>
           
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Exam</h5>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-3">
                            <div className="card card-outline card-primary">
                                <div className="text-center">
                                    <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                </div>
                                <hr/>
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <div className="input-group input-group-sm">
                                            <input onChange={event => setSearchExam(event.target.value)} value={searchExam} name="searchExam" id="searchExam" className="form-control form-control-navbar" type="text" placeholder="Exam Name" aria-label="Search"/>
                                            <div className="input-group-append">
                                                <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                            </div>
                            </div>
                          
                                <div className="col-md-9">
                                {exams.length>0?
                                <>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                        {exams.map((item,index)=>(
                                        <div className="card card-outline card-primary" key={item.examId}>
                                            <div className="card-header" id="headingOne">
                                                <i className="d-flex justify-content-between"> 
                                                    <p className="text-muted text-xs"></p>
                                                    <p className="text-muted text-xs"></p>
                                                    <p className="text-justify">Total Mark : {item.maxMarks}</p>
                                                    {/* <button className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                    </button> */}
                                                </i>
                                                <div className="d-flex justify-content-between">
                                                    <h5>{item.examName}</h5> 
                                                    
                                                </div>                                         
                                                
                                                <div className="d-flex justify-content-between">
                                                    {/* <Link className="btn btn-link" to={{pathname: '/app/evaluate-exam/'+item.examId+'/'+item.examName,props:{ id: item.examId}}}>
                                                        <span className="text-warning"><i className="fa fa-id-card"></i> Valuate</span>
                                                    </Link> */}
                                                    {item.isResultPublished ?
                                                     <>
                                                         <Link className="btn btn-link" to={{pathname: '/app/student-exam-result/'+item.examMstId,props:{ id: item.examMstId}}}>
                                                            <span className="text-success"><i className="fa fa-search"></i> Result View</span>
                                                        </Link>
                                                    </>
                                                    :<></>}
                                                </div>
                                            </div>
                                        </div>
                                        ))}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                </>
                                :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }    
                                
                            </div>                 
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        }
    </>
  )
}

export default StudentExamination
