import  ApiDataService  from './ApiDataService'

const createExam = data => {
  return ApiDataService.post("exam", data);
};

const findExam = (values) => {
    return ApiDataService.getWithParam("exam/find",values);
  };

  const deleteExam = id => {
    let values={'id':id}
    return  ApiDataService.remove("exam",values);
  };

  const getStudentQuestionPaper = (examId,studentId) => {
    let values={"examId":examId,"studentId":studentId}
    return ApiDataService.getWithParam("exam/studentquestionpaper",values);
  };
  
  const getUserQuestionPaper = (examId) => {
    let values={"examId":examId}
    return ApiDataService.getWithParam("exam/userquestionpaper",values);
  };

  const publishExam = id => {
    let data={"examId":id,"published":true,"resultPublished":false}
    return ApiDataService.put("exam/publishexam", data);
  }

  const findStudentExam = (values) => {
    return ApiDataService.getWithParam("exam/findstudentexams",values);
  };

  const TodaysStudentExam = () => {
    return ApiDataService.get("exam/todaysstudentexams");
  };

  const getExamStudenList = (examId) => {
    let values={"examId":examId}
    return ApiDataService.getWithParam("exam/examstudentslist",values);
  };

  const getSubmissionStatus= (examId) => {
    let values={"examId":examId}
    return ApiDataService.getWithParam("exam/submissionstatus",values);
  };

  const submitExam= (examId,examSerial) => {
    let values={"examId":examId,"examSerial":examSerial}
    return ApiDataService.put("exam/submitexam",values);
  };


export default {createExam,
                findExam,
                deleteExam,
                getStudentQuestionPaper,
                getUserQuestionPaper,
                publishExam,
                findStudentExam,
                TodaysStudentExam,
                getExamStudenList,
                getSubmissionStatus,
                submitExam};