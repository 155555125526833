import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser';
import ExamEvalDataService from '../../../../services/ExamEvalDataService'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../../ui/alert/toast/ToastType"
import {LearnToast} from "../../../ui/alert/toast/LearnToast"

const LoadSingleQuestion = (props) => {
    const [question, setQuestion] = useState({})
    const [mark, setMark] = useState(0)
    const [markError, setMarkError] =useState("")
    const [slNo, setSlNo] = useState(0)


    useEffect(() => {
        setQuestion(props.question)
        setSlNo(props.question.questionSerialNo)
    }, [])

    const saveMark= async (studentID,examQuesId) =>
    {
        let SlNo=slNo
        setMarkError("")
        let data={
            examQuestionId:parseInt(examQuesId),
            studentId:parseInt(studentID),
            isCorrect:(parseFloat(mark)>0)?true:false,
            markAwarded:parseFloat(mark)

        }
        if(parseFloat(mark)>=0 && parseFloat(mark)<=props.maxMark)
        {
            await ExamEvalDataService.saveExamEvaluation(data) 
                .then(response => {
                    setQuestion(response.data.result)
                    setSlNo(SlNo)
                    LearnToast(success,"Mark Saved Successfully")
            })
            .catch(error => {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")            
            })
        }
        else if(parseFloat(mark)>props.maxMark){
            setMarkError("Mark greater than max mark")
        }
        else{
            setMarkError("Mark is Required")
        }
    }

  return (
    <>
      <ToastContainer/>
        <div className="d-flex justify-content-between mt-1">
        <div className="">
                {slNo}. &nbsp;
        </div>
        <div className="flex-grow-1" style={{fontSize:"18px"}}>
            <p style={{ textAlign: "justify", marginBottom: "3px" }}>{parse(""+ question.questionText)}</p>
            
            <div className="row">
                {question.isAnswered?
                    <>
                    <div className="col-md-9" style={{border:"1px solid #e2d9d9"}}> 
                        <i>Answer Text:</i>&nbsp;&nbsp;            
                        <p className={((question.isCorrectAnswer && question.isEvaluated)?"text-success":(!question.isCorrectAnswer && question.isEvaluated)?"text-danger":"")}> {parse(question.answeredText)}</p>
                    </div>
                    <div className="col-md-3">
                        {!question.isEvaluated?
                        <>
                             <p className="text-right">--</p>
                        </>
                        :
                        <p className="text-right">{question.marksAwarded}</p>
                        }
                    </div>
                    </>:
                    <div className="col-md-9">               
                        <p className="text-danger"> Not Answered</p>
                    </div>
                }                     
            </div>
            <div className="row">
                <p><i>Answer Key:  </i></p>&nbsp;&nbsp;
                <p className="text-success">{parse(""+question.answerText)}</p>
            </div>
        </div>
    </div>
    </>
  )
}

export default LoadSingleQuestion
