import { combineReducers } from "redux";
import loginReducer from './login/loginReducer'
import MessageListReducer from './messageList/MessageListReducer'
import {persistReducer} from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const persistConfig = {
    key: 'root',
    storage,
    whitelist:['login','messages']
}

const rootReducer = combineReducers({
    login:loginReducer,
    messages:MessageListReducer
})

export default persistReducer(persistConfig, rootReducer);