import  FormValidator  from '../../../helpers/form-validator'

  const NewExamAdminRule = () =>  new FormValidator([
    {
      field: 'examTitle', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Title is Required'
    },
    { 
      field: 'examGrade', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Exam Grade is Required'
    },
    { 
      field: 'remarks', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Remark is Required'
    }
  ])

  const NewExamStaffRule = () =>  new FormValidator([
    {
      field: 'examTitle', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Title is Required'
    },
    { 
      field: 'examGrade', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Exam Grade is Required'
    },
    { 
      field: 'classesId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Class is Required'
    },
    { 
      field: 'classSubjectId', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Subject is Required'
    },
    { 
      field: 'maxMarks', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Maximum Mark is Required'
    }
  ])

  const TimeBoundRule = () =>  new FormValidator([
    {
      field: 'scheduleDate', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Date is Required'
    },
    { 
      field: 'scheduleTime', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Time is Required'
    },
    { 
      field: 'duration', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Duration is Required'
    }
  ])
  const DocumentRule = () =>  new FormValidator([
    { 
      field: 'documentFile', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'File is Required'
    }
  ])

  const MarkSettingRule = () =>  new FormValidator([
    { 
      field: 'questionType', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Question Type is Required'
    },
    { 
      field: 'requiredQuestion', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Required Questions is Required'
    },
    { 
      field: 'totalQuestion', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Total Question is Required'
    },
    { 
      field: 'questionTypemark', 
      method: 'isEmpty', 
      validWhen: false, 
      message: 'Mark/Question is Required'
    }
  ])



  export default {NewExamAdminRule,
                  NewExamStaffRule,
                  TimeBoundRule,
                  DocumentRule,
                  MarkSettingRule}

  