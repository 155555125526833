import  ApiDataService  from './ApiDataService'

const createQuestionPaperdDetail = data => {
  return ApiDataService.post("questionpaperdetail", data);
};

const getQuestionPaperdDetail = questionSectionId => {
  let values={'questionSectionId':questionSectionId}
  return  ApiDataService.getWithParam("questionpaperdetail",values);
};

const getQuestionPaperdDetailAssignable = (questionSectionId,questionText) => {
  let values={'questionSectionId':questionSectionId,"questionText":questionText}
  return  ApiDataService.getWithParam("questionpaperdetail/assignablequestions",values);
};

const deleteQuestionPaperdDetail = id => {
  let values={'id':id}
  return  ApiDataService.remove("questionpaperdetail",values);
};


export default {createQuestionPaperdDetail,
                getQuestionPaperdDetail,
                getQuestionPaperdDetailAssignable,
                deleteQuestionPaperdDetail};