import  ApiDataService  from './ApiDataService'

const createOptonalSubject = data => {
  return ApiDataService.post("optionalsubject", data);
};
  
const findOptonalSubject = (data) => {
    return ApiDataService.getWithParam("optionalsubject/find",data);
  };

  const findOptonalSubjectWithClass = (classesId) => {
    let data={"classesId":classesId}
    return ApiDataService.getWithParam("optionalsubject/classoptionalsubjects",data);
  };


const deleteOptionalSubject = (id) => {
  let values={'id':id}
  return ApiDataService.remove("optionalsubject", values);
};


export default {createOptonalSubject,
                findOptonalSubject,
                findOptonalSubjectWithClass,
                deleteOptionalSubject};