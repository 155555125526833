import  ApiDataService  from './ApiDataService'

const createOneword = data => {
  return ApiDataService.post("questionbank/saveoneword", data);
};

const createMultipleChocie = data => {
    return ApiDataService.post("questionbank/savemultiplechoice", data);
  };

const createShortAnswer = data => {
  return ApiDataService.post("questionbank/saveshortanswer", data);
};

const createParagrahQuestion = data => {
  return ApiDataService.post("questionbank/saveparagraph", data);
};

const createEssayQuestion = data => {
  return ApiDataService.post("questionbank/saveessay", data);
};

const allQuestionbank = () => {
  return  ApiDataService.get("questionbank/all");
};

const findQuestion = (values) => {
  return ApiDataService.getWithParam("questionbank/find",values);
};

const deleteQuestion = id => {
  let values={'id':id}
  return  ApiDataService.remove("questionbank/deletequestion",values);
};

export default {createOneword,
                createMultipleChocie,
                createShortAnswer,
                createParagrahQuestion,
                createEssayQuestion,
                allQuestionbank,
                deleteQuestion,
                findQuestion};