import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import Loader from '../../ui/loader/Loader';
import QuestionPaperMasterDataService from "../../../services/QuestionPaperMasterDataService"
import LearnPagination from '../../ui/pagination/LearnPagination';

let subjectListIds=""
const AdminQuestionPaper = () => {
    const [loading,setLoading] = useState(false);
    const [questionPapers,setQuestionPapers]= useState([])
    const [subjects,setSubjects]=useState([])  
    const [searchQus,setSearchQus]=useState("")    
    const [isfilterModified,setisFilterModified]=useState(false)
    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
            academicYearId:0,
            questionPaperName:searchQus,
            classSubjects:""
         })


    
//Filter

   

    const serachByText=()=>{
        setisFilterModified(!isfilterModified)
      }


      useEffect(() => {
        setParametes()
    }, [isfilterModified])

    useEffect(() => {
        fetchQuestionPapers()
    }, [params])
    

    const setParametes = () => {
        setParams({
            academicYearId:0,
            questionPaperName:searchQus,
            classSubjects:""
        })
    }

    const fetchQuestionPapers = async () =>{
        setLoading(true);
        await QuestionPaperMasterDataService.findQuestionPaperMaster(params) 
        .then(response => {
        setQuestionPapers(response.data.result.itemList)
        setTotalPages(response.data.result.totalPages)
        setActivePage(response.data.result.activePageNumber)
        setLoading(false)
       })
       .catch(error => {
        setLoading(false)   
                    
       })
    }

    //create

  return (
    <>  
            {loading?<Loader/>:
            <>               
                <div className="container">
                    <div className="jumbotron">
                        <div className="card card-primary">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Question Paper</h5>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                <div className="col-md-3">
                                <div className="card card-outline card-primary">
                                    <div className="text-center">
                                        <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                    </div>
                                    <hr/>
                                    <div className="col-md-12">
                                    <div className="input-group">
                                        <div className="input-group input-group-sm">
                                            <input onChange={event => setSearchQus(event.target.value)} value={searchQus} name="searchQus" id="searchQus" className="form-control form-control-navbar" type="text" placeholder="Type Question Paper Name" aria-label="Search"/>
                                            <div className="input-group-append">
                                                <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <br/>
                                </div>
                                   </div>
                                </div>
                              
                                    <div className="col-md-9">
                                    {questionPapers.length>0?
                                    <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                            {questionPapers.map((item,index)=>(
                                                 <div className="card card-outline card-primary" key={item.questionPaperMstId}>
                                                 <div className="card-header" id="headingOne">
                                                     <i className="d-flex justify-content-between"> 
                                                         <p className="text-muted text-xs">Prepared By: {item.preparedBy}</p>
                                                         <p className="text-muted text-xs">Prepared On : {item.preparedOn}</p>
                                                         {/* <button className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                             <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                         </button> */}
                                                     </i>
                                                     <div className="">
                                                         <h5>{item.questionPaperName}</h5> 
                                                         <h6>{item.classSubjectName}</h6>
                                                         
                                                     </div>  
                                                     <div className="d-flex justify-content-between">
                                                    
                                                         <Link className="btn btn-link" to={{pathname: '/app/view-question-paper/'+item.questionPaperMstId,props:{ id: item.questionPaperMstId}}}>
                                                             <span className="text-warning"><i className="fa fa-search"></i> View</span>
                                                         </Link>                                                         
                                                     </div>
                                                 </div>
                                             </div>

                                            ))

                                            }
                                    <div className="row">
                                        <div className="col-md-12">
                                            <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                        </div>
                                    </div>
                                    </>
                                    :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }
    
                                    </div>
                                    
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
            }
        </>
  )
}

export default AdminQuestionPaper
