import  ApiDataService  from './ApiDataService'

const createDifficultyLevel = data => {
  return ApiDataService.post("difficultylevel", data);
};

const allDifficultyLevel= () => {
  return  ApiDataService.get("difficultylevel/all");
};

const getDifficultyLevel = id => {
  let values={'id':id}
  return  ApiDataService.getWithParam("difficultylevel",values);
};

const updateDifficultyLevel = data => {
  return ApiDataService.put("difficultylevel", data);
}

const deleteDifficultyLevel = id => {
  let values={'id':id}
  return  ApiDataService.remove("difficultylevel",values);
};

export default {createDifficultyLevel,
                allDifficultyLevel,
                getDifficultyLevel,
                updateDifficultyLevel,
                deleteDifficultyLevel};