import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, PDFViewer, BlobProvider, PDFDownloadLink } from '@react-pdf/renderer';


const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      fontSize:"12"
    },
    pageSize:{   
    },
   
      title:{
          fontSize:"14",
         textAlign:"center"
        },
        subtitle:{
            fontSize:"10",
            textAlign:"center",
            marginBottom:"10",
            marginTop:"2"
        },
        image: {
          marginVertical: 15,
          marginHorizontal: 10,
          textAlign:"center"
        }


     });


const StudentExamReport = (props) => {
  return (
    <>
    {props.reportData.length>0 &&
    <PDFViewer width="100%" height="450px">
    <>
        <Document>
          <Page size="A4" wrap={true} style={{padding:20}}>
              <View style={styles.pageSize}>
                  <View style={styles.title}>
                    <Text>{props.reportTitle}</Text>
                </View>
                <View style={styles.subtitle}>
                    <Text>{props.reportSubTitle}</Text>
                </View>
                <View style={styles.page}>
                    <View style={{width:"5%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                        <Text>Sl</Text>
                    </View>
                    <View style={{width:"35%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                        <Text>Subject</Text>
                    </View>
                    <View style={{width:"20%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                        <Text>Mark Scored</Text>
                    </View>
                    <View style={{width:"20%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                        <Text>Maximum Mark</Text>
                    </View>
                    <View style={{width:"20%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                        <Text>Mark Percent</Text>
                    </View>
                </View>

                {props.reportData.map((item, index) => (
                              <>    
                                <View style={styles.page}>
                                    <View style={{width:"5%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                                        <Text>{index+1}</Text>
                                    </View>
                                    <View style={{width:"35%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                                        <Text>{item.subjectName}</Text>
                                    </View>
                                    <View style={{width:"20%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                                        <Text>{item.markScored}</Text>
                                    </View>
                                    <View style={{width:"20%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                                        <Text>{item.maxMaks}</Text>
                                    </View>
                                    <View style={{width:"20%",border:1,borderColor:"black",textAlign:"center",padding:5}}>
                                        <Text>{item.markPercent+"%"}</Text>
                                    </View>
                                </View>
                               </>
                            ))}
                        <View style={{marginTop:30}}>
                            <View style={styles.page}>
                                <View style={{width:"33.3%",fontWeight:5,textAlign:"center"}}>
                                    <Text>Total Scored: {props.resultDetail.markScored}</Text>
                                </View>
                                <View style={{width:"33.3%",fontWeight:5,textAlign:"center"}}>
                                    <Text>Maximum  Mark: {props.resultDetail.maxMarks}</Text>
                                </View>
                                <View style={{width:"33.3%",fontWeight:5,textAlign:"center"}}>
                                    <Text>Mark Percent: {props.resultDetail.markPercent+"%"}</Text>
                                </View>
                            </View>
                        </View>
                
            </View>
          </Page>
        </Document>
    </>
</PDFViewer>
}
</>
  )
}

export default StudentExamReport
