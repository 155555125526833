import  ApiDataService  from './ApiDataService'

const createCourseLevel = data => {
  return ApiDataService.post("courselevel", data);
};

const allCourseLevel= () => {
  return  ApiDataService.get("courselevel/all");
};

const getCourseLevel = id => {
  let values={'id':id}
  return  ApiDataService.getWithParam("courselevel",values);
};

const updateCourseLevel = data => {
  return ApiDataService.put("courselevel", data);
}

const deleteCourseLevel = id => {
  let values={'id':id}
  return  ApiDataService.remove("courselevel",values);
};

export default {createCourseLevel,
                allCourseLevel,
                getCourseLevel,
                updateCourseLevel,
                deleteCourseLevel};