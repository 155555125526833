import React from 'react'
import TimelineAttendanceItem from './TimelineAttendanceItem'
import TimelineExamItem from './TimelineExamItem'
import TimelineAssignmentItem from './TimelineAssignmentItem'

const TimelineItem = (props) => {
  return (
      <>
        {props.studentActivities.map((item,index)=>(
        <div key={index}>
            {item.isAttendanceActivity && !item.isExamActivity && !item.isAssignmentActivity?
            <TimelineAttendanceItem data={item}/>
            :!item.isAttendanceActivity && item.isExamActivity && !item.isAssignmentActivity? 
            <TimelineExamItem data={item}/>
            :!item.isAttendanceActivity && !item.isExamActivity && item.isAssignmentActivity?
            <TimelineAssignmentItem data={item}/>
            :<></>}            
        </div>
        ))}
  </>
  )
}

export default TimelineItem
