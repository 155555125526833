import  ApiDataService  from './ApiDataService'

  const saveExamEvaluation = data => {
    return ApiDataService.post("examevaluation", data);
  };

  const publishExamReult = id => {
    let data={"examId":id,"published":true,"resultPublished":true}
    return ApiDataService.put("examevaluation/publishexamresult", data);
  }
  

export default {saveExamEvaluation,
                publishExamReult};