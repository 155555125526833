import React, { useState, useEffect } from 'react'
import AssignmentDataService from "../../../services/AssignmentDataService"

const EvaluateUngradedAssignment = (props) => {
    const saveMark = async () =>{    
        await props.onSavemark(props.assignmentStudentId,-2)
    }
  return (
    <div>
         <div className="input-group input-group-sm">           
            <span className="input-group-append">
            <button type="button" onClick={()=>saveMark()} className="btn btn-info btn-flat">Valuate!</button>
            </span>
        </div>
    </div>
  )
}

export default EvaluateUngradedAssignment
