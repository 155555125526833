import React, { useState } from 'react'
import StudentDataService from "../../../services/StudentDataService"
import Loader from '../../ui/loader/Loader';
import ReactSpeedometer from "react-d3-speedometer"
import ApexBarChart from '../../ui/barChart/ApexBarChart';

var examdata=[]
var categories=[]
var seriesdata=[]
const StudentExam = (props) => {
    const [examDetail, setExamDetail]= useState({})
    const [singleDetail, setSingleDetail] = useState([])
    const [isDetailView, setIsDetailView] = useState(false) 
    const [loading,setLoading] = useState(false);
    
  
  const ViewExamDetail = async (classSectionId,subjectId) =>{
   
    setLoading(true)
    
    
    await StudentDataService.getSubjectExamProfile(parseInt(props.profile.studentId),parseInt(classSectionId),parseInt(subjectId)) 
      .then(response => {
        setExamDetail(response.data.result)
        setSingleDetail(response.data.result.studentExamDetails)
        examdata="["
        {response.data.result.studentExamDetails.map((item, index)=>(        
          (
            seriesdata.push(item.markPercent),
            categories.push(item.examName)
          )
        ))}
        examdata=examdata+"]"
        setLoading(false)
     })
     .catch(error => {
         const errorMsg=error.message;            
     })
     setIsDetailView(true)
  }  

  return (
    <>
    {!isDetailView?
      <div className="row">
         <div className="col-md-4 text-center">
        <div className="card card-outline">
            <div className="card-header">
                <h5 className="card-title">Overall Exam</h5>
            </div>
            <div className="card-body box-profile">
                <ReactSpeedometer
                    maxValue={100}
                    value={props.profile.examPerformancePercent}
                    needleColor="blue"
                    startColor="red"
                    segments={10}
                    width={200}
                    height={150}
                    endColor="green"
                    />
                </div>   
            </div>                                           
        </div>
        <div className="col-md-8"> 

        <div className="card card-outline">
            <div className="card-header">
                <h5 className="card-title">Subject Exam</h5>
            </div>
            <div className="card-body box-profile">  
            {/* <h5 className="text-center">Subject Attendance</h5>  */}
            <br/>
                {props.examProfileSummaries.map((item,index)=>(
                    <div  className="row" key={index}>
                        <div className="col-md-11">
                            <div className="progress-group">
                                {item.classSubjectName} - {item.totalPercent}%
                                <div className="progress progress-sm">
                                    <div className={item.progressbarClassName} style={{ width: item.totalPercent+"%" }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-1">
                          {item.totalExams>0?
                            <button className="btn btn-link" onClick={()=>ViewExamDetail(props.profile.classSectionId,item.classSubjectId)}>
                                <span className="text-info"><i className="fa fa-search"></i></span>
                            </button>
                            :
                            <button className="btn btn-link" disabled>
                              <span className="text-info"><i className="fa fa-search"></i></span>
                            </button>
                          }
                        </div>
                    </div>
                ))}
                </div>
                <div className="card-footer">
                <div className="d-flex justify-content-between">
                    <span className="mr-2">
                        <i className="fas fa-square text-success"></i> Above 80%
                    </span>
                    <span className="mr-2">
                        <i className="fas fa-square text-warning"></i> Between 60% and 80%
                    </span>
                    <span className="mr-2">
                        <i className="fas fa-square text-danger"></i> Lessthan 60%
                    </span>
                </div>
                </div>
            </div>
        </div>
    </div>
:
<>
{loading? <Loader/>:
  <div className="row">
      <div className="col-md-12">
          <div className="card card-outline">
              <div className="card-body box-profile">
                  <div className="d-flex justify-content-between">
                      <div className="col-md-8"> 
                           <h6>{examDetail.classSubjectName}</h6>
                      </div>
                      <div className="col-md-4">
                          <button onClick={()=>setIsDetailView(false)} className="btn btn-primary btn-block">Back to Summary</button>
                      </div>
                  </div>
              </div>
          </div>
      </div>

    <div className="col-md-12">
        <div className="row">
            <div className="col-md-12">
                <div className="card card-outline">
                    <div className="card-body box-profile">
                        {/* <LineChart examdata={examdata}/> */}
                        {/* <ApexLineChart/> */}
                        <ApexBarChart categories={categories} seriesdata={seriesdata}/>
                    </div>
                </div>
            </div>
           
        </div>
    </div>
    <div className="col-md-4">
            <div className="card card-outline">
                <div className="card-body box-profile text-center">
                    <ReactSpeedometer
                        maxValue={100}
                        value={examDetail.totalPercent}
                        needleColor="blue"
                        startColor="red"
                        segments={10}
                        width={200}
                        height={150}
                        endColor="green"
                        />
                    </div>  
                </div>
            </div>
  
      <div className="col-md-8"> 

        <div className="card card-outline">
            <div className="card-header">
                <h5 className="card-title">Exam Detail</h5>
            </div>
            <div className="card-body box-profile">  
                <table className="table table-hover">
                      <thead>
                          <tr>
                              <th scope="col">#</th>
                              <th scope="col">Exam Name</th>
                              <th scope="col">Exam Date</th>
                              <th scope="col">Marks Scored</th>                              
                              <th scope="col">%</th>
                          </tr>
                      </thead>
                      <tbody> 
                      {singleDetail.map((item, index)=>(
                            <tr>
                              <th scope="row">{index+1}</th>
                              <td>{item.examName}</td>
                              <td>{item.scheduleDate}</td>
                              <td>{parseInt(item.markScored)}</td>
                              <td>{item.markPercent}</td>
                          </tr>
                        ))}                                
                    </tbody>
                </table>
            </div>
        </div>
      </div>
  </div>
 }
 </>
}
 </>
  )
}

export default StudentExam
