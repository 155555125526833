import  ApiDataService  from './ApiDataService'

const createExaminationSchedule = data => {
  return ApiDataService.post("examinationschedule", data);
};

const getExaminationSchedule = examMstId => {
    let values={"examMstId":examMstId}
  return  ApiDataService.getWithParam("examinationschedule",values);
};

const getExaminationScheduleCombo = examMstId => {
    let values={"examMstId":examMstId}
  return  ApiDataService.getWithParam("examinationschedule/schedulescombo",values);
};

const getExaminationScheduleClassSections = examScheduleId => {
    let values={"examScheduleId":examScheduleId}
  return  ApiDataService.getWithParam("examinationschedule/classssections",values);
};

const getStudentTodayExams = () => {
return  ApiDataService.get("examinationschedule/studenttodaysexams");
};

const getTeacherEvaluationSchedules = (data) => {
  return  ApiDataService.getWithParam("examinationschedule/teacherevaluationschedules",data);
  };

const deleteExaminationSchedule = id => {
  let values={'id':id}
  return  ApiDataService.remove("examinationschedule",values);
};


export default {createExaminationSchedule,
                getExaminationSchedule,
                getExaminationScheduleCombo,
                getExaminationScheduleClassSections,
                getStudentTodayExams,
                getTeacherEvaluationSchedules,
                deleteExaminationSchedule};