import  ApiDataService  from './ApiDataService'

const saveStudentAttendance = data => {
  return ApiDataService.put("studentattendance", data);
};

const markStudentAttendance = data => {
    return ApiDataService.post("studentattendance", data);
  };

const getStudentAttendance = (studentAttendanceId) => {
    let values={'studentAttendanceId':studentAttendanceId}
    return ApiDataService.getWithParam("studentattendance", values);
};

const getStudentAttendanceList = (classScheduleId) => {
    let values={'classScheduleId':classScheduleId}
    return ApiDataService.getWithParam("studentattendance/list", values);
};

const getStudentAttendanceEntry = (classScheduleId) => {
    let values={'classScheduleId':classScheduleId}
    return ApiDataService.getWithParam("studentattendance/entry", values);
};

const getUserShedules = () => {
    return ApiDataService.get("studentattendance/userschedules");
};

const intiateAttendanceEntry = (classScheduleId) => {
    let values={'classScheduleId':classScheduleId}
    return ApiDataService.getWithParam("studentattendance/initiate", values);
};

export default{saveStudentAttendance,
                markStudentAttendance,
                getStudentAttendance,
                getStudentAttendanceEntry,
                getStudentAttendanceList,
                getUserShedules,
                intiateAttendanceEntry}