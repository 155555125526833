import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import Loader from '../../ui/loader/Loader'
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import StudentDataService from "../../../services/StudentDataService"
import AssignmentDataService from "../../../services/AssignmentDataService"
import ValidationRule from './ValidationRule'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';

let options = [];
let classSectionListIds = ""
let subjectListIds = ""
let studentIds=""
const AssignmentTask = () => {

    const [show, setShow] = useState(false);
    const [topicName, setTopicName] = useState("")
    const [classSectionId, setClassSectionID] = useState("")
    const [subjectId, setSubjectId] = useState("")
    const [taskDetail, setTaskDetail] = useState("")
    const [submissionDate, setSubmissionDate] = useState("")
    const [appliedTo, setAppliedTo] = useState("false")
    const [maxMark, setMaxMark]= useState("")
    const [allClassSections, setAllClassSections] = useState([])
    const [classSubjects, setClassSubjects] = useState([])
    const [assignments, setAssignments] = useState([])

    const [classSections, setClassSections] = useState([])
    const [classSubjectList, setClassSubjectList] = useState([])
    const [searchText, setSearchText] = useState([])
    const [isfilterModified, setisFilterModified] = useState(false)

    const [submitted, setSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [gradeType, setGradeType] = useState("false")
    const [selectedStudent, setSelectedStudent] = useState([])

    const [isCreate, setIsCreate] = useState(true)
    const [assignmentId,setAssignmentId] = useState(0)
    const [instruction, setInstruction] = useState("")
    const [instructions, setInstructions] = useState([])

    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        topicName: searchText,
        topicDetail:"",
        classSectionList:classSectionListIds,
        classSubjectList:subjectListIds
    })

    const validator= ValidationRule.AssignmentRule();    
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false);

    const validatorApplied= ValidationRule.AppliedtoRule();    
    const [validationApplied, setValidationApplied] = useState(validatorApplied.valid());
    const [validateSubmittedApplied, setValidateSubmittedApplied]=useState(false);

    const validatorGrade= ValidationRule.GrdadeRule();    
    const [validationGrade, setValidationGrade] = useState(validatorGrade.valid());
    const [validateSubmittedGrade, setValidateSubmittedGrade]=useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    var validationData={
        classSectionId:classSectionId,
        subjectId:subjectId,
        topicName:topicName,
        taskDetail:taskDetail,
        submissionDate:submissionDate,
        validation:validation
    }

    var validationDataApplied={
        studentIds:studentIds,
        validation:validationApplied
    }

    var validationDataGrade={
        maxMark:maxMark,
        validation:validationGrade
    }

    const newAssignment =()=>{
        setIsCreate(true);
        handleShow()
    }

    const openInstruction = (id) =>{
        fetchInstruction(id)
        setAssignmentId(id)
        setIsCreate(false);
        handleShow()
    }


    useEffect(() => {
        fetchClassSection()
        fetchallClassSection()
    }, [])

    useEffect(() => {
        setParametes()
    }, [submitted, isfilterModified])

    useEffect(() => {
        fetchAssignments()
    }, [params])

    useEffect(() => {
        if(parseInt(classSectionId)>0)
        {
            fetchallClassSubjects()
            fetchstudentList()
        }
    }, [classSectionId])


    const setParametes = () => {
        setParams({
            topicName: searchText,
            topicDetail:"",
            classSectionList:classSectionListIds,
            classSubjectList:subjectListIds
        })
    }

    const fetchAssignments = async () => {
        setLoading(true)
        await AssignmentDataService.findAssignment(params)
            .then(response => {
                setAssignments(response.data.result.itemList)
                setTotalPages(response.data.result.totalPages)
                setActivePage(response.data.result.activePageNumber)
                setLoading(false)
            })
            .catch(error => {
                const errorMsg = error.message;
                setLoading(false)
            })
    }



    const fetchallClassSection = async () => {
        await TeacherAssociationDataService.getTeacherCurrentSections()
            .then(response => {
                setAllClassSections(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }


    const fetchallClassSubjects = async () => {
        await TeacherAssociationDataService.getTeacherCurrentSubjectbySection((classSectionId=="")?0:parseInt(classSectionId))
            .then(response => {
                setClassSubjects(response.data.result)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchstudentList = async () => {
        options=[]
        await StudentDataService.getClassSectionStudentList((classSectionId==="")?0:parseInt(classSectionId),"")
            .then(response => {
                response.data.result.map((item, index)=>(
                    options.push({value:item.studentId,label:item.studentName})
                ));
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }


    //filter
    const fetchClassSection = async () => {
        let classSectionList = []
        await TeacherAssociationDataService.getTeacherCurrentSections()
            .then(response => {
                response.data.result.map((item, index) => (
                    classSectionList.push({ id: item.classSectionId, value: item.classSectionName, isChecked: false })
                ));
                setClassSections(classSectionList)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const handleCheckClassSections = (event) => {
        let chkclassSections = classSections
        classSectionListIds = ""
        chkclassSections.forEach(sectiondata => {
            if (sectiondata.value === event.target.value) {
                sectiondata.isChecked = event.target.checked

            }
            if (sectiondata.isChecked) {
                if (classSectionListIds.length > 0 || classSectionListIds !== "") {
                    classSectionListIds += ","
                }
                classSectionListIds += sectiondata.id
            }
        })
        setClassSections(chkclassSections)
        setisFilterModified(!isfilterModified)
    }


    const serachByText = () => {
        setisFilterModified(!isfilterModified)
    }


    const submitForm = async () => {
        studentIds=""
        if(selectedStudent !==null)
        {
            selectedStudent.map((item, index)=>(
                (studentIds==="")?
                    studentIds =item.value:
                    studentIds =studentIds+"," + item.value  
            ))
        }

        let AppliedValidate=true
        let GradeValidate=true

        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        if(appliedTo=="true")
        {
            validationDataApplied={
                studentIds:studentIds,
                validation:validationApplied
            }
            const validateApplied = validatorApplied.validate(validationDataApplied)
            setValidationApplied({ validateApplied });
            setValidateSubmittedApplied(true)
            AppliedValidate=validateApplied.isValid
        }
        else
        {            
            setValidationApplied(validatorApplied.valid());
            setValidateSubmittedApplied(false); 
            AppliedValidate=true  
        }
        if(gradeType=="true")
        {
            const validateGrade = validatorGrade.validate(validationDataGrade)
            setValidationGrade({ validateGrade });
            setValidateSubmittedGrade(true)
            GradeValidate=validateGrade.isValid
        }
        else
        {            
            setValidationGrade(validatorGrade.valid());
            setValidateSubmittedGrade(false); 
            GradeValidate=true  
        }

        let data={
            assignmentId:0,
            classSectionId:parseInt(classSectionId),
            classSubjectId:parseInt(subjectId),
            topicName:topicName,
            topicDetail:taskDetail,
            submissionDate:submissionDate,
            isAppliedToAll:(appliedTo==="false")?true:false,
            studentsList:studentIds.toString(),
            isGraded:(gradeType==="false")?false:true,
            maxMark:maxMark==""?0:parseInt(maxMark)
        }
        
        if (validate.isValid && AppliedValidate && GradeValidate) {
            setLoading(true)
            await AssignmentDataService.createAssignment(data) 
            .then(response => {
                setLoading(false)
                LearnToast(success,"Saved Successfully")
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })         
                        LearnToast(danger,msgText)
                
                }        
            })
            resetForm()
            setSubmitted(!submitted)
            handleClose()
        }
    }

    const resetForm = () => {
                 
        setValidation(validator.valid());
        setValidateSubmitted(false);  
                 
        setValidationApplied(validatorApplied.valid());
        setValidateSubmittedApplied(false);  
                 
        setValidationGrade(validatorGrade.valid());
        setValidateSubmittedGrade(false);  

        setIsCreate(true);
        setInstructions([])
        setInstruction("")
        setAssignmentId(0)
        setSelectedStudent([])
        setClassSectionID("")
        setSubjectId("")
        setClassSubjects([])
        setTopicName("")
        setTaskDetail("")
        setSubmissionDate("")
        setAppliedTo("false")
        studentIds=[]
        setGradeType("false")
        setMaxMark("")

    }

    const fetchInstruction = async (id) =>{
        setLoading(true)
        await AssignmentDataService.getAssignmentInstruction(id)
        .then(response => {
            setLoading(false)
            setInstructions(response.data.result)
        })
        .catch(error => {
            setLoading(false)
            const errorMsg = error.message;

        })
        
    }

    const saveInstruction = async () =>{
        let data={
            assignmentInstructionId:0,
            assignmentId:assignmentId,
            instructionText:instruction
        }
        setLoading(true)
        await AssignmentDataService.createAssignmentInstruction(data)
        .then(response => {
            setLoading(false)
            setInstruction("")
            fetchInstruction(assignmentId)
            LearnToast(success,"Saved Successfully")
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })      
                    LearnToast(danger,msgText)
            
            }                    
        })
        
    }

    const deleteInstruction = async (id) =>{
        setLoading(true)
        await AssignmentDataService.deleteAssignmentInstruction(id)
        .then(response => {
            fetchInstruction(assignmentId)
            setLoading(false)
            LearnToast(success,"Instruction Deleted Successfully")
        })
        .catch(error => {
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })       
                    LearnToast(danger,msgText)
            
            }
        })
        
    }

    const deleteAssignment = async id => {
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
       
    }

    const handleClickDelete = async (id) =>{
        setLoading(true)
        await AssignmentDataService.deleteAssignment(id) 
        .then(response => {
            setLoading(false)
            LearnToast(success,"Assignment Deleted Successfully")
            fetchAssignments()
        })
        .catch(error => {       
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })        
                    LearnToast(danger,msgText)
            
            }                    
        })

    }

    const closeInstruction = () =>{
            resetForm()
            handleClose()

    }

  
    let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
    let validateOutputGrade= validateSubmittedGrade ? validatorGrade.validate(validationDataGrade) : validationDataGrade.validation 
    let validateOutputApplied= validateSubmittedApplied ? validatorApplied.validate(validationDataApplied) : validationDataApplied.validation 
    return (
        <>
         <ToastContainer />    
        {loading?<Loader/>:
            <>
                <Modal show={show} onHide={handleClose} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Assign Task</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {isCreate?
                            <form>
                                <div className="row">
                                    <div className="col-md-7">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Class Section</label>
                                                <select onChange={event => setClassSectionID(event.target.value)} value={classSectionId} name="classSectionId" className={validateOutput.classSectionId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                                <option value="">-- select class section --</option>
                                                {allClassSections.map(item => (
                                                    <option 
                                                    key={item.classSectionId} 
                                                    value={item.classSectionId}
                                                    >                                                
                                                        {item.classSectionName}
                                                    </option>
                                                ))}
                                            </select>
                                            <span className="text-danger">{validateOutput.classSectionId.message}</span>
                                            </div>
                                            <div className="col">
                                                <label>Subject</label>
                                                <select onChange={event => setSubjectId(event.target.value)} value={subjectId} name="subjectId" className={validateOutput.subjectId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                                    <option value="">-- select subject --</option>
                                                    {classSubjects.map(item => (
                                                        <option
                                                            key={item.classSubjectId}
                                                            value={item.classSubjectId}
                                                        >
                                                            {item.subjectName}
                                                        </option>
                                                    ))}
                                                </select>
                                                <span className="text-danger">{validateOutput.subjectId.message}</span>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Topic</label>
                                                <input type="text" onChange={event => setTopicName(event.target.value)} value={topicName} name="topicName" id="topicName" className={validateOutput.topicName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Topic.." />
                                                <span className="text-danger">{validateOutput.topicName.message}</span>
                                            </div>
                                            <div className="col">
                                                <label>Submission Date</label>
                                                <input type="date" onChange={event => setSubmissionDate(event.target.value)} value={submissionDate} name="submissionDate" id="submissionDate" className={validateOutput.submissionDate.isInvalid ? 'form-control is-invalid':'form-control'} />
                                                <span className="text-danger">{validateOutput.submissionDate.message}</span>
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Task Detail</label>
                                                <textarea  className={validateOutput.taskDetail.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setTaskDetail(event.target.value)} value={taskDetail} name="taskDetail" id="taskDetail" placeholder="Place some text here"
                                                    style={{
                                                        width: "100%", height: "110px", fontSize: "14px", lineHeight: "18px",
                                                        border: "1px solid #dddddd", padding: "10px"
                                                    }} />
                                                    <span className="text-danger">{validateOutput.taskDetail.message}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-5">
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Applied To</label>
                                                <select onChange={event => setAppliedTo(event.target.value)} value={appliedTo} className="form-control">
                                                    <option value="false">All Students</option>
                                                    <option value="true">Specific Students</option>
                                                </select>
                                            </div>
                                        </div>
                                        {(appliedTo === "true") ? <><div className="form-row">
                                            <div className="col">
                                                <label>Select Students</label>
                                                <Select
                                                    value={selectedStudent}
                                                    isMulti
                                                    onChange={selectedStudent => setSelectedStudent(selectedStudent)}
                                                    options={options}
                                                />
                                                <span className="text-danger">{validateOutputApplied.studentIds.message}</span>
                                            </div>
                                        </div></> : <></>}
                                        <div className="form-row">
                                            <div className="col">
                                                <label>Grade Type</label>
                                                <select onChange={event => setGradeType(event.target.value)} value={gradeType} className="form-control">
                                                    <option value="false">Ungraded</option>
                                                    <option value="true">Graded</option>
                                                </select>
                                            </div>
                                        </div>
                                        {(gradeType === "true") ? <div className="form-row">
                                            <div className="col">
                                                <label>Max Marks</label>
                                                <input type="number" onChange={event => setMaxMark(event.target.value)} value={maxMark} name="maxMark" id="maxMark" className={validateOutputGrade.maxMark.isInvalid ? 'form-control is-invalid':'form-control'} />
                                                <span className="text-danger">{validateOutputGrade.maxMark.message}</span>
                                            </div>
                                        </div> : <></>}
                                    </div>
                                </div>
                            </form>
                        :
                        <>
                            <div className="form-group">
                                    <label>Instruction</label>
                                    <div className="input-group">
                                        <input type="text" onChange={event => setInstruction(event.target.value)} value={instruction} name="instruction" id="instruction" className="form-control" placeholder="Type Instruction.." />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" onClick={()=>saveInstruction()}  type="submit">
                                                Save
                                            </button>
                                        </div>                                        
                                    </div>
                                    <span className="text-danger"></span>
                                </div>
                                <br />
                                {instructions.length>0?
                                    <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Sl</th>
                                            <th scope="col">Instruction</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {instructions.map((item,index)=>(
                                            <tr key={index}>
                                                <td>{index+1}</td>
                                                <td>{item.instructionText}</td>
                                                <td className="text-right">
                                                    <button className="btn btn-link" onClick={()=>deleteInstruction(item.assignmentInstructionId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}                                    
                                    </tbody>
                                </table>
                                :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }
                        </>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        {isCreate?
                        <>
                            <Button variant="secondary" onClick={handleClose}>
                                Close
                            </Button>
                            <Button type="submit" variant="primary" onClick={()=>submitForm()}>
                                Submit
                            </Button>
                        </>
                        :
                        <>
                            <Button variant="secondary" onClick={()=>closeInstruction()}>
                                Close
                            </Button>
                        </>}
                    </Modal.Footer>
                </Modal>
                <div className="container">
                    <div className="jumbotron">
                        <div className="card card-primary">
                            <div className="card-header">
                                <div className="row">
                                    <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                        <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Assignments</h5>
                                    </div>
                                    <div className="col-md-6 text-right">
                                        <button className="btn btn-light" onClick={()=>newAssignment()} style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                    </div>
                                    <br />
                                    <br />
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="card card-outline card-primary">
                                            <div className="text-center">
                                                <h5 style={{ marginTop: "10px" }}>Filter Options</h5>
                                            </div>
                                            <hr />
                                            <div className="col-md-12">
                                                <div className="input-group">
                                                    <div className="input-group input-group-sm">
                                                        <input onChange={event => setSearchText(event.target.value)} value={searchText} name="searchText" id="searchText" className="form-control form-control-navbar" type="text" placeholder="Topic.." aria-label="Search" />
                                                        <div className="input-group-append">
                                                            <button onClick={() => serachByText()} className="btn btn-primary" type="submit">
                                                                <i className="fas fa-search"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="col-md-12">
                                                <h6 style={{ marginTop: "10px", marginBottom: "10px" }}><b>Class Sections</b></h6>
                                                <ul className="list-group list-group-unbordered mb-3">
                                                    {classSections.map((item, index) => (
                                                        <li key={index} className="list-group-item">
                                                            <input key={item.id} onClick={event => handleCheckClassSections(event)} type="checkbox" checked={item.isChecked} value={item.value} /> {item.value}
                                                        </li>

                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-9">
                                        {assignments.length>0?
                                        <>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                            </div>
                                            {assignments.map((item, index)=>(                                  
                                                <div className="card card-outline card-primary">
                                                <div className="card-header">
                                                    <div class="row">
                                                        <div className="col-md-8">
                                                            <h2 className="card-title">
                                                                <span class="info-box-icon text-success"><i class="far fa-bookmark"></i> <b>{item.topicName}</b></span>
                                                            </h2>
                                                        </div>
                                                        <div className="col-md-4 text-right">
                                                            <p><i>Due On {item.dueDate}</i></p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-md-9">
                                                            <p>Posted On {item.postedOn}</p>
                                                            <h5>Class {item.classSectionName}</h5>
                                                            <h5>Subject {item.classSubjectName}</h5>
                                                            <p>{item.topicDetail}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <div className="row">
                                                                <div className="col-md-6 border-left">
                                                                    <div className="description-block">
                                                                        <h5 className="description-header">{item.totalAssigned}</h5>
                                                                        <span className="description-text">Assigned</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 border-left">
                                                                    <div className="description-block">
                                                                        <h5 className="description-header">{item.totalSubmitted}</h5>
                                                                        <span className="description-text">Turned In</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-12 text-right">
                                                                    <br />
                                                                    <br />
                                                                    {item.isEvaluated?
                                                                    <span class="info-box-icon text-success"><i class="fa fa-check"></i> Evaluated Successfully</span>
                                                                    :
                                                                    <span class="info-box-icon text-danger"><i class="fa fa-times"></i> Pending Evaluation</span>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    <div className="col-md-12">
                                                            <div className="d-flex justify-content-between">
                                                                <button className="btn btn-link" onClick={()=>openInstruction(item.assignmentId)}><span className="text-info"><i className="fas fa-search"> &nbsp; Instructions</i></span></button>
                                                                <button className="btn btn-link" onClick={()=>deleteAssignment(item.assignmentId)}><span className="text-danger"><i className="fa fa-trash"> &nbsp; Delete</i></span></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-footer">
                                                    <Link className="btn btn-primary" to={{
                                                        pathname: '/app/assignment-detail/'+item.assignmentId,
                                                        props: { id: item.assignmentId }
                                                    }}>View Assignments</Link>
                                                    {/* <button className="btn btn-info">View Assignments</button> */}
                                                </div>
                                            </div>                                            
                                            ))}
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                                </div>
                                            </div>
                                        </>
                                        :
                                            
                                            <div className="alert alert-info alert-dismissible">
                                                <div className="text-center text-white"><h4>No Records Found</h4></div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        </>
    );
}

export default AssignmentTask
