import React, { useState, useEffect } from 'react'
import StudentAttendanceDataservice from "../../../services/StudentAttendanceDataservice"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import LiveAttendanceList from './LiveAttendanceList';


const IntiateAttendance = () => {

  const [classSchedules, setClassSchedules] = useState([])
  const [students, setStudents] = useState([])
  const [isAttendanceView, setIsAttendanceView] = useState(false)
  const [initiated,setInitiated] = useState(false)
  const [scheduleId, setScheduleId] = useState(0)
  const [studentId,setStudentID] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
        fetchClassShedules()
    }, 1000);
    return () => clearInterval(interval);
  }, []);

//   useEffect(() => {
//     const interval = setInterval(() => {
//         intiateAttendance(scheduleId)
//     }, 1000);
//     return () => clearInterval(interval);
//   }, [scheduleId]);

    // useEffect(() =>{
    //  fetchClassShedules()
    // },[])    

   

    const fetchClassShedules = async () =>{      
        await StudentAttendanceDataservice.getUserShedules() 
        .then(response => {
            setClassSchedules(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }


    const intiateAttendance = async (id) =>{  
        setScheduleId(id)
        await StudentAttendanceDataservice.intiateAttendanceEntry(id) 
        .then(response => {
            setStudents(response.data.result)
            setIsAttendanceView(true)
            LearnToast(success, "Attendance Initiated Successfully")
      })
       .catch(error => {
         
        let errorMsg=[]
        let msgText=""      
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }                   
      })  
       
   
    }

    // const loopAttendance = async (id) =>{    
    //     await StudentAttendanceDataservice.intiateAttendanceEntry(id) 
    //     .then(response => {
    //         setStudents(response.data.result)
    //         setIsAttendanceView(true)
    //         LearnToast(success, "Attendance Initiated Successfully")
    //   })
    //    .catch(error => {
    //       const errorMsg=error.message;            
    //   })  
    // }

   

  return (
    <>
    <ToastContainer />
    <div className="container">
        <div className="jumbotron">
            <div className="card card-primary">
                <div className="card-header">
                    <div className="row">
                        <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                            <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Attendnace</h5>
                        </div>                    
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        {!isAttendanceView?
                        <>
                            
                                <div className="col-md-12">
                                {classSchedules.length>0?
                                <>
                                {classSchedules.map((item,index)=>(
                                        <div className="card card-outline card-primary">
                                            <div className="card-header" id="headingOne">
                                                <i className="d-flex justify-content-between"> 
                                                    {/* <p className="text-muted text-xs">Scheduled By:  {item.createdBy}</p>
                                                    <p className="text-muted text-xs">Posted On:  {item.createdOn} </p> */}
                                                    <p></p>  
                                                    <p className="text-muted text-danger"> Time:  {item.scheduleFromTime + " - " + item.scheduleEndTime}</p>  
                                                </i>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <h5>Class: {item.classSectionName}</h5> 
                                                        <h6>Subject:  {item.classSubjectName}</h6>   
                                                        <h6>Teacher: {item.teacherName} </h6>
                                                        <div className="d-flex justify-content-between">                                  
                                                        <div></div> 
                                                            {(item.isActiveSchedule && !item.isAttendanceInitiated)?
                                                                <button className="btn btn-link" onClick={()=>intiateAttendance(item.classScheduleId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                                        <span className="text-info"><i className="fa fa-plus"></i> Initiate Attendance </span>
                                                                </button>
                                                            :<></>}                                                              
                                                        </div>  
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>
                                    ))}
                                </>
                                :
                                <div className="alert alert-info alert-dismissible">
                                    <div className="text-center text-white"><h4>No Records Found</h4></div>
                                </div>
                            }
                              </div>
                        </>
                        :
                        <div className="col-md-12">
                        <LiveAttendanceList scheduleID={scheduleId} students={students}/>
                    </div>
                        }
                    </div>
                </div>
                <div className="card-footer">
                    {isAttendanceView?
                        <div className="text-right">
                            <button className="btn btn-primary" onClick={()=>setIsAttendanceView(false)}>Back</button>
                        </div>
                        :<></>
                    }
                    </div>
            </div>
        </div>
    </div>
  </>
  )
}

export default IntiateAttendance
