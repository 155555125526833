import React, { useState, useEffect } from 'react'
import { Modal, Button } from 'react-bootstrap'
import Select from 'react-select';
import { Link } from 'react-router-dom'
import Loader from '../../ui/loader/Loader';
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import CommonDataService from "../../../services/CommonDataService"
import ExaminationDataService from "../../../services/ExaminationDataService"
import ExaminationStudentDataService from "../../../services/ExaminationStudentDataService"
import QuestionPaperMasterDataService from "../../../services/QuestionPaperMasterDataService"
import ValidationRule from './ValidationRule'
import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"
import LearnPagination from '../../ui/pagination/LearnPagination';
import ExaminationScheduleDataSevice from '../../../services/ExaminationScheduleDataSevice';

let option = [];
let studentIds=""
const Examination = () => {
    const [show, setShow] = useState(false);
    const [formMode, setFormMode]=useState("")
    const [formTitle, setFormTitle] = useState("")
    const [modalSize, setModalSize] = useState("lg")
    const [submitted,setSubmitted]=useState(false);
    const [classes,setClasses] =useState([])
    const [allClassSubject, setAllClassSubject]= useState([]);
    const [examGrades, setExamGrades] =useState([])
    const [examName, setExamName] = useState("")
    const [classesId,setClassesId] = useState("")
    const [classSubjectId,setClassSubjectId]= useState("")
    const [examGrade, setExamGrade] =useState("")
    const [maxMarks, setMaxMarks] = useState("")    
    const [examId, setExamId]= useState(0)

    const [scheduleDate, setScheduleDate] =useState("")
    const [scheduleFromTime, setScheduleFromTime] =useState("")
    const [scheduleToTime, setScheduleToTime] =useState("")
    const [questionPaperMstId,setQuestionPaperMstId] = useState("")
    const [questionPapers, setQuestionPapers] = useState([])
    const [schedules, setShedules] = useState([])

    const [scheduleCombo, setScheduleCombo] = useState([])
    const [examScheduleId,setExamScheduleId] = useState("")
    const [classSections, setClassSections] = useState([])
    const [classSectionId, setClassSectionId] = useState("")    
    const [appliedTo, setAppliedTo] = useState("false")
    const [options, setOptions]= useState([])
    const [selectedStudent, setSelectedStudent] = useState([])
    const [studentSettingsList, setStudentSettingList] = useState([])

    const [searchExam,setSearchExam]=useState("")
    const [isfilterModified,setisFilterModified]=useState(false)
    const [loading,setLoading] = useState(false);

    const [exams, setExams]=useState([])
    const [totalPages, setTotalPages] = useState(0)
    const [activePage, setActivePage] = useState(1)
    const [params, setParams] = useState({
        examName:searchExam
         })

    const validator= ValidationRule.NewExamRule();    
    const [validation, setValidation] = useState(validator.valid());
    const [validateSubmitted, setValidateSubmitted]=useState(false);

    const validatorSchedule= ValidationRule.ScheduleRule();    
    const [validationSchedule, setValidationSchedule] = useState(validatorSchedule.valid());
    const [validateSubmittedSchedule, setValidateSubmittedSchedule]=useState(false);

    const validatorStudent= ValidationRule.StudentSettingRule();    
    const [validationStudent, setValidationStudent] = useState(validatorStudent.valid());
    const [validateSubmittedStudent, setValidateSubmittedStudent]=useState(false);

    const validatorApplied= ValidationRule.AppliedtoRule();    
    const [validationApplied, setValidationApplied] = useState(validatorApplied.valid());
    const [validateSubmittedApplied, setValidateSubmittedApplied]=useState(false);

    var validationDataApplied={
        studentIds:studentIds,        
        validation:validationApplied
    }

    var validationDataStudent={
        examScheduleId:examScheduleId,
        classSectionId:classSectionId,
        validation:validationStudent
    }

    var validationData={
        examName:examName,
        examGrade:examGrade,
        maxMarks:maxMarks,
        validation:validation
    }

    var validationDataSchedule={
        classSubjectId:classSubjectId,
        scheduleDate:scheduleDate,
        scheduleFromTime:scheduleFromTime,
        scheduleToTime:scheduleToTime,
        questionPaperMstId:questionPaperMstId,
        validation:validationSchedule
    }

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setParametes()
    }, [submitted,isfilterModified])

    useEffect(() => {
        fetchExams()
    }, [params])  

    const setParametes = () => {
        setParams({
        examName:searchExam
        })
    }

    const fetchExams = async () =>{
        setLoading(true)
        await ExaminationDataService.findExamination(params) 
        .then(response => {
        setExams(response.data.result.itemList)
        setTotalPages(response.data.result.totalPages)
        setActivePage(response.data.result.activePageNumber)
        setLoading(false)
       })
       .catch(error => {
           setLoading(false)                      
       })
    }
    useEffect(() =>{
        fetchAllGrades()
        fetchTeacherCurrentSections()
    },[])

    useEffect(() =>{
        fetchTeacherCurrentSubjects()
    },[classesId])    

    useEffect(() =>{
        fetchQuestionPapers()
    },[classSubjectId])  
    
    useEffect(() =>{
        fetchExamClassSection()
        fetchstudentSettingslist()
    },[examScheduleId])  

    useEffect(() =>{
        fetchstudentList()
    },[classSectionId])  

    const fetchAllGrades = async () =>
    {
        await CommonDataService.allGradeSchemes() 
        .then(response => {
            setExamGrades(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchTeacherCurrentSections = async () =>{
        setAllClassSubject([])
        setClassSubjectId("")
        await TeacherAssociationDataService.getTeacherCurrentClasses()
        .then(response => {
            setClasses(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchTeacherCurrentSubjects = async () =>{
        setQuestionPapers([])
        setQuestionPaperMstId("")
        await TeacherAssociationDataService.getTeacherCurrentSubjects((classesId=="")?0:parseInt(classesId)) 
        .then(response => {
            setAllClassSubject(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchQuestionPapers = async () =>
    {
        await QuestionPaperMasterDataService.getQuestionPaperMasterCombo(classSubjectId) 
        .then(response => {
            setQuestionPapers(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchSchedules = async (id) =>
    {
        await ExaminationScheduleDataSevice.getExaminationSchedule(id) 
        .then(response => {
            setShedules(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchComboSchedules = async (id) =>
    {
       setOptions([])
        setClassSections([])
        setClassSectionId("")
        await ExaminationScheduleDataSevice.getExaminationScheduleCombo(id) 
        .then(response => {
            setScheduleCombo(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchExamClassSection = async () =>
    {
        setOptions([])
        setSelectedStudent([])
        await ExaminationScheduleDataSevice.getExaminationScheduleClassSections(examScheduleId) 
        .then(response => {
            setClassSections(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }

    const fetchstudentList = async () => {
        option=[]
        setOptions([])
        await ExaminationStudentDataService.getExaminationStudentAssignable((examScheduleId==="")?0:parseInt(examScheduleId),(classSectionId==="")?0:parseInt(classSectionId))
            .then(response => {
                response.data.result.map((item, index)=>(
                    option.push({value:item.studentId,label:item.studentName})
                ));
                setOptions(option)
            })
            .catch(error => {
                const errorMsg = error.message;
            })
    }

    const fetchstudentSettingslist= async () =>
    {
        await ExaminationStudentDataService.getExaminationStudent(examScheduleId) 
        .then(response => {
            setStudentSettingList(response.data.result)
       })
       .catch(error => {
           const errorMsg=error.message;            
       })
    }


    const createExam = () => {   
        resetCreateForm()
        resetScheduleForm()
        resetStudentSetting()        
        setValidation(validator.valid());
        setValidateSubmitted(false);    
        setModalSize("lg")
        setFormTitle("Create Exam")
        setFormMode("Create")
        handleShow();
    }

    const saveExam= ()=>{
        submitExam("save")
        setExamId(0);
    }

    const continueCreateExam = () => {        
        submitExam("continue")
    }

    const submitExam = async (submitMode) => {   
        
        let data={
            examMstId:0,
            examName:examName,
            maxMarks:parseInt(maxMarks),
            gradeSchemeId:parseInt(examGrade)
        }
        const validate = validator.validate(validationData)
        setValidation({ validate });
        setValidateSubmitted(true)
        if (validate.isValid )
        {
            setLoading(true)
            await ExaminationDataService.createExamination(data)
            .then(response => {  
              setLoading(false)                    
              LearnToast(success,"Exam Created Successfully")
              setExamId(response.data.result.id)  
              resetCreateForm()
              fetchExams()
              if(submitMode=="save")
              {                  
                  handleClose()
              }
              else
              {
                    setFormMode("Schedule")
                    setFormTitle("Schedule Setting")
              }              
            })
            .catch(error => { 
              let errorMsg=[]
              let msgText=""
              setLoading(false)      
              if(error.response.status==500)
              {
                  LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
              }
              else
              {
                  errorMsg=error.response.data.errors
                  errorMsg.map((item,index)=>{
                      if(msgText.length>0)
                      {
                      msgText = msgText+","
                      }
                      msgText=msgText+item
                  })            
                      LearnToast(danger,msgText)
              
              }
            })
        }

        
    }

    const resetCreateForm = () =>{
        setExamName("")
        setMaxMarks("")
        setExamGrade("")
        setValidation(validator.valid());
        setValidateSubmitted(false);   
    }

    const deleteExam = (id)=>{
        confirmAlert({
            customUI: ({ onClose }) => {
              return (
                <div className='custom-ui'>
                  <h1>Are you sure?</h1>
                  <p>You want to delete this?</p>
                  <button  onClick={onClose}>No</button>
                  <button className="btn-alert-danger"
                    onClick={() =>{handleClickDelete(id);
                      onClose();
                    }}
                  >
                    Yes, Delete it!
                  </button>
                </div>
              );
            }
          });
    }

    const handleClickDelete = async id =>{
        setLoading(true)
        await ExaminationDataService.deleteExamination(id) 
            .then(response => {
                setLoading(false)
                LearnToast(success,"Deleted Successfully")
                setSubmitted(!submitted);
            })
            .catch(error => {
                let errorMsg=[]
                let msgText=""
                setLoading(false)      
                if(error.response.status==500)
                {
                    LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
                }
                else
                {
                    errorMsg=error.response.data.errors
                    errorMsg.map((item,index)=>{
                        if(msgText.length>0)
                        {
                        msgText = msgText+","
                        }
                        msgText=msgText+item
                    })            
                        LearnToast(danger,msgText)
                
                }     
            })
   }

   const openScheduleSetting= (id)=>
   {
        resetCreateForm()
        resetScheduleForm()
        resetStudentSetting()
       setFormMode("Schedule")
       setFormTitle("Schedule Setting")
       setExamId(id)
       fetchSchedules(id)
       handleShow()
   }

   const saveScheduleSetting= async ()=>
   {
       let data={
        examScheduleId:0,
        examMstId:parseInt(examId),
        classSubjectId:parseInt(classSubjectId),
        questionPaperMstId:parseInt(questionPaperMstId),
        scheduleDate:(scheduleDate=="")?"1900-01-01T00:00:00.000Z":scheduleDate,
        scheduleFromTime:scheduleDate+"T"+scheduleFromTime+":00.000",
        scheduleToTime:scheduleDate+"T"+scheduleToTime+":00.000"
       }
       const validate = validatorSchedule.validate(validationDataSchedule)
       setValidationSchedule({ validate });
       setValidateSubmittedSchedule(true)
       if (validate.isValid )
        {
            setLoading(true)
            await ExaminationScheduleDataSevice.createExaminationSchedule(data)
            .then(response => {  
              setLoading(false)                    
              LearnToast(success,"Schedule Saved Successfully")
              resetScheduleForm()
              fetchSchedules(examId)
            })
            .catch(error => { 
              let errorMsg=[]
              let msgText=""
              setLoading(false)      
              if(error.response.status==500)
              {
                  LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
              }
              else
              {
                  errorMsg=error.response.data.errors
                  errorMsg.map((item,index)=>{
                      if(msgText.length>0)
                      {
                      msgText = msgText+","
                      }
                      msgText=msgText+item
                  })            
                      LearnToast(danger,msgText)
              
              }
            })
        }
   }

   const resetScheduleForm = () =>{
       setQuestionPaperMstId("")
       setClassSubjectId("")
       setClassesId("")
       setScheduleToTime("")
       setScheduleDate("")
       setScheduleFromTime("")       
       setValidationSchedule(validatorSchedule.valid());
       setValidateSubmittedSchedule(false);   
   }

   const deleteSchedule= async (id)=>{
        setLoading(true)
        await ExaminationScheduleDataSevice.deleteExaminationSchedule(id)
        .then(response => {  
        setLoading(false)                    
        LearnToast(success,"Schedule Deleted Successfully")
        fetchSchedules(examId)
        })
        .catch(error => { 
        let errorMsg=[]
        let msgText=""
        setLoading(false)      
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }
        })

    }

    const openStudentSetting= (id) =>{
        resetCreateForm()
        resetScheduleForm()
        resetStudentSetting()
        setFormMode("studentSetting")
        setFormTitle("Exam Student Setting")
        setExamId(id)
        fetchComboSchedules(id)
        handleShow()
    }

    const saveStudentSetting = async ()=>{
        studentIds=""
        if(selectedStudent !==null)
        {
            selectedStudent.map((item, index)=>(
                (studentIds==="")?
                    studentIds =item.value:
                    studentIds =studentIds+"," + item.value  
            ))
        }

        let AppliedValidate=true

        const validate = validatorStudent.validate(validationDataStudent)
        setValidationStudent({ validate });
        setValidateSubmittedStudent(true)
        if(appliedTo=="true")
        {
            validationDataApplied={
                studentIds:studentIds,
                validation:validationApplied
            }
            const validateApplied = validatorApplied.validate(validationDataApplied)
            setValidationApplied({ validateApplied });
            setValidateSubmittedApplied(true)
            AppliedValidate=validateApplied.isValid
        }
        else
        {            
            setValidationApplied(validatorApplied.valid());
            setValidateSubmittedApplied(false); 
            AppliedValidate=true  
        }

        let data={
            examScheduleId:parseInt(examScheduleId),
            classSectionId:parseInt(classSectionId),
            isAllStudents:(appliedTo==="false")?true:false,
            studentIdList:studentIds.toString(),
        }
        if (validate.isValid && AppliedValidate)
        {
            setLoading(true)
            await ExaminationStudentDataService.createExaminationStudent(data)
            .then(response => {  
              setLoading(false)                    
              LearnToast(success,"Saved Successfully")
              resetStudentSetting()
              fetchstudentSettingslist()
            })
            .catch(error => { 
              let errorMsg=[]
              let msgText=""
              setLoading(false)      
              if(error.response.status==500)
              {
                  LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
              }
              else
              {
                  errorMsg=error.response.data.errors
                  errorMsg.map((item,index)=>{
                      if(msgText.length>0)
                      {
                      msgText = msgText+","
                      }
                      msgText=msgText+item
                  })            
                      LearnToast(danger,msgText)
              
              }
            })

        }

    }  
    
    const resetStudentSetting= ()=>{
        setClassSectionId("")
        setClassSections([])
        setExamScheduleId("")
        setSelectedStudent([])
        setStudentSettingList([])
        setOptions([])  
        setAppliedTo("false")        
        setValidationStudent(validatorStudent.valid());
        setValidateSubmittedStudent(false);  
    }

    const deleteStudentSetting= async (id)=>{
        setLoading(true)
        await ExaminationStudentDataService.deleteExaminationStudent(id)
        .then(response => {  
        setLoading(false)                    
        LearnToast(success,"Deleted Successfully")
        fetchstudentSettingslist()
        })
        .catch(error => { 
        let errorMsg=[]
        let msgText=""
        setLoading(false)      
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }
        })

    }

    const publishExamResult= async (id)=>{
        setLoading(true)
        await ExaminationDataService.publishExaminationResult(id)
        .then(response => {  
        setLoading(false)                    
        LearnToast(success,"Exam Result Published Successfully")
        fetchExams()
        })
        .catch(error => { 
        let errorMsg=[]
        let msgText=""
        setLoading(false)      
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }
        })

    }

    const publishExam= async (id)=>{
        setLoading(true)
        await ExaminationDataService.publishExamination(id)
        .then(response => {  
        setLoading(false)                    
        LearnToast(success,"Exam Published Successfully")
        fetchExams()
        })
        .catch(error => { 
        let errorMsg=[]
        let msgText=""
        setLoading(false)      
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }
        })

    }



    const serachByText=()=>{
        setisFilterModified(!isfilterModified)
      }


      let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
      let validateOutputScheduleSetting= validateSubmittedSchedule ? validatorSchedule.validate(validationDataSchedule) : validationDataSchedule.validation 
      let validateOutputStudent= validateSubmittedStudent ? validatorStudent.validate(validationDataStudent) : validationDataStudent.validation 
      let validateOutputApplied= validateSubmittedApplied ? validatorApplied.validate(validationDataApplied) : validationDataApplied.validation 

  return (
    <>
    <ToastContainer />    
        {loading?<Loader/>:
        <>
            <Modal show={show} onHide={handleClose} size={modalSize}>
                <Modal.Header closeButton>
                    <Modal.Title>{formTitle}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(formMode=="Create") ?
                        <>

                            <div className="form-row">                                
                                <div className="col">
                                    <label>Exam Title</label>
                                    <input type="text" onChange={event => setExamName(event.target.value)} value={examName} name="examName" id="examName" className={validateOutput.examName.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Exam Name" />
                                    <span className="text-danger">{validateOutput.examName.message}</span>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="col">
                                    <label>Grade</label>
                                    <select className={validateOutput.examGrade.isInvalid ? 'form-control is-invalid':'form-control'} onChange={event => setExamGrade(event.target.value)} value={examGrade} name="examGrade" >
                                        <option value="">--select --</option>
                                        {examGrades.map(item => (
                                        <option
                                            key={item.gradeSchemeId}
                                            value={item.gradeSchemeId}
                                        >
                                            {item.gradeSchemeName}
                                        </option>
                                    ))}
                                    </select>
                                    <span className="text-danger">{validateOutput.examGrade.message}</span>
                                </div>
                                <div className="col">
                                    <label>Maximum Marks</label>
                                    <input type="number" onChange={event => setMaxMarks(event.target.value)} value={maxMarks} name="maxMarks" id="maxMarks" className={validateOutput.maxMarks.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="Maximum Mark" />
                                    <span className="text-danger">{validateOutput.maxMarks.message}</span>
                                </div>
                            </div>                           
                           </> : <></>}   
                           {formMode=="Schedule"?
                           <>
                             <div className="form-row">
                                    <div className="col">
                                        <label>Class Section</label>
                                        <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className='form-control'>
                                            <option value="">-- select class section --</option>
                                            {classes.map(item => (
                                                <option 
                                                key={item.classesId} 
                                                value={item.classesId}
                                                >                                                
                                                    {item.classesName}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label>Subject</label>
                                        <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className={validateOutputScheduleSetting.classSubjectId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                            <option value="">-- select subject --</option>
                                            {allClassSubject.map(item => (
                                                <option
                                                    key={item.classSubjectId}
                                                    value={item.classSubjectId}
                                                >
                                                    {item.subjectName}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutputScheduleSetting.classSubjectId.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Question Paper</label>
                                        <select onChange={event => setQuestionPaperMstId(event.target.value)} value={questionPaperMstId} name="questionPaperMstId" className={validateOutputScheduleSetting.questionPaperMstId.isInvalid ? 'form-control is-invalid':'form-control'}>
                                            <option value="">-- select subject --</option>
                                            {questionPapers.map(item => (
                                                <option
                                                    key={item.comboValue}
                                                    value={item.comboValue}
                                                >
                                                    {item.comboText}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutputScheduleSetting.questionPaperMstId.message}</span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Exam Date</label>
                                        <input type="date" onChange={event => setScheduleDate(event.target.value)} value={scheduleDate} name="scheduleDate" id="scheduleDate" className={validateOutputScheduleSetting.scheduleDate.isInvalid ? 'form-control is-invalid':'form-control'} />
                                        <span className="text-danger">{validateOutputScheduleSetting.scheduleDate.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Exam From Time</label>
                                        <input type="time" onChange={event => setScheduleFromTime(event.target.value)} value={scheduleFromTime} name="scheduleFromTime" id="scheduleFromTime" className={validateOutputScheduleSetting.scheduleFromTime.isInvalid ? 'form-control is-invalid':'form-control'} />
                                        <span className="text-danger">{validateOutputScheduleSetting.scheduleFromTime.message}</span>
                                    </div>
                                      <div className="col">
                                          <label>Exam To Time</label>
                                          <div className="input-group">
                                              <input type="time" onChange={event => setScheduleToTime(event.target.value)} value={scheduleToTime} name="scheduleToTime" id="scheduleToTime" className={validateOutputScheduleSetting.scheduleToTime.isInvalid ? 'form-control is-invalid' : 'form-control'} />
                                              <div className="input-group-append">
                                                    <button className="btn btn-primary" onClick={() => saveScheduleSetting()} type="submit">
                                                      Save
                                                    </button>
                                              </div>
                                          </div>
                                          <span className="text-danger">{validateOutputScheduleSetting.scheduleToTime.message}</span>
                                      </div>
                                </div>
                                <br/>
                                {schedules.length>0?
                                    <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Class</th>
                                            <th scope="col">Subject</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Time</th>
                                            <th scope="col">Max mark</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {schedules.map((item,index)=>(
                                            <tr key={index}>
                                                <td>{item.classesName}</td>
                                                <td>{item.subjectName}</td>
                                                <td>{item.scheduleDate}</td>
                                                <td>{item.fromTime+" - "+item.toTime}</td>
                                                <td>{item.maxMarks}</td>
                                                <td className="text-right">
                                                    <button className="btn btn-link" onClick={()=>deleteSchedule(item.examScheduleId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}                                    
                                    </tbody>
                                </table>
                                 :
                                    <div className="alert alert-info alert-dismissible">
                                        <div className="text-center text-white"><h4>No Records Found</h4></div>
                                    </div>
                                }
                          </>:<></>}    
                           {(formMode=="studentSetting")?
                           <>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Schedule</label>
                                        <select onChange={event => setExamScheduleId(event.target.value)} value={examScheduleId} name="examScheduleId" className='form-control'>
                                            <option value="">-- select schedule --</option>
                                            {scheduleCombo.map(item => (
                                                <option 
                                                key={item.comboValue} 
                                                value={item.comboValue}
                                                >                                                
                                                    {item.comboText}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutputStudent.examScheduleId.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Class Section</label>
                                        <select onChange={event => setClassSectionId(event.target.value)} value={classSectionId} name="classSectionId" className='form-control'>
                                            <option value="">-- select class section --</option>
                                            {classSections.map(item => (
                                                <option 
                                                key={item.comboValue} 
                                                value={item.comboValue}
                                                >                                                
                                                    {item.comboText}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutputStudent.classSectionId.message}</span>
                                    </div>
                                    <div className="col">
                                        <label>Applied To</label>
                                        <select onChange={event => setAppliedTo(event.target.value)} value={appliedTo} className="form-control">
                                            <option value="false">All Students</option>
                                            <option value="true">Specific Students</option>
                                        </select>
                                    </div>
                                </div>  
                                {(appliedTo === "true") ? <>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Select Students</label>
                                        <Select
                                            value={selectedStudent}
                                            isMulti
                                            onChange={selectedStudent => setSelectedStudent(selectedStudent)}
                                            options={options}
                                        />
                                        <span className="text-danger">{validateOutputApplied.studentIds.message}</span>
                                    </div>
                                </div></> : <></>}  
                                <br/>    
                                <div className="form-row">
                                    <div className="col"> 
                                        <button className="btn btn-primary" onClick={() => saveStudentSetting()} type="submit">
                                            Save
                                        </button> 
                                    </div>
                                </div>   
                                <br/>
                                <table className="table table-bordered table-hover">
                                    <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Class Section</th>
                                            <th className="text-right">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {studentSettingsList.map((item,index)=>(
                                            <tr key={index}>
                                                <td>{item.studentName}</td>
                                                <td>{item.classSection}</td>
                                                <td className="text-right">
                                                    <button className="btn btn-link" onClick={()=>deleteStudentSetting(item.examStudentId)} data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}                                    
                                    </tbody>
                                </table>
                           </>
                           :<></>} 
                </Modal.Body>
                <Modal.Footer>
                    
                    <Button variant="secondary" onClick={handleClose}>
                            Close
                    </Button>
                    {(formMode=="Create")?
                    <>
                        <Button type="submit" onClick={()=>saveExam()} variant="primary">
                                Save Exam
                        </Button>
                        <Button type="submit" onClick={()=>continueCreateExam()} variant="primary">
                            Save & Continue
                        </Button>  
                    </>:<></>}                  
                   
                </Modal.Footer>
            </Modal>
            <div className="container">
                <div className="jumbotron">
                    <div className="card card-primary">
                        <div className="card-header">
                            <div className="row">
                                <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                    <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Exam</h5>
                                </div>
                                <div className="col-md-6 text-right">
                                    <button className="btn btn-light" style={{ marginBottom: "0.1rem", marginTop: "0.1rem" }} onClick={()=>createExam()}><i className="fas fa-plus"> &nbsp; CREATE</i></button>
                                </div>
                                <br />
                                <br />
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                            <div className="col-md-3">
                            <div className="card card-outline card-primary">
                                <div className="text-center">
                                    <h5 style={{marginTop:"10px"}}>Filter Options</h5>
                                </div>
                                <hr/>
                                <div className="col-md-12">
                                    <div className="input-group">
                                        <div className="input-group input-group-sm">
                                            <input onChange={event => setSearchExam(event.target.value)} value={searchExam} name="searchExam" id="searchExam" className="form-control form-control-navbar" type="text" placeholder="Exam Name" aria-label="Search"/>
                                            <div className="input-group-append">
                                                <button onClick={()=>serachByText()} className="btn btn-primary" type="submit">
                                                    <i className="fas fa-search"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br/>
                            </div>
                            </div>
                          
                                <div className="col-md-9">
                                <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                        {exams.map((item,index)=>(
                                        <div className="card card-outline card-primary" key={item.examId}>
                                            <div className="card-header" id="headingOne">
                                                <i className="d-flex justify-content-between"> 
                                                    <p className="text-muted text-xs"></p>
                                                    <p className="text-muted text-xs"></p>
                                                    <p className="text-justify">Total Mark : {item.maxMarks}</p>
                                                    {/* <button className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                    </button> */}
                                                </i>
                                                <div className="d-flex justify-content-between">
                                                    <h5>{item.examName}</h5> 
                                                    
                                                </div>                                         
                                                
                                                <div className="d-flex justify-content-between">
                                                    {/* <Link className="btn btn-link" to={{pathname: '/app/evaluate-exam/'+item.examId+'/'+item.examName,props:{ id: item.examId}}}>
                                                        <span className="text-warning"><i className="fa fa-id-card"></i> Valuate</span>
                                                    </Link> */}
                                                    
                                                     {item.isPublished && !item.isResultPublished ?
                                                     <>
                                                        <button className="btn btn-link" onClick={()=>publishExamResult(item.examMstId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                <span className="text-success"><i className="fa fa-cog"></i> Publish Result</span>
                                                        </button>
                                                    </>
                                                    :<></>}
                                                    {item.isResultPublished ?
                                                     <>
                                                         <Link className="btn btn-link" to={{pathname: '/app/master-result/'+item.examMstId+'/'+item.examName,props:{ id: item.examMstId}}}>
                                                            <span className="text-success"><i className="fa fa-search"></i> Result View</span>
                                                        </Link>
                                                    </>
                                                    :<></>}
                                                     {!item.isPublished ?
                                                     <>
                                                        <button className="btn btn-link" onClick={()=>publishExam(item.examMstId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                <span className="text-success"><i className="fa fa-cog"></i> Publish</span>
                                                        </button>
                                                        <button className="btn btn-link" onClick={()=>openScheduleSetting(item.examMstId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            <span className="text-danger"><i className="fa fa-cog"></i> Schedule Setting</span>
                                                        </button>
                                                        <button className="btn btn-link" onClick={()=>openStudentSetting(item.examMstId)} type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                            <span className="text-info"><i className="fa fa-cog"></i> Student Setting</span>
                                                        </button>
                                                    </>
                                                    :<></>}
                                                    <button onClick={()=>deleteExam(item.examMstId)} className="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                        <span className="text-danger"><i className="fa fa-trash"></i> </span>
                                                    </button>

                                                </div>
                                            </div>
                                        </div>
                                    
                                        ))}
                                        <div className="row">
                                            <div className="col-md-12">
                                                <LearnPagination page={activePage} setPage={setActivePage} totalPages={totalPages} pageSize={10} setParams={setParams} />
                                            </div>
                                        </div>
                                </div>
                                
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        }
    </>
  )
}

export default Examination
