import React from 'react'
import { Pagination } from 'react-bootstrap'

export default function LearnPagination({page, setPage, totalPages, pageSize, setParams}) {
    function adjustPage(amount) {
        setPage(amount)
        const isPaginateParam = 'isPaginate'
        const isPaginateValue = 'true'
        const pageSizeParam = 'pageSize'
        const pageSizeValue = pageSize
        const pageIndexParam = 'pageIndex'
        const pageIndexValue = amount
        setParams(prevParams => {
            return { ...prevParams, [isPaginateParam]: isPaginateValue, [pageSizeParam]: pageSizeValue, [pageIndexParam]: pageIndexValue }
          })
    }

    let items = []
    if(page !== 1)
    {
        items.push(<Pagination.First key={totalPages+2} onClick={() => adjustPage(1)} ></Pagination.First>)
        items.push(<Pagination.Prev key={0} onClick={() => adjustPage(page - 1)} ></Pagination.Prev>)
    }

    if(totalPages > 5)
    {
        let pageNeighbours = 2
        let from = page > 1? page - 1 : 1
        let to = page === totalPages ? totalPages : page + 1

        for(let idx = from; idx <= to; idx++)
        {
            items.push(
                <Pagination.Item key={idx} active={idx === page} onClick={() => adjustPage(idx)}>{idx}</Pagination.Item>
            )
        }
    }
    else
    {
        for(let idx = 1; idx <= totalPages; idx++)
        {
            items.push(
                <Pagination.Item key={idx} active={idx === page} onClick={() => adjustPage(idx)}>{idx}</Pagination.Item>
            )
        }
    }

    if(page !== totalPages && totalPages > 1)
    {
        items.push(<Pagination.Next key={totalPages+1} onClick={() => adjustPage(page+1)} ></Pagination.Next>)
        items.push(<Pagination.Last key={totalPages+3} onClick={() => adjustPage(totalPages)} ></Pagination.Last>)
    }

    return (
    <div className="d-flex justify-content-between">
        {totalPages > 0 && <div>Page {page} of {totalPages}</div>}
        <Pagination>
            {items}
        </Pagination>
    </div>
    )
}