import React, { useState, useEffect } from 'react'
import StudentAttendanceDataservice from "../../../services/StudentAttendanceDataservice"

const LiveAttendanceList = (props) => {
    const [students, setStudents]= useState([])
    // const [scheduleID, setScheduleID] = useState(0)

    useEffect(() => {
        setStudents(props.students)
        // setScheduleID(props.scheduleID)       
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            intiateAttendance()
        }, 1000);
        return () => clearInterval(interval);
      }, []);


    const intiateAttendance = async () =>{  
        await StudentAttendanceDataservice.intiateAttendanceEntry(props.scheduleID) 
        .then(response => {
            setStudents(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })  
       
   
    }

  return (
    <table className="table table-bordered table-hover">
        <thead>
            <tr>
                <th scope="col">#</th>
                <th scope="col">Name</th>
                <th className="text-right"></th>
            </tr>
        </thead>
        <tbody>  
            {students.map((item, index)=>(   
                <tr>
                <th scope="row">{item.sl}</th>
                <td>{item.studentName}</td>
                <td className="text-right">
                    {item.isPresent?
                        <span class="info-box-icon text-success"><i class="fa fa-check"></i></span>
                    : <span class="info-box-icon text-danger"><i class="fa fa-times"></i> </span>}
                </td>
            </tr>                       
                
            ))}
        </tbody>
    </table>
  )
}

export default LiveAttendanceList
