import React, { useState, useEffect } from 'react'
import { ButtonGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import QuestionBankDataService from "../../../services/QuestionBankDataService"
import TeacherAssociationDataService from "../../../services/TeacherAssociationDataService"
import ValidationRule from './ValidationRule'
import ListQuestion from './ListQuestion'
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';

let choices=[]

const QuestionBank = () => {

    const [isOneWord, setIsOneWord]= useState(false)
    const [isMultiChocie, setIsMultiChocie]= useState(false)
    const [isShortAnswer, setIsShortAnswer]= useState(false)
    const [choice, setChoice] =useState("")
    const [multiChoices, setMultiChoices]= useState([])
    const [isAddChoice, setIsAddChoice]= useState(false)
    const [isChoiceValid, setIsChoiceValid ] = useState(false)    
    const [questionView, setQuestionView ] = useState(true)
    const [choiceValidationText, setChoiceValidationText] =useState("")
    const [validateOptionText, setValidateOptionText] =useState("")
    const [classesId,setClassesId] = useState(0)
    const [classSubjectId,setClassSubjectId]= useState("")
    const [classes,setClasses] =useState([])
    const [allClassSubject, setAllClassSubject]= useState([]);
    const [question,setQuestion]=useState("")
    const [oneWordAns, setOneWordAns]=useState("")
    const [shortAnswer,setShortAnswer]=useState("")
    const [submitted,setSubmitted]=useState(false);

    const validatorOneWord= ValidationRule.OneWordForm();
    const validatorShortAnswer= ValidationRule.ShorAnswerForm();
    const validatorMultipleChoice= ValidationRule.MultipleChoiceForm();

    const [validationOneWord, setValidationOneWord] = useState(validatorOneWord.valid());
    const [validateSubmittedOneWord, setValidateSubmittedOneWord]=useState(false)

    const [validationShortAnswer, setValidationShortAnswer] = useState(validatorShortAnswer.valid());
    const [validateSubmittedShortAnswer, setValidateSubmittedShortAnswer]=useState(false)

    const [validationMultipleChoice, setValidationMultipleChoice] = useState(validatorMultipleChoice.valid());
    const [validateSubmittedMultipleChoice, setValidateSubmittedMultipleChoice]=useState(false)
    
   var oneWordValidationData={
        classSubjectId:classSubjectId,
        question:question,
        oneWordAns:oneWordAns,    
        validation:validationOneWord
    }

    var shortAnswerValidationData={
        classSubjectId:classSubjectId,
        question:question,   
        shortAnswer:shortAnswer, 
        validation:validationShortAnswer
    }

    var multipleChoiceValidationData={
        classSubjectId:classSubjectId,
        question:question,
        validation:validationShortAnswer
    }

    const clickOneWord = () => {
        setQuestionView(false)     
        setIsMultiChocie(false)
        setIsShortAnswer(false)        
        setIsOneWord(true);
    }

    const setOneWordQuestion = (data)=>
    {
        //setQuestion(editor.data)
    }

    const saveOneWord = async () => {

        var data={  
            questionId: 0,
            classSubjectId:parseInt(classSubjectId),
            questionText: question,
            answerText: oneWordAns
        }
        const validate = validatorOneWord.validate(oneWordValidationData)
        setValidationOneWord({ validate });
        setValidateSubmittedOneWord(true)
        if (validate.isValid) {
            await QuestionBankDataService.createOneword(data)
                .then(response => {
                })
                .catch(error => {
                    const errorMsg = error.message;
                })
            setSubmitted(!submitted)
            resetForm()
            closeWindow()
        }
        
    }

    const clickMultipleChoice = () => {    
        setQuestionView(false)      
        setIsOneWord(false);
        setIsShortAnswer(false)
        setIsMultiChocie(true)
    }

    const clickShortAnswer = () => {
        setQuestionView(false)   
        setIsOneWord(false);
        setIsMultiChocie(false)
        setIsShortAnswer(true)
    }


    
    const saveShorAnswer = async () => {

        var data={  
            questionId: 0,
            classSubjectId:parseInt(classSubjectId),
            questionText: question,
            answerText:shortAnswer
        }
        const validate = validatorShortAnswer.validate(shortAnswerValidationData)
        setValidationShortAnswer({ validate });
        setValidateSubmittedShortAnswer(true)
        if (validate.isValid) {
        await QuestionBankDataService.createParagrahQuestion(data)
                            .then(response => {
                            })
                            .catch(error => {
                                const errorMsg = error.message;
                            })
        setSubmitted(!submitted)
        resetForm()
        closeWindow()
        }
    }

    const addChoices = () => {  
        if(choice=="")
        {
            setValidateOptionText("Please type a choice")
        }
        else{
            setValidateOptionText("");
            setChoiceValidationText("")
            choices.push({answerOptionId:0,answerText:choice,isCorrectAnswer:false})
            setIsAddChoice(!isAddChoice)
        }
    }

    const addAnswer = () => {  
        if(choice=="")
        {
            setValidateOptionText("Please type a choice")
        }
        else{
            setValidateOptionText("");
            choices.map((chice,index)=>(
                    choices[index].isCorrectAnswer=false
            ));         
            setChoiceValidationText("")
            choices.push({answerOptionId:0,answerText:choice,isCorrectAnswer:true})
            setIsAddChoice(!isAddChoice)
        }
    }

    const closeWindow = () => {        
       setIsOneWord(false)
       setIsMultiChocie(false)
       setIsShortAnswer(false)
       setQuestionView(true)
    }

    const removeChoice = (index) => {        
        choices.splice(index,1)      
        setIsAddChoice(!isAddChoice)
    }

    const submitMultiChoice = async () => {
        var valid=validateChoice()
        const validate = validatorMultipleChoice.validate(multipleChoiceValidationData)
        setValidationMultipleChoice({ validate });
        setValidateSubmittedMultipleChoice(true)
        if (validate.isValid) {
        if (parseInt(valid) == 1) {
            var data = {
                questionId: 0,
                classSubjectId: parseInt(classSubjectId),
                questionText: question,
                answerOptions: multiChoices
            }
            console.log(data)
            await QuestionBankDataService.createMultipleChocie(data)
                .then(response => {
                    console.log(response);
                })
                .catch(error => {
                    console.log(error)
                    const errorMsg = error.message;
                })
            setSubmitted(!submitted)
            resetForm()
            closeWindow()
        }
    }

    }

    const validateChoice = () => {   
        setIsChoiceValid(true)
        setChoiceValidationText("")
        let valid=0
        if(multiChoices.length>0)
        {
            multiChoices.map((item,index)=>(
                item.isCorrectAnswer?(valid=valid+1):(valid=valid+0)
            ));
            if(parseInt(valid)==0)
            {
                setIsChoiceValid(false)
                setChoiceValidationText("Option does not contains right answer")
            }
        }
        else
        {
            setIsChoiceValid(false)
            setChoiceValidationText("Please Add Answer Choices")
        }        
        return valid
    }

    const resetForm=()=>{
        choices=[]
        setClassesId("")
        setClasses([])
        setClassSubjectId("")
        setAllClassSubject([])
        setQuestion("")
        setOneWordAns("")
        setShortAnswer("")        
        setChoice("")
        setMultiChoices([])
        setValidationOneWord(validatorOneWord.valid())
        setValidationMultipleChoice(validatorMultipleChoice.valid())
        setValidationShortAnswer(validatorShortAnswer.valid())
        setValidateSubmittedOneWord(false)
        setValidateSubmittedShortAnswer(false)
        setValidationMultipleChoice(false)
    }


    useEffect(() =>{
        setChoice("")
        setMultiChoices(choices)
    },[isAddChoice]) 

    useEffect(() =>{
        fetchTeacherCurrentClasses()
    },[submitted]) 

    useEffect(() =>{
        fetchTeacherCurrentSubjects()
    },[classesId]) 

    const fetchTeacherCurrentClasses = async () =>{
        await TeacherAssociationDataService.getTeacherCurrentClasses() 
        .then(response => {
            console.log(response.data.result);
            setClasses(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }

    const fetchTeacherCurrentSubjects = async () =>{
        await TeacherAssociationDataService.getTeacherCurrentSubjects(parseInt(classesId)) 
        .then(response => {
            console.log(response.data.result);
            setAllClassSubject(response.data.result)
      })
       .catch(error => {
          const errorMsg=error.message;            
      })
    }
 

   
    let validateOutputOneWord= validateSubmittedOneWord ? validatorOneWord.validate(oneWordValidationData) : oneWordValidationData.validation 
    let validateOutputShortAnswer= validateSubmittedShortAnswer ? validatorShortAnswer.validate(shortAnswerValidationData) : shortAnswerValidationData.validation 
    let validateOutputMultipleChoice= validateSubmittedMultipleChoice ? validatorMultipleChoice.validate(multipleChoiceValidationData) : multipleChoiceValidationData.validation 

    return (
        <div className="container">
            <div className="jumbotron">
                <div className="card card-primary">
                    <div className="card-header">
                        <div className="row">
                            <div className="col-md-6" style={{ verticalAlign: "middle" }}>
                                <h5 style={{ marginBottom: "0.4rem", marginTop: "0.4rem" }}>Question Bank </h5>
                            </div>
                            <div className="col-md-6 text-right">
                                {/* <button className="btn btn-light" style={{marginBottom:"0.1rem",marginTop:"0.1rem"}}><i className="fas fa-plus"> &nbsp; CREATE</i></button> */}
                                <ButtonGroup>
                                    <DropdownButton variant="light" style={{color:"black"}} title="CREATE" id="bg-nested-dropdown">
                                        <Dropdown.Item onClick={clickOneWord} style={{color:"black"}}>One Word Question</Dropdown.Item>
                                        <Dropdown.Item onClick={clickMultipleChoice} style={{color:"black"}}>Multiple Choice Question</Dropdown.Item>
                                        <Dropdown.Item onClick={clickShortAnswer} style={{color:"black"}}>Paragraph Question</Dropdown.Item>
                                        <Dropdown.Item onClick={clickShortAnswer} style={{color:"black"}}>Match Case</Dropdown.Item>
                                        <Dropdown.Item onClick={clickShortAnswer} style={{color:"black"}}>Passage</Dropdown.Item>
                                        <Dropdown.Item onClick={clickShortAnswer} style={{color:"black"}}>Essay</Dropdown.Item>
                                    </DropdownButton>
                                </ButtonGroup>
                            </div>
                            <br />
                            <br />
                        </div>
                    </div>
                    <div className="card-body">

                        {questionView?
                        (<ListQuestion changed={submitted}/>)
                        :(<></>)
                        }

                        {isOneWord?
                        <div className="col-md-6 offset-md-3 card">
                                <div className="form-row">
                                    <div className="col">
                                        <label>Class</label>
                                        <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className="form-control">
                                        <option value="">-- select class --</option>
                                        {classes.map(item => (
                                            <option
                                                key={item.classesId}
                                                value={item.classesId}
                                            >
                                                {item.classesName}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label>Subject</label>
                                        <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className="form-control">
                                            <option value="">-- select subject --</option>
                                            {allClassSubject.map(item => (
                                                <option
                                                    key={item.classSubjectId}
                                                    value={item.classSubjectId}
                                                >
                                                    {item.subjectName}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutputOneWord.classSubjectId.message}</span>
                                    </div>
                                </div> 
                                <div className="form-row">
                                    <div className="col">
                                        <label>Question?</label>
                                        <CKEditor
                                            onInit={ editor => {
                                                console.log( 'Editor is ready to use!', editor );

                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement()
                                                );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                console.log( { event, editor, data } );
                                                setQuestion(data);
                                            }}
                                            editor={ DecoupledEditor }
                                            data=""
                                            config={{
                                                ckfinder:{uploadUrl: "http://cla.campuslte.com/api/fileupload/editorupload"}
                                            }}
                                        />
                                        {/* <input type="text" onChange={event => setQuestion(event.target.value)} value={question} name="question" id="question" className="form-control"/> */}
                                        <span className="text-danger">{validateOutputOneWord.question.message}</span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Answer</label>
                                        <input type="text" onChange={event => setOneWordAns(event.target.value)} value={oneWordAns} name="oneWordAns" id="oneWordAns" className="form-control"/>
                                    <span className="text-danger">{validateOutputOneWord.oneWordAns.message}</span>
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group text-right">
                                    <button onClick={closeWindow} className="btn btn-light">close</button>&nbsp;&nbsp;&nbsp;
                                    <button  onClick={saveOneWord} className="btn btn-primary">Save</button>
                                </div>
                        </div>
                        :<></>}

                        {isMultiChocie?
                        <div className="col-md-6 offset-md-3 card">
                                <div className="form-row">
                                <div className="col">
                                        <label>Class</label>
                                        <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className="form-control">
                                        <option value="">-- select class --</option>
                                        {classes.map(item => (
                                            <option
                                                key={item.classesId}
                                                value={item.classesId}
                                            >
                                                {item.classesName}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label>Subject</label>
                                        <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className="form-control">
                                            <option value="">-- select subject --</option>
                                            {allClassSubject.map(item => (
                                                <option
                                                    key={item.classSubjectId}
                                                    value={item.classSubjectId}
                                                >
                                                    {item.subjectName}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutputMultipleChoice.classSubjectId.message}</span>
                                    </div>
                                </div> 
                                <div className="form-row">
                                    <div className="col">
                                        <label>Question?</label>
                                        <CKEditor
                                            onInit={ editor => {
                                                console.log( 'Editor is ready to use!', editor );

                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement()
                                                );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                console.log( { event, editor, data } );
                                                setQuestion(data);
                                            }}
                                            editor={ DecoupledEditor }
                                            data=""
                                            config={{
                                                ckfinder:{uploadUrl: "http://cla.campuslte.com/api/fileupload/editorupload"}
                                            }}
                                        />
                                        {/* <input type="text" onChange={event => setQuestion(event.target.value)} value={question} name="question" id="question" className="form-control"/> */}
                                        <span className="text-danger">{validateOutputMultipleChoice.question.message}</span>
                                    </div>
                                </div>                                
                                <div className="form-row">
                                    <div className="col">
                                        <label>Option</label>
                                        <div className="input-group">
                                            <input type="text" style={{height:"2.16rem",borderRadius:"0",marginTop:".03rem"}} onChange={event => setChoice(event.target.value)} value={choice} name="choice" id="choice" className="form-control" placeholder="Type Choice" />
                                            <span className="input-group-append">
                                            <ButtonGroup>
                                                <DropdownButton variant="primary btn-flat" style={{color:"black"}} title="Add" id="bg-nested-dropdown">
                                                    <Dropdown.Item onClick={addChoices} style={{color:"black"}}>Choice</Dropdown.Item>
                                                    <Dropdown.Item onClick={addAnswer} style={{color:"black"}}>Right Choice</Dropdown.Item>
                                                </DropdownButton>
                                            </ButtonGroup>
                                                {/* <button type="button" onClick={addChoices} className="btn btn-primary">Add</button> */}
                                            </span>
                                        </div>
                                        <span className="text-danger">{validateOptionText}</span>
                                        <span className="text-danger">{choiceValidationText}</span>
                                    </div>
                                    </div>
                                    <div className="form-row">
                                    <div className="col">
                                        <label>Answer Choices</label>
                                        <ul class="list-group">
                                            {multiChoices.map((item, index) => (
                                                <li key={index} class="list-group-item" style={{padding:"0 .5rem"}}>
                                                    <div className="d-flex justify-content-between">
                                                        <div className={item.isCorrectAnswer?("text-success"):("")}>
                                                            <h6 style={{lineHeight:"2"}}>{item.answerText}</h6>
                                                        </div>
                                                        <div>
                                                            <button type="button" onClick={() => removeChoice(index)} class="btn btn-link" data-toggle="tooltip" data-placement="top" title="Delete">
                                                                <span className="text-danger"><i className="fa fa-trash"></i></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </li>                                                    
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group text-right">
                                    <button onClick={closeWindow} className="btn btn-light">close</button>&nbsp;&nbsp;&nbsp;
                                    <button onClick={submitMultiChoice} className="btn btn-primary">Save</button>
                                </div>
                        </div>
                        :<></>}

                        {isShortAnswer?
                        <div className="col-md-6 offset-md-3 card">
                                <div className="form-row">
                                <div className="col">
                                        <label>Class</label>
                                        <select onChange={event => setClassesId(event.target.value)} value={classesId} name="classesId" className="form-control">
                                        <option value="">-- select class --</option>
                                        {classes.map(item => (
                                            <option
                                                key={item.classesId}
                                                value={item.classesId}
                                            >
                                                {item.classesName}
                                            </option>
                                        ))}
                                        </select>
                                    </div>
                                    <div className="col">
                                        <label>Subject</label>
                                        <select onChange={event => setClassSubjectId(event.target.value)} value={classSubjectId} name="classSubjectId" className="form-control">
                                            <option value="">-- select subject --</option>
                                            {allClassSubject.map(item => (
                                                <option
                                                    key={item.classSubjectId}
                                                    value={item.classSubjectId}
                                                >
                                                    {item.subjectName}
                                                </option>
                                            ))}
                                        </select>
                                        <span className="text-danger">{validateOutputShortAnswer.classSubjectId.message}</span>
                                    </div>
                                </div> 
                                <div className="form-row">
                                    <div className="col">
                                        <label>Question?</label>
                                        <CKEditor
                                            onInit={ editor => {
                                                console.log( 'Editor is ready to use!', editor );

                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement()
                                                );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                console.log( { event, editor, data } );
                                                setQuestion(data);
                                            }}
                                            editor={ DecoupledEditor }
                                            data=""
                                            config={{
                                                ckfinder:{uploadUrl: "http://cla.campuslte.com/api/fileupload/editorupload"}
                                            }}
                                        />
                                        {/* <input type="text"  onChange={event => setQuestion(event.target.value)} value={question} name="question" id="question" className="form-control"/> */}
                                        <span className="text-danger">{validateOutputShortAnswer.question.message}</span>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="col">
                                        <label>Answer</label>
                                        <CKEditor
                                            onInit={ editor => {
                                                console.log( 'Editor is ready to use!', editor );

                                                // Insert the toolbar before the editable area.
                                                editor.ui.getEditableElement().parentElement.insertBefore(
                                                    editor.ui.view.toolbar.element,
                                                    editor.ui.getEditableElement()
                                                );
                                            } }
                                            onChange={ ( event, editor ) => {
                                                const data = editor.getData();
                                                console.log( { event, editor, data } );
                                                setShortAnswer(data);
                                            }}
                                            editor={ DecoupledEditor }
                                            data=""
                                            config={{
                                                ckfinder:{uploadUrl: "http://cla.campuslte.com/api/fileupload/editorupload"}
                                            }}
                                        />
                                        {/* <textarea  onChange={event => setShortAnswer(event.target.value)} value={shortAnswer} name="shortAnswer" id="shortAnswer"  className="form-control" placeholder="Place some text here"
                                        style={{
                                            width: "100%", height: "200px", fontSize: "14px", lineHeight: "18px",
                                            border: "1px solid #dddddd", padding: "10px"
                                        }} /> */}
                                        <span className="text-danger">{validateOutputShortAnswer.shortAnswer.message}</span>
                                    </div>
                                </div>
                                <br/>
                                <div className="form-group text-right">
                                    <button onClick={closeWindow} className="btn btn-light">close</button>&nbsp;&nbsp;&nbsp;
                                    <button onClick={saveShorAnswer} className="btn btn-primary">Save</button> 
                                </div>
                        </div>
                        :<></>}


                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuestionBank
