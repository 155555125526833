import React from 'react'

const StudentQandASession = () => {
  return (
    <div>
      <p>Q and A</p>
    </div>
  )
}

export default StudentQandASession
