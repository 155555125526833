import  ApiDataService  from './ApiDataService'

const createOptonalSubjectStudent = data => {
  return ApiDataService.post("optionalsubjectstudent", data);
};
  
const findAssociaedSubjectStudent = (optionalSubjectBatchId) => {
  let data={"optionalSubjectBatchId":optionalSubjectBatchId}
  return ApiDataService.getWithParam("optionalsubjectstudent/associatedstudents",data);
};

const getOptonalSubjectEntryStudents = (optionalSubjectBatchId,classSectionId) => {
  let data={"optionalSubjectBatchId":optionalSubjectBatchId,"classSectionId":classSectionId}
  return ApiDataService.getWithParam("optionalsubjectstudent/entrystudents",data);
};

const deleteOptonalSubjectStudent = id => {
  let data={"id":id}
  return ApiDataService.remove("optionalsubjectstudent", data);
};


export default {createOptonalSubjectStudent,
                findAssociaedSubjectStudent,
                getOptonalSubjectEntryStudents,
                deleteOptonalSubjectStudent};