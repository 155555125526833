import React, { useState } from 'react'
import ValidationRule from './ValidationRule'
import UserDataService from "../../../services/UserDataService"
import { Toast } from 'react-bootstrap'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success,warning,danger,info}from "../../ui/alert/toast/ToastType"
import {LearnToast} from "../../ui/alert/toast/LearnToast"

const ChangePassword = () => {
const passwordMatch = (confirmation, state) => (password === confirmation)
const validator= ValidationRule.ChangePasswordForm(passwordMatch);

const [showMessage, setShowMessage] = useState(false);
const [currentPassword, setCurrentPassword] = useState("")
const [password ,setPassword]=useState('');
const [confirmpassword ,setConfirmPassword]=useState('');
const [validation, setValidation] = useState(validator.valid());
const [validateSubmitted, setValidateSubmitted]=useState(false)    

var validationData={
    currentPassword:currentPassword,
    password:password,
    confirmpassword:confirmpassword,
    validation:validation
}

const closeToast = () =>{
    setShowMessage(false)
}

const savePassword = async () =>{
    setShowMessage(false)
    let data={
        currentPassword:currentPassword,
        newPassword:password
    }
    const validate = validator.validate(validationData)
    setValidation({ validate });
    setValidateSubmitted(true)
    if (validate.isValid) {
        await UserDataService.changePassword(data) 
        .then(response => {
            resetForm()
            setShowMessage(true)
            LearnToast(success,"Password Changed Successfully")
       })
       .catch(error => {
        let errorMsg=[]
        let msgText=""    
        if(error.response.status==500)
        {
            LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
        }
        else
        {
            errorMsg=error.response.data.errors
            errorMsg.map((item,index)=>{
                if(msgText.length>0)
                {
                msgText = msgText+","
                }
                msgText=msgText+item
            })            
                LearnToast(danger,msgText)
        
        }           
       })
    }
}

const resetForm = () =>{
    setCurrentPassword("")
    setPassword("")
    setConfirmPassword("")    
    setValidation(validator.valid());
    setValidateSubmitted(false);
}



let validateOutput= validateSubmitted ? validator.validate(validationData) : validationData.validation 
  return (
      <>
      <ToastContainer/>
    <Toast show={showMessage} onClose={closeToast}>
    <Toast.Header>                   
        <strong className="mr-auto">Message</strong>
    </Toast.Header>
    <Toast.Body><p className="text-success">Password Changed Successfully</p></Toast.Body>
</Toast>
    <div className="col-md-6">
        <div className="form-group">
            <label htmlFor="cpassword" className="col-form-label">Current Password</label>
            <input type="password"  onChange={event => setCurrentPassword(event.target.value)} value={currentPassword} name="currentPassword" id="currentPassword" className={validateOutput.currentPassword.isInvalid ? 'form-control is-invalid':'form-control'}placeholder="Current Password" />
            <span className="text-danger">{validateOutput.currentPassword.message}</span>
        </div>
        <div className="form-group">
            <label htmlFor="password" className="col-form-label">New Password</label>
            <input type="password" onChange={event => setPassword(event.target.value)} value={password} name="password" id="password" className={validateOutput.password.isInvalid ? 'form-control is-invalid':'form-control'} placeholder="New Password" />
            <span className="text-danger">{validateOutput.password.message}</span>
        </div>
        <div className="form-group">
            <label htmlFor="conpassword" className="col-form-label">Confirm Password</label>
            <input type="password" onChange={event => setConfirmPassword(event.target.value)} value={confirmpassword} name="confirmpassword" id="confirmpassword" className={validateOutput.confirmpassword.isInvalid ? 'form-control is-invalid':'form-control'}placeholder="Confirm Password" />
            <span className="text-danger">{validateOutput.confirmpassword.message}</span>
        </div>
        <div className="form-group">
            <button type="submit" onClick={()=>savePassword()} className="btn btn-primary">Change</button>
        </div>
    </div>
    </>
  )
}

export default ChangePassword
