import React, { useState, useEffect } from 'react'
import StudentAttendanceDataservice from "../../../services/StudentAttendanceDataservice"
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { success, warning, danger, info } from "../../ui/alert/toast/ToastType"
import { LearnToast } from "../../ui/alert/toast/LearnToast"
import Loader from '../../ui/loader/Loader'

const AttendanceEntry = (props) => {
    const [student, setStudent]= useState({})
    const [checkAttendance, setChechAttendance] = useState(false)
    const [slNo, setSlNo]  = useState(0)
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setStudent(props.student)
        setChechAttendance(props.student.isPresent)
        setSlNo(props.student.sl)
    }, [])

    const handleAttendenceClick = async () => 
    {        
        let serialNo=slNo     
        // setChechAttendance(!checkAttendance)
        var data={
            studentAttendanceId:student.studentAttendanceId,
            isPresent:!checkAttendance,
            isLate:false,
            isAdjusted:false,
            remarks:""
        }
        setLoading(true)
        await StudentAttendanceDataservice.saveStudentAttendance(data) 
        .then(response => {
            setStudent(response.data.result)
            setChechAttendance(response.data.result.isPresent)
            setLoading(false)
            response.data.result.isPresent?            
            LearnToast(success, response.data.result.studentName+" - Present")
            :            
            LearnToast(warning, response.data.result.studentName+" - Absent")
            setSlNo(serialNo)
            
        })
        .catch(error => {
           
            let errorMsg=[]
            let msgText=""
            setLoading(false)      
            if(error.response.status==500)
            {
                LearnToast(danger,"Sorry, Something Went Wrong. Please Check Your Data and Try Again")
            }
            else
            {
                errorMsg=error.response.data.errors
                errorMsg.map((item,index)=>{
                    if(msgText.length>0)
                    {
                    msgText = msgText+","
                    }
                    msgText=msgText+item
                })            
                    LearnToast(danger,msgText)
            
            }               
        })

    }

  return (
      <>
    <ToastContainer />
    {loading?<Loader/>
    :
    <tr>
        <th scope="row">{slNo}</th>
        <td>{student.studentName}</td>
        <td className="text-right">
            <input type="checkbox" onChange={handleAttendenceClick} checked={checkAttendance} id="checkAttendance" className="form-check-input" />                           
        </td>
    </tr>
    }
    </>
  )
}

export default AttendanceEntry
